var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('el-table',{staticClass:"striped-table",attrs:{"data":_vm.data}},[_vm._l((_vm.columns),function(column,index){return _c('el-table-column',_vm._b({key:index,attrs:{"align":"center"}},'el-table-column',column,false))}),_c('el-table-column',{attrs:{"label":"registered at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.created_at ? _vm.dateFormat(row.created_at) : "--")+" ")]}}])}),_c('el-table-column',{attrs:{"label":"last reported at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.created_at ? _vm.dateFormat(row.last_reported_at) : "--")+" ")]}}])}),_c('el-table-column',{attrs:{"fixed":_vm.$rtl.isRTL ? false : 'right',"label":_vm.$tc('report.action', 2),"align":"center","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"row justify-content-between m-0"},[_c('div',{staticClass:"col-12 mt-1 d-flex justify-content-center align-items-center"},[_c('router-link',{attrs:{"to":{ path: "/forklift/report/2",}}},[_c('base-button',{staticClass:"mr-2 px-0",attrs:{"type":"primary"}},[_vm._v(" Create Service Request ")])],1),_c('router-link',{attrs:{"to":{
              path: ("/forklift/device/" + (row.id))
            }}},[_c('base-button',{staticClass:"mx-2 px-2 py-1",attrs:{"simple":"","size":"sm","type":"secondary","round":""}},[_c('i',{staticClass:"fa fa-info"})])],1)],1)])}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }