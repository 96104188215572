<template>
  <div class="row justify-content-center">
    <div class="col-md-11">
      <Wizard ref="test" @finish="onFinish">
        <template slot="header">
          <h3 class="card-title d-none">Register New Structure</h3>
        </template>
        <WizardTab>
          <template slot="label">
            <i class="tim-icons icon-bank"></i>
            <p>Type</p>
          </template>

          <div class="row justify-content-center">
            <div class="col-md-4 col-6">
              <base-select v-model="StructureType" placeholder="test" :options="options"></base-select>
            </div>
          </div>
        </WizardTab>
        <WizardTab :before-change="() => validateStep('step1')">
          <template slot="label">
            <i class="tim-icons icon-notes"></i>
            <p>Information</p>
          </template>
          <Step1 ref="step1" :structure_type="StructureType"></Step1>
        </WizardTab>
        <WizardTab :before-change="() => validateStep('step2')">
          <template slot="label">
            <i class="tim-icons icon-align-center"></i>
            <p>Floors</p>
          </template>
          <Step2 ref="step2"></Step2>
        </WizardTab>
      </Wizard>
    </div>
  </div>
</template>

<script>
import Wizard from "@/components/Wizard/Wizard";
import WizardTab from "@/components/Wizard/WizardTab";
import Step1 from "./Creation-Steps/StructureInformation";
import Step2 from "./Creation-Steps/StructureFloors";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      
    };
  },
  computed: {
    options(){
      return this.$store.getters.structure_types.map(type => ({
        value: type.id,
        label: type.name
      }))
    },
    ...mapGetters({
      s_type: "s_type",
      isVilla: "isVilla"
    }),
    StructureType:{
        get(){
            return this.s_type;
        },
        set(s_t){
            this.$store.commit('setStructureType',s_t)
            return s_t;
        }
    }
  },
  methods: {
    onFinish(){
      this.$store.dispatch('submitStructure');
    },
    validateStep(ref) {
      return this.$refs[ref].validate();
     // return true
    }
  },
  components: {
    Wizard: Wizard,
    WizardTab: WizardTab,
    Step1,
    Step2
  },
  mounted(){
    console.log(this.$refs)
  },
  destroyed() {
    this.$store.commit("reset_structure_store");
  }
};
</script>

<style>
</style>