<template>
  <form>
    <card footer-classes="text-right">

        
      <div slot="header">
        <h4 class="card-title">{{$t("Create")}} {{$t("Teltonika Template")}}</h4>
      </div>
      <div>
        <!--  -->
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">
                <base-input :placeholder="$t('Name')" v-model="$v.name.$model">
                  <template v-if="$v.name.$error" v-slot:error>
                    <label class="error" v-if="!$v.name.required">{{$t("requireds.name")}}</label>
                  </template>
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input :placeholder="$t('vendor')" v-model="$v.vendor.$model">
                  <template v-if="$v.vendor.$error" v-slot:error>
                    <label class="error" v-if="!$v.vendor.required">{{$t("requireds.vendor")}}</label>
                  </template>
                </base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">
                <base-select v-model="$v.solution.$model" :options="solutions">
                  <template v-if="$v.solution.$error" v-slot:error>
                    <label class="error" v-if="!$v.solution.required">{{$t("requireds.Solution")}}</label>
                  </template>
                </base-select>
              </div>
              <div class="col-md-6">
                <base-select v-model="$v.communication.$model" :options="communications">
                  <template v-if="$v.communication.$error" v-slot:error>
                    <label
                      class="error"
                      v-if="!$v.communication.required"
                    >{{$t("requireds.Communication")}}</label>
                  </template>
                </base-select>
              </div>
            </div>
          </div>
        </div>
        <!--  -->




        <!--  -->
      </div>
      <template slot="footer" class="text-right">
        <base-button native-type="submit" @click.prevent="submit" type="primary">{{$t("Register")}}</base-button>
      </template>
    </card>
  </form>
</template>
<script>
import {
  required
  //sameAs,
  // minLength
  //email
  //numeric
} from "vuelidate/lib/validators";

import BaseAutoComplete from "@/components/BaseAutoComplete.vue";
// import moment from "moment";

var notempty = data => data.length > 0;

export default {
  props:{
    category_id :[String,Number]
  },
  data() {
    return {
      id: "",
      name: "",
      vendor: "",
      solution: "",
      communication: "",


      //
      
    };
  },

 
  components: {
    BaseAutoComplete,
  },
  computed: {

    solutions() {
      return this.$store.state.solutions.map(solution => ({
        value: solution.id,
        label: solution.name
      }));
    },
    communications() {
      return this.$store.state.communications.map(com => ({
        value: com.id,
        label: com.name
      }));
    },
    
  },
  mounted() {
    if (/update\/[0-9]/.test(this.$route.path)) {
      this.id = this.$route.params.id;
      this.$api
        .get(`template/${this.id}`)
        .then(res => {
          console.log("template update", res);
          let data = res.data.data;

          this.name = data.name;
          this.vendor = data.vendor;
          this.solution = +data.solution_id;
          this.communication = data.communication_id;
          
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  validations: {
    name: {
      required
    },
    vendor: {
      required
    },
    solution: {
      required
    },
    communication: {
      required
    },

  },
  methods: {



    submit(e) {
      e.preventDefault();
      this.$v.$touch();

      if (this.$v.$invalid ) {
        return;
      } else {
        let data = {
          name: this.name,
          category_id:this.category_id,
          vendor: this.vendor,
          solution_id: this.solution,
          communication_id: this.communication,

        };

        console.log("template", data);
        //
        //edit
        if (this.id) {
          data.id = this.id;
          console.log('update template',JSON.stringify(data));
          
          this.$api
            .put(`/teltonika/template/${this.id}`, data)
            .then(res => {
              this.$router.push({ path: "/template/list" });
            })
            .catch(err => {});
        } else {
          //create
          this.$api
            .post(`/teltonika/template`, data)
            .then(res => {
              this.$router.push({ path: "/template/list" });
            })
            .catch(err => {});
        }
      }
    }
  }
};
</script>
<style scoped>
.okStyle{
  background-color: rgb(232, 217, 29);
}
.problemSty{
background-color: rgb(242, 57, 59);;
}
.poorSty{
  background-color: rgb(230, 162, 60);
}
</style>
