<template>
  <card :bodyClasses="'p-2'">
    <div class="row">
      <div class="col-3">
        <base-input class="m-0" placeholder="villa name" v-model="villaName" />
      </div>
      <div class="col-3">
        <base-select
          class="m-0"
          placeholder="villa area"
          v-model="areaId"
          :options="areas"
        />
      </div>
      <base-button type="info" size="sm"> Submit </base-button>
    </div>
  </card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import helper from "../../../util/helper";
export default {
  name: "villa-list-filter",
  data() {
    return {
      villaName: null,
      areaId: undefined,
    };
  },
  computed: {
    ...mapGetters("IntrusionSystem", {
      areas: "getAreaOptions",
    }),
  },
  created() {
    this.getIntrusionGeneralInfo();
  },
  methods: {
    ...mapActions({
      getIntrusionGeneralInfo: "IntrusionSystem/getGeneralInfo",
    }),
  },
  watch: {
    areaId: {
      handler: helper.debounce(function () {
        this.$emit("input", {
          area_id: this.areaId,
          villa_name: this.villaName,
        });
      }, 1500),
    },
    villaName: {
      handler: helper.debounce(function () {
        this.$emit("input", {
          area_id: this.areaId,
          villa_name: this.villaName,
        });
      }, 1500),
    },
  },
};
</script>

<style>
</style>