<template>
  <form>
    <card footer-classes="text-right">
      <div slot="header">
        <h4 class="card-title">
          {{ state == 'View' ? $t("Sensor") + " View" : state + " " +  $t("Sensor") }}

        </h4>
      </div>
      <div>
        <!--  -->
        <!--  -->
        <SensorTemplate ref="snrtemp" :id="id" :key="id" :canupdate="state != 'View'" />
      </div>
      <template slot="footer" class="text-right">
        <base-button
          native-type="submit"
          @click.prevent="submit"
          type="primary"
          size="md"
        >
          {{ state == 'View' ? 'Edit' : state }}
        </base-button>
      </template>
      </card>
  </form>
</template>

<script>
import SensorTemplate from "src/pages/SWAM/components/sensor-template.vue";
export default {
  data() {
    return {
      id: "",
    };
  },
  mounted() {
    if (/update\/[0-9]/.test(this.$route.path)) {
      this.id = this.$route.params.id;
    }
    if(this.$route.name == "Sensor View") {
      this.id = this.$route.params.id;
    }
  },
  components: {
    SensorTemplate,
  },
  computed: {
    state() {
      let state = "Create";
      if(this.$route.name == "Sensor View") {
        state = "View";
      }
      else if(this.$route.name == "Update Sensor") {
        state = "Update";
      }
      return state;
    },
  },

  validations: {},
  methods: {
    submit() {
      //
      if(this.state == 'View') {
        this.$router.push('/SWAM/Sensors/update/' + this.id);
        return;
      }
      let data = this.$refs.snrtemp.submit();
      console.log("create sensor:", data);

      if (data) {
        if (this.id) {
          this.$api
            .post(`iot/sensors/${this.id}`, data)
            .then(() => {
              this.$router.push({ path: "/SWAM/Sensors/list" });
            })
            .catch(() => {});
        } else {
          this.$api
            .post("iot/sensors", data)
            .then(() => {
              this.$router.push({ path: "/SWAM/Sensors/list" });
            })
            .catch(() => {});
        }
      }
    },
  },
};
</script>
<style scoped>
</style>
