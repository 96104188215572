<template>
  <div class="row justify-content-between">
    <div class="col-md-5">
      <div class="form-group">
        <label for="floors">Floors Count:</label>
        <input
          class="form-control mb-1"
          id="floors"
          placeholder="Floors Count"
          type="number"
          v-model.number="floorsCount"
        />
        <template v-if="$v.floorsCount.$error">
          <label class="error" v-if="!$v.floorsCount.required">{{$t("requireds.floors")}}</label>
          <label
            class="error"
            v-else-if="!$v.floorsCount.between"
          >floors count should be in range of (1-300)</label>
          <label class="error" v-else>invalid floors count</label>
        </template>
      </div>
      <div class="form-group" v-if="floors.length!=0">
        <label for="floorsUG">Floors Under The Ground:</label>
        <input
          class="form-control mb-1"
          id="floorsUG"
          placeholder="Floors Under The Ground"
          type="number"
          v-model.number="floorsUGCount"
        />
        <small
          v-if="$v.floorsUGCount.$invalid && !$v.floorsCount.$invalid"
          class="form-text error"
        >Under ground floors count should be in range of (0 - {{floorsCount}})</small>
      </div>
      <template v-if="floors.length!=0">
        <el-divider content-position="center">Floors Naming</el-divider>
        <label>Set Naming Range</label>
        <div class="form-row justify-content-around">
          <div class="form-group">
            <input
              type="number"
              class="form-control rangeInput"
              name="from"
              id="from"
              aria-describedby="from"
              placeholder="From"
              v-model.number="naming.from"
            />
          </div>
          <div class="form-group">
            <input
              type="number"
              class="form-control rangeInput"
              name="To"
              id="To"
              aria-describedby="To"
              placeholder="To"
              v-model.number="naming.to"
            />
          </div>
        </div>
        <label>Set Names</label>
        <div class="form-row justify-content-around">
          <div class="form-group">
            <input
              type="text"
              class="form-control rangeInput"
              placeholder="Prefix"
              v-model="naming.prefix"
            />
          </div>
          <button
            class="btn btn-dark number-button"
            @click="naming.includeNumber=!naming.includeNumber"
            :class="{'line-through':!naming.includeNumber,'text-muted':!naming.includeNumber}"
          >#number</button>
          <div class="form-group">
            <input
              type="text"
              class="form-control rangeInput"
              placeholder="Postfix"
              v-model="naming.postfix"
            />
          </div>
        </div>
        <button
          class="btn btn-primary btn-sm mt-2"
          @click="applyNaming"
          :disabled="!applyButton"
        >Apply</button>
      </template>
    </div>
    <div class="col-md-6" v-if="floors.length!=0">
      <el-divider content-position="center">Floors</el-divider>
      <div class="floors">
        <div class="floor-block" v-for="(floor,index) in floors" :key="index">
          <span>#{{index+1}}</span>
          <input type="text" v-model="floor.name" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, integer, between } from "vuelidate/lib/validators";
import helper from "@/util/helper";
import { mapGetters } from "vuex";
import { FLOORS_MAX_COUNT } from "./../Structure-stores";
import { Divider } from "element-ui";
function myBetween(value) {
  console.log(this);
  return value <= this.floorsCount;
}
export default {
  components: {
    [Divider.name]: Divider
  },
  data() {
    return {
      floorsCount: null,
      floorsUGCount: 0,
      floors: [],
      naming: {
        includeNumber: true,
        prefix: "",
        postfix: "",
        from: null,
        to: null
      }
    };
  },
  mounted() {
    // this.createFloors();
  },
  watch: {
    floorsCount: {
      handler() {
        helper.debounce(() => {
          this.$v.$touch();
          if (!this.$v.$invalid) {
            this.createFloors();
          }
        })();
      }
    },
    floorsUGCount: {
      handler(newVal) {
        helper.debounce(() => {
          if (!this.$v.floorsUGCount.$invalid) {
            this.createFloors();
          }
        })();
      }
    }
  },
  methods: {
    validate() {
        this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      } else {
        this.commit();
        return true;
      }
    },
    commit() {
      this.$store.commit("setStructureInnerInfo", { 
        floors: this.floors,
        UGFloorsCount: this.floorsUGCount
      });
    },
    applyNaming() {
      for (
        let i = this.naming.from-1;
        i <= this.naming.to-1 && i < this.floorsCount;
        i++
      ) {
        if (this.naming.includeNumber) {
          this.floors[i].name = `${this.naming.prefix}${Math.abs(
            i - this.floorsUGCount
          )}${this.naming.postfix}`;
        } else {
          this.floors[i].name = `${this.naming.prefix}${this.naming.postfix}`;
        }
      }
    },
    createFloors() {
      console.log("create floors!");
      if (this.floorsCount <= FLOORS_MAX_COUNT && this.floorsCount >= 1) {
        this.floors = [];
        for (let i = 0; i < this.floorsCount; i++) {
          let name = i - this.floorsUGCount;
          if (name > 0) {
          } else if (name == 0) {
            name = "G";
          } else {
            name = "P " + Math.abs(name);
          }
          //this.floors[`${i}`]={label:name}
          this.floors.push({ name });
        }
      }
    }
  },
  validations: {
    floorsCount: {
      required,
      integer,
      between: between(1, FLOORS_MAX_COUNT)
    },
    floorsUGCount: {
      required,
      integer,
      between: myBetween
    }
  },
  computed: {
    ...mapGetters({}),
    applyButton() {
      return (
        typeof this.naming.from == "number" && typeof this.naming.to == "number"
      );
    }
  }
};
</script>

<style scoped>
.line-through {
  text-decoration: line-through;
}
.number-button {
  font-size: 0.7rem;
  text-transform: capitalize;
  padding: 0.4rem;
  margin: 2px;
  margin-bottom: 10px;
  font-weight: 400;
}
.rangeInput {
  max-width: 120px;
}
.floors {
  overflow-x: auto;
  height: 50vh;
}
.floor-block {
  margin: 0 1px;
  background: #00000033;
  border-radius: 5px;
  padding: 3px;
  margin: 5px;
  text-align: center;
  position: relative;
}
.floor-block span {
  position: absolute;
  left: 10px;
  top: 9px;
}
.floor-block input {
  margin: 5px;
  background: transparent;
  border: none;
  outline: none;
  color: white;
  text-align: center;
  cursor: pointer;
}
.error {
  color: #ec250d;
}
</style>
<style>

</style>