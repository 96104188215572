<template>
    <card v-loading="loading">
        <div class="customtableheader">
            <h4 slot="header" class="card-title">WorkOrder List</h4>
            <div>
                <el-tooltip content="Add New Order" effect="light" :open-delay="300" placement="top">
                    <base-button type="primary" round icon @click="createOrder">
                          <i class="tim-icons icon-simple-add"></i>
                    </base-button>
                </el-tooltip>
            </div>
        </div>
        <WorkOrderListTable
          :columns="orderListColumns"
          :data="orderListData"
          @change="fetchOrdersList"
        />
        <TablePagination
            slot="footer"
            ref="paginator"
            v-if="orderListData.length"
            :total="totalOrders"
            @change="updatePagination"
        />
        <WorkOrderModal ref="modal" @change="fetchOrdersList({total:''})" />
    </card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import WorkOrderListTable from './components/WorkOrderListTable';
import TablePagination from '@/components/TablePagination';
import WorkOrderModal from './components/WorkOrderModal';

export default {
    components: {
        WorkOrderListTable,
        TablePagination,
        WorkOrderModal
    },
    computed: {
        ...mapGetters('VsaasStore/WorkOrder',{
            loading: 'loading',
            orderListColumns: 'orderListColumns',
            orderListData: 'orderListData',
            totalOrders: 'totalOrders'
        })
    },
    methods: {
        ...mapActions('VsaasStore/WorkOrder',{
            fetchOrdersList: 'fetchOrdersList'
        }),
        updatePagination(data) {
            this.$store.commit("VsaasStore/WorkOrder/updatePagination",data);
            this.fetchOrdersList();
        },
        createOrder() {
            this.$refs['modal'].open();
        },
    },
    created() {
        this.fetchOrdersList({total:''});
    },
    pagination: {
        handler(v) {
          if (this.$refs.paginator) this.$refs.paginator.goTo(v.limit, v.offset);
        },
    },
}
</script>

<style>

</style>