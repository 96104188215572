<template>
  <div>
      <CustomerMap />
      <CustomerListTable />
  </div>
</template>

<script>
import CustomerMap from '../../core/CustomersMap/index.vue';
import CustomerListTable from '../../core/CustomerListTable/index.vue';

export default {
    components: {
        CustomerMap,
        CustomerListTable
    }
}
</script>

<style>

</style>