import alarm from '../initial-alarms-type';

function convertObjectToArray(convertedObject) {
  let array = [];
  Object.keys(convertedObject)
    .filter((k) => {
      array.push(convertedObject[k]);
    });
  return array;
}

function BuildOperationalWithStatusNotification(operationalArray,allSensor=[]) {
  let operationalArrayTemp = [];
  if (operationalArray.length > 1) {
    operationalArray.forEach((e) => {
      e.forEach((z) => {
        let record = {
          sensor_type_id: z.sensor_type_id,
          status: z.status,
          notification: z.notification,
          email: z.email,
          whatsapp: z.whatsapp,
          sms: z.sms,
        };
        operationalArrayTemp.push(record);
      });
    });
  }
  else
  {
    allSensor.forEach(e=>{
      alarm.forEach(z=>{
      let record={
        sensor_type_id: e.id,
        status: z.status,
        notification: z.notification,
        email: z.email,
        whatsapp: z.whatsapp,
        sms: z.sms,
      }
      operationalArrayTemp.push(record);
    })
    })
  }
  return operationalArrayTemp;
}

function CorrectOperational(operational){
  let operationArray=[];
  for(let i=0;i<operational.length;i+=3)
  {
    let alarms=[];
    for(let j=0;j<3;j++)
    {
      let alarm={
        status:operational[i+j].status,
        email:operational[i+j].email,
        whatsapp:operational[i+j].whatsapp,
        sms:operational[i+j].sms,
        notification:operational[i+j].notification,
      }
      alarms.push(alarm);
    }
    operationArray.push({sensor_type_id:operational[i].sensor_type_id,alarms:alarms})
  }
  return operationArray;
}

export { convertObjectToArray,BuildOperationalWithStatusNotification,CorrectOperational }
