<template>
  <card v-loading="loading">
    <div slot="header">
      <p> {{$tc('comment', 2)}}</p>
    </div>
    <div v-if="comments.length != 0">
      <div class="comments-wrapper" id="commentsWrapper">
        <div class="comment" v-for="comment in comments" :comment="comment" :key="comment.id">
          <div class="avatar2">
            <!-- <img src="" alt=""> -->
            <div>{{getAvatarText(comment.user)}}</div>
          </div>
          <div class="text">
            <el-tooltip
              effect="dark"
              :open-delay="300"
              placement="top"
            >
              <div slot="content">
                <span v-if="comment.user.email">{{$t('email')}}: {{comment.user.email}}</span>
                <br />
                <span v-if="comment.user.company_name">{{$t('companyName')}}: {{comment.user.company_name}}</span>
              </div>
              <a class="username">{{ comment.user.first_name + ' ' + comment.user.last_name }}</a>
            </el-tooltip>
            <span>{{ comment.created_at }}</span>
            <p style="color:#3c3a3a;">{{comment.text}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="no-comments" v-else>
      <p v-if="!loading" class="h4">{{ $t('noComments') }} </p>
      <p v-else class="h4">Loading...</p>
    </div>
    <div slot="footer">
      <hr />
      <div class="reply">
        <input
          type="text"
          v-model.trim="reply"
          class="reply--text"
          :placeholder="$t('leaveComment')"
          maxlength="250"
          required
          dir="auto"
        />
        <button class="reply--button" @click.prevent="submitComment">
          <i class="fa fa-paper-plane"></i>{{$t('send')}}
        </button>
      </div>
    </div>
  </card>
</template>

<script>
import { Tooltip } from "element-ui";
export default {
  components: {
    [Tooltip.name]: Tooltip
  },
  props: {
    comments: {
      default: function() {
        return [];
      }
    },
    loading: {
      default: false
    }
  },
  data() {
    return {
      reply: ""
    };
  },
  watch: {
    comments() {
      this.$nextTick().then(() => {
        var messageBody = document.getElementById("commentsWrapper");
        if (messageBody)
          messageBody.scrollTop =
            messageBody.scrollHeight - messageBody.clientHeight;
      });
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      var messageBody = document.getElementById("commentsWrapper");
      if (messageBody)
        messageBody.scrollTop =
          messageBody.scrollHeight - messageBody.clientHeight;
    });
  },
  methods: {
    submitComment() {
      if (this.reply != "") {
        this.$emit("submit", this.reply);
      }
    },
    getAvatarText(str) {
      if(!str) return ''; 
      if(!str.first_name) return ''; 
      str=str.first_name;
      var matches = str.match(/\b(\w)/g);
      return (matches.join("") + "").toUpperCase();
    },
    clear() {
      this.reply = "";
    }
  }
};
</script>

<style scoped>
.card {
  -webkit-box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.5);
}
::v-deep .card-body {
  min-height: 300px;
  padding-bottom: 0;
}
a {
  text-decoration: none;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #3f4054;
  margin: 1em 0;
  padding: 0;
}

.post-owner {
  display: flex;
  align-items: center;
}
.post-owner .avatar2 > img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.post-owner .username {
  margin-left: 5px;
}
.post-owner .username > a {
  color: #333;
}
/* *********************************COMMENTS COMPONENT***************************************** */
.comments-wrapper {
  font-family: greyFont;
  max-height: 450px;
  overflow-y: auto;
  padding-right: 10px;
}
.no-comments {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 270px;
}
/* Reply component */
.reply {
  display: flex;
  position: relative;
  align-items: center;
  background-color: #ebebeb;
  border-radius: 30px;
  padding: 5px 10px;
  overflow: hidden;
}
.reply .avatar2 {
  position: absolute;
}
.reply .avatar2 > img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.reply .reply--text {
  min-height: 40px;
  padding: 10px 10px 10px 55px;
  margin-right: 10px;
  border: 0;
  color: #333;
  width: 100%;
  outline: 0;
  background-color: transparent;
  box-shadow: none;
}
.reply input.reply--text:valid {
  margin-right: 71px;
}
.reply input.reply--text:valid + .reply--button {
  right: 10px;
}
.reply .reply--button {
  position: absolute;
  right: -100px;
  border: 1px solid #1d8cf8;
  background-color: transparent;
  color: #2a629c;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 30px;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
    border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out,
    right 0.25s ease-in-out;
  outline: 0;
}
.reply .reply--button:hover {
  color: #fff;
  background-color: #1d8cf8;
}
.reply .reply--button:focus,
.reply .reply--button:active {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
/* ***********************************************SINGLE COMENT COMPONENT*********************************************************** */
.comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* color: #333; */
  background-color:#e2e5e6;
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}
.comment .avatar2 {
  align-self: flex-start;
}
.comment .avatar2 > img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  align-self: start;
}
.comment .avatar2 > div {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  align-self: start;
  background: rgb(115, 121, 183);
  text-align: center;
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
}
.comment .text {
  text-align: left;
  margin-left: 5px;
  margin-right: 20px;
  margin-inline-end: 20px;
}
.comment .text span {
  margin-left: 5px;
}
.comment .text .username {
  font-weight: bold;
  color: #333;
}
</style>
