<template>
  <div>
    <div class="main-colors">
      <div class="row">
        <div class="col-md-2">
          <div class="main-colors-area">
            <div class="color-item" v-for="(item , index) in mainColors" :key="index">
              <div class="color-circle" :key="index" :style="{'backgroundColor':item}"
                   :class="{'active' : mainItemChosed === item}"
                   @click="showVariations(item)"></div>
            </div>
          </div>
        </div>
        <div class="col-md-10 m-0">
          <div class="main-variations-area">
            <transition-group name="slide-fade" mode="out-in" appear>
              <template v-for="(item , index) in variationsShown">
                <div class="color-variations-area" v-if="item" :key="index">
                  <div class="color-variations-item text-white"
                      :class="{'active' : itemChose === item}"
                      :style="{'backgroundColor':item}"
                      @click="choseItem(item)"
                  >{{item}}
                  </div>
                </div>
              </template>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer mt-4 text-center text-start">
      <div class="button-part-1">
        <base-button class="btn-simple btn-sm" :class="{'text-white' : isDarkMode}"
                     @click="hiddenDialog()">{{$t('cancel')}}
        </base-button>
      </div>
      <div class="button-part-2">
        <base-button class="btn-simple mx-2 btn-sm"
                     @click="reset()">{{$t('resetToDefault')}}
        </base-button>
        <base-button class="btn-primary btn-sm" :class="{'disabled' : itemChose == ''}"
                     @click="save()">{{$t('save')}}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import UserPreferencesActions from '../global/user-preferences-store/action-types';
  import colors from '../global/colors/colors';
  export default {
    name: "ColorPicker",
    props: {
      data: {
        visible: {
          type: Boolean,
          default: false
        }
      }
    },
    mounted() {
      // chose first color to show there variation
      this.showVariations(Object.keys(this.colors)[0]);
    },
    data() {
      return {
        /**
         * @type array
         * @desc show color variation
         * @author karam mustafa
         *
         * */
        variationsShown: [],
        /**
         * @type string
         * @desc value for what we chose color
         * @author karam mustafa
         *
         * */
        itemChose: '',
        /**
         * @type string
         * @desc main color in side poston
         * @author karam mustafa
         *
         * */
        mainItemChosed: '',
        /**
         * @type Object
         * @desc material colors from github
         * @author karam mustafa
         *
         * */
        colors: colors
      };
    },
    computed: {
      // to determine if we are in drk mode , we will add some white space for text
      ...mapGetters([
        'isDarkMode',
      ]),
      /**
       * @desc get main color
       * @author karam mustafa
       * */
      mainColors() {
        return Object.keys(this.colors);
      },
      /**
       * @desc determine page direction
       * */
      dir() {
        return this.$rtl.isRTL ? 'rtl' : 'ltr';
      },
      /**
       * @desc determine page direction
       * */
      revDir() {
        return this.$rtl.isRTL ? 'ltr' : 'rtl';
      },
    },
    methods: {
      ...mapActions('UserPreferencesStore', {
        resetToDefault : UserPreferencesActions.RESET_SENSOR_COLOR,
        changeSensorColor : UserPreferencesActions.CHANGE_SENSOR_COLOR,
      }),

      /**
       * @desc show color variation
       * @author karam mustafa
       *
       * */
      showVariations(mainColor) {
        // array to append colors
        let colors = [];
        // get this main colors object
        let main = this.getValuesFromColors(mainColor);
        // loop throughout this main color object
        Object.values(main).forEach((item, index) => {
          // append new variation item
          colors.push(Object.values(item))
        });
        this.variationsShown = colors[0];
        this.mainItemChosed = mainColor
      },
      /**
       * @desc show color variation
       * @author karam mustafa
       *
       * */
      getValuesFromColors(mainColor) {
        return Object.fromEntries(Object.entries(this.colors).filter(([value]) => value === mainColor));
      },
      /**
       * @desc ave changes
       * @author karam mustafa
       *
       * */
      save() {
        // sensor to modify color
        let data = {
          color: this.itemChose,
          sensorId: this.data.info.id
        };
        // dispatch modify sensor color action
        let result = this.changeSensorColor(data);
        // handle result
        this.handleResponse(result);
      },
      /**
       * @desc reset sensor color to default
       * @author karam mustafa
       *
       * */
      reset() {
        // sensor to modify color
        let data = {sensorId: this.data.info.id};
        // dispatch action
        let result = this.resetToDefault(data);
        // handle result
        this.handleResponse(result);
      },
      /**
       * @desc inject what we chose item to itemChose property
       * @author karam mustafa
       *
       * */
      choseItem(item) {
        // new color chosen
        this.itemChose = item;
      },
      /**
       * @desc close dialog ,  we will use it if request success ,  or any action .
       * @author karam mustafa
       *
       * */
      hiddenDialog() {
        this.data.visible = false;
      },
      /**
       * @desc the logic from response is duplicated , so we will handle all response in the same type here
       * @author karam mustafa
       *
       * */
      handleResponse(result) {
        // check if response is success
        result.then(res => {
          if (res.status < 300) {
            this.hiddenDialog();
          }
        })
      },
    }
  }
</script>

<style scoped lang="scss">
  .color-item {
    display: block;
    padding: 10px 0;
  }

  .sensor-color-area {
    height: 100%;
  }

  .main-colors-area, .main-variations-area {
    max-height: 500px;
    overflow-y: scroll;
  }

  .color-item .active {
    width: 35px;
    height: 35px;
    transition: 0.4s all ease-in-out;
  }

  .color-circle {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid white;
  }

  .color-variations-item {
    cursor: pointer;
    width: 100%;
    margin: 10px 0;
    height: 50px;
    border-radius: 10px;
    border: 2px solid white;
    padding: 13px;
    text-align: center;
    transition: 0.4s all ease-in-out;
  }

  .main-colors-area {
    padding: 5px;
  }

  .main-colors {
    height: 100%;
  }

  .slide-fade-enter-active {
    transition: all .3s ease;
  }

  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }

  .color-variations-item:not(.active) {
    width: 94%;
    margin: 10px 3%;
  }

  .disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
  }

  .primary-border {
    border: 1px solid $primary-green;
  }

  .el-dialog__body {
    height: 100%;
  }
  .dialog-footer{
    display: flex;
    justify-content: space-between;
    /*margin: 5px 0;*/
  }
  .button-part-1 {
    text-align: start;
  }

  .button-part-2 {
    text-align: end;
  }
</style>
