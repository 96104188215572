<template>
  <el-table-column
    :fixed="$rtl.isRTL ? false : 'right'"
    :label="$tc('report.action', 2)"
    width="165"
  >
    <div slot-scope="{ row }" class="row justify-content-between m-0">
      <div class="col-12">
        <base-button
          :type="getAlarmState(row).type"
          size="sm"
          class="ack_button px-0"
          @click="$emit(getAlarmState(row).event, row)"
          :disabled="!getAlarmState(row).event"
          :simple="getAlarmState(row).type == 'warning'"
        >{{ $t(getAlarmState(row).action) }}</base-button>
      </div>
      <div class="col-12 mt-1 d-flex justify-content-center">
        <base-button
          v-if="canTerminate"
          class="btn-terminate"
          simple
          size="sm"
          :type="getButtonTerminateStatus(row).type"
          @click="$emit('terminate', row)"
          :disabled="row.termination != null"
        >
          <i class="fa fa-exclamation-triangle"></i>
        </base-button>
      </div>

      <!-- <base-button
        class="py-0 px-2 mx-1"
        simple
        round
        size="sm"
        @click="$emit('more-info-clicked', row)"
      >
        <i class="fa fa-info"></i>
      </base-button>-->
    </div>
  </el-table-column>
</template>

<script>
import { mapGetters } from "vuex";
import IntrusionVuexGetter from "../store/types/getter-types";
export default {
  computed: {
    canTerminate() {
      const user = this.$store.getters.user;
      // superadmin or supervisor or admin
      return (
        user.role_id == 1 || user.type == "supervisor" || user.role_id == 2
      );
    },
    ...mapGetters("IntrusionStore", {
      userType: IntrusionVuexGetter.MORO_OR_POLICE_GETTER
    })
  },
  methods: {
    getAlarmState(row) {
      if (this.userType == "moro") {
        if (row.verification) {
          return {
            type: "primary",
            action: "verified"
          };
        } else if (row.acknowledgement) {
          return {
            type: "warning",
            action: "not verified",
            event: "open-ack-modal"
          };
        } else {
          // not ack
          if (row.termination) {
            return {
              type: "primary",
              action: "acknowledge",
              event: "acknowledge"
            };
          } else {
            return {
              type: "warning",
              action: "acknowledge",
              event: "acknowledge"
            };
          }
        }
      } else {
        if (row.police_acknowledgement) {
          return {
            type: "primary",
            action: "acknowledged"
          };
        } else {
          return {
            type: "warning",
            action: "acknowledge",
            event: "open-ack-modal"
          };
        }
      }
    }
  }
};
</script>

<style scoped>
.ack_button {
  word-break: keep-all;
  border-width: 2px;
  width: 100%;
  /* background: unset;
  background-image: unset; */
}

.ack_button:hover .ack_button:focus {
  background: unset;
  background-image: unset !important;
}

.btn-terminate {
  float: right;
  height: 100%;
  width: 100%;
  border-width: 2px;
}

.btn-escalate {
  border-width: 2px;
}
</style>
