<template>
  <form>
    <card footer-classes="text-right">
      <div slot="header">
        <h4 class="card-title">{{name}}</h4>
      </div>
      <div>
        <!--  -->
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">

                <div class="co-view">
                  <h5>{{$t("Name")}}:</h5>
                  <p>{{name}}</p>
                </div>

              </div>
              <div class="col-md-6">
                <div class="co-view">
                  <h5>{{$t("vendor")}}:</h5>
                  <p>{{vendor}}</p>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">

                <div class="co-view">
                  <h5>{{$t("Solution")}}:</h5>
                  <p>{{solution}}</p>
                </div>

              </div>
              <div class="col-md-6">
                <div class="co-view">
                  <h5>{{$t("Communication")}}:</h5>
                  <p>{{communication}}</p>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-3">

                <div class="co-view">
                  <h5>{{$t("Not report time")}}:</h5>
                  <p>{{not_report_time}}</p>

                </div>


                <!-- open ticket -->
              </div>
              <div class="col-md-3">
                <base-checkbox disabled v-model="open_ticket_unresponsive">{{$t("Opent ticket")}}</base-checkbox>

              </div>

              <div class="col-md-2">
                <p>{{$t("Battery Life")}}:</p>
              </div>
              <div class="col-md-2">

                <div class="co-view">
                  <h5>{{$t("year")}}:</h5>
                  <p>{{battery_life.year}}</p>
                </div>

              </div>
              <div class="col-md-2">
                <div class="co-view">
                  <h5>{{$t("month")}}:</h5>
                  <p>{{battery_life.month}}</p>
                </div>

              </div>
            </div>


          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <!-- ok -->


                        <div class="co-view">
                          <h5>{{$t("OK")}} percent:</h5>
                          <p>{{battery_status.ok.percent}}</p>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <base-checkbox disabled v-model="battery_status.ok.open_ticket">Open ticket</base-checkbox>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <!-- poor -->


                        <div class="co-view">
                          <h5>{{$t("Poor")}} percent:</h5>
                          <p>{{battery_status.poor.percent}}</p>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <!-- open ticket on poor -->
                        <base-checkbox disabled v-model="battery_status.poor.open_ticket">Open ticket</base-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <!-- problem-->

                        <div class="co-view">
                          <h5>{{$t("Problem")}} percent:</h5>
                          <p>{{battery_status.problem.percent}}</p>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <!-- open ticket on problem -->
                        <base-checkbox disabled v-model="battery_status.problem.open_ticket">Open ticket</base-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--  -->
        <hr />
        <h5>{{$t("Sensors")}}</h5>
        <template v-for="(snr,index) in allsensores">
          <div :key="index">

            <SensorTemplate disabled :key="snr.sensorId"
                            :ref="'snr'+index"
                            :id="snr.sensorId"
                            :canupdate="false" 
                             />
            <hr />
          </div>
        </template>

        <!--  -->
        
      </div>

    </card>
  </form>
</template>

<script>
  import { Table, TableColumn } from "element-ui";
  import SensorTemplate from "src/pages/SWAM/components/sensor-template.vue";
export default {
  props: ["template"],
   data() {
    return {
      id: "",
      name: "",
      vendor: "",
      solution: "",
      communication: "",
      not_report_time: "",
      open_ticket_unresponsive: false,
      battery_life: {
        year: "",
        month: ""
      },
      selected_sensor: null,
      allsensores: [],
      refs: [],

      battery_status: {
        ok: {
          percent: "",
          open_ticket: false
        },
        poor: {
          percent: "",
          open_ticket: false
        },
        problem: {
          percent: "",
          open_ticket: false
        }
      },

      //

    };
  },

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,

    SensorTemplate

  },
    mounted() {

      this.id = this.$route.params.id;
       this.$api
        .get(`template/${this.id}`)
        .then(res => {
          console.log("template update", res);
          let data = res.data.data;

          this.name = data.name;
          this.vendor = data.vendor;
          this.solution = +data.solution_id;
          this.communication = data.communication_id;
          this.not_report_time = data.not_report_time;
          this.open_ticket_unresponsive = !!data.open_ticket_unresponsive;
          let battery_life = data.battery_life.split("|");
          this.battery_life.year = battery_life[0];
          this.battery_life.month = battery_life[1];
          let allsensores = data.sensors;
          this.allsensores = allsensores;
          if (data.battery_status.length > 0) {
            let battery_status = data.battery_status.reduce((acc, curr) => {
              let status = curr.battery_status;
              acc[status] = {
                percent: curr.percent,
                open_ticket: !!curr.open_ticket
              };
              return acc;
            }, {});

            this.battery_status = battery_status;
          }
        })
        .catch(err => {
          console.log(err);
        });
    
  },
};
</script>
<style scoped>
  .row {
    margin-right: 0px;
    margin-left: 0px;
  }

    .co-view h5 ,.co-view p{
        display: inline-block;
        margin: 0 10px;
    }

</style>
