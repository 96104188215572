<template>
  <div>
    <card>
      <div slot="header" class="mb-4">
        <router-link
          to="/tickets/view"
          tag="button"
          class="btn btn-info routeCl"
        >{{$t('Check All Tickets')}}</router-link>
      </div>
      <!-- <router-link slot="header" to="/tickets/view">
        <div class="flex-container title-icon-container">
          <i
            class="fa fa-list"
            style="cursor: pointer;padding-right: 10px;color: #2fc177;font-size: 19px;"
          ></i>
          <a style="color:white;" class="dash-title">view all tickets</a>
        </div>
      </router-link>-->

      <div class="p-1">
        <div class="row justify-content-around">
          <div class="col-xl-4 col-lg-4">
            <card>
              <div class="component-container-2 m-0 message-option-container">
                <form @submit.prevent class="form-style-2">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleInputPassword1">{{$t('Subject')}}</label>
                        <base-input :placeholder="$t('Type Subject')" v-model="subject">
                          <template v-slot:error v-if="$v.subject.$error">
                            <span
                              class="error"
                              v-if="!$v.subject.required"
                            >{{$t("requireds.Subject")}}</span>
                          </template>
                        </base-input>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group select-btn">
                        <label for="send to">{{$t("Add receivers")}}</label>
                        <select class="form-control" v-model="receiverType">
                          <option :value="null">Select a Receiver</option>
                          <template v-for="(item,index) in optins">
                            <option v-if="!item.selected" :key="index" :value="item">{{item.label}}</option>
                          </template>
                        </select>
                        <template v-if="$v.receivers.$error">
                          <span
                            style="color: #ec250d;"
                            v-if="$v.receivers.$error"
                          >{{$t("validations.One Receiver At least")}}</span>
                        </template>
                      </div>
                    </div>
                  </div>

                  <div
                    class="row"
                    v-if="receiverType && receiverType.role!='super_admin' && ( !receiverType.single || (receiverType.role && !isRegularUser)) "
                  >
                    <div class="col-md-12 mb-3">
                      <autocomplete
                        :placeholder="`Search in ${receiverType.label}s`"
                        ref="autocomplete"
                        :source="Endpoint"
                        @input="addReceiver"
                      ></autocomplete>

                      <button class="btn btn-success d-none" @click="addReceiver">{{$t("Add")}}</button>
                    </div>
                  </div>
                  <div class="col-md-12" v-if="receivers.length>0">
                    <div class="form-group">
                      <label for="exampleInputPassword1" class="bold-title">{{$t("Receivers")}}</label>
                      <div>
                        <!-- <button
                        :key="index"
                        class="btn btn-success btn-item"
                        @click="(e)=>clearReceiver(e,index)"
                      >
                        {{receiver.text}}
                        <i class="fa fa-close"></i>
                        </button>-->

                        <badge
                          v-for="(receiver,index) in receivers"
                          :key="index"
                          class="btn-item"
                          @click="(e)=>clearReceiver(e,index)"
                          type="primary"
                        >
                          {{receiver.label}}
                          <i class="tim-icons icon-simple-remove"></i>
                        </badge>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="send to">{{$t("Send Way")}}</label>
                      <el-select
                        id="send to"
                        multiple
                        class="select-primary"
                        v-model="$v.methods.$model"
                        :placeholder="$t('Enter The notification Method')"
                      >
                        <el-option
                          v-for="option in notification_methods"
                          class="select-primary"
                          :value="option"
                          :label="option"
                          :key="option"
                        ></el-option>
                      </el-select>
                      <template v-if="$v.methods.$error">
                        <span
                          style="color: #ec250d;"
                          v-if="$v.methods.$error"
                        >{{$t("One Method At least")}}</span>
                      </template>
                    </div>
                  </div>
                </form>
              </div>
            </card>
          </div>
          <card class="col-xl-7 col-lg-7">
            <el-tooltip
              :effect="themMode!='dark'?'dark':'light'"
              placement="top"
              :manual="true"
              :value="$v.content.$error"
              content="Ticket Content Must Not Be Empty"
            >
              <vue-editor v-model="content" class="my-editor" />
            </el-tooltip>
            <div class="form-group mt-2" v-if="preview.length!=0">
              <label>{{$t("whatsapp and sms preview")}}</label>
              <div class="preview" v-text="preview"></div>
            </div>
          </card>
        </div>
      </div>

      <div slot="footer" class="col-md-12">
        <button
          type="button"
          class="btn btn-success send-btn"
          @click="(e)=>submit(e)"
        >{{$t("Send")}}</button>
      </div>
    </card>
  </div>
</template>
<script>
const notEmpty = val => val.length > 0;
import { VueEditor } from "vue2-editor";
import { required } from "vuelidate/lib/validators";
import Autocomplete from "../../components/BaseAutoComplete";
import axios from "axios";
import { Badge } from "src/components";
import { mixin, initTicket } from "./TicketsHelper";
export default {
  mixins: [mixin],
  data: () => ({
    content: "",
    preview: ``,
    receiverType: null,
    superAdmin: false,
    methods: ["email"],
    receivers: [],
    subject: "",
    destination: null,
    optins: []
  }),
  watch: {
    receiverType(newVal) {
      if (!newVal) return;
      console.log(newVal);
      if (newVal.single) {
        // all_{type}
        newVal.selected = true;
        for (const op of this.optins) {
          if (
            op.type == newVal.single ||
            (newVal.role && op.role == newVal.single) // in case of admins
          ) {
            op.selected = true;
            break;
          }
        }
        this.receivers.push(newVal);
        this.receiverType = null;
      } else if (
        (this.isRegularUser && this.receiverType.role == "admin") ||
        this.receiverType.role == "super_admin"
      ) {
        newVal.selected = true;
        this.receivers.push(newVal);
        this.receiverType = null;
      }
    },
    content() {
      this.preview = this.extractContent(this.content);
    }
  },
  components: {
    VueEditor,
    Autocomplete,
    Badge
  },
  computed: {
    notification_methods() {
      return this.$store.state.notification_methods;
    },
    ticketingPermissions() {
      return this.$store.getters.getTicketingPermissions;
    },
    isSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isRegularUser() {
      return this.$store.getters.isRegularUser;
    },
    themMode() {
      return this.$store.getters.themMode;
    }
  },
  validations: {
    methods: {
      notEmpty
    },
    receivers: {
      notEmpty,
      required
    },
    subject: {
      required
    },
    content: {
      required,
      notEmpty
    }
  },
  methods: {
    Endpoint(input) {
      var link;
      if (this.receiverType.role) {
        switch (this.receiverType.role) {
          case "admin":
            link = "user/admin/search/";
            break;
        }
      } else
        switch (this.receiverType.type) {
          case "authority":
            link = "authority/search/";
            break;
          case "fmc":
            link = "fmc/search/";
            break;
          case "amc":
            link = "amc/search/";
            break;
          case "paying_customer":
            link = "customer/search/";
            break;
        }
      return axios.defaults.baseURL + link + input;
    },
    addReceiver(e) {
      if (!e) {
        return;
      }
      if (e.isTrusted) {
        //mouse event
        return;
      }

      const resc = e.selectedObject;
      if (resc) {
        var found = this.receivers.some(
          el =>
            el.info &&
            el.info.id == resc.id &&
            ((this.receiverType.type &&
              el.info.type == this.receiverType.type) ||
              (this.receiverType.role &&
                el.info.role == this.receiverType.role))
        );
        if (found) {
          this.$notify({
            message: `The Receiver [ ${resc.name} ] Already Added`,
            type: "danger"
          });
        } else {
          if (this.receiverType.role == "admin") {
            this.receivers.push({
              info: { id: resc.id, role: this.receiverType.role },
              label: resc.name
            });
          } else {
            this.receivers.push({
              info: { id: resc.id, type: this.receiverType.type },
              label: resc.name
            });
          }
          this.$refs.autocomplete.clear();
        }
      }
    },
    clearReceiver(e, index) {
      e.preventDefault();
      if (this.receivers[index].single) {
        // type_{all}
        this.receivers[index].selected = false;
        for (const op of this.optins) {
          if (
            op.type == this.receivers[index].single ||
            (this.receivers[index].role &&
              op.role == this.receivers[index].single) // in case of admins
          ) {
            op.selected = false;
            break;
          }
        }
      } else if (
        this.receivers[index].role == "admin" ||
        this.receivers[index].role == "super_admin"
      ) {
        this.receivers[index].selected = false;
      }
      this.receivers.splice(index, 1);
    },
    submit(e) {
      e.preventDefault();
      // e.preventDefault();
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$notify({
          type: "danger",
          title: "Invalid Data",
          message: "There are invalid fields"
        });
        return false;
      } else {
        const finalR = [];
        const temp = {};
        const admins = { adminsIds: [], role: "admin" };
        
        this.receivers.forEach(r => {
          if (r.role || (r.info && r.info.role)) {
            /*if(r.role== 'super_admin'){
              finalR.push({ role: "super_admin" });
              return;
            }
            if (r.single) { //all admins
              finalR.push({ role: "admin" });
            } else {
              if (this.isRegularUser) {
                finalR.push({ role: "admin" });
              }else {
                admins.adminsIds.push(r.id)
              }
            }*/
            if (!this.isSuperAdmin) {
              finalR.push({ role: r.role });
            } else {
              if (r.single) {
                finalR.push({ role: r.role });
              } else {
                admins.adminsIds.push(r.info.id);
              }
            }
          } else if (r.single) {
            // all_{type}
            finalR.push({ companyType: r.single });
          } else {
            if (temp[r.info.type]) {
              temp.push(r.info.id);
            } else {
              temp[r.info.type] = [r.info.id];
            }
          }
        });
        if (
          admins.adminsIds.length != 0 &&
          !finalR.some(r => r.role == "admin")
        ) {
          finalR.push(admins);
        }

        for (const comp of finalR) {
          if (!comp.role) delete temp[comp.companyType];
        }
        const ids = [];
        for (const comp in temp) {
          if (temp.hasOwnProperty(comp)) {
            const element = temp[comp];
            ids.push(...element);
          }
        }
        if (ids.length != 0) {
          finalR.push({ ids });
        }

        let data = {
          type: "ticket",
          subject: this.subject,
          content: this.content,
          textContent: this.preview,
          receivers: finalR,
          method: this.methods.join(",")
        };
        console.log(data);
        this.$store.commit("setSpinner", true);
        this.$api
          .post("messages", data)
          .then(() => {
            this.$router.push({ path: "/tickets/view" });
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    getReceivers(permissions) {
      // unfinished work
      const options = [];
      const cos = permissions.companyType;
      const all = cos.all;
      Object.keys(cos).forEach(key => {
        if (key == "all") {
          return;
        }
        if (cos[key]) {
          if (all) {
            options.push({
              label: `All ${key.toUpperCase()}s`,
              type: `all_${key}`,
              selected: false,
              single: key
            });
          }
          options.push({
            label: `${key.toUpperCase()}`,
            type: key,
            selected: false
          });
        }
      });
      if (permissions.role.admin) {
        if (this.isSuperAdmin) {
          options.push({
            label: `All Admins`,
            role: `admin`,
            selected: false,
            single: "admin"
          });
        }
        options.push({
          label: `Admin`,
          role: `admin`,
          selected: false
        });
      }
      if (permissions.role.super_admin) {
        options.push({
          label: `Super Admin`,
          role: `super_admin`,
          selected: false
        });
      }

      this.optins = options;
      console.log(options);
    }
  },
  created() {
    if (!this.ticketingPermissions) {
      this.$store.dispatch("loadTicketingPermissions");
    } else {
      this.getReceivers(this.ticketingPermissions);
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setTicketingPermissions") {
        this.getReceivers(mutation.payload);
      }
    });
    if (initTicket) {
      this.subject = initTicket.subject;
      this.content = initTicket.content;
      this.receivers = initTicket.receivers;
      this.reset();
    }
  },
  destroyed() {
    this.unsubscribe();
  }
};
</script>
<style scoped>
::v-deep .ql-editor * {
  color: black;
}
.my-editor {
  background: var(--secondary) !important;
  color: var(--primary);
}
.btn-item {
  margin: 0.1rem 0.2rem;
  font-size: 1rem;
  max-width: -webkit-fill-available;
  white-space: normal;
  cursor: pointer;
  line-height: 1.5rem;
}
.preview {
  font-size: 1.5rem;
  background: transparent;
  padding: 15px;
  border-bottom: 1px solid var(--primary);
  -webkit-box-shadow: 1px 1px 4px black;
  box-shadow: 1px 1px 4px black;
  color: white;
  white-space: pre-wrap;
}
.card {
  -webkit-box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.5);
}
.tim-icons {
  font-weight: bold;
}
option {
  font-size: 1rem;
  color: var(--dark);
  line-height: 34px;
  cursor: pointer;
}
.white-content .preview {
  color:#211e1e 
}
</style>
