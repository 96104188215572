<template>
  <el-table class="striped-table" :data="devices">
    <el-table-column prop="name" label="Name" style="width: 25%" />
    <!-- <el-table-column prop="active_operational_alarms" label="Active Operational Alarms" style="width: 25%">
      <template slot-scope="{ row }">
        {{ row.active_operational_alarms ? row.active_operational_alarms : "--" }}
      </template>
    </el-table-column> -->
    <el-table-column prop="active_intrusion_alarms" label="Active Intrusion Alarms" style="width: 25%">
      <template slot-scope="{ row }">
        {{ row.active_intrusion_alarms ? row.active_intrusion_alarms : "--" }}
      </template>
    </el-table-column>
    <!-- <el-table-column label="Installation Time" style="width: 25%">
      <template slot-scope="{ row }">
        {{ row.created_at ? format(row.created_at) : "--" }}
      </template>
    </el-table-column> -->
  </el-table>
</template>

<script>
  import { dateFormat } from "@/util/helper"

  export default {
    props: {
      devices: {
        type: Array,
        default: () => [],
        validator: function (value) {
          return value.find(
            (val) =>
              val.name === undefined ||
              val.active_operational_alarms === undefined ||
              val.active_intrusion_alarms === undefined
          ) === undefined;
        },
        required: true,
      },
    },
    methods: {
      format(date) {
        return dateFormat(date)
      }
    }
  };
</script>

<style>
</style>