<template>
  <div>
    <AcknowledgmentModal
      :contacts="customerContactsList"
      :isOpen="showAcknowledgmentModal"
      @submit="submitAcknowledgment"
      @close="submitAcknowledgment"
    />
    <TerminateModal
      :isOpen="showTerminateModal"
      @submit="submitTerminate"
      @close="submitTerminate"
    />
    <!--<CommentsModal
      @close="closeCommentsModal"
      @send-msg="addComment"
      :isChatOpen="closeOpenCommentsGetter"
      :users="usersCommentsGetter"
      :messageList="commentsListGetter"
    />-->
    <EscalateModal
      :isOpen="showEscalateModal"
      :deviceCompanies="deviceCompanies"
      @close="submitEscalation"
      @submit="submitEscalation"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AcknowledgmentModal from "./vsaas-acknowledgment-modal";
import CommentsModal from "./vsaas-comments-modal";
import EscalateModal from "./vsaas-escalate-modal";
import TerminateModal from "./vsaas-terminate-modal";

export default {
  components: {
    AcknowledgmentModal,
    TerminateModal,
    CommentsModal,
    EscalateModal
  },
  props: {
    sourceStore: {
      type: String,
      default: 'VsaasStore/OperationalAlarms/OperationalAlarmsActions'
    },
  },
  computed: {
    deviceCompanies() {
      return this.getters("deviceCompanies");
    },
    customerContactsList() {
      return this.getters("customerContactsList");
    },
    showEscalateModal() {
      return this.getters("currentAction") == "escelate";
    },
    showAcknowledgmentModal() {
      return this.getters("currentAction") == "ack";
    },
    showTerminateModal() {
      return this.getters("currentAction") == "terminate";
    }
  },
  methods: {
    getters(getterName) {
      return this.$store.getters[`${this.sourceStore}/${getterName}`];
    },
    actions(actionName, data) {
      return this.$store.dispatch(`${this.sourceStore}/${actionName}`, data);
    },
    submitEscalation(data) {
      return this.actions("closeEscalateModal", data);
    },
    submitAcknowledgment(data) {
      return this.actions("closeAcknowledgmentModal", data);
    },
    submitTerminate(data) {
      return this.actions("closeTerminationModal", data);
    }
  }
};
</script>

<style></style>
