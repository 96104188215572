<template>
  <Ticker
    :data="statistics"
    :cardWidth="'500'"
    :slideEffect="true"
    :animationDuration="'30'"
    :timeToMove="'5'"
    :stopOnHover="true"
  >
    <template #tickerBody="{item}">
      <AnalyticCard :cardInfo="item"/>
    </template>
  </Ticker>
</template>
<script>
  import AnalyticCard from "./analytic-card";
  import Ticker from "../../../../components/Ticker/Ticker";

  export default {
    components: {
      Ticker,
      AnalyticCard,
    },
    mounted() {
    },
    props: {
      analyticCardsList: {
        type: Array,
        required: false,
        default: () => [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6},],
      },
      statistics: {
        type: Array,
        required: true,
      }
    },
  };
</script>

<style scoped>
  .flexRowBetween {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center !important;
  }

  .card-rows {
    display: flex;
    max-width: 100%;
    overflow: auto;
    margin-bottom: 25px;
    padding-bottom: 10px;
  }

  .card-rows::-webkit-scrollbar {
    cursor: pointer;
    height: 7px;
  }

  .card-rows::-webkit-scrollbar-thumb {
    background: #27293D;
  }
</style>
