<template>
  <card class="h-100 vsaas-devicess">
    <div class="row">
      <div class="col-12">
        <h4 class="m-0">Devices</h4>
      </div>
    </div>

    <div class="mt-4">
      <!--  -->
      <div class="row mb-3">
        <div class="col-12">
          <div class="row">
            <div
              class="rounded-circle d-flex justify-content-center align-items-center count mr-2 ml-4"
            >
              <inline-svg
                :src="require('@/assets/Icons/nvr.svg')"
                width="30"
                height="30"
                class="black-icon"
              />
            </div>
            <base-select v-model="nvr" :options="nvrOptions"></base-select>
          </div>
        </div>
      </div>

      <template v-if="nvr === 1">
        <div class="row mb-4 mr-1 ml-1 odd p-2">
          <div class="col-8 p-0">
            <div class="d-flex align-items-center justify-content-start">
              <div
                class="rounded-circle d-flex justify-content-center align-items-center count mr-2"
              >
                <inline-svg
                  :src="require('@/assets/Icons/nvr.svg')"
                  width="30"
                  height="30"
                  class="black-icon"
                />
              </div>
              <base-button type="primary btn-sm" round>DPPoCXVR</base-button>
              <!-- <base-checkbox :checked="true" disabled class="custom-check ml-2"></base-checkbox> -->
            </div>
          </div>
          <div class="col-4 text-right p-0">
            <div>
              <i
                class="fas fa-circle fa-xs mr-2"
                :style="{ color: colors.good }"
                v-if="DPPoCXVR"
              ></i>
              <i
                class="fas fa-circle fa-xs mr-2"
                :style="{ color: colors.problem }"
                v-else
              ></i>
              <span>{{ DPPoCXVR ? "connected" : "disconnected" }}</span>
            </div>
          </div>
        </div>

        <!--  -->
        <div class="row mb-4 mr-1 ml-1 odd p-2">
          <div class="col-8 p-0">
            <div class="d-flex align-items-center justify-content-start">
              <div
                class="rounded-circle d-flex justify-content-center align-items-center count mr-2"
                style="cursor: pointer"
                @click="openCameraShow = true"
              >
                <inline-svg
                  :src="require('@/assets/Icons/camera.svg')"
                  width="30"
                  height="30"
                  class="black-icon"
                />
              </div>
              <base-button
                type="primary btn-sm"
                round
                @click="openCameraShow = true"
                >Test Cam 1</base-button
              >
              <!-- <base-checkbox :checked="true" disabled class="custom-check ml-2"></base-checkbox> -->
            </div>
          </div>
          <div class="col-4 text-right p-0">
            <div>
              <i
                class="fas fa-circle fa-xs mr-2"
                :style="{ color: colors.good }"
                v-if="TestCam1"
              ></i>
              <i
                class="fas fa-circle fa-xs mr-2"
                :style="{ color: colors.problem }"
                v-else
              ></i>
              <span>{{ TestCam1 ? "connected" : "disconnected" }}</span>
            </div>
          </div>
        </div>

        <!--   -->
        <div class="row mb-4 mr-1 ml-1 odd p-2">
          <div class="col-8 p-0">
            <div class="d-flex align-items-center justify-content-start">
              <div
                class="rounded-circle d-flex justify-content-center align-items-center count mr-2"
              >
                <inline-svg
                  :src="require('@/assets/Icons/camera.svg')"
                  width="30"
                  height="30"
                  class="black-icon"
                />
              </div>
              <base-button type="primary btn-sm" round>Test Cam 2</base-button>
              <!-- <base-checkbox :checked="true" disabled class="custom-check ml-2"></base-checkbox> -->
            </div>
          </div>
          <div class="col-4 text-right p-0">
            <div>
              <i
                class="fas fa-circle fa-xs mr-2"
                :style="{ color: colors.good }"
                v-if="TestCam2"
              ></i>
              <i
                class="fas fa-circle fa-xs mr-2"
                :style="{ color: colors.problem }"
                v-else
              ></i>
              <span>{{ TestCam2 ? "connected" : "disconnected" }}</span>
            </div>
          </div>
        </div>

        <!--  -->
        <div class="row mb-4 mr-1 ml-1 odd p-2">
          <div class="col-8 p-0">
            <div class="d-flex align-items-center justify-content-start">
              <div
                class="rounded-circle d-flex justify-content-center align-items-center count mr-2"
              >
                <inline-svg
                  :src="require('@/assets/Icons/camera.svg')"
                  width="30"
                  height="30"
                  class="black-icon"
                />
              </div>
              <base-button type="primary btn-sm" round>Test Cam 3</base-button>
            </div>
          </div>
          <div class="col-4 text-right p-0">
            <div>
              <i
                class="fas fa-circle fa-xs mr-2"
                :style="{ color: colors.good }"
                v-if="TestCam3"
              ></i>
              <i
                class="fas fa-circle fa-xs mr-2"
                :style="{ color: colors.problem }"
                v-else
              ></i>
              <span>{{ TestCam3 ? "connected" : "disconnected" }}</span>
            </div>
          </div>
        </div>
        <div class="row mb-4 mr-1 ml-1 odd p-2">
          <div class="col-8 p-0">
            <div class="d-flex align-items-center justify-content-start">
              <div
                class="rounded-circle d-flex justify-content-center align-items-center count mr-2"
              >
                <inline-svg
                  :src="require('@/assets/Icons/camera.svg')"
                  width="30"
                  height="30"
                  class="black-icon"
                />
              </div>
              <base-button type="primary btn-sm" round>Test Cam 4</base-button>
            </div>
          </div>
          <div class="col-4 text-right p-0">
            <div>
              <i
                class="fas fa-circle fa-xs mr-2"
                :style="{ color: colors.good }"
                v-if="TestCam4"
              ></i>
              <i
                class="fas fa-circle fa-xs mr-2"
                :style="{ color: colors.problem }"
                v-else
              ></i>
              <span>{{ TestCam4 ? "connected" : "disconnected" }}</span>
            </div>
          </div>
        </div>
      </template>
      <template v-if="nvr === 2">
        <div class="row mb-4 mr-1 ml-1 odd p-2">
          <div class="col-8 p-0">
            <div class="d-flex align-items-center justify-content-start">
              <div
                class="rounded-circle d-flex justify-content-center align-items-center count mr-2"
              >
                <inline-svg
                  :src="require('@/assets/Icons/nvr.svg')"
                  width="30"
                  height="30"
                  class="black-icon"
                />
              </div>
              <base-button type="primary btn-sm" round>NVR 2</base-button>
              <!-- <base-checkbox :checked="true" disabled class="custom-check ml-2"></base-checkbox> -->
            </div>
          </div>
          <div class="col-4 text-right p-0">
            <div>
              <i
                class="fas fa-circle fa-xs mr-2"
                :style="{ color: colors.good }"
                v-if="nvrAvailable"
              ></i>
              <i
                class="fas fa-circle fa-xs mr-2"
                :style="{ color: colors.problem }"
                v-else
              ></i>
              <span>{{ nvrAvailable ? "connected" : "disconnected" }}</span>
            </div>
          </div>
        </div>

        <div class="row mb-4 mr-1 ml-1 odd p-2">
          <div class="col-8 p-0">
            <div class="d-flex align-items-center justify-content-start">
              <div
                class="rounded-circle d-flex justify-content-center align-items-center count mr-2"
                style="cursor: pointer"
                @click="openCameraShow = true"
              >
                <inline-svg
                  :src="require('@/assets/Icons/camera.svg')"
                  width="30"
                  height="30"
                  class="black-icon"
                />
              </div>
              <base-button
                type="primary btn-sm"
                round
                @click="openCameraShow = true"
                >Cam 1</base-button
              >
              <!-- <base-checkbox :checked="true" disabled class="custom-check ml-2"></base-checkbox> -->
            </div>
          </div>
          <div class="col-4 text-right p-0">
            <div>
              <i
                class="fas fa-circle fa-xs mr-2"
                :style="{ color: colors.good }"
                v-if="cameraAvailable"
              ></i>
              <i
                class="fas fa-circle fa-xs mr-2"
                :style="{ color: colors.problem }"
                v-else
              ></i>
              <span>{{ cameraAvailable ? "connected" : "disconnected" }}</span>
            </div>
          </div>
        </div>

      </template>

      <!--  -->
    </div>

    <!-- camera show -->
    <SlideYUpTransition :duration="500" v-if="openCameraShow">
      <camera-show v-if="openCameraShow" @close="openCameraShow = false" />
    </SlideYUpTransition>
  </card>
</template>
<script>
import { BaseCheckbox, BaseRadio } from "src/components/index";
import CameraShow from "./CameraShow.vue";
import { SlideYUpTransition } from "vue2-transitions";
import { colors } from "src/util/helper.js";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BaseCheckbox,
    CameraShow,
    SlideYUpTransition,
  },
  data() {
    return {
      colors,
      openCameraShow: false,
      selected_camera: null,
      
      nvrOptions: [
        {
          value: 1,
          label: "DP PoC XVR",
        },
        {
          value: 2,
          label: "NVR 2",
        },
      ],
    };
  },
  computed: {
    ...mapState("VsassStore", {
      selected_nvr:state=>state.selected_nvr,
      nvrAvailable: (state) => state.nvrAvailable,
      cameraAvailable: (state) => state.cameraAvailable,
      DPPoCXVR: (state) => state.DPPoCXVR,
      TestCam1: (state) => state.TestCam1,
      TestCam2: (state) => state.TestCam2,
      TestCam3: (state) => state.TestCam3,
      TestCam4: (state) => state.TestCam4,
    }),

    nvr:{
      get(){
        return this.selected_nvr
      },
      set(value){
        this.setNVR(value)
      }
    }
    
  },
  mounted() {
    this.timer = setInterval(() => {
      this.checkConnection();
    }, 2000);
  },
  methods: {
    ...mapActions("VsassStore", [
      "checkConnection",
      "setNVR"
      ]),
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped>
.count {
  width: 40px;
  height: 40px;
  background-color: #d650d5;
}

.areas i {
  font-size: 20px;
}
.odd {
  background-color: #2f324a;
  border-radius: 10px;
}
.icon-simple-remove {
  color: #f5465c;
  font-weight: bolder;
}
</style>
<style>
.black-icon path {
  fill: #2f324a;
}
.custom-check input {
  position: relative !important;
}
.settings-icon path {
  fill: #a5f560;
}
.worker-icon path {
  fill: #fff;
}
.areas-main-icon path {
  fill: #00ced4;
}
</style>
