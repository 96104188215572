<template>
  <div class="create-edit-template">
    <card>
      <div class="row">
        <div class="col-md-4">
          <base-input
            label="Template Name"
            :placeholder="$t('Template Name')"
            v-model="templateNameComp"
          >
          </base-input>
        </div>
        <div class="col-md-2">
            <base-select
              v-model="reminderComp"
              :filterable="true"
              :options="reminderOptions"
              :label="'Reminder'"
            ></base-select>
        </div>

      </div>
    </card>

    <template v-if="canModule(M.alarms)">
      <TemplateFilter
        v-if="isFilterTemplateOpen"
        @changedFilter="changedFilter"
        @isFilterChanged="isFilterChanged"
        :sendedFilter="sendedFilter"
      />
      <div class="row">
      <div class="col-md-4 mb-2">
        <base-button @click="globalFilter" type="primary" size="sm"
          >Global Filter</base-button
        >
      </div>
      </div>
      <OperationalAlarmTable :allSensors="allSensors"
      :sendedOperational="sendedOperational"
      :isCreate="isCreate"
      @operationalChanged="operationalChanged"
      ref="globalSelectorRef"
      />
    </template>
    <StatusAlarmTable
      v-if="canModule(M.status_alarms)"
      :sendedStatus="sendedStatus"
      :isCreate="isCreate"
      @statusChanged="statusChanged"
    />
    <TemplateGlobalFilter
      v-if="isGlobalFilterOpen == true"
      @handleGlobalSelector="handleGlobalSelector"
      :isGlobalFilterOpen="isGlobalFilterOpen"
    />
    <div>
      <base-button
        :disabled="!isSubmitButtonActive"
        type="primary"
        v-if="templateId != undefined"
        @click="handleSubmit('edit')"
        >Edit</base-button
      >
      <base-button :disabled="!isSubmitButtonActive" type="primary" v-else @click="handleSubmit('create')"
        >Create</base-button
      >
    </div>
    <CorrectDataMessage v-if="isConfirmDataMessageModalOpen" :isConfirmDataMessageModalOpen="isConfirmDataMessageModalOpen"
    @confirmCorrectDataMsg="confirmCorrectDataMsg"/>
  </div>
</template>
<script>
import OperationalAlarmTable from "./tables/OperationalAlarmTable";
import StatusAlarmTable from "./tables/StatusAlarmTable";
import TemplateFilter from "./Filter/TemplateFilter";
import { mapGetters, mapActions } from "vuex";
import TemplateGlobalFilter from "./Filter/TemplateGlobalFilter";
import statusSensor from "./initial-status-sensor";
import reminderOptionsData from './reminder-options';
import {CorrectOperational} from './utlilities';
import CorrectDataMessage from './CorrectDataMessage';

export default {
  name: "CreateEditTempate",
  components: {
    OperationalAlarmTable,
    StatusAlarmTable,
    TemplateFilter,
    TemplateGlobalFilter,
    CorrectDataMessage
  },

  data: function () {
    return {
      isGlobalFilterOpen: false,
      isCreate:true,
      isFilterChangedValue:false,
      isFilterComeFromChild:false,
      templateName: "",
      companyId: 0,
      templateId: 0,
      isFilterTemplateOpen: true,
      filter: {
        structure_id: undefined,
        community_id: undefined,
        customer_id: null
        },
      reminder: "never",
      reminderOptions:reminderOptionsData,
      isConfirmDataMessageModalOpen:false
    };
  },
  watch:{
    template(newValue,oldValue)
    {
      this.templateName=newValue.name;
      this.reminder=newValue.reminder;
    }
  },
  computed: {
    ...mapGetters({
      allSensors:"SwamStore/NotificationsTemplate/allSensors",
      template:"SwamStore/NotificationsTemplate/template"
    }),
    templateNameComp: {
      get() {
        return this.templateName;
      },
      set(val) {
        this.templateName = val;
      },
    },
    reminderComp: {
      get() {
         return this.reminder;
      },
      set(val) {
        this.reminder = val;
      },
    },
    sendedFilter: {
      get() {
        let temp = {
          structure_id: null,
          community_id: null,
          customer_id: null,
        };
        if (this.templateId) {
          temp.structure_id = this.template.structure_id;
          temp.community_id = this.template.community_id;
          temp.customer_id = this.template.customer_id;
          // eslint-disable-next-line
          this.operational=this.template.operational;
        }
        if(this.templateId&&!this.isFilterComeFromChild)
        {
          // eslint-disable-next-line
          this.filter.structure_id = this.template.structure_id;
          // eslint-disable-next-line
          this.filter.community_id = this.template.community_id;
          // eslint-disable-next-line
          this.filter.customer_id = this.template.customer_id;
        }
        return temp;
      },
    },
    sendedStatus: {
      get() {
        let status = [];
        if (this.templateId) {
          status = [...this.template.status];
        }
        return status;
      },
    },
    sendedOperational:{
      get() {
        let operational = {...this.template.operational};
        if(this.templateId)
        {
          // eslint-disable-next-line
          this.isCreate=false;
        }
        return operational;
      },
    },
    isSubmitButtonActive(){
      let isTempalteNameEmpty=this.templateName=="";
      return !isTempalteNameEmpty;
    }

  },
  beforeMount() {
    this.companyId = this.$route.query.companyId;
    this.templateId = this.$route.query.templateId;
    this.getAllSensores();
    if (this.templateId) {
      this.getTemplateById(this.templateId);
    }
    else
    {
      this.getOperationalSensorsByFilter(this.filter);
    }
  },
  methods: {
    ...mapActions({
      getTemplateById:"SwamStore/NotificationsTemplate/getTemplateById",
      getAllSensores:"SwamStore/NotificationsTemplate/getAllSensores",
      resetTemplate:"SwamStore/NotificationsTemplate/resetTemplate",
      createTemplate:"SwamStore/NotificationsTemplate/createTemplate",
      updateTemplate:"SwamStore/NotificationsTemplate/updateTemplate",
      getOperationalSensorsByFilter:"SwamStore/NotificationsTemplate/getOperationalSensorsByFilter"
    }),
    confirmCorrectDataMsg(status){
      this.isConfirmDataMessageModalOpen=false
    },
    globalFilter() {
      this.isGlobalFilterOpen = true;
    },
    statusChanged(status) {
      this.status = [...status];
    },
    operationalChanged(operational)
    {
      this.operational = operational;
    },
    changedFilter(filter) {
      this.filter = Object.assign({},filter);
      this.isFilterComeFromChild=true;
      this.getOperationalSensorsByFilter(this.filter);
    },
    isFilterChanged(val){
      this.isFilterChangedValue=val;
    },
    handleSubmit(type) {
      // function to correct the operational array as it should be sent
      let correctedOperational=CorrectOperational(this.operational);
      if(this.isFilterChangedValue)
      {
        this.isConfirmDataMessageModalOpen=true
      }
      else
      {
      let template={
          company_id: this.companyId,
          name: this.templateName,
          reminder: this.reminder,
          operational: correctedOperational,
          status: this.status,
          structure_id: this.filter.structure_id,
          community_id: this.filter.community_id,
          customer_id: this.filter.customer_id,
      }
      if (type == "create") {
        this.createTemplate({payload:template}).then(res=>
          this.$router.push(`/SWAM/notifications-templates/list/${this.companyId}`)
          .catch(err=>this.$router.push(`/SWAM/notifications-templates/list/${this.companyId}`))
        )
      }
      else {
        this.updateTemplate({templateId:this.templateId,payload:template}).then(res=>
          this.$router.push(`/SWAM/notifications-templates/list/${this.companyId}`))
          .catch(err=>this.$router.push(`/SWAM/notifications-templates/list/${this.companyId}`))
      }
    }
    },
    handleGlobalSelector(result) {
      let { globalSelector, status } = result;
      if (status)
      {
        if(this.$refs["globalSelectorRef"])
        {
          this.$refs["globalSelectorRef"].applyGlobalSelector(globalSelector);
        }
      }
      this.isGlobalFilterOpen = false;
    },
  },
};
</script>
