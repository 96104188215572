<template>
  <Ticker :data="statistics"
          :slideEffect="true"
          :animationDuration="'30'"
          :cardWidth="450"
          :timeToMove="5"
          :stopOnHover="true"
  >
    <template #tickerBody="{item}">
      <AnalyticCard :cardInfo="item"/>
    </template>
  </Ticker>
</template>

<script>
  import AnalyticCard from "./analytic-card";
  import Ticker from "../../../../components/Ticker/Ticker";

  export default {
    components: {
      Ticker,
      AnalyticCard,
    },
    mounted() {
    },
    props: {
      analyticCardsList: {
        type: Array,
        required: false,
        default: () => [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6},],
      },
      statistics: {
        type: Array,
        required: true,
      }
    },
  };
</script>

<style scoped>

</style>
