<template>
  <card v-loading="loading">
    <el-table :data="data" class="striped-table">
      <el-table-column prop="name" label="Name" width="160"  align="center"/>
      <el-table-column prop="battery_life" label="Battery life" width="160"  align="center"/>
      <el-table-column prop="battery_level" label="Battery level" width="160"  align="center"/>
      <el-table-column prop="battery_temperature" label="Battery temperature" width="160"  align="center"/>
      <el-table-column prop="model" label="Model" width="160" align="center" />
      <el-table-column prop="speed_limit" label="Speed limit" width="160" align="center" />
      <el-table-column label="Created at" width="200" align="center">
        <template slot-scope="{ row }">
          {{ row.created_at ? dateFormat(row.created_at) : "--" }}
        </template>
      </el-table-column>
      <el-table-column
        :fixed="$rtl.isRTL ? false : 'right'"
        :label="$tc('report.action', 2)"
        align="center"
        width="150"
      >
        <div slot-scope="{ row }" class="row justify-content-between m-0">
          <div class="col-12 mt-1 d-flex justify-content-center align-items-center">
            <router-link :to="{path: `/globalmobility/scooter/${row.id}`}">
              <base-button
                class="mx-2 px-2 py-1"
                simple
                size="sm"
                type="secondary"
                round>
                <i class="fa fa-info"></i>
              </base-button>
            </router-link>
          </div>
        </div>
      </el-table-column>
    </el-table>
    <TablePagination
      slot="footer"
      ref="paginator"
      :initialPerPage="5"
      v-if="totalRecords"
      :total="totalRecords"
      @change="updatePagination"
    ></TablePagination>
  </card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { dateFormat } from "@/util/helper"
import TablePagination from "@/components/TablePagination.vue";

export default {
  data: () => ({
    columns: [],
    pagination: {
      offset: 0,
      limit: 5,
    },
  }),
  components:{TablePagination},
  computed: {
    ...mapGetters("GlobalMobility/Scooters", ["data", "loading", "totalRecords"])
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions("GlobalMobility/Scooters", ["fetchScootersData"]),
    updatePagination(data) {
      this.pagination.limit = data.limit;
      this.pagination.offset = data.offset;
      this.fetchData();
    },
    fetchData(){
      const payload = {
        limit: this.pagination.limit,
        offset: this.pagination.offset,
      }
      this.fetchScootersData(payload)
    },
    dateFormat
  }
};
</script>

<style></style>
