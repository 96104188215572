<template>
  <card title="Add Lock" class="add-lock">
    <inputs-above-map @imageChanged="changeImage" :img="lockImg"></inputs-above-map>
    <map-section></map-section>
    <inputs-under-map></inputs-under-map>
    <template slot=footer>
      <div class="d-flex align-items-center justify-content-end mt-2 mr-2 mb-3">
        <base-button style="border-radius: 1px" class="btn btn-primary btn-simple mr-2">Cancel</base-button>
        <base-button style="border-radius: 1px" class="btn btn-primary ">Add Lock</base-button>
      </div>
    </template>
  </card>
</template>

<script>
import InputsAboveMap from './components/InputsAboveMap'
import MapSection from './components/MapSection'
import InputsUnderMap from './components/InputsUnderMap'
import { mapActions } from 'vuex'
export default {
  name: "add-lock",
  components:{
    InputsAboveMap,
    MapSection,
    InputsUnderMap,
  },
  data(){
    return{
      base64: "",
      lockImg: ""
    }
  },
  methods:{
    ...mapActions('SmartLockStore/AddLock', ['add']),
    changeImage(val){
      this.base64 = val
      // get img from backend after upload 
      this.lockImg = require('@/assets/Icons/smartlock/lock-24px.svg')
       
    },
  }
}
</script>