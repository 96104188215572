<template>
  <el-dialog :title="'Moro Verification'" :visible.sync="show">
      <div class="modal-container p-1">
        <card>
          <company-contacts-table :contacts="contacts" />
        </card>
        <div>
          <div class="d-flex py-3 px-2">
            <div class="d-flex flex-column w-50">
              <base-radio inline name="false alarm" v-model="type">{{$t('intrusion.falseAlarm')}}</base-radio>
              <base-radio inline name="verified intrusion" v-model="type">{{$t('intrusion.verifiedIntrusion')}}</base-radio>
              <base-radio inline name="customer did not respond" v-model="type">{{$t('intrusion.customerDidntRespond')}}</base-radio>
              <base-radio inline name="other" v-model="type">{{$t('other')}}</base-radio>
            </div>
            <div>
              <base-checkbox v-model="form.escalate">{{$t('intrusion.escalateToPolice')}}</base-checkbox>
            </div>
          </div>
          <div class="py-3" v-if="type == 'other'">
            <base-input :label="$t('comment')">
            <textarea
              class="form-control"
              :placeholder="$t('comment')"
              v-model="form.comment"
            />
            </base-input>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <base-button
            type="primary"
            size="sm"
            class="mx-1 ack_button"
            @click="submit()"
          >
            {{ $t('Submit') }}
          </base-button>
        </div>
      </div>
  </el-dialog>
</template>

<script>
import CompanyContactsTable from '../../../../pages/Profiles/components/CompanyContactsTable.vue'
import { BaseRadio, BaseCheckbox } from "src/components"

export default {
  name: "verification-modal",
  components: { CompanyContactsTable, BaseRadio, BaseCheckbox },
  props:{
      contacts:{
          type:Array,
          default:() => []
      },
    isOpen: {
        type: Boolean
    }
  },
  computed:{
      show:{
          get(){
            return this.isOpen;
          },
          set(val){
            this.$emit('close')
            this.resetForm()
          }
      }
  },
  data: () => ({
    type: '',
    form: {
      escalate: false,
      comment: ''
    },
  }),
  mounted() {
      this.show = this.isOpen
  },
  methods: {
    submit() {
      if (this.type != 'other') {
        this.form.comment = this.type;
      }
      this.form.reason = this.type;
      this.$emit('submit', this.form)
      this.resetForm()
    },
    resetForm() {
      this.type = ''

      this.form = {
        escalate: false,
        comment: ''
      }
    }
  },
  watch: {
    type(val) {
      if (val == 'false alarm' || val == 'other' || val == '')
        this.form.escalate = false
      else
        this.form.escalate = true

      if (val == 'other') {
        this.form.comment = ''
      }
    },
  }
}
</script>

<style>
  .modal-container {
    max-height: 60vh;
    overflow-y: auto;
  }
</style>