<template>
  <div>
      <div class="row">
          <div class="col-12">
            <ProgressBar
                :data="progressBarData"
            />
          </div>
      </div>
      <div class="d-flex">
          <div v-for="(element,i) in data" :key="i" class="d-flex align-items-center mr-4">
              <span class="point mr-1" :class="status[i] + '-background'"></span>
              <p class="font-weight-bold d-inline-block m-0">{{ element.key }}</p>
          </div>
      </div>

  </div>
</template>

<script>
import ProgressBar from '@/components/progress-bar'
export default {
    props: {
        data: {
            type: Array,
            description: "[{key:'',value:}]"
        },
    },
    components: {
        ProgressBar
    },
    data() {
        return {
            status: ['problem','good','ok','poor']
        }
    },
    computed: {
        progressBarData() {
            let result = [];
            this.data.forEach((element,i) => {
                result.push({state:this.status[i],value:element.value});
            });
            return result;
        }
    }
}
</script>

<style lang="scss" scoped>

.point{
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

</style>