<template>
  <card>
    <div class="row">
      <div class="col-6 border-right">
        <div class="d-flex flex-column justify-content-center align-items-center h-100">
          <div class="zone-icon">
            <inline-svg :src="cardIcon" />
          </div>
          <!-- <span class="m1-2">
            {{ cardInfo.active_alarms }}
          </span> -->
          <span class="text-text-muted m-2">
            {{ cardInfo.police_station_name }}
          </span>
        </div>
      </div>
      <div class="col-6 border-left">
        <div class="d-flex flex-column justify-content-center align-items-center h-100 p-1">
          <div class="d-flex justify-content-between align-items-center w-100">
            <div>
              <i class="ml-2 fas fa-exclamation-triangle text-warning" />
              <span class="ml-2 text-warning">Active</span>
            </div>
            <span class="font-weight-bold text-warning">
              {{ cardInfo.active_alarms }}
            </span>
          </div>
          <div class="d-flex justify-content-between align-items-center w-100 mt-2">
            <div>
              <i class="ml-2 fas fa-exclamation-triangle" />
              <span class="ml-2">This Week</span>
            </div>
            <span class="font-weight-bold">
              {{ cardInfo.week }}
            </span>
          </div>
          <div class="d-flex justify-content-between align-items-center w-100 mt-2">
            <div>
              <i class="ml-2 fas fa-exclamation-triangle" />
              <span class="ml-2">This Month</span>
            </div>
            <span class="font-weight-bold">
              {{ cardInfo.month }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
export default {
  props: {
    cardInfo: Object
  },
  data() {
    return {
      cardIcon: require(`src/assets/icons-2/google-map-marker.svg`)
    };
  },
  mounted() {
    console.log('sdsd', this.cardInfo);
  }
};
</script>

<style scoped lang="scss">
.zone-icon{
  background: #ECF6FF;
  border: 1px solid #42b883;
  width: 45px;
  border-radius: 50%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 25px;
    height: 25px;
    margin-bottom: 3px;
  }
}

</style>
