<template>
  <div>
    <card class="ticket-details">
      <div slot="header" class="col-lg-12 col-xs-12 btn-header mb-3">
        <!-- <router-link to="/tickets/ticket-group" tag="div" style="max-width:100px;">
            <h5 style="color:white;max-width:100px;" class="dash-title my-a">
              <i class="fa fa-arrow-left m-2 fa-xx" style="cursor: pointer;"></i>back
            </h5>
        </router-link>-->
        <router-link to="/tickets/view" tag="button" class="btn btn-info routeCl">Check All Tickets</router-link>
        <router-link to="/tickets/create" tag="button" class="btn btn-success routeCl" v-if="canPage(P.create_ticket)">
          <i class="fa fa-plus"></i> Creat New Tickets
        </router-link>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-4 col-xs-12">
            <card>
              <div>
                <div class="title">Details</div>
                <div class="details-menu">
                  <ul class="ul-D">
                    <li>
                      <span class="detail-title">ID</span>
                      <span class="detail-info">{{ticket.id}}</span>
                    </li>
                    <!-- <li>
                  <span class="detail-title">Origin</span>
                  <span class="detail-info">Customer Portal</span>
                    </li>-->
                    <li>
                      <span class="detail-title">Created</span>
                      <span class="detail-info">{{ticket.created_at}}</span>
                    </li>
                    <!-- <li v-if="ticket.updated_at">
                  <span class="detail-title">Updated</span>
                  <span class="detail-info">{{ticket.updated_at}}</span>
                    </li>-->
                    <li>
                      <span class="detail-title">By</span>
                      <span class="detail-info">{{ticket.from}}</span>
                    </li>
                    <li>
                      <span class="detail-title">To</span>
                      <span class="detail-info">{{ticket.to}}</span>
                    </li>
                    <li>
                      <span class="detail-title">Status</span>
                      <span class="detail-info">{{statusText(ticket.status)}}</span>
                    </li>
                    <li class="d-flex">
                      <span class="detail-title">Mark As</span>
                      <span class="detail-info d-flex">
                        <div class="detail-info-divU">
                          <div>
                            <base-button
                              class="animation-on-hover but-Multi"
                              type="warning"
                              :loading="loading"
                              @click="markAs('pending')"
                            >Pending</base-button>
                          </div>
                          <div>
                            <base-button
                              class="animation-on-hover but-Multi"
                              type="default"
                              :loading="loading"
                              @click="markAs('closed')"
                            >Closed</base-button>
                          </div>
                        </div>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </card>
          </div>
          <div class="col-lg-8 col-xs-12">
            <card>
              <div class="ticket-content-container">
                <div id="vue-editor" class="ticket-title">{{ticket.subject}}</div>
                <div class="ticket-contents">
                  <div v-if="showEditor" class="ticket-content-card">
                    <vue-editor
                      :disabled="loading"
                      placeholder="Enter Your Reply"
                      v-model="currentMessage"
                      class="my-editor"
                    />
                    <div class="row mt-1 justify-content-around">
                      <base-button
                        class="animation-on-hover but-MultiTW"
                        type="danger"
                        @click="showEditor = false"
                        :loading="loading"
                      >Cancel</base-button>

                      <base-button
                        class="animation-on-hover but-MultiTW"
                        type="success"
                        @click="sendMessage"
                        :loading="loading"
                      >Send</base-button>
                    </div>
                  </div>

                  <div
                    class="ticket-content-card"
                    v-for="message in ticket.messages"
                    :key="message.id"
                  >
                    <div class="ticket-date-details">
                      <div class="ticket-owner">
                        Created By :
                        <span class="owner-email">{{message.from}}</span>
                      </div>
                      <div class="ticket-date">{{message.date}}</div>
                    </div>
                    <div class="ticket-content ql-editor ql-style">
                      <div v-html="message.content"></div>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </card>
    <a href="#vue-editor" @click="show" class="float">
      <i class="fa fa-plus my-float fa-lg"></i>
    </a>
  </div>
</template>

<script>
class Message {
  content;
  date;
  from;
  id;
  constructor(id, content, date, from) {
    this.content = content;
    this.date = date;
    this.from = from;
    this.id = id;
  }
}
class Ticket {
  id;
  status;
  origin;
  created_at;
  from;
  subject;
  messages = [];
  to;
  firstMessage;
  constructor(id, status, origin, created_at, from, to, subject, firstMessage) {
    this.id = id;
    this.status = status;
    this.origin = origin;
    this.created_at = created_at;
    this.from = from;
    this.to = to;
    this.subject = subject;
    this.firstMessage = firstMessage;
    this.messages.push(firstMessage);
  }
}
import { VueEditor } from "vue2-editor";
import { mixin } from "./TicketsHelper";
export default {
  props: ["id"],
  mixins: [mixin],
  data() {
    return {
      ticket: new Ticket(
        1,
        "open",
        null,
        "2020-4-7 10:50",
        null,
        "test@test.com",
        "test",
        "testing",
        new Message(1, "<h1>Hello world</h1>", "2020-4-8 13:50", "me@me.com")
      ),
      showEditor: false,
      currentMessage: null,
      raw: ``,
      loading: false
    };
  },
  created() {
    const room = this.$store.getters.openedRoom;

    if (!room) {
      this.$router.push("/tickets");
      return;
    }

    this.ticket = new Ticket(
      this.id,
      room.status,
      null,
      room.created_at,
      room.fromWho,
      room.to.name,
      room.subject,
      new Message(room.id, room.content, room.created_at, room.fromWho)
    );
    this.getMessages();
  },
  mounted() {},
  methods: {
    getMessages() {
      this.loading = true;
      this.$api
        // .get("messages/type/answer?parent_id=" + this.id)
        .get(`messages/type/answer/parentType/ticket/parentId/${this.id}`)
        .then(res => {
          console.log(res);
          const arr = res.data.data;
          const msgs = [];
          arr.forEach(msg => {
            msgs.push(
              new Message(
                msg.id,
                msg.content,
                this.gDateFormat(msg.created_at),
                msg.fromWho
              )
            );
          });
          this.ticket.messages = msgs; //.reverse(); //temproray solution
          this.ticket.messages.push(this.ticket.firstMessage);
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    sendMessage() {
      this.loading = true;
      this.$api
        .post(`messages/parentType/ticket/parentId/${this.id}`, {
          content: this.currentMessage,
          type: "answer"
        })
        .then(res => {
          this.$api
            .get(`messageReceivers/${this.id}`)
            .then(res2 => {
              if (res2.data.isReceiver) {
                this.ticket.status = res2.data.data.receiver_status;
              } else {
                this.ticket.status = res2.data.data.owner_status;
              }
              this.ticket.messages.unshift(
                new Message(
                  res.data.messageId,
                  this.currentMessage,
                  this.gDateFormat(res2.data.data.last_action_at),
                  this.$store.state.user.username
                )
              );
              this.currentMessage = null;
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
          this.showEditor = false;
        })
        .catch(() => {});
    },
    markAs(newStatus) {
      if (newStatus == this.ticket.status) {
        return;
      }
      this.loading = true;
      this.$api
        .patch(`messageReceivers/${this.id}`, { status: newStatus })
        .then(() => {
          this.ticket.status = newStatus;
          this.$notify({
            group: "main",
            type: "success",
            message: `Marked As ${this.statusText(newStatus)}!`
          });
        })
        .catch(() => {
          //  this.$errorHandler(`Something went wrong, Try again later.`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    show() {
      this.showEditor = true;
    }
  },
  components: {
    VueEditor
  }
};
</script>

<style scoped>
.card {
  -webkit-box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.5);
}
/* plus button*/
.float {
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 40px;
  right: 50px;
  background: linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3);
  color: #fff;
  border-radius: 50px;
  text-align: center;
}

.my-float {
  margin-top: 27px;
  color: white;
}
/* plus button*/
.my-editor {
  background: white !important;
  color: black;
}
.my-editor >>> .ql-container {
  max-height: 45vh;
  overflow: auto;
}

.my-editor >>> .ql-container::-webkit-scrollbar {
  width: 1em;
}
.my-editor >>> .ql-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.my-editor >>> .ql-container::-webkit-scrollbar-thumb {
  background-color: #2a3643;
  outline: 1px solid #30404d;
}
.title,
.ticket-title {
  font-weight: 600;
  padding: 20px 20px;
  background: #1e1e2e;
  color: white;
}
.ticket-content-card {
  margin-top: 3em;
}
.ticket-details-menu,
.ticket-content {
  margin-top: 30px;
}

@media (min-width: 576px) {
  .routeCl {
    width: 24%;
    margin-right: 10px;
    padding: 10px 5px;
  }
  .but-MultiTW {
    width: 93px;
    padding: 7px 14px;
  }
  .but-Multi {
    width: 35%;
    padding: 7px 14px;
  }
}

@media (min-width: 768px) {
  .routeCl {
    width: 23%;
    margin-right: 10px;
    padding: 10px 5px;
  }
  .but-Multi {
    width: 35%;
    padding: 7px 14px;
  }
}

@media (min-width: 992px) {
  .but-Multi {
    width: 90%;
    padding: 7px 14px;
  }
  .routeCl {
    width: 19%;
    margin-right: 10px;
    padding: 10px 5px;
  }
}

@media (min-width: 1200px) {
  .routeCl {
    width: 15%;
    margin-right: 10px;
    padding: 10px 5px;
  }
}

.ul-D {
  list-style: none;
  line-height: 3em;
  padding-left: 0px;
}
.ul-D li {
  padding: 5px;
  border-bottom: 1px solid var(--gray);
  box-shadow: 1px 1px 4px #14181d;
}

.ul-D li span:first-child {
  padding-right: 9px;
  color: #3da675;
  display: inline-block;
  width: 35%;
  font-weight: 600;
  font-size: medium;
}
.detail-info {
  width: 65%;
}
.detail-info-divU {
  width: 100%;
}
.detail-info-divU div {
  width: 50%;
  display: inline-block;
}
.ticket-date-details {
  padding: 20px 20px;
  background-color: #1e1e2e;
  font-weight: 600;
  color: white;
  border-radius: 5px 5px 0px 0;
}

.ticket-date-details div {
  display: inline-block;
  width: 50%;
}
.ticket-date-details div:nth-child(n + 2) {
  text-align: right;
}
.ql-style {
  margin-top: 0px !important;
  padding: 20px;
  color: rgb(30, 30, 39) !important;
  background-color: white;
  border-radius: 0 0 5px 5px;
}
::v-deep .ql-editor * {
  color: black;
}
</style>