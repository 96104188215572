<template>
  <card class="overflow-hidden full-height-card" bodyClasses="p-0">
    <div>
      <base-map :zoom="11" :center="center">
        <template v-for="(marker, index) in mapMarkers">
          <base-map-marker
            :position="marker"
            :title="marker.structure_name"
            :label="markerLabelFormatter(marker)"
            @click="onMarkerClick(marker)"
            :key="index"
            :icon="markerIcon"
          ></base-map-marker>
        </template>
        <base-heatmap v-if="showHeatmap" :heatmapData="heatmapData" :radius="60"></base-heatmap>
      </base-map>
    </div>
    <div slot="footer">
      <slot name="footer">
        <div>
          <slot name="left-footer"></slot>
        </div>
        <div>
          <slot name="right-footer"></slot>
        </div>
      </slot>
    </div>
  </card>
</template>

<script>
import BaseMap from "src/components/BaseMap/Map.vue";
import MapMarker from "src/components/BaseMap/MapMarker.vue";
import HeatMap from "src/components/BaseMap/HeatMap.vue";
import { mapState } from "vuex";
export default {
  name: "villas-locations",
  props: {
    center: {
      default: () => ({
        lat: 25.221643,
        lng: 55.255257
      })
    },
    displayHeatMap: {
      type: Boolean,
      default: false
    },
    markerIcon: {
      default() {
        return require("src/assets/icons-2/google-map-marker.svg");
      }
    },
    height: {
      type: String,
      default: "250px"
    },
    showHeatmap: {
      type: Boolean,
      default: false
    },
    markers:{
      type: Array,
      default: ()=>([])
    },
    markerLabelFormatter:{
      type: Function,
      default: ()=> "",
    }
  },
  computed: {
    mapMarkers() {
      return this.markers.map(marker => {
        return { ...marker, lat: +marker.lat, lng: +marker.lng };
      });
    },
    heatmapData() {
      return this.mapMarkers.map(marker => ({
        lat: marker.lat,
        lng: marker.lng,
        weight: marker.activeIntrusionAlarms,
      }));
    }
  },
  methods: {
    onMarkerClick(event) {
      this.$emit("markerClick", event);
    },
    getMarkerLabel(marker) {
      return `${marker.devicesCount}`;
    }
  },
  components: {
    [BaseMap.name]: BaseMap,
    [MapMarker.name]: MapMarker,
    [HeatMap.name]: HeatMap
  }
};
</script>

<style>
</style>
