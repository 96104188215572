<template>
  <div class="main-ticker-area">
    <div class="slide-effect"
         v-if="slideEffect"
         @mouseover="stopSlide()"
         @mouseleave="runSlide()"
    >
      <div class="slide-effect-wrapper">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="col-12 col-md-6 col-lg-3 ticker-item"
          :class="{'custom-width' : !slideEffect  , 'active' : index === 0}"
          :style="{'width' : `${cardWidth}px`}"
        >
          <slot name="tickerBody" :item="item"></slot>
        </div>
      </div>
    </div>
    <div class="row ticker-container" v-else>
      <div class="ticker-wrap row">
        <div class="ticker-move row" :style="{animationDuration : animationDuration + 's'}"
             :class="{'stop-on-hover' : stopOnHover}">
          <div
            v-for="(item, index) in data"
            :key="index"
            class="col-12 col-md-6 col-lg-3 ticker-item"
            :class="{'custom-width' : !slideEffect}"
            :style="{'width' : `${cardWidth}px`}"
          >
            <slot name="tickerBody" :item="item"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Ticker",
    props: {
      /**
       * @desc main data
       * @return array
       * @author karam mustafa
       * */
      data: {
        type: Array,
      },
      /**
       * @desc determine the slider type
       * @return boolean
       * @author karam mustafa
       * */
      slideEffect: {
        type: Boolean,
        default: true,
      },
      /**
       * @desc determine animation duration , this work if slideEffect is false
       * @return string
       * @author karam mustafa
       * */
      animationDuration: {
        default: '20s',
      },
      /**
       * @desc stop slider runing if this props true
       * @return integer
       * @author karam mustafa
       * */
      stopOnHover: {
        default: false,
      },
      /**
       * @desc for slideEffect , the value passes in second
       * @return integer
       * @author karam mustafa
       * */
      timeToMove: {
        default: '2',
      },
      /**
       * @desc determine shown card width ,  the value passes in [px]
       * @return integer
       * @author karam mustafa
       * */
      cardWidth: {
        default: '330',
      },
    },
    data() {
      return {
        /**
         * @return integer
         * @author karam mustafa
         * */
        stopAnimation: true,
        /**
         * @desc interval action
         * @return mixed
         * @author karam mustafa
         * */
        interval: '',
        /**
         * @desc count of all card width process to translateX
         * @return integer
         * @author karam mustafa
         * */
        count: 0,
        /**
         * @return integer
         * @author karam mustafa
         * */
        index: -1,
        /**
         * @return array
         * @author karam mustafa
         * */
        initData: [],
      }
    },
    destroyed() {
      this.data = this.initData;
      clearInterval(this.interval);
    },
    mounted() {
      this.checkIfDataIsset();
    },
    computed: {
      /**
       * @desc parent vue component , get the node .slide-effect div
       * @author karam mustafa
       * */
      parentTicker() {
        return this.$el.querySelector('.slide-effect');
      },
    },
    methods: {
      /**
       * @desc check if parent slide-effect div could contains all child without slide
       * @return boolean
       * @author karam mustafa
       * */
      cardsWidthIsOut() {
        let parent = this.$el;
        let countChildWidth = this.getCountChildWidth();
        return countChildWidth > parent.clientWidth;
      },
      /**
       * @return void
       * @author karam mustafa
       * */
      applyAnimationMethod() {
        if (this.cardsWidthIsOut()) {
          this.interval = this.setNewInterval();
        }
      },
      /**
       * @return void
       * @author karam mustafa
       * */
      stopSlide() {
        if (this.stopOnHover) {
          clearInterval(this.interval);
        }
      },
      /**
       * @return void
       * @author karam mustafa
       * */
      runSlide() {
        if (this.stopOnHover && this.cardsWidthIsOut()) {
          this.interval = this.setNewInterval();
        }
      },
      /**
       * @desc get count of children width
       * @return integer
       * @author karam mustafa
       * */
      getCountChildWidth() {
        return this.data.length * parseInt(this.cardWidth);
      },
      /**
       * @desc set logic to interval
       * @return void
       * @author karam mustafa
       * */
      setNewInterval() {
        let wrapper = this.parentTicker.querySelector('.slide-effect-wrapper');
        if (wrapper.childNodes[0]
          && wrapper.childNodes[0] != undefined
          && wrapper.childNodes[0] != null
        ) {
          return setInterval(() => {
            return new Promise((res, rej) => {
              this.count += parseInt(this.cardWidth);
              this.index += 1;
              this.data.push(this.data[this.index]);
              this.parentTicker.style.transform = `translateX(-${this.count}px)`;
              res()
            }).then(() => {
              setTimeout(() => {
                wrapper.style.transform = `translateX(+${this.count}px)`;
                wrapper.childNodes[0].remove();
              }, (this.timeToMove * 1000) > 1000 ? 1000 : 500);
            })
          }, (this.timeToMove * 1000));
        }
      },
      /**
       * @return void
       * @author karam mustafa
       * */
      removeChild(element) {
        element.remove();
      },
      /**
       * @desc this function to check if api request is finished and data is now ready
       * @return void
       * @author karam mustafa
       * */
      checkIfDataIsset() {
        if (this.data.length > 0) {
          this.initData = this.data;
          if (this.slideEffect && this.cardsWidthIsOut()) return this.applyAnimationMethod();
          return this.stopSlide();
        } else {
          setTimeout(() => {
            this.checkIfDataIsset();
          }, 2000)
        }
      }
    },
  }
</script>

<style scoped>
  .ticker-container {
    width: 100%;
    overflow: hidden;
  }

  .slide-effect {
    transition: 1s all ease-in-out;
  }

  .ticker-wrap {
    padding-inline-start: 100%;
  }

  @keyframes ticker {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-move {
    display: inline-block;
    white-space: nowrap;
    padding-inline-end: 100%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: ticker;
  }

  .stop-animation {
    animation-play-state: paused;
  }

  .stop-on-hover:hover {
    animation-play-state: paused;
  }

  .ticker-item {
    display: inline-table;
    transition: 1s all ease-in-out;
  }

  .slide-effect {
    margin-left: 0;
  }

  .main-ticker-area {
    overflow: hidden;
    white-space: nowrap;
  }

  @media (max-width: 992px) {
    .custom-width {
      width: 165px;
    }
  }

  .slide-effect-wrapper {
    margin: 0 -15px;
  }
</style>
