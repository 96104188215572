<template>
    <div>
        <base-input :placeholder="'Nvr Quantity'" v-model="$v.nvrQuantity.$model">
            <template v-if="$v.nvrQuantity.$error" v-slot:error>
                <label class="error" v-if="!$v.nvrQuantity.required">{{ 'Nvr Quantity' + $t("validations.isRequired")}}</label>
            </template>
        </base-input>
        <base-input :placeholder="'Camera Quantity'" v-model="$v.cameraQuantity.$model">
            <template v-if="$v.cameraQuantity.$error" v-slot:error>
                <label class="error" v-if="!$v.cameraQuantity.required">{{ 'Camera Quantity' + $t("validations.isRequired")}}</label>
            </template>
        </base-input>
        <base-select
            :placeholder="'Customer'"
            v-model="$v.customerId.$model"
            :filterable="true"
            :remote="true"
            :remote-method="customerMethod"
            :options="customerOptions"
            :loading="loading"
        >
            <template v-if="$v.customerId.$error" v-slot:error>
                <label class="error" v-if="!$v.customerId.required">{{ 'customer' + $t("validations.isRequired")}}</label>
            </template>
        </base-select>
        <base-select
            :placeholder="'Structure'"
            v-model="$v.structureId.$model"
            :filterable="true"
            :remote="true"
            :remote-method="structureMethod"
            :options="structureOptions"
            :loading="loading"
        >
            <template v-if="$v.structureId.$error" v-slot:error>
                <label class="error" v-if="!$v.structureId.required">{{ 'structure' + $t("validations.isRequired")}}</label>
            </template>
        </base-select>
        <base-select
            :placeholder="'AMC'"
            v-model="$v.amcId.$model"
            :filterable="true"
            :remote="true"
            :remote-method="amcMethod"
            :options="amcOptions"
            :loading="loading"
        >
            <template v-if="$v.amcId.$error" v-slot:error>
                <label class="error" v-if="!$v.amcId.required">{{ 'amc' + $t("validations.isRequired")}}</label>
            </template>
        </base-select>
        <base-select
            :placeholder="'Operator'"
            v-model="$v.operatorId.$model"
            :filterable="true"
            :remote="true"
            :remote-method="operatorMethod"
            :options="operatorOptions"
            :loading="loading"
        >
            <template v-if="$v.operatorId.$error" v-slot:error>
                <label class="error" v-if="!$v.operatorId.required">{{ 'operator' + $t("validations.isRequired")}}</label>
            </template>
        </base-select>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import api from '@/axiosWithDelimiterFile';

export default {
    props: {
        data: {}
    },
    data() {
        return {
            nvrQuantity: '',
            cameraQuantity: '',
            customerId: '',
            customerOptions: [],
            structureId: '',
            structureOptions: [],
            operatorId: '',
            operatorOptions: [],
            amcId: '',
            amcOptions: [],
            loading: false,
        }
    },
    validations: {
        nvrQuantity: {
            required
        },
        cameraQuantity: {
            required
        },
        customerId: {
            required
        },
        structureId: {
            required
        },
        operatorId: {
            required
        },
        amcId: {
            required
        }
    },
    methods: {
        extactData() {
            this.$v.$touch();
            if(this.$v.$invalid)
                return;
            return {
                nvrQuantity: +this.nvrQuantity,
                cameraQuantity: +this.cameraQuantity,
                customerId: this.customerId,
                structureId: this.structureId,
                operatorId: this.operatorId,
                amcId: this.amcId,
            }
        },
        customerMethod(query) {
            if(query) {
                this.loading = true;
                api.get(`${this.$api.defaults.baseURL}customer/search/${query}`).then(res => {
                    this.customerOptions = res.data.data.map(option => {
                        return {
                            label:option.name,
                            value:option.id
                        }
                    });
                    this.loading = false;
                })
            }
        },
        structureMethod(query) {
            if(query) {
                this.loading = true;
                api.get(`${this.$api.defaults.baseURL}structures/search/${query}`).then(res => {
                    this.structureOptions = res.data.data.map(option => {
                        return {
                            label:option.structure_name,
                            value:option.id
                        }
                    });
                    this.loading = false;
                })
            }
        },
        amcMethod(query) {
            if(query) {
                this.loading = true;
                api.get(`${this.$api.defaults.baseURL}amc/search/${query}`).then(res => {
                    this.amcOptions = res.data.data.map(option => {
                        return {
                            label:option.name,
                            value:option.id
                        }
                    });
                    this.loading = false;
                })
            }
        },
        operatorMethod(query) {
            if(query) {
                this.loading = true;
                api.get(`${this.$api.defaults.baseURL}operator/search/${query}`).then(res => {
                    this.operatorOptions = res.data.data.map(option => {
                        return {
                            label:option.name,
                            value:option.id
                        }
                    });
                    this.loading = false;
                })
            }
        },
        reset() {
            this.$v.nvrQuantity.$model = '';
            this.$v.cameraQuantity.$model = '';
            this.$v.customerId.$model = '';
            this.$v.structureId.$model = '';
            this.$v.operatorId.$model = '';
            this.$v.amcId.$model = '';
            this.$v.$reset();
        }
    },
    created() {
        if(this.data) {
            this.$v.nvrQuantity.$model = this.data.nvrQuantity;
            this.$v.cameraQuantity.$model = this.data.cameraQuantity;
            this.$v.customerId.$model = this.data.customerId;
            this.$v.structureId.$model = this.data.structureId;
            this.$v.operatorId.$model = this.data.operatorId;
            this.$v.amcId.$model = this.data.amcId;
        }
    },
    watch: {
        data: {
            handler() {
                if(this.data) {
                    this.$v.nvrQuantity.$model = this.data.nvrQuantity;
                    this.$v.cameraQuantity.$model = this.data.cameraQuantity;
                    this.$v.customerId.$model = this.data.customerId;
                    this.$v.structureId.$model = this.data.structureId;
                    this.$v.operatorId.$model = this.data.operatorId;
                    this.$v.amcId.$model = this.data.amcId;
                } else {
                    this.$v.$reset();
                }
            },
            deep: true,
        }
    }
}
</script>

<style>

</style>