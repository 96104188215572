<template>
  <card :class="cardClasses" v-loading="loading">
    <div slot="header" class="container">
      <h4 class="font-color-4 mb-3 font-weight-bold">
        <slot name="header-icon" />
        {{ title }}
      </h4>
      <hr class="mt-1 mb-1" style="border-top-width: 2px" />
    </div>
    <div class="container list-contrainer mt-3">
      <div class="row mb-3" v-for="(item, i) in data" :key="i">
        <div class="align-items-center col-2 d-flex justify-content-center">
            <slot v-bind="item" name="item-icon" />
        </div>
        <div class="col-10">
          <div class="d-flex flex-column">
            <h4 class="mb-2 font-color-5 font-weight-bold" :class="clickAble ? 'clickable' : ''" @click="itemClicked(item)">{{ item.title }}</h4>
            <div class="row font-color-1 font-weight-600">
              <div class="col-6 d-flex">
                <div class="col-10 p-0">
                  <!-- <i class="el-icon-school"></i> -->
                  <span>{{ item.firstValue.key }}</span>
                </div>
                <div class="col-2 p-0">
                  <span>{{ item.firstValue.value }}</span>
                </div>
              </div>
              <div class="col-6 d-flex">
                <div class="col-9 p-0">
                  <!-- <i class="el-icon-message-solid"></i> -->
                  <span>{{ item.secondValue.key }}</span>
                </div>
                <div class="col-3 p-0">
                  <span>{{ item.secondValue.value }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
         <hr class="mt-3 w-75" style="border-top-width: 2px" />
      </div>
    </div>
  </card>
</template>

<script>
export default {
    name: "summary-list",
    props:{
      clickAble: {
        type:Boolean,
        default:false
      },
      loading: {
        type: Boolean,
        default: false
      },
      cardClasses:{
        default: ''
      },
        title: {
            type: String,
            default: "Police Stations"
        },
        data:{
            type: Array,
            default: ()=>{
                return [
                    {
                        title: "Barsha Police Staion",
                        firstValue: {
                            value: 1,
                            key: "total villas"
                        },
                        secondValue: {
                            value: 1,
                            key: "total alarms"
                        },
                    }
                ]
            }
        }
    },
    methods: {
      itemClicked(item) {
        if(this.clickAble) {
          this.$emit('itemClicked',item);
        }
      }
    }
};
</script>

<style scoped>
.list-contrainer{
  height: 450px;
  overflow: auto;
}
</style>