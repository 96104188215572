<template>
  <card id="tasksTab">
    <card style="margin-bottom: 10px;">
      <div class="row justify-content-center">
        <div class="col-11 text-center">
          <h2 v-if="!tasks ||tasks.length == 0 || percentage == -1">
            {{$t('No Tasks')}}!</h2>
          <BaseProgress v-else type="success" :value="percentage"></BaseProgress>
        </div>
        <!-- <div class="col-4 d-flex justify-content-around">
          <base-button @click.prevent="Close_All_Task" type="primary" class="closeAll">Close All</base-button>
        </div>-->
      </div>
    </card>
    <card v-if="tasks && tasks.length != 0">
      <div slot="header" class="tasks">
        <p> {{$t('Tasks Details')}}</p>
      </div>
      <!-- <card>
      </card>-->
      <card
        v-for="(task, index) in tasks"
        :key="index"
        v-on:click="viewDivDtl(index,task)"
        class="normal-card mb-2"
      >
        <div class="d-flex">
          <div class="d-flex align-items-center" style="min-width:2.4rem;">
            <div v-if="!task.id" style="width:2.375rem;"></div>
            <base-button
              @click="finishTask(task)"
              v-else-if="!task.finished"
              :loading="loading"
              round
              icon
              simple
              type="primary"
              :title="$t('complete this task')"
            ></base-button>
            <base-button v-else  @click="unFinishTask(task)" round icon type="primary">
              <i class="tim-icons icon-check-2 checked-icon"></i>
            </base-button>
          </div>
          <div class="d-flex align-items-center ml-3 mr-3 w-75">
            <input
              type="text"
              class="my-form-control"
              v-model="task.name"
              :readonly="task.id"
              @click="openTask(task)"
              :placeholder="$t('Task Title')"
            />
          </div>
        </div>
      </card>
    </card>
    <div slot="footer" class="row">
      <div class="col-12">
        <base-button @click.prevent="Add_Task" v-if="canAddTask" type="success" class="closeAll">{{$t('Add')}} {{$t('Task')}} </base-button>
      </div>
    </div>
  </card>
</template>
<script>
import BaseProgress from "./../../../components/BaseProgress.vue";
import BaseButton from "./../../../components/BaseButton";
export default {
  props: ["id"],
  components: {
    BaseProgress,
    BaseButton
  },
  data: () => {
    return {
    };
  },
  computed: {
    loading() {
      return this.$store.getters.spinner;
    },
    tasks(){
       return this.$store.getters.tasks;
    },
    percentage(){
      return this.$store.getters.percentage
    },
    canUnFinishTask(){
      return this.$store.getters.canUnFinishTask;
    },
    canFinishTask(){
      return this.$store.getters.canFinishTask;
    },
    canAddTask(){
      return this.canUnFinishTask;
    }
  },
  created() {
    console.log(this)
  },
  methods: {
    finishTask(task) {
      if(!this.canFinishTask){
        this.$notify({
          type:'warning',
          message:'You Are Not Responsible For Finishing This Task'
        })
        return;
      }
      this.$store.dispatch('changeTaskState',{task,newState:1})
    },
    unFinishTask(task) {
      if(!this.canUnFinishTask){
        this.$notify({
          type:'warning',
          message:'You Are Not Responsible For Undo This Task'
        })
        return;
      }
      this.$store.dispatch('changeTaskState',{task,newState:0})
    },
    Add_Task() {
      var newElem = {
        name: "",
        description: "",
        inside_location: ""
      };
      this.tasks.push(newElem);
    },
    openTask(task) {
      this.$store.commit("openTask", task);
    }
  }
};
</script>
<style scoped>
#tasksTab {
  min-height: 50%;
}
::v-deep .btn.btn-icon:not(.btn-footer) .fas {
  top: 6px;
  left: 6px;
}
.my-form-control {
  border-radius: 0.2rem;
  font-size: 1.2rem;
  -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  outline: none;
  border: none;
  border-bottom: 1px solid transparent;
  display: block;
  width: 100%;
  height: calc(3.25rem + 2px);
  padding: 0.5rem 0.7rem;
  font-weight: 400;
  line-height: 1.42857;
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  background-clip: padding-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
}
.white-content .my-form-control {
  color:#1d253b
}
.my-form-control:focus:not(:read-only) {
  border-bottom: 1px solid var(--primary);
}
.my-form-control:read-only {
  cursor: pointer;
}
.checked-icon {
  font-weight: bold;
  font-size: 1.2rem;
}
::v-deep .card-header {
  border-bottom: 1px solid #ffffff29;
  border-radius: 3px;
}
::v-deep .progress {
  height: 1rem !important;
}
::v-deep .progress-container.progress-sm .progress .progress-value {
  left: 50%;
  font-size: 0.8rem;
  top: 8px;
  font-weight: 600;
  color: #ffffffcc;
}
.card {
  -webkit-box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.5);
}
.normal-card {
  -webkit-box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.2) !important;
}
.closeAll {
  width: 93px;
  padding: 7px 14px;
}
</style>
