<template>
  <div>
    <verification-modal 
      :isOpen="openMoroVerificationModal" 
      :contacts="contacts"
      @close="()=>closeAckModalAction({closeOrOpen: false})"
      @submit="submitVerification"
    />
    <customer-villa-info-modal 
      :isOpen="openOrCloseMoreInfoModalGetter"
      :info="villaModalInfo"
      :contacts="contacts"
      @close="closeInfoModal(false)"
    />
    <global-acknowledgement-modal
      :hasEscalate="false"
      :contacts="contacts"
      :isOpen="openAckModal"
      @close="()=>closeAckModalAction({closeOrOpen: false})"
      @submit="submitAck"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomerVillaInfoModal from "./customer-villa-info-modal.vue";
import VerificationModal from "./verification-modal.vue";
import GlobalAcknowledgementModal from "src/components/global-acknowledgement-modal.vue";
import IntrusionVuexGetter from "../../store/types/getter-types";
import IntrusionVuexActions from "../../store/types/actions-types";
export default {
  components: {
    VerificationModal,
    CustomerVillaInfoModal,
    GlobalAcknowledgementModal,
  },
  props: {
    selectedAlarm:{
      type: Object
    },
    type:{
      required: true
    }
  },
  computed: {
    ...mapGetters("IntrusionStore", {
      contacts: IntrusionVuexGetter.CUSTOMER_CONTACTS_LIST_GETTER,
      openOrCloseAckModalGetter:
        IntrusionVuexGetter.OPEN_ACKNOWLEDGEMENT_MODAL_GETTER,
        openOrCloseMoreInfoModalGetter: IntrusionVuexGetter.OPEN_MORE_INFORMATION_MODAL_GETTER,
      userType: IntrusionVuexGetter.MORO_OR_POLICE_GETTER
    }),
    openPoliceAckModal(){
      return this.userType == "police" && this.type == "intrusion" && this.openOrCloseAckModalGetter;
    },
    openVerificationModal(){
      return this.userType == "moro" && this.type == "operational" && this.openOrCloseAckModalGetter;
    },
    openAckModal(){
      return this.openPoliceAckModal || this.openVerificationModal;
    },
    openMoroVerificationModal(){
      return this.userType == "moro" && this.type == "intrusion" && this.openOrCloseAckModalGetter;
    },
    villaModalInfo() {
      if (!this.selectedAlarm) return {}

      let device = this.selectedAlarm.device, customer = this.selectedAlarm.customer

      return {
        structure_name: device.structure_name,
        structure_address: device.structure_address,
        customer_name: customer.name,
        lat: device.lat,
        lng: device.lng
      }
    }
  },
  methods:{
    ...mapActions("IntrusionStore",{
        closeAckModalAction: IntrusionVuexActions.OPEN_ACKNOWLEDGEMENT_MODAL_ACTION,
        verifyAction: IntrusionVuexActions.VERIFY_ALARM_ACTION,
        closeInfoModal: IntrusionVuexActions.OPEN_MORE_INFORMATION_MODAL_ACTION,
        byPoliceAck: IntrusionVuexActions.BY_POLICE_ACKNOWLEDGE_ALARM_ACTION,
        acknowledgeByMoroDispatcher:
          IntrusionVuexActions.BY_MORO_ACKNOWLEDGE_ALARM_ACTION,
    }),
    submitVerification(data){
       this.verifyAction({data,alarm: this.selectedAlarm});
    },
    submitAck(data){
      if(this.openPoliceAckModal){
        this.byPoliceAck({
          alarm: this.selectedAlarm,
          data
        })
      }else if(this.openVerificationModal){
        this.acknowledgeByMoroDispatcher({
          alarm: this.selectedAlarm,
          comment: data.comment
        })
      }else{
        console.error("Unknown state!!!!")
      }
    },
  },
};
</script>

<style></style>
