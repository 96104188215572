<template>
  <el-table-column :fixed="$rtl.isRTL?false:'right'" :label="$tc('report.action', 2)" width="175">
    <div slot-scope="{ row }" class="row actions-row">
      <div class="col-12" style>
        <base-button
          :type="getButtonAcknowledgeState(row).type"
          :simple="row.acknowledgement != null"
          size="sm"
          class="ack_button"
          @click="$emit('change-ack-state', row)"
          :disabled="row.acknowledgement != null"
        >{{ $t(getButtonAcknowledgeState(row).action) }}</base-button>
      </div>
      <div class="col-12">
        <base-button
          simple
          class="btn-escalate"
          size="sm"
          type="primary"
          @click="$emit('escalate', row)"
        >{{ $t('escalate') }}</base-button>
        <base-button
          v-if="canTerminate"
          class="btn-terminate"
          simple
          size="sm"
          :type="getButtonTerminateStatus(row).type"
          @click="$emit('terminate', row)"
          :disabled="row.termination != null"
        >
          <i class="fa fa-exclamation-triangle"></i>
        </base-button>
      </div>
    </div>
  </el-table-column>
</template>

<script>
import AlarmButtonsStateMixin from "../../../util/AlarmButtonsStateMixin.js"
export default {
  mixins:[AlarmButtonsStateMixin],
  computed: {
    canTerminate() {
      const user = this.$store.getters.user;
      // superadmin or supervisor or admin
      return (
        user.role_id == 1 || user.type == "supervisor" || user.role_id == 2
      );
    }
  }
};
</script>

<style scoped>
.ack_button {
  word-break: keep-all;
  margin-bottom: 15px !important;
  border-width: 2px;
  width: 100%;
}

:v-deep .el-table__fixed-right {
  top: 1px !important;
}

.actions-row {
  justify-content: space-around;
}

.comments-button {
  font-size: 1.5rem;
  cursor: pointer;
}

.comments-button sup {
  font-size: 60%;
  padding-left: 5px;
  color: #ccc;
}

.comments-button sup[content="0"] {
  color: var(--gray);
}

::v-deep th div.cell {
  letter-spacing: 1px;
}

.btn-terminate {
  float: right;
  height: 100%;
  border-width: 2px;
}

.btn-escalate {
  border-width: 2px;
}
</style>
