<template>
<div class="row">

  <div class="col-12">
      <HistoryFilter
        :filterOptions="filterOptions"
        :initialFilter="{villa_id:villaId}"
        @filter="updateFilter"
      >
      </HistoryFilter>   
  </div>
  <div class="col-12">
   <card>
     <div class="d-flex justify-content-end">
        <base-button simple size="sm" type="primary" @click="downloadCSV()" class="position-absolute" style="z-index: 2">Download CSV</base-button>
      </div>
    <CustomTable
        :columns="columns"
        :table-key="tableKey"
        @config-change="configChangeHandler"
        @reset="resetHandler"
      >
        <div class="table-striped">
          <el-table :data="alarmsGetter" style="width: 100%" max-height="700">
            <el-table-column
              v-for="(column, index) in columns.filter(e => e.visible)"
              :key="index"
              v-bind="column"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
      </CustomTable>
    <TablePagination
      slot="footer"
      ref="paginator"
      v-if="alarmsGetter.length"
      :total="totalRecordsGetter"
      @change="updatePagination"
    />
  </card>

    </div>
  </div>
</template>

<script>
import CustomTable from "@/components/CustomTable.vue";
import TablePagination from "@/components/TablePagination.vue";
import { mapActions, mapGetters } from "vuex";
import IntrusionVuexGettersTypes from "../store/types/getter-types";
import IntrusionVuexActionsTypes from "../store/types/actions-types";
import HistoryFilter from "../../vsaas/Helpers/HistoryFilter";
import { USER_PREFERENCES_KEYS } from '@/global/user-preferences-store/keys';

export default {
  components: {
    CustomTable,
    TablePagination,
    HistoryFilter
  },
  data() {
    return {
      tableKey: USER_PREFERENCES_KEYS.INTRUSION_INTRUSION_ALARMS_HISTORY_TABLE_CONFIGURATIONS,
      historyType: "intrusion",
      pagination: {
        limit: 10,
        offset: 0
      },
      filterOptions: {},
      columns: [],
      currentFilter: {}
    };
  },
  created() {
    this.historyType = this.$route.name == "intrusion operational alarms history"? "operational" : "intrusion";
    // this.gettingAlarmsHistoryDispatcher({
    //   limit: 10,
    //   offset: 0,
    //   type: this.historyType
    // });
    // call resetHandler to copy columnsConfigGetter array to save it from change
    this.resetHandler();
  },
  mounted() {
    this.moreInformationDispatcher().then(data=>{
      this.filterOptions = {
        villaOptions: [{label: "all"},...data.villas.map(v=>({
          label: v.name,
          value: v.id
        }))],
        areaOptions: [{label: "all"},...data.areas.map(v=>({
          label: v.name,
          value: v.id
        }))],
        eventOptions: [{label: "all"},...data.events.map(v=>({
          label: v,
          value: v
        }))],
        alarmOptions: [{label: "all"},...data.alarms.map(v=>({
          label: v,
          value: v
        }))],
        policeStationOptions: [{label: "all"},...data.police_stations.map(v=>({
          label: v.name,
          value: v.id
        }))],
        statusOptions: [{label: "all"}]
      };
    })
  },
  computed: {
    ...mapGetters("IntrusionStore", {
      alarmsGetter: IntrusionVuexGettersTypes.HISTORY_DATA_GETTER,
      columnsConfigsGetter:
        IntrusionVuexGettersTypes.INTRUSION_ALARMS_HISTORY_TABLE_COLUMNS_GETTER,
      totalRecordsGetter:
        IntrusionVuexGettersTypes.HISTORY_ALARMS_TOTAL_RECORDS_GETTER
    }),
    villaId(){
      return this.$route.query.villa_id? +this.$route.query.villa_id: undefined;
    }
  },
  methods: {
    ...mapActions("IntrusionStore", {
      gettingAlarmsHistoryDispatcher:
        IntrusionVuexActionsTypes.GETTING_HISTORY_DATA_ACTION,
      resetStoreToInitialState:
        IntrusionVuexActionsTypes.RESET_THE_WHOLE_STATE_ACTION,
      moreInformationDispatcher:
        IntrusionVuexActionsTypes.MORE_INFORMATION_FOR_ALARMS_ACTION,
    }),
    configChangeHandler(configurations) {
      this.columns = configurations;
    },
    resetHandler() {
      this.columns = this.columnsConfigsGetter.map(e => ({ ...e }));
    },
    updatePagination(pagination) {
      this.pagination = pagination;
      this.gettingAlarmsHistoryDispatcher({...pagination, type: this.historyType });
    },
    updateFilter(filter){
      console.log(filter);
      this.pagination.offset = 0;
      this.gettingAlarmsHistoryDispatcher({...this.pagination, type: this.historyType, ...filter });
      this.currentFilter = {
        type: this.historyType,
        ...filter
      }
    },
    downloadCSV() {
      this.$store.dispatch('downloadFile', {
        url: '/intrusion/alarms/history/download-csv',
        filename: 'intrusion-alarms-history.csv',
        method: 'post',
        params: { ...this.currentFilter },
        data: {
          columns: this.columns.map(c => ({ prop: c.prop, label: c.label, visible: c.visible }))
        }
      })
    }
  },
  beforeDestroy() {
    this.resetStoreToInitialState();
  }
};
</script>

<style></style>
