<template>
  <div>
    <LocationsMap
      :markers="markers"
      :center="center"
      :markerIcon="markerIcon"
      :mapType="mapType"
      @onMarkerClicked="onCustomerClicked"
      :markerLabel="markerLabel"
    >
      <div slot="footer">
        <div class="d-flex justify-content-between algin-items-center">
          <div slot="left-footer" class="pl-2">
            <img class="marker-image" :src="markerIcon" alt="" />
          </div>

          <div slot="right-footer">
            <base-button
              :round="false"
              size="sm"
              :type="'primary'"
              :simple="!isCustomersMapSelected"
              class="py-1"
              @click="changeMap(1)"
              >Customers</base-button
            >
            <base-button
              :round="false"
              size="sm"
              :type="'primary'"
              :simple="!isAlarmsMapSelected"
              class="py-1"
              @click="changeMap(2)"
              >Customers With Alarms</base-button
            >
          </div>
        </div>
      </div>
    </LocationsMap>
  </div>
</template>

<script>
import LocationsMap from "src/components/Locations/locations.vue";

import helper from "src/util/helper";

export default {
  name: "customers-map",
  components: { LocationsMap },
  props: {
    markers: { 
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: function () {
    return {
      center: { lat: 25.221643,
        lng: 55.255257 },
      mapType: 1,
    };
  },
  created() {
  },

  computed: {
    markerIcon() {
      if(this.isCustomersMapSelected)
      return require("src/assets/icons-2/google-map-marker.svg");
      else if (this.isAlarmsMapSelected)
      return require("src/assets/icons-2/google-map-marker-red.svg");
    },
    isCustomersMapSelected(){
        return this.mapType == 1;
    },
    isAlarmsMapSelected(){
        return this.mapType == 2;
    },
  },
  methods: {
    markerLabel(customer){
        return (this.mapType == 1 ? customer.devicesCount || '' : customer.activeAlarmsCount || '').toString();
    },
    onCustomerClicked(customer) {
      this.center = customer;
    },
    changeMap(type) {/// Todo: filter the markers on activeAlarmsCount when map type is 2
        this.mapType = type;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  .marker-image {
    width: 15px;
  }
  button {
    margin: 0;
    border-radius: 0;
  }
}
</style>
