<template>
    <div v-loading="loading">
        <HistoryFilter
            :filterOptions="filterOptions"
            :initialFilter="{}"
            @filter="updateFilter"
        />
        <card>
            <OperationalAlarmsHistoryTable
                :tableColumns="tableColumns"
                :data="tableData"
            />
            <TablePagination
                slot="footer"
                ref="paginator"
                v-if="total"
                :total="total"
                @change="updatePagination"
            />
        </card>
        
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import HistoryFilter from './components/HistoryFilter';
import OperationalAlarmsHistoryTable from './components/OperationalAlarmsHistoryTable';
import TablePagination from "@/components/TablePagination.vue";

export default {
    components: {
        HistoryFilter,
        OperationalAlarmsHistoryTable,
        TablePagination
    },
    computed: {
        ...mapState({
            loading(state,getters) {
                return getters['VsaasStore/OperationalAlarmsHistory/loading'];
            },
            tableColumns(state,getters) {
                return getters['VsaasStore/OperationalAlarmsHistory/tableColumns'];
            },
            tableData(state,getters) {
                return getters['VsaasStore/OperationalAlarmsHistory/tableData'];
            },
            pagination(state,getters) {
                return getters['VsaasStore/OperationalAlarmsHistory/pagination'];
            },
            total(state,getters) {
                return getters['VsaasStore/OperationalAlarmsHistory/total'];
            },
            filters(state,getters) {
                return getters['VsaasStore/OperationalAlarmsHistory/filters'];
            },
            filterOptions(state,getters) {
                return getters['VsaasStore/OperationalAlarmsHistory/filterOptions'];
            }
        })
    },
    methods: {
        ...mapActions({
            fetchData(dispatch,payload) {
                return dispatch('VsaasStore/OperationalAlarmsHistory/fetchData',payload);
            },
            fetchFilterOptions(dispatch,payload) {
                return dispatch('VsaasStore/OperationalAlarmsHistory/fetchFilterOptions');
            }
        }),
        updatePagination(data) {
            let params = {};
            if(data.total) {
                params.total = '';
                delete data.total;
            }
            this.$store.commit("VsaasStore/OperationalAlarmsHistory/updatePagination",data);
            this.fetchData(params);
        },
        updateFilter(filter) {
            this.$store.commit("VsaasStore/OperationalAlarmsHistory/updateFilters",filter);
            this.updatePagination({
                limit: this.pagination.limit,
                offset:0,
                total:true,
            })
        },
    },
    created() {
        this.fetchFilterOptions();
        this.fetchData({total:''});
    },
    watch: {
        pagination: {
          handler(v) {
            if (this.$refs.paginator) this.$refs.paginator.goTo(v.limit, v.offset);
          },
        },
    },
}
</script>

<style>

</style>