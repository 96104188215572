<template>
  <card v-loading="loading">
    <el-table :data="data" class="striped-table">
      <el-table-column
        v-for="(column, index) in columns"
        :key="index"
        v-bind="column"
        align="center"
      >
      </el-table-column>
      <el-table-column label="registered at">
        <template slot-scope="{ row }">
          {{ row.created_at ? dateFormat(row.created_at) : "--" }}
        </template>
      </el-table-column>
      <el-table-column label="last reported at">
        <template slot-scope="{ row }">
          {{ row.created_at ? dateFormat(row.last_reported_at) : "--" }}
        </template>
      </el-table-column>
      <el-table-column
        :fixed="$rtl.isRTL ? false : 'right'"
        :label="$tc('report.action', 2)"
        align="center"
        width="250"
      >
        <div slot-scope="{ row }" class="row justify-content-between m-0">
          <div class="col-12 mt-1 d-flex justify-content-center align-items-center">
            <router-link :to="{ path: `/forklift/report/2`,}">
              <base-button type="primary" class="mr-2 px-0">
              Create Service Request
              </base-button>
            </router-link>
            <router-link
              :to="{
                path: `/forklift/device/${row.id}`
              }"
            >
              <base-button
                class="mx-2 px-2 py-1"
                simple
                size="sm"
                type="secondary"
                round
              >
                <i class="fa fa-info"></i>
              </base-button>
            </router-link>
          </div>
        </div>
      </el-table-column>
    </el-table>
  </card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ForkliftService from "../../services/forklift.service";
import { dateFormat } from "@/util/helper"

export default {
  data: () => ({
    columns: [
      {
        label: "Device name",
        prop: "device_name"
      },
      {
        label: "Type",
        prop: "type"
      },
      {
        label: "Model",
        prop: "model"
      },

    ]
  }),
  computed: {
    ...mapState("ForkliftStore/Devices", ["data", "loading"])
  },
  mounted() {
    this.fetchData()
      .then(data => {})
      .catch(err => console.log(err));
  },
  methods: {
    ...mapActions("ForkliftStore/Devices", ["fetchData"]),
    dateFormat
  }
};
</script>

<style></style>
