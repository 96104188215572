<template>
  <el-dialog class="sensor-color-area text-center"
             :title="data.info.name"
             :visible.sync="data.visible"
             top="4%"
  >
    <ColorPicker :data="data"></ColorPicker>
  </el-dialog>
</template>

<script>
  import ColorPicker from "./ColorPicker";

  export default {
    components: {ColorPicker},
    props: {
      data: {
        visible: {
          type: Boolean,
          default: false
        }
      }
    },
    name: "SensorsColorsDialog",
  }
</script>

<style scoped lang="scss">
  /deep/ .el-dialog{
    width: 500px !important;
    border-radius: 10px;
  }
  /deep/ .el-dialog__body{
    height: 100%;
  }
</style>
