<template>
  <div>
    <CustomersMap
      v-if="!minimized"
      :markers="customerLocationsArrayGetter"
      secondFilterOnMapFilterKey="active_operational_alarms"
    />
    <OperationalFilter
      :filterOptions="filterOptions"
      :initialFilter="{ villa_id: villa_id }"
      @filter="handleFilter"
    >
    </OperationalFilter>
    <card>
      <router-link
        class="history-button btn btn-primary btn-simple btn-sm"
        :to="{
          path: '/intrusion/operational-alarms-history',
          query: historyQueryFilter
        }"
      >
        History</router-link
      >
      <div
        class="align-items-center d-flex position-absolute"
        style="left: 45%; top: 17px;"
      >
        <span
          class="socket-status"
          :class="live ? 'background-good' : 'background-problem'"
        ></span>
        <p class="font-weight-bold font-color-4 letter-spacing: 1px; m-0">
          {{ live ? "Live" : "offline" }}
        </p>
      </div>
      <CustomTable
        :columns="columns"
        :table-key="tableKey"
        @config-change="configChangeHandler"
        @reset="resetHandler"
      >
        <el-table
          :data="alarmsListGetter"
          class="striped-table"
          :row-style="highlightAlarmRow"
        >
          <el-table-column
            v-for="(column, index) in columns.filter(e => e.visible)"
            :key="index"
            v-bind="column"
          />
          <!-- <IntrusionActionsColumn
            @more-info-clicked="moreInfoForAlarmDispatcher"
            @acknowledge="acknowledgeAnAlarm"
            @open-ack-modal="openAckModal"
            @terminate="terminate"
          /> -->

          <AlarmsActionsColumn
            @change-ack-state="openAckModal"
            @terminate="terminate"
            :hideEscalateButton="true"
          />
          <!-- @open-comments="openComments"
            @escalate="escalate"
            -->
        </el-table>
      </CustomTable>
      <TablePagination
        slot="footer"
        ref="paginator"
        :initialPerPage="25"
        :total="totalRecordsGetter"
        @change="updatePagination"
      />
    </card>
    <intrusion-modals :selectedAlarm="selectedAlarm" type="operational" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import IntrusionVuexGetter from "../store/types/getter-types";
import IntrusionVuexActions from "../store/types/actions-types";
import CustomTable from "@/components/CustomTable.vue";
import TablePagination from "@/components/TablePagination.vue";
import AlarmsActionsColumn from "../../vsaas/components/table/vsaas-actions-column";

import IntrusionActionsColumn from "./intrusion-actions-column.vue";
import IntrusionModals from "./modals/intrusion-modals";
import {
  NEW_ALARM_EVENT,
  TERMINATE_ALARM_EVENT,
  ACKNOWLEDGE_ALARM_EVENT,
  VERIFY_ALARM_EVENT
} from "../../../plugins/moro-iot-socket/socket/custom-events";

import {
  SOCKET_NAMESPACES,
  INTRUSION_SOCKET_NAMESPACE
} from "../../../plugins/moro-iot-socket/socket/namespaces";

import AnalyticCardRow from "./cards/analytic-card-row";
import CustomersMap from "./map/customers-map";
import { USER_PREFERENCES_KEYS } from "@/global/user-preferences-store/keys";
import OperationalFilter from "../../vsaas/Helpers/OperationalFilter";
export default {
  props: {
    minimized: {
      type: Boolean,
      default: false
    },
    villa_id: {}
  },
  data() {
    return {
      selectedAlarm: null,
      ackowledgeStyleInterval: 10, // In seconds
      type: "operational", // For getting the alarms from backend, we must specifiy wethear we need intrusion or operational alarms
      live: false,
      columns: [],
      filterOptions: {},
      pagination: {
        limit: 25,
        offset: 0
      },
      tableKey:
        USER_PREFERENCES_KEYS.INTRUSION_OPERATIONAL_ALARMS_TABLE_CONFIGURATIONS
    };
  },
  created() {
    this.resetHandler();
    this.fillFilterOptions();
  },
  [`sockets_${INTRUSION_SOCKET_NAMESPACE}`]: {
    connect: function() {
      console.log(
        "Socket for intrusion alarms got connected --------------------------"
      );
      this.live = true;
    },
    connect_error: function(err) {
      console.error("Connection error in socket: ", err);
      this.live = false;
    },
    disconnect: function() {
      console.log(
        "Socket for intrusion alarms got disposed --------------------------"
      );
      this.live = false;
    }
  },
  mounted() {
    if (!this.minimized) {
      this.gettingCustomerLocationsDispatcher();
    }

    this.socketInterval = setInterval(() => {
      if (
        !this[`$moroIoTSocketIo_${INTRUSION_SOCKET_NAMESPACE}`][
          `io_${INTRUSION_SOCKET_NAMESPACE}`
        ].connected
      ) {
        this[`$moroIoTSocketIo_${INTRUSION_SOCKET_NAMESPACE}`][
          `io_${INTRUSION_SOCKET_NAMESPACE}`
        ].connect();
      }
    }, 1500);
    this[`sockets_${INTRUSION_SOCKET_NAMESPACE}`].subscribe(
      NEW_ALARM_EVENT,
      this.newAlarmFromSocket
    );
    this[`sockets_${INTRUSION_SOCKET_NAMESPACE}`].subscribe(
      ACKNOWLEDGE_ALARM_EVENT,
      this.listenToAckAlarmDispatcher
    );
    this[`sockets_${INTRUSION_SOCKET_NAMESPACE}`].subscribe(
      VERIFY_ALARM_EVENT,
      this.listenToVerifyAlarmDispatcher
    );
    this[`sockets_${INTRUSION_SOCKET_NAMESPACE}`].subscribe(
      TERMINATE_ALARM_EVENT,
      this.listenToTerminateAlarmDispatcher
    );
    if (
      this[`$moroIoTSocketIo_${INTRUSION_SOCKET_NAMESPACE}`][
        `io_${INTRUSION_SOCKET_NAMESPACE}`
      ].connected
    ) {
      this.live = true;
    }
  },
  computed: {
    ...mapGetters("IntrusionStore", {
      alarmsListGetter: IntrusionVuexGetter.INTRUSION_ALARMS_GETTER,
      columnsConfigsGetter:
        IntrusionVuexGetter.INTRUSION_ALARMS_TABLE_COLUMNS_CONFIG,
      totalRecordsGetter:
        IntrusionVuexGetter.INTRUSION_ALARMS_TOTAL_RECORDS_GETTER,
      moroOrPoliceGetter: IntrusionVuexGetter.MORO_OR_POLICE_GETTER,
      contactsListGetter: IntrusionVuexGetter.CUSTOMER_CONTACTS_LIST_GETTER,
      customerLocationsArrayGetter:
        IntrusionVuexGetter.CUSTOMER_LOCATIONS_ARRAY_GETTER,
      getCurrentFilter: 
        IntrusionVuexGetter.INTRUSION_ALARMS_CURRENT_FILTER

    }),
    historyQueryFilter() {
      if (this.villa_id) {
        return {
          villa_id: this.villa_id
        };
      }
      return {};
    }
  },
  methods: {
    ...mapActions("IntrusionStore", {
      loadIntrusionAlarmsDispatcher:
        IntrusionVuexActions.GETTING_INTRUSION_ALARMS_ACTION,
      acknowledgeByMoroDispatcher:
        IntrusionVuexActions.BY_MORO_ACKNOWLEDGE_ALARM_ACTION,
      acknowledgeByPoliceDispatcher:
        IntrusionVuexActions.OPEN_ACKNOWLEDGEMENT_MODAL_ACTION,
      verifyAlarmDispatcher: IntrusionVuexActions.VERIFY_ALARM_ACTION,
      terminateAlarmDispatcher: IntrusionVuexActions.TERMINATE_ALARM_ACTION,
      moreInfoForAlarmDispatcher:
        IntrusionVuexActions.GET_MORE_INFO_ABOUT_CUSTOMER,
      openCloseTerminateModalDispatcher:
        IntrusionVuexActions.OPEN_TERMINATE_MODAL_ACTION,

      changeMoroOrPolice: IntrusionVuexActions.MORO_OR_POLICE_ACTION,
      reOpenAckModalDispatcher: IntrusionVuexActions.RE_OPEN_ACK_MODAL_ACTION,
      listenToNewAlarmDispatcher:
        IntrusionVuexActions.NEW_ALARM_RECEIVED_FROM_SERVER_ACTION,
      listenToAckAlarmDispatcher:
        IntrusionVuexActions.LISTEN_TO_ACKNOWLEDGE_ALARM_ACTION,
      listenToTerminateAlarmDispatcher:
        IntrusionVuexActions.LISTEN_TO_TERMINATE_ALARM_EVENT_ACTION,
      listenToVerifyAlarmDispatcher:
        IntrusionVuexActions.LISTEN_TO_VERIFY_ALARM_EVENT_ACTION,

      resetStoreToInitialState:
        IntrusionVuexActions.RESET_THE_WHOLE_STATE_ACTION,

      gettingCustomerLocationsDispatcher:
        IntrusionVuexActions.CUSTOMER_LOCATIONS_ARRAY_ACTION,
      moreInformationDispatcher:
        IntrusionVuexActions.MORE_INFORMATION_FOR_ALARMS_ACTION,
      updateFilter: IntrusionVuexActions.UPDATE_FILTER
    }),
    fillFilterOptions() {
      this.moreInformationDispatcher().then(data => {
        this.filterOptions = {
          villaOptions: [
            { label: "all" },
            ...data.villas.map(v => ({
              label: v.name,
              value: v.id
            }))
          ],
          areaOptions: [
            { label: "all" },
            ...data.areas.map(v => ({
              label: v.name,
              value: v.id
            }))
          ],
          alarmOptions: [
            { label: "all" },
            ...data.alarms.map(v => ({
              label: v,
              value: v
            }))
          ],
          policeStationOptions: [
            { label: "all" },
            ...data.police_stations.map(v => ({
              label: v.name,
              value: v.id
            }))
          ]
        };
      });
    },
    handleFilter(filter) {
      this.pagination.offset = 0;
      this.currentFilter = filter;
      this.updateFilter(filter);
      this.loadIntrusionAlarmsDispatcher({
        ...this.pagination,
        type: this.type,
        villa_id: this.villa_id,
        ...filter
      });
    },
    newAlarmFromSocket(data) {
      if (!this.minimized || data.structure_id == this.villa_id)
        this.listenToNewAlarmDispatcher(data);
    },
    updatePagination(pagination) {
      this.pagination = pagination;
      this.loadIntrusionAlarmsDispatcher({
        ...pagination,
        type: this.type,
        villa_id: this.villa_id
      });
    },
    openAckModal(alarm) {
      this.selectedAlarm = alarm;
      this.reOpenAckModalDispatcher({
        ...alarm
      });
    },
    highlightAlarmRow({ row, rowIndex }) {
      if (row.highlight) {
        setTimeout(() => {
          row.highlight = null;
        }, this.ackowledgeStyleInterval * 1000);
        return {
          "background-color": "#ef52522b !important"
        };
      }
    },
    terminate(row) {
      this.selectedAlarm = { ...row };
      this.terminateAlarmDispatcher(this.selectedAlarm);
    },
    configChangeHandler(newConfig) {
      this.columns = newConfig;
    },
    resetHandler() {
      this.columns = this.columnsConfigsGetter.map(e => ({ ...e }));
    }
  },
  beforeDestroy() {
    this.resetStoreToInitialState();
    this[`sockets_${INTRUSION_SOCKET_NAMESPACE}`].unsubscribe(NEW_ALARM_EVENT);
    this[`sockets_${INTRUSION_SOCKET_NAMESPACE}`].unsubscribe(
      ACKNOWLEDGE_ALARM_EVENT
    );
    this[`sockets_${INTRUSION_SOCKET_NAMESPACE}`].unsubscribe(
      VERIFY_ALARM_EVENT
    );
    this[`sockets_${INTRUSION_SOCKET_NAMESPACE}`].unsubscribe(
      TERMINATE_ALARM_EVENT
    );
    clearInterval(this.socketInterval);
    this[`$moroIoTSocketIo_${INTRUSION_SOCKET_NAMESPACE}`][
      `io_${INTRUSION_SOCKET_NAMESPACE}`
    ].disconnect();
  },
  components: {
    CustomTable,
    TablePagination,
    IntrusionActionsColumn,
    IntrusionModals,
    AnalyticCardRow,
    CustomersMap,
    AlarmsActionsColumn,
    OperationalFilter
  }
};
</script>

<style scoped>
::v-deep .el-table__body-wrapper {
  min-height: 75vh;
}
.history-button {
  position: absolute;
  right: 25px;
  z-index: 1000;
}
.socket-status {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 5px;
}
</style>
