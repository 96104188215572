<template>
  <card>
    <div class="col-md-12">
      <el-table :data="statusData" style="width: 100%">
        <!-- 1- Sensor -->
        <el-table-column
          prop="status"
          label="status"
          min-width="200"
          align="center"
        ></el-table-column>
        <!-- 2- Status -->
        <!-- 2- Notification -->
        <el-table-column label="Notification" min-width="200" align="center">
          <template slot-scope="scope">
            <!-- <base-checkbox v-model="scope.row.notification" />-->
            <base-select
              v-model="scope.row.notification"
              :options="NotifictationOptions"
            ></base-select>
          </template>
        </el-table-column>

        <!-- 2- Email -->
        <el-table-column label="Email" min-width="200" align="center">
          <template slot-scope="scope">
            <base-checkbox v-model="scope.row.email" @input="input" />
          </template>
        </el-table-column>

        <!-- 2- Whatsapp -->
        <el-table-column label="Whatsapp" min-width="200" align="center">
          <template slot-scope="scope">
            <base-checkbox v-model="scope.row.whatsapp" @input="input" />
          </template>
        </el-table-column>

        <!-- 2- SMS -->
        <el-table-column label="SMS" min-width="200" align="center">
          <template slot-scope="scope">
            <base-checkbox v-model="scope.row.sms" @input="input" />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </card>
</template>
<script>
import NotifictationOptionsData from "./notification-options";
import statusSensor from "../initial-status-sensor";

export default {
  name: "StatusAlarmTable",
  props: ["sendedStatus","isCreate"],
  computed: {
    statusData: {
      get()
      {
          return this.status;
      },
      set(val) {
        this.status=[...val];
      },
    },
  },
  created(){
  if(this.isCreate)
    this.status=JSON.parse(JSON.stringify(statusSensor));
  else
   this.status=[...this.sendedStatus];
  },
  watch:{
    sendedStatus(newValue,oldValue){
      if(JSON.stringify(newValue)!=JSON.stringify(oldValue))
      {
        this.status=[...newValue];
      }
    }
  },
  data: function () {
    return {
      NotifictationOptions: NotifictationOptionsData,
      status:[]
    };
  },
  methods: {
    input(value) {
      this.$emit("statusChanged", this.statusData);
    },
  },
};
</script>
