<template>
  <card class="h-100 customer-info">
    <div class="row">
      <div class="col-md-6">
        <div class="row align-items-center h-100">
          <div data-v-41552ae3 data-v-7445dd9c class="photo mr-2 ml-4">
            <img src="https://moro-iot-dev.s3.amazonaws.com/img/profile/1598777356934.jpeg" alt />
          </div>
          <h2 class="m-0">Customer Name</h2>
        </div>
      </div>
      <div class="col-md-6">
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>connected</th>
              <th>problem</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div
                  class="rounded-circle d-flex justify-content-center align-items-center count m-auto icon-1"
                >
                  <inline-svg
                    :src="require('@/assets/Icons/nvr.svg')"
                    width="30"
                    height="30"
                
                  />
                </div>
              </td>
              <td>
                <base-button type="primary btn-sm w-100" round>{{nvr_info.name}}</base-button>
              </td>
              <td>
                <div
                style="background-color:#94f377"
                  class="rounded-circle d-flex justify-content-center align-items-center count m-auto"
                >
                  <span class="display-5" >{{nvr_info.nvr_connected ? 1:0}}</span>
                </div>
              </td>
              <td>
                <div
                    style="background-color:#f44151"
                  class="rounded-circle d-flex justify-content-center align-items-center count m-auto"
                >
                  <span class="display-5" >{{nvr_info.nvr_connected ? 0:1}}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  class="rounded-circle d-flex justify-content-center align-items-center count m-auto icon-2"
                >
                  <inline-svg
                    :src="require('@/assets/Icons/camera.svg')"
                    width="30"
                    height="30"
                  />
                </div>
              </td>
              <td>
                <base-button type="primary btn-sm w-100" round>Camara</base-button>
              </td>
              <td>
                <div
                  style="background-color:#94f377"
                  class="rounded-circle d-flex justify-content-center align-items-center count m-auto"
                >
                  <span class="display-5" >{{nvr_info.cameras_connected}}</span>
                </div>
              </td>
              <td>
                <div
                  style="background-color:#f6bd6a"
                  class="rounded-circle d-flex justify-content-center align-items-center count m-auto"
                >
                  <span class="display-5" >{{nvr_info.cameras_disconnected}}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </card>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
  computed:{
    ...mapGetters('VsassStore',[
      'nvr_info'
    ])
  }
};
</script>
<style scoped>
.photo {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  vertical-align: middle;
  overflow: hidden;
}
.count {
  width: 40px;
  height: 40px;
}
.customer-info table td {
  border: none;
}
.customer-info table th,
.customer-info table td {
  text-align: center;
}
</style>
<style >
    .icon-1 svg path,.icon-2 svg path{
        fill: #27293d;
    }
    .icon-1 {
        background-color: #00c2da;
    }
    .icon-2{
        background-color: #c452e9;
    }
</style>