<template>
  <card v-loading="loading" class="full-height-card">
      <InformationCard
        :icon="require('@/assets/icons-2/bell.svg')"
        :title="'Active Alarms'"
        :total="''"
        :data="getData"
      />
  </card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import InformationCard from '@/components/InformationCard';

export default {
    props: {
    namespace: {
      type: String,
      default: "IntrusionSystem/Dashboard"
    },
    moduleName: {
      type: String,
      default: "AlarmsStatistics",
    },
  },
  components: {
      InformationCard
  },
  computed: {
    storePath() {
      return this.namespace + "/" + this.moduleName;
    },
    ...mapState({
      loading(state, getters) {
        return getters[this.storePath + "/loading"];
      },
      data(state, getters) {
        return getters[this.storePath + "/data"];
      },
    }),
    getData() {
        let result = [];
        result.push({label:'Acknowledge',value:this.data.active.acknowledgements, backgroundClass: "good"});
        result.push({label:'Not Acknowledge',value:this.data.active.total - this.data.active.acknowledgements, backgroundClass: "problem"});
        result.push({label:'Terminate',value:this.data.active.terminations, color: "#AAAAAA"});
        return result;
    }
  },
  methods: {
    ...mapActions({
      fetchData(dispatch, payload) {
        return dispatch(this.storePath + "/fetchData", payload);
      },
    })
  },
  mounted() {
      this.fetchData();
  }
}
</script>

<style>

</style>