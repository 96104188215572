<template>
  <div class="row">
    <div class="col-12">
      <HistoryFilter
        :filterOptions="filterOptions"
        :initialFilter="{}"
        @filter="updateFilter"
      >
      </HistoryFilter>
    </div>
    <div class="col-12">
      <card>
        <CustomTable
          :columns="columns"
          :table-key="tableKey"
          @config-change="configChangeHandler"
          @reset="resetHandler"
        >
          <div class="table-striped">
            <el-table :data="alarmsGetter" style="width: 100%" max-height="700">
              <el-table-column
                v-for="(column, index) in columns.filter(e => e.visible)"
                :key="index"
                v-bind="column"
                align="center"
              >
                <!-- <template slot-scope="scope">
                  <span style="margin-left: 10px">{{
                    scope.row[`${column.prop}`]
                  }}</span>
                </template>-->
              </el-table-column>
            </el-table>
          </div>
        </CustomTable>
        <TablePagination
          slot="footer"
          ref="paginator"
          v-if="alarmsGetter.length"
          :total="alarmsTotalRecordsGetter"
          @change="updatePagination"
        />
      </card>
    </div>
  </div>
</template>

<script>
import CustomTable from "@/components/CustomTable.vue";
import TablePagination from "@/components/TablePagination.vue";
import { mapActions, mapGetters } from "vuex";
import HistoryFilter from "../Helpers/HistoryFilter.vue";

import VSaaSGettersTypes from "../store/types/getter-types";
import VSaaSActionsTypes from "../store/types/actions-types";
import { USER_PREFERENCES_KEYS } from '@/global/user-preferences-store/keys';

export default {
  components: {
    CustomTable,
    TablePagination,
    HistoryFilter,
  },
  created() {
    this.gettingAlarmsHistoryDispatcher({limit: 10, offset: 0});
    this.moreInformationDispatcher().then(data=>{
      this.filterOptions = {
        villaOptions: [{label: "all"},...data.villas.map(v=>({
          label: v.name,
          value: v.id
        }))],
        areaOptions: [{label: "all"},...data.areas.map(v=>({
          label: v.name,
          value: v.id
        }))],
        eventOptions: [{label: "all"},...data.events.map(v=>({
          label: v,
          value: v
        }))],
        alarmOptions: [{label: "all"},...data.alarms.map(v=>({
          label: v,
          value: v
        }))],
        statusOptions: [{label: "all"}]
      };
    });
    this.resetHandler();
  },
  data:() => ({
    pagination: {
      limit: 10,
      offset: 0
    },
    filterOptions: {},
    columns: [],
    tableKey: USER_PREFERENCES_KEYS.VSAAS_OPERATIONAL_ALARMS_HISTORY_TABLE_CONFIGURATIONS
  }),
  computed: {
    ...mapGetters("VSaaSStore", {
      alarmsGetter: VSaaSGettersTypes.ALARMS_HISTORY_GETTER,
      columnsConfigsGetter:
        VSaaSGettersTypes.OPERATIONAL_ALARMS_HISTORY_TABLE_COLUMNS_GETTER,
      alarmsTotalRecordsGetter:
        VSaaSGettersTypes.HISTORY_ALARMS_TOTAL_RECORDS_GETTER,
    })
  },
  methods: {
    ...mapActions("VSaaSStore", {
      gettingAlarmsHistoryDispatcher:
        VSaaSActionsTypes.GETTING_ALARMS_HISTORY_ACTION,
      resetStoreToInitialState: VSaaSActionsTypes.RESET_THE_WHOLE_STATE_ACTION,
      moreInformationDispatcher: VSaaSActionsTypes.MORE_INFORMATION_FOR_ALARMS_ACTION
    }),
    configChangeHandler(newConfig) {
      this.columns = newConfig;
    },
    resetHandler() {
      this.columns = this.columnsConfigsGetter.map(e => ({ ...e }));
    },
    updatePagination(pagination) {
      this.pagination = pagination;
      this.gettingAlarmsHistoryDispatcher(pagination);
    },
    updateFilter(filter) {
      this.pagination.offset = 0;
      this.gettingAlarmsHistoryDispatcher({ ...this.pagination, ...filter });
    },
  },
  beforeDestroy() {
    this.resetStoreToInitialState();
  },
};
</script>

<style></style>
