<template>
  <summary-list cardClasses="full-height-card" :data="getSummaryListData" title="police stations alarms stats" :loading="loading" :clickAble="true" @itemClicked="itemClicked">
    <template slot="header-icon">
      <inline-svg :src="policeBadgeIcon" class="mx-2" />
    </template>
    <template slot="item-icon">
      <inline-svg :src="policeBadgeIcon" width="30px" height="30px" class="svg-color-1" />
    </template>
  </summary-list>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SummaryList from "../../components/SummaryList.vue";
export default {
  components: { SummaryList },
  props:{
    namespace: {
      type: String,
      default: "IntrusionSystem/Dashboard",
    },
    moduleName: {
      type: String,
      default: "PoliceStationStatistics",
    },
  },
  data() {
    return {
      policeBadgeIcon: require("@/assets/icons-2/intrusion/police-badge.svg"),
      villaIcon: require("@/assets/icons-2/intrusion/villa.svg"),
    };
  },
  computed:{
    storePath() {
      return this.namespace + "/" + this.moduleName;
    },
    ...mapState({
      statistics(state, getters) {
        return getters[this.storePath + "/getPoliceStationsStatistics"];
      },
      loading(state, getters) {
        return getters[this.storePath + "/loading"];
      },
    }),
    getSummaryListData(){
      return this.statistics.map(s=>{
        return {
          title: s.policeStationName,
          firstValue: {
            value: s.totalVillas,
            key: "total villas"
          },
          secondValue: {
            value: s.activeAlarms,
            key: "active alarms"
          },
          policeStationId: s.policeStationId
        }
      })
    }
  },
  mounted(){
    this.fetchPoliceStationsStatistics();
  },
  methods:{
    ...mapActions({
      fetchPoliceStationsStatistics(dispatch, payload) {
        return dispatch(this.storePath + "/fetchPoliceStationsStatistics", payload);
      },
    }),
    itemClicked(item) {
      this.$store.commit(this.namespace + "/setPoliceStationId", item.policeStationId);
    }
  }

};
</script>

<style lang="scss" >
.svg-color-1{
  path {
    fill: white;
  }
}
.white-content{
  .svg-color-1{
    path{
      fill: #27293D;
    }
  }
}
</style>