<template>
  <form>
    <card footer-classes="text-right">
      <div slot="header">
        <h4 class="card-title">{{$t("Create")}} {{$t("IOT Template")}}</h4>
      </div>
      <div>
        <!--  -->
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">
                <base-input :placeholder="$t('Name')" v-model="$v.name.$model">
                  <template v-if="$v.name.$error" v-slot:error>
                    <label class="error" v-if="!$v.name.required">{{$t("requireds.name")}}</label>
                  </template>
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input :placeholder="$t('vendor')" v-model="$v.vendor.$model">
                  <template v-if="$v.vendor.$error" v-slot:error>
                    <label class="error" v-if="!$v.vendor.required">{{$t("requireds.vendor")}}</label>
                  </template>
                </base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">
                <base-select 
                  v-model="$v.solution.$model" 
                  placeholder="Solution"
                  :options="solutions">
                  <template v-if="$v.solution.$error" v-slot:error>
                    <label class="error" v-if="!$v.solution.required">{{$t("requireds.Solution")}}</label>
                  </template>
                </base-select>
              </div>
              <div class="col-md-6">
                <base-select 
                    v-model="$v.communication.$model" 
                    placeholder="Communication"
                    :options="communications">
                  <template v-if="$v.communication.$error" v-slot:error>
                    <label
                      class="error"
                      v-if="!$v.communication.required"
                    >{{$t("requireds.Communication")}}</label>
                  </template>
                </base-select>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-8">
                    <base-input
                      type="number"
                      :placeholder="$t('Status Alarm – Not Reporting (Number of Hours)')"
                      v-model="$v.not_report_time.$model"
                    >
                      <template v-if="$v.not_report_time.$error" v-slot:error>
                        <label
                          class="error"
                          v-if="!$v.not_report_time.required"
                        >Not report time is require</label>

                        <label
                          class="error"
                          v-if="!$v.not_report_time.validator"
                        >  Value must be larger than 0</label>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-4">
                    <!-- open ticket -->
                    <base-checkbox v-model="open_ticket_unresponsive">{{$t("Opent ticket")}}</base-checkbox>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4">
                    <p>{{$t("Battery Life")}}:</p>
                  </div>
                  <div class="col-md-4">
                    <base-select v-model="$v.battery_life.year.$model" :options="years">
                      <template v-if="$v.battery_life.year.$error" v-slot:error>
                        <label
                          class="error"
                          v-if="!$v.battery_life.year.required"
                        >{{$t("requireds.Year")}}</label>
                        <label
                          class="error"
                          v-if="!$v.battery_life.year.validator"
                        >Must select a value</label>
                      </template>
                    </base-select>
                  </div>
                  <div class="col-md-4">
                    <base-select v-model="$v.battery_life.month.$model" :options="monthes">
                      <template v-if="$v.battery_life.month.$error" v-slot:error>
                        <label
                          class="error"
                          v-if="!$v.battery_life.month.required"
                        >{{$t("requireds.Month")}}</label>
                      </template>
                    </base-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--  -->
        <!--  -->

        <div class="row"></div>
          <div class="col-md-12">
                    <p>Define in Number at What Percentage Status Alarms Should be activated (%):</p>
          </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <!-- ok -->
                        <base-input>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text okStyle"
                                style=" padding: 0 10px;"
                                
                              >{{$t("OK")}}</span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              :placeholder="'ex:15%'"
                              v-model="$v.battery_status.ok.percent.$model"
                            />
                          </div>

                          <template v-if="$v.battery_status.ok.$anyError" v-slot:error>
                            <label
                              class="error"
                              v-if="!$v.battery_status.ok.percent.required"
                            >Ok percent is require</label>
                          </template>
                        </base-input>
                      </div>
                      <div class="col-md-6">
                        <base-checkbox v-model="battery_status.ok.open_ticket">Open ticket</base-checkbox>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <!-- poor -->
                        <base-input>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text poorSty"
                                style=" padding: 0 10px;"
                              >{{$t("Poor")}}</span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              :placeholder="'ex:10%'"
                              v-model="$v.battery_status.poor.percent.$model"
                            />
                          </div>

                          <template v-if="$v.battery_status.poor.$anyError" v-slot:error>
                            <label
                              class="error"
                              v-if="!$v.battery_status.poor.percent.required"
                            >Poor percent is require</label>
                          </template>
                        </base-input>
                      </div>
                      <div class="col-md-6">
                        <!-- open ticket on poor -->
                        <base-checkbox v-model="battery_status.poor.open_ticket">Open ticket</base-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <!-- problem-->
                        <base-input>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text problemSty"
                                style="padding: 0 10px;"
                              >{{$t("Problem")}}</span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              :placeholder="'ex:5%'"
                              v-model="$v.battery_status.problem.percent.$model"
                            />
                          </div>

                          <template v-if="$v.battery_status.problem.$anyError" v-slot:error>
                            <label
                              class="error"
                              v-if="!$v.battery_status.problem.percent.required"
                            >Problem percent is require</label>
                          </template>
                        </base-input>
                      </div>
                      <div class="col-md-6">
                        <!-- open ticket on problem -->
                        <base-checkbox v-model="battery_status.problem.open_ticket">Open ticket</base-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--  -->
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">
                <BaseAutoComplete
                  :placeholder="$t('Select Sensor')"
                  :source="Sensorendpoint"
                  v-model="selected_sensor"
                >
                  <template v-if="$v.allsensores.$error" v-slot:error>
                            <label
                              class="error"
                              v-if="!$v.allsensores.empty"
                            >Select sensor and press add </label>
                  </template>
                </BaseAutoComplete>
              </div>
              <div class="col-md-6">
                <base-button type="primary" @click="addSensor" size="sm">{{$t("Add")}} {{$t("Sensor")}}</base-button>
              </div>
            </div>
          </div>
        </div>
        <template v-for="(snr,index) in allsensores">
          <div :key="index">
            <p class="aspointer" @click="removeSensor(index)">
              <i class="fa fa-minus-circle"></i>
              <span style="margin: 0 5px;color:#fff">
                {{ $t("Remove")
                }}
              </span>
            </p>
            <SensorTemplate
              :key="snr.sensorId"
              :ref="'snr'+index"
              :id="snr.sensorId"
              :canupdate="false"
            />
          </div>
        </template>

        <!--  -->
      </div>
      <template slot="footer" class="text-right">
        <base-button native-type="submit" @click.prevent="submit" type="primary">{{$t("Register")}}</base-button>
      </template>
    </card>
  </form>
</template>
<script>
import {
  required
  //sameAs,
  // minLength
  //email
  //numeric
} from "vuelidate/lib/validators";

import BaseAutoComplete from "@/components/BaseAutoComplete.vue";
// import moment from "moment";
import SensorTemplate from "src/pages/SWAM/components/sensor-template.vue";

var notempty = data => data.length > 0;

export default {
  props:{
    category_id:[String,Number]
  },
  data() {
    return {
      id: "",
      name: "",
      vendor: "",
      solution: "",
      communication: "",
      not_report_time: "",
      open_ticket_unresponsive: false,
      battery_life: {
        year: "",
        month: ""
      },
      selected_sensor: null,
      allsensores: [],
      refs: [],

      battery_status: {
        ok: {
          percent: "15",
          open_ticket: false
        },
        poor: {
          percent: "10",
          open_ticket: false
        },
        problem: {
          percent: "5",
          open_ticket: true
        }
      },

      //
      monthes: [
        { value: "", label: "Month" },
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" }
      ],
      years: [
        { value: "", label: "Year" },
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" }
      ]
    };
  },

  updated() {
    this.$nextTick(() => {
      console.log("refs:", this.$refs); // => 'not updated'

      this.refs = this.$refs;
    });
  },
  components: {
    BaseAutoComplete,
    SensorTemplate
  },
  computed: {
    solutions() {
      return this.$store.state.solutions.map(solution => ({
        value: solution.id,
        label: solution.name
      }));
    },
    communications() {
      return this.$store.state.communications.map(com => ({
        value: com.id,
        label: com.name
      }));
    },
    sensor_type() {
      return this.$store.state.sensor_type;
    },
    
  },


  
  mounted() {
    if (/update\/[0-9]/.test(this.$route.path)) {
      this.id = this.$route.params.id;
      this.$api
        .get(`template/${this.id}`)
        .then(res => {
          console.log("template update", res);
          let data = res.data.data;

          this.name = data.name;
          this.vendor = data.vendor;
          this.solution = +data.solution_id;
          this.communication = data.communication_id;
          this.not_report_time = data.not_report_time;
          this.open_ticket_unresponsive = !!data.open_ticket_unresponsive;
          let battery_life = data.battery_life.split("|");
          this.battery_life.year = battery_life[0];
          this.battery_life.month = battery_life[1];
          let allsensores = data.sensors;
          this.allsensores = allsensores;
          if (data.battery_status.length > 0) {
            let battery_status = data.battery_status.reduce((acc, curr) => {
              let status = curr.battery_status;
              acc[status] = {
                percent: curr.percent,
                open_ticket: !!curr.open_ticket
              };
              return acc;
            }, {});

            this.battery_status = battery_status;
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  validations: {
    name: {
      required
    },
    vendor: {
      required
    },
    solution: {
      required
    },
    communication: {
      required
    },
    not_report_time: {
      required,
      validator: function(value) {
        return value > 0;
      }
    },
    battery_life: {
      year: {
        required,
        validator: function() {
          return !(
            this.battery_life.month == "0" && this.battery_life.year == "0"
          );
        }
      },
      month: {
        required
      }
    },

    battery_status: {
      ok: {
        percent: {
          required
        }
      },
      poor: {
        percent: {
          required
        }
      },
      problem: {
        percent: {
          required
        }
      }
    },

    allsensores: {
      notempty
    }
  },
  methods: {
    validateAllSensors() {
      let refs = this.refs;
      let invalid = [];
      for (var key in refs) {
        if (refs[key].length) {
          let vueCom = refs[key][0];
          let status = !vueCom.validation();
          invalid.push(status);
        }
      }

      console.log(invalid);

      return invalid.indexOf(true) != -1;
    },

    addSensor() {
      if (this.selected_sensor) {
        let id = this.selected_sensor.selectedObject.id;
        let exist = this.allsensores.find(snr => snr.sensorId == id);
        if (exist) {
        } else {
          this.allsensores.push({
            id: "",
            sensorId: id
          });
        }
        //
      }
    },

    Sensorendpoint(input) {
      return `${this.$api.defaults.baseURL}iot/sensors/search/${input}`;
    },
    removeSensor(index) {
      console.log(index);
      this.allsensores.splice(index, 1);
    },

    submit(e) {
      e.preventDefault();

      let allsensors_validation = this.validateAllSensors();
      this.$v.$touch();

      if (this.$v.$invalid || allsensors_validation) {
        return;
      } else {
        let data = {
          name: this.name,
          category_id:this.category_id,
          vendor: this.vendor,
          solution_id: this.solution,
          communication_id: this.communication,
          not_report_time: this.not_report_time,
          open_ticket_unresponsive:this.open_ticket_unresponsive,
          battery_life: `${this.battery_life.year}|${this.battery_life.month}`,
          battery_status: this.battery_status,
          allsensores: this.allsensores
        };

        console.log("template", data);
        //
        //edit
        if (this.id) {
          data.id = this.id;
          console.log('update template',JSON.stringify(data));
          
          this.$api
            .post(`iot/template/${this.id}`, data)
            .then(res => {
              this.$router.push({ path: "/template/list" });
            })
            .catch(err => {});
        } else {
          //create
          this.$api
            .post(`iot/template`, data)
            .then(res => {
              this.$router.push({ path: "/template/list" });
            })
            .catch(err => {});
        }
      }
    }
  }
};
</script>
<style scoped>
.okStyle{
  background-color: rgb(232, 217, 29);
}
.problemSty{
background-color: rgb(242, 57, 59);;
}
.poorSty{
  background-color: rgb(230, 162, 60);
}
</style>
