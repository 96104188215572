<template>
  <div class="smart-tracker">
    <div class="row">
      <div class="col-12 p-1">
          <customer-info />
      </div>
    </div>
    <div class="row">
        <div class="col-12 p-1">
          <VsassMap />
        </div>
        
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6  p-1">
          <Devices />
      </div>
      <div class="col-lg-6 col-md-6 p-1">
          <customer-details />
      </div>
     
    </div>


    <div class="row">
      <div class="col-12 p-1">
        <alarms />
      </div>
    </div>
  </div>
</template>
<script>
import CustomerInfo from './CustomerInfo.vue';
import VsassMap from './VsassMap.vue';
import Devices from './Devices.vue';
import CustomerDetails from './CustomerDetails.vue'
import Alarms from './Alarms'

export default {
    components:{
        CustomerInfo,
        VsassMap,
        Devices,
        CustomerDetails,
        Alarms
    }
};
</script>
<style >
  .smart-tracker{
    color:#fff;
  }
</style>