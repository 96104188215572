<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div slot="header">
            <card>
              <div class="row">
                <!-- <div class="col-md-">
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>-->
                <div class="col-md-3" v-if="canPage(P.create_maintenance_order)">
                  <router-link to="create">
                    <button class="btn btn-sm btn-info">{{$t('instructions.createOrder')}}!</button>
                  </router-link>
                </div>
              </div>
            </card>
          </div>
          <div class="striped-table">
            <el-table :data="orders" style="width: 100%" :height="tableHeight">
              <el-table-column min-width="120" :label="$t('id')">
                <template slot-scope="scope">
                  <p @click="openMO(scope.row)" class="mo-link linkable">{{$generateID(scope.row.id,'MO')}}</p>
                </template>
              </el-table-column>
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
                :formatter="column.formatter?column.formatter:isEmpty"
              ></el-table-column>
            </el-table>
          </div>
          <TablePagination
            slot="footer"
            ref="paginator"
            v-if="total"
            :total="total"
            @change="updatePagination"
          ></TablePagination>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { actions } from "./MaintenaceStore";
import TablePagination from "@/components/TablePagination.vue";

export default {
  components:{
    TablePagination
  },
  data() {
    return {
      total: 0,
      offset: 0,
      limit: 10,
      orders: [],
      tableHeight: document.documentElement.clientHeight * 0.7, // to get 70% of the viewport
      tableColumns: []
    };
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.tableColumns = [
      {
        prop: "subject",
        label: "subject",
        minWidth: 200
      },
      {
        prop: "target_type",
        label: "maintenanceOrder.targetType",
        minWidth: 125,
        formatter: (row, col, cellValue) => {
          cellValue = cellValue.toLowerCase();
          return this.$t(`maintenanceOrder.targetTypes.${cellValue}`);
        }
      },
      {
        prop: "target_name",
        label: "maintenanceOrder.targetName",
        minWidth: 200,
      },
      {
        prop: "state.id",
        label: "maintenanceOrder.state",
        minWidth: 120,
        formatter: (row, col, cellValue) => {
          return this.$t(`maintenanceOrder.states.${cellValue}`);
        }
      },
      {
        prop: "Community_English",
        label: "maintenanceOrder.community",
        minWidth: 150
      },
      {
        prop: "priority.name",
        label: "maintenanceOrder.priority",
        minWidth: 100,
        formatter: (row, col, cellValue) => {
          cellValue = cellValue.toLowerCase();
          return this.$t(`priority.${cellValue}`);
        }
      },
      {
        prop: "created_at",
        label: "maintenanceOrder.createdAt",
        minWidth: 150,
        formatter: (row, column, cellValue, index) => {
          return this.gDateFormat(cellValue);
        }
      },
      {
        prop: "current_assignment.name",
        label: "AMC",
        minWidth: 150
      },
      {
        prop: "current_assignment.user.assignedUser.email",
        label: "maintenanceOrder.assignedTo",
        minWidth: 150
      },
      {
        prop: "description",
        label: "description",
        minWidth: 250
      }
    ];
    this.getAll();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },

  methods: {
    updatePagination(data) {
      this.limit = data.limit;
      this.offset = data.offset;
      this.getAll();
    },
    resize() {
      this.tableHeight = document.documentElement.clientHeight * 0.7;
    },
    getAll() {
      this.$api
        .get(`maintenance/order/${this.limit}/${this.offset}`)
        .then(res => {
          this.orders = [];
          res = res.data;
          this.total = res.count;
          res.orders.forEach(order => {
            if(order.device_name){
              order.target_name = order.device_name;
              order.target_type = 'Device';
            }else{
              order.target_name = (order.space.space_id || !order.space.floor_id)?order.space.name:order.space.number;
              order.space.display = order.target_name;
              order.target_type = 'Space';
            }
            this.orders.push(order);
          });
        })
        .catch(console.error);
    },
    isEmpty(row, column, cellValue, index) {
      return cellValue ? cellValue : "--";
    },
    // openMO(scope) {
    //   console.log(scope);
    //   //this.$router.push({path:`./order/${scope.row.id}`});
    // },
    ...mapActions({
      openMO: actions.openMO
    })
  }
};
</script>

<style scoped>
.mo-link {
  /* color: #ba54f5; */
  font-weight: 300;
  /* text-decoration: none; */
  background-color: transparent;
  cursor: pointer;
}
</style>
