<template>
  <div>
    <CustomersMap :markers="mapMarkers" />
    <AnalyticCardRow :statistics="alarmsStatisticsGetter" />
    <!-- <OperationalFilter
      :filterOptions="filterOptions"
      :initialFilter="{}"
      @filter="updateFilter"
    >
    </OperationalFilter> -->
    <card>
      <router-link
        class="history-button btn btn-primary btn-simple btn-sm"
        to="/vsass/operational-alarms-history"
        >History</router-link
      >
      <div
        class="align-items-center d-flex position-absolute"
        style="left: 45%;"
      >
        <span
          class="socket-status"
          :class="live ? 'background-good' : 'background-problem'"
        ></span>
        <p class="font-weight-bold font-color-4 letter-spacing: 1px; m-0">
          {{ live ? "Live" : "offline" }}
        </p>
      </div>
      <CustomTable
        :columns="columns"
        :table-key="tableKey"
        @config-change="configChangeHandler"
        @reset="resetHandler"
      >
        <el-table
          :data="alarmsGetter"
          class="striped-table"
          style="width: 100%"
          max-height="500"
          :row-style="highlightAlarmRow"
        >
          <el-table-column
            v-for="(column, index) in columns.filter(e => e.visible)"
            :key="index"
            v-bind="{ ...column, ...column.tableProps }"
            align="center"
            :formatter="column.tableProps.formatter"
          >
          </el-table-column>
          <VsaaSActionsColumn
            v-if="alarmsGetter.length > 0"
            @open-comments="openComments"
            @change-ack-state="changeAckState"
            @escalate="escalate"
            @terminate="terminate"
          />
        </el-table>
      </CustomTable>
      <TablePagination
        slot="footer"
        ref="paginator"
        v-if="alarmsGetter.length"
        :total="alarmsTotalRecordsGetter"
        @change="updatePagination"
      />
    </card>
    <ModalsViewer :alarm="selectedAlarm" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomTable from "@/components/CustomTable.vue";
import VSaaSGettersTypes from "../store/types/getter-types";
import VSaaSActionsTypes from "../store/types/actions-types";
import AnalyticCardRow from "./cards/analytic-card-row";
import VsaaSActionsColumn from "./table/vsaas-actions-column";
import ModalsViewer from "./table/modals/modals-viewer";
import CustomersMap from "./map/customers-map";
import TablePagination from "@/components/TablePagination.vue";
import OperationalFilter from '../Helpers/OperationalFilter'
import { USER_PREFERENCES_KEYS } from '@/global/user-preferences-store/keys';

import {
  NEW_ALARM_EVENT,
  UPDATE_STATUS_EVENT,
  TERMINATE_ALARM_EVENT,
  ACKNOWLEDGE_ALARM_EVENT
} from "../../../plugins/moro-iot-socket/socket/custom-events";
import {
  SOCKET_NAMESPACES,
  VSAAS_SOCKET_NAMESPACE
} from "../../../plugins/moro-iot-socket/socket/namespaces";

export default {
  name: "operational-alarm",
  [`sockets_${VSAAS_SOCKET_NAMESPACE}`]: {
    connect: function() {
      console.log(
        "Socket for operational alarms got connected --------------------------"
      );
      this.live = true;
    },
    connect_error: function(err) {
      console.error("Connection error in socket: ", err);
      this.live = false;
    },
    disconnect: function() {
      console.log(
        "Socket for operational alarms got disposed --------------------------"
      );
      this.live = false;
    }
  },
  components: {
    CustomTable,
    AnalyticCardRow,
    VsaaSActionsColumn,
    ModalsViewer,
    CustomersMap,
    TablePagination
  },
  mounted() {
    this.moreInformationOnAlarmsDispatcher().then(info => {
      // TODO
      // Use info
    });
    // Backend calls
    // Initial call to feed map with data
    this.gettingCustomerLocationsDispatcher();
    // Initial call to feed table with data
    this.grabAlarmsFromServer(this.selectedPageSize, this.currentPage);
    this.gettingAlarmsStatisticsDispatcher();
    // this.gettingAlarmsTableConfigDispatcher();
    this.socketInterval = setInterval(() => {
      if (
        !this[`$moroIoTSocketIo_${VSAAS_SOCKET_NAMESPACE}`][
          `io_${VSAAS_SOCKET_NAMESPACE}`
        ].connected
      ) {
        this[`$moroIoTSocketIo_${VSAAS_SOCKET_NAMESPACE}`][
          `io_${VSAAS_SOCKET_NAMESPACE}`
        ].connect();
      }
    }, 1500);

    if (
      this[`$moroIoTSocketIo_${VSAAS_SOCKET_NAMESPACE}`][
        `io_${VSAAS_SOCKET_NAMESPACE}`
      ].connected
    ) {
      this.live = true;
    }

    // Socket event listeners
    this[`sockets_${VSAAS_SOCKET_NAMESPACE}`].subscribe(
      NEW_ALARM_EVENT,
      this.listenToNewAlarmDispatcher
    );
    this[`sockets_${VSAAS_SOCKET_NAMESPACE}`].subscribe(
      ACKNOWLEDGE_ALARM_EVENT,
      this.listenToAckAlarmDispatcher
    );
    this[`sockets_${VSAAS_SOCKET_NAMESPACE}`].subscribe(
      TERMINATE_ALARM_EVENT,
      this.listenToTerminateAlarmDispatcher
    );
    this[`sockets_${VSAAS_SOCKET_NAMESPACE}`].subscribe(
      UPDATE_STATUS_EVENT,
      this.listenToUpdateAlarmStatusDispatcher
    );
  },
  data() {
    return {
      mapMarkers: [],
      selectedAlarm: {},
      currentPage: 0,
      selectedPageSize: 10,
      pageSizeOptionsArray: [5, 10, 25, 50],
      ackowledgeStyleInterval: 10, // This means when the opartor acknowledge an alarm the alarm will get styled for this period of time,
      live: true,
      columns: [],
      filterOptions: {},
      tableKey: USER_PREFERENCES_KEYS.VSAAS_OPERATION_ALARMS_TABLE_CONFIGURATIONS
    };
  },
  created() {
    this.columns = this.columnsConfigsGetter.map(e => ({ ...e }));
    // this.fillFilterOptions()
  },
  computed: {
    // Mapping getters
    ...mapGetters("VSaaSStore", {
      alarmsGetter: VSaaSGettersTypes.ALARMS_GETTER,
      columnsConfigsGetter: VSaaSGettersTypes.OPERATIONAL_ALARMS_TABLE_COLUMNS_GETTER,
      alarmsTotalRecordsGetter: VSaaSGettersTypes.ALARMS_TOTAL_RECORDS_GETTER,
      customerLocationsArrayGetter:
        VSaaSGettersTypes.CUSTOMER_LOCATIONS_ARRAY_GETTER,
      openCloseAckGetter: VSaaSGettersTypes.OPEN_ACKNOWLEDGEMENT_MODAL_GETTER,
      alarmsStatisticsGetter: VSaaSGettersTypes.ALARMS_STATISTICS_GETTER
    })
  },
  watch: {
    customerLocationsArrayGetter: {
      handler() {
        this.feedMapWithData();
      },
      deep: true
    },
    // Detect data members when change and re-feed table with data
    selectedPageSize: function(_) {
      this.grabAlarmsFromServer(this.selectedPageSize, this.currentPage);
      // if (this.$refs.paginator) this.$refs.paginator.goTo(this.selectedPageSize, this.currentPage - 1);
    },
    currentPage: function(_) {
      this.grabAlarmsFromServer(this.selectedPageSize, this.currentPage);
      // if (this.$refs.paginator) this.$refs.paginator.goTo(this.selectedPageSize, this.currentPage - 1);
    }
  },
  methods: {
    // Mapping actions
    ...mapActions("VSaaSStore", {
      // Alarms
      gettingAlarmsDispatcher: VSaaSActionsTypes.GETTING_ALARMS_ACTION,
      gettingCustomerDispatcher: VSaaSActionsTypes.GETTING_CUSTOMER_ACTION,
      gettingCustomerLocationsDispatcher:
        VSaaSActionsTypes.CUSTOMER_LOCATIONS_ARRAY_ACTION,
      gettingAlarmsStatisticsDispatcher:
        VSaaSActionsTypes.GETTING_ALARMS_STATISTICS_ACTION,

      // Table Config
      gettingAlarmsTableConfigDispatcher:
        VSaaSActionsTypes.GETTING_ALARMS_TABLE_COLUMNS_CONFIG_ACTION,

      // Modals actions
      openCloseAckModalDispatcher:
        VSaaSActionsTypes.OPEN_ACKNOWLEDGEMENT_MODAL_ACTION,
      openCloseCommentsModalDispatcher:
        VSaaSActionsTypes.OPEN_COMMENTS_MODAL_ACTION,
      openCloseEscalateModalDispatcher:
        VSaaSActionsTypes.OPEN_ESCALATE_MODAL_ACTION,
      openCloseTerminateModalDispatcher:
        VSaaSActionsTypes.OPEN_TERMINATE_MODAL_ACTION,
      moreInformationOnAlarmsDispatcher:
        VSaaSActionsTypes.MORE_INFORMATION_FOR_ALARMS_ACTION,
      ackAlarmDispatcher: VSaaSActionsTypes.ACK_ALARM_ACTION,

      // Socket actions
      listenToAckAlarmDispatcher:
        VSaaSActionsTypes.LISTEN_TO_ACKNOWLEDGE_ALARM_ACTION,
      listenToNewAlarmDispatcher:
        VSaaSActionsTypes.NEW_ALARM_RECEIVED_FROM_SERVER_ACTION,
      listenToTerminateAlarmDispatcher:
        VSaaSActionsTypes.LISTEN_TO_TERMINATE_ALARM_EVENT_ACTION,
      listenToUpdateAlarmStatusDispatcher:
        VSaaSActionsTypes.UPDATE_ALARM_STATUS_ACTION,

      resetStoreToInitialState: VSaaSActionsTypes.RESET_THE_WHOLE_STATE_ACTION,
      moreInformationDispatcher: VSaaSActionsTypes.MORE_INFORMATION_FOR_ALARMS_ACTION
    }),
    fillFilterOptions() {
      this.moreInformationDispatcher().then(data => {
        this.filterOptions = {
          villaOptions: [
            { label: "all" },
            ...data.villas.map(v => ({
              label: v.name,
              value: v.id
            }))
          ],
          areaOptions: [
            { label: "all" },
            ...data.areas.map(v => ({
              label: v.name,
              value: v.id
            }))
          ],
          alarmOptions: [
            { label: "all" },
            ...data.alarms.map(v => ({
              label: v,
              value: v
            }))
          ],
          policeStationOptions: [
            { label: "all" },
            ...data.police_stations.map(v => ({
              label: v.name,
              value: v.id
            }))
          ]
        };
      });
    },
    calculateDiffInSeconds(t1, t2) {
      return Math.abs(Math.round((t1.getTime() - t2.getTime()) / 10000));
    },
    updatePagination(paginationData) {
      const { limit, offset } = paginationData;
      this.selectedPageSize = limit;
      this.currentPage = offset;
    },
    openComments(alarmsTableSingleRow) {
      this.selectedAlarm = { ...alarmsTableSingleRow };
      this.openCloseCommentsModalDispatcher(true);
    },
    async grabAlarmsFromServer(limit, offset) {
      this.gettingAlarmsDispatcher({
        limit: limit,
        offset: offset
      });
    },
    // Acknowledgement
    changeAckState(alarmsTableSingleRow) {
      this.selectedAlarm = { ...alarmsTableSingleRow };
      this.openCloseAckModalDispatcher({
        customerId: alarmsTableSingleRow.customerId,
        closeOrOpen: true
      });
    },

    // Escalate
    escalate(alarmsTableSingleRow) {
      this.selectedAlarm = { ...alarmsTableSingleRow };

      this.openCloseEscalateModalDispatcher({
        closeOrOpen: true,
        deviceId: alarmsTableSingleRow.deviceId
      });
    },

    // Termination
    terminate(alarmsTableSingleRow) {
      this.selectedAlarm = { ...alarmsTableSingleRow };
      this.openCloseTerminateModalDispatcher(true);
    },

    feedMapWithData() {
      this.mapMarkers = this.customerLocationsArrayGetter;
    },
    configChangeHandler(configurations) {
      this.columns = configurations;
    },
    resetHandler() {
      this.columns = this.columnsConfigsGetter.map(e => ({ ...e }));
    },
    highlightAlarmRow({ row, rowIndex }) {
      if (row.highlight) {
        setTimeout(() => {
          row.highlight = null;
        }, this.ackowledgeStyleInterval * 1000);
        return {
          "background-color": "#ef52522b !important"
        };
      }
    }
  },
  beforeDestroy() {
    console.log("Destroying [operational-alarms.vue]...");
    this.resetStoreToInitialState();
    this[`sockets_${VSAAS_SOCKET_NAMESPACE}`].unsubscribe(NEW_ALARM_EVENT);
    this[`sockets_${VSAAS_SOCKET_NAMESPACE}`].unsubscribe(
      ACKNOWLEDGE_ALARM_EVENT
    );
    this[`sockets_${VSAAS_SOCKET_NAMESPACE}`].unsubscribe(
      TERMINATE_ALARM_EVENT
    );
    this[`sockets_${VSAAS_SOCKET_NAMESPACE}`].unsubscribe(UPDATE_STATUS_EVENT);
    this[`$moroIoTSocketIo_${VSAAS_SOCKET_NAMESPACE}`][
          `io_${VSAAS_SOCKET_NAMESPACE}`
        ].disconnect();
    clearInterval(this.socketInterval);
  }
};
</script>

<style scoped>
.custom-icon {
  font-size: 2rem;
}

.el-table .new-alarm {
  background: radial-gradient(#61c07487, transparent) !important;
  background-color: #ef52522b !important;
}
.history-button {
  position: absolute;
  right: 25px;
  z-index: 1000;
}
.socket-status {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 5px;
}
</style>
