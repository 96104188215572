<template>
  <el-dialog :visible.sync="show">
    <div class="modal-container p-1">
      <card>
        <company-contacts-table :contacts="contacts" />
      </card>
      <div class="p-3">
        <div class="row py-2">
          <div class="col">
            <h4 class="m-0">{{$t('structureWords.structureName')}}</h4>
            <span class="font-color-3">{{this.info.structure_name || '--'}}</span>
          </div>
          <div class="col">
            <h4 class="m-0">{{$t('customerName')}}</h4>
            <span class="font-color-3">{{this.info.customer_name || '--'}}</span>
          </div>
        </div>
        <div class="row py-2">
          <div class="col">
            <h4 class="m-0">{{$t('address')}}</h4>
            <span class="font-color-3">{{this.info.structure_address || '--'}}</span>
          </div>
        </div>

        <div class="my-2 map-section">
          <base-map :zoom="12" :center="center" style="height: 250px">
            <base-map-marker :position="marker"/>
          </base-map>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import CompanyContactsTable from "../../../../pages/Profiles/components/CompanyContactsTable.vue";
import BaseMap from 'src/components/BaseMap/Map'
import MapMarker from "src/components/BaseMap/MapMarker.vue";

export default {
  components: {
    CompanyContactsTable,
    [BaseMap.name]: BaseMap,
    [MapMarker.name]: MapMarker
  },
  props: {
      contacts:{
          type:Array,
          default:()=> [
                {
                    email: null,
                    first_name: "Office",
                    id: 484,
                    last_name: " ",
                    phone: "+963988827697"
                }
            ]
      },
      info:{
          type: Object,
          default: ()=>{
              return {
                  structure_name: "test",
                  customer_name: "test",
                  address: "test",
                  lng: 25,
                  lat: 25
              }
          }
      },
      isOpen: {
        type: Boolean
      }
  },
  data: () => ({
    markerIcon:{
      default(){
        return require("src/assets/icons-2/google-map-marker.svg")
      }
    }
  }),
  computed: {
    show: {
      get(){
        return this.isOpen
      },
      set(val) {
        this.$emit('close')
      }
    },
    center() {
      return {
        lat: this.info.lat ? this.info.lat : 0,
        lng: this.info.lng ? this.info.lng : 0
      }
    },
    marker() {
      return {
        lat: this.info.lat ? this.info.lat : 0,
        lng: this.info.lng ? this.info.lng : 0
      }
    }
  }
};
</script>

<style>
  .modal-container {
    max-height: 50vh;
    overflow-y: auto;
  }

  .map-section {
    border-radius: 20px;
    overflow: hidden;
  }
</style>