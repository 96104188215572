<template>
  <card v-loading="loading">
    <template slot="header">
      <div class="d-flex justify-content-between">
        <h3>{{currentAlarmType}} alarms comparison</h3>
        <slot name="header-right">
          <base-select
            :light="true"
            v-model="currentPeriodIndex"
            :options="periodOptions"
          />
        </slot>
      </div>
    </template>
    <!-- filters -->
    <div class="row mt-2">
      <div class="col-6 col-md-4 col-lg-3" v-if="alarmsTypesOptions.length > 2 && !hideAlarmTypeSelect">
        <base-select
          :inline="true"
          :filterable="true"
          :label="'alarm type: '"
          v-model="currentAlarmType"
          :options="alarmsTypesOptions"
        />
      </div>
      <div
        class="col-6 col-md-4 col-lg-3"
        v-if="policeStationsOptions.length > 2 && !isOperational "
      >
        <base-select
          :inline="true"
          :filterable="true"
          :label="'police station: '"
          v-model="currentPoliceStationId"
          :options="policeStationsOptions"
        />
      </div>
      <div class="col-6 col-md-4 col-lg-3" v-if="esclationOptions.length > 2 && !isOperational && currentUserType == 'moro'">
        <base-select
          :inline="true"
          :label="'escalated: '"
          v-model="currentEscalated"
          :options="esclationOptions"
        />
      </div>
      <div class="col-6 col-md-4 col-lg-3" v-if="!isOperational">
        <base-select
          :inline="true"
          :filterable="true"
          :label="'reason: '"
          v-model="currentReason"
          :options="alarmsReasons"
        />
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <base-select
          :inline="true"
          :filterable="true"
          :label="'alarm status: '"
          v-model="currentAlarmStatus"
          :options="alarmStatus"
        />
      </div>
    </div>

    <!-- data -->
    <zingchart-wrapper
      :height="'350px'"
      :chartType="'area'"
      :series="chartSeries"
      :labels="chartLabels"
      :showRanges="chartLabels.length > 1 && chartLabels[0].labels.length > 0"
      :customConfiguration="{}"
    />
  </card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ZingchartWrapper from '../../../../components/Charts/zingchart-wrapper.vue';
import helper, { getDeep } from "../../../../util/helper";
import moment from 'moment'

export default {
  props: {
    namespace: {
      type: String,
      default: "IntrusionSystem/Dashboard"
    },
    moduleName: {
      type: String,
      default: "IntrusionComparison",
    },
    peroidCount: {
      default: 2
    },
    alarmType:{
      
    },
    hideAlarmTypeSelect:{
      default: false
    },
    data: {},
  },
  mounted() {
    let filter = {key: "peroidCount", value:this.peroidCount};
    this.$store.commit(this.storePath + "/selectFilter", filter);
    this.getIntrusionGeneralInfo();
    this.currentAlarmType = this.alarmType;
    this.fetchData();

  },
  computed: {
    isOperational(){
      return this.currentAlarmType == 'operational'
    },
    ...mapState({
      loading(state, getters) {
        return getters[this.storePath + "/loading"];
      },
      filters(state, getters) {
        return getters[this.storePath + "/filters"];
      },
      onePeriod(state, getters) {
        return getters[this.storePath + "/onePeriod"];
      },
      twoPeriods(state, getters) {
        return getters[this.storePath + "/twoPeriods"];
      },
      firstPeriod(state, getters) {
        return getDeep(state, this.storePath + "/firstPeriod");
      },
      secondPeriod(state, getters) {
        return getDeep(state, this.storePath + "/secondPeriod");
      },
    }),
    chartSeries(){
      let firstPeriodTitle;
      let secondPeriodTitle;
      if(this.secondPeriod){
        const selectedPeriod = this.twoPeriods[this.currentPeriodIndex];
        firstPeriodTitle = selectedPeriod.periods[0].title;
        secondPeriodTitle = selectedPeriod.periods[1].title; 
      }else{
        // const selectedPeriod = this.onePeriod[this.peroidCount];

      }
      let series = [
        {
          values: this.firstPeriod.values,
          label: firstPeriodTitle
        }
      ]

      if (this.secondPeriod)
        series.push({
          values: this.secondPeriod.values,
          label: secondPeriodTitle
        })

      return series
    },
    chartLabels() {
      let labels = [
        { labels: this.firstPeriod.dates }
      ]

      if (this.secondPeriod)
        labels.push({ labels: this.secondPeriod.dates })

      return labels
    },
    ...mapGetters("IntrusionSystem", {
      villas: "getVillaOptions",
      areas: "getAreaOptions",
      alarmStatus: "getAlarmStatusOptions",
      policeStationsOptions: "getPoliceStationOptions",
      esclationOptions: "esclationOptions",
      alarmsReasons: "alarmsReasonsOptions",
      alarmsTypesOptions: "alarmsTypesOptions",
      currentUserType: "getCurrentUserType"
    }),
    storePath() {
      return this.namespace + "/" + this.moduleName;
    },
    currentPoliceStationId: {
      get() {
        return this.filters.policeStationId;
      },
      set(value) {
        let filter = { key: "policeStationId", value };
        this.$store.commit(this.storePath + "/selectFilter", filter);
      },
    },
    currentEscalated: {
      get() {
        return this.filters.escalated;
      },
      set(value) {
        let filter = { key: "escalated", value };
        this.$store.commit(this.storePath + "/selectFilter", filter);
      },
    },
    currentReason: {
      get() {
        return this.filters.reason;
      },
      set(value) {
        let filter = { key: "reason", value };
        this.$store.commit(this.storePath + "/selectFilter", filter);
      },
    },
    currentAlarmType: {
      get() {
        return this.filters.alarmType;
      },
      set(value) {
        let filter = { key: "alarmType", value };
        this.$store.commit(this.storePath + "/selectFilter", filter);
      },
    },
    currentAlarmStatus: {
      get() {
        return this.filters.alarmStatus;
      },
      set(value) {
        let filter = { key: "alarmStatus", value };
        this.$store.commit(this.storePath + "/selectFilter", filter);
      },
    },
    currentPeriodIndex: {
      get() {
        return this.filters.periodIndex;
      },
      set(value) {
        let filter = { key: "periodIndex", value };
        this.$store.commit(this.storePath + "/selectFilter", filter);
      },
    },
    periodOptions() {
      return (this.peroidCount == 1 ? this.onePeriod : this.twoPeriods)
    }
  },
  methods: {
    ...mapActions({
      getIntrusionGeneralInfo: "IntrusionSystem/getGeneralInfo",
      fetchData(dispatch, payload) {
        return dispatch(this.storePath + "/fetchData", payload);
      },
    }),
  },
  watch: {
    filters: {
      handler: helper.debounce(function () {
        this.fetchData();
      }, 500),
      deep: true,
    },
    peroidCount() {
      let filter = {key: "peroidCount", value:this.peroidCount};
      this.$store.commit(this.storePath + "/selectFilter", filter);
      this.currentPeriodIndex = 0;
    },
  },
  components:{
    ZingchartWrapper
  }
};
</script>

<style>
</style>