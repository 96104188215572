<template>
    <card class="full-height-card">
        <InformationCard
            :icon="require('@/assets/icons-2/statistics.svg')"
            :title="'Statistics'"
            :total="''"
            :data="getData"
        />
    </card>
</template>

<script>
import InformationCard from "@/components/InformationCard";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    props:{
        namespace: {
          type: String,
          default: "IntrusionSystem/Dashboard",
        },
        moduleName: {
          type: String,
          default: "IntrusionVillasOverall",
        },
  },
    components: {
        InformationCard
    },
    computed: {
        storePath() {
            return this.namespace + "/" + this.moduleName;
        },
        getData() {
            let data = [
                {label: 'total devices', value: this.totalDevices, color: '#aaa'},
                {label: 'total villas', value: this.totalVillas, color: '#aaa'}
            ];
            return data;
        },
        ...mapState({
            totalDevices(state, getters) {
              return getters[this.storePath + "/getVillaList"].reduce((pv,cv)=> pv+cv.devicesCount, 0);
            },
            totalVillas(state, getters) {
              return getters[this.storePath + "/getVillaList"].length;
            },
        }),
    }
}
</script>

<style>

</style>