<template>
  
  <vue-draggable-resizable
    :w="400"
    :h="400"
    @dragging="onDrag"
    @resizing="onResize"
    :parent="false"
    :handles="['tl', 'tr', 'br', 'bl']"
    :classNameHandle="'screen-handle'"
  >
    
    <div ref="cameraContent" class="row p-4 camera-screen w-100 h-100 m-auto animate__animated animate__fadeInDown"  :id="`camera-show-${id}`">
      <i class="el-icon el-icon-close  remove-icon" @click="destroy"></i>
      <!-- <img width="900px" height="500px" id="camera-img" class="d-block m-auto"> -->

      <div id="camera-screen" class="m-auto w-100 h-100"></div>
    </div>
  </vue-draggable-resizable>
  
</template>
<script>
import VueDraggableResizable from "vue-draggable-resizable";
import "vue-draggable-resizable/dist/VueDraggableResizable.css";

export default {
  props: ["id"],
  components: {
    VueDraggableResizable,
  },
  data() {
    return {
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    };
  },
 
  beforeDestroy() {
    this.$IoTSocket.unSubscribeAll();
  },
  methods: {
    destroy(){

      this.$refs.cameraContent.classList.replace('animate__fadeInDown','animate__fadeOutUp');
      let vm = this;
      setTimeout(function(){
          this.$destroy();
          this.$el.parentNode.removeChild(this.$el);
      }.bind(this),500)
      
    },
    onResize: function (x, y, width, height) {
      this.x = x;
      this.y = y;
      this.width = width;
      this.height = height;
    },
    onDrag: function (x, y) {
      this.x = x;
      this.y = y;
    },
  },
  mounted() {
    
    var img = document.getElementById("camera-img");
    this.$IoTSocket.emit("start-nvr");
    this.$IoTSocket.subscribe("nvr-data", function (data) {
      console.log("nvr data event", data);
      img.src = "data:image/jpeg;base64," + data;
    });

    //SET THE RTSP STREAM ADDRESS HERE
    var address =
      "rtsp://test-naji.ddns.net:554/user=admin_password=tlJwpbo6_channel=1_stream=0.sdp?real_stream";

    var output =
      '<object width="100%" height="100%" id="qt" classid="clsid:02BF25D5-8C17-4B23-BC80-D3488ABDDC6B" codebase="http://www.apple.com/qtactivex/qtplugin.cab">';
    output += '<param name="src" value="' + address + '">';
    output += '<param name="autoplay" value="true">';
    output += '<param name="controller" value="false">';
    output +=
      '<embed id="plejer" name="plejer" src="/poster.mov" bgcolor="000000" width="100%" height="100%" scale="ASPECT" qtsrc="' +
      address +
      '"  kioskmode="true" showlogo=false" autoplay="true" controller="false" pluginspage="http://www.apple.com/quicktime/download/">';
    output += "</embed></object>";

    //SET THE DIV'S ID HERE
    //document.getElementById("camera-screen").innerHTML = output;
  },
  
};
</script>
<style >

.remove-icon{
  position: absolute;
  top:3%;
  right:10px;
  font-size: 14px;
  color:#fff;
  cursor: pointer;

}
.remove-icon:hover{
  font-size: 16;
}
.vdr {
  top: 3px;
  right: 3px;
  z-index: 9999 !important;
  padding: 0;
  margin: 0;
}
.vdr:focus {
outline: 0;
}

.screen-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background:transparent;
  
}
 .screen-handle-tl{
    top:0px;
    left:0px;
    cursor:nw-resize;
    border-top: 2px solid #eee;
    border-left: 2px solid #eee;
}
 .screen-handle-tr{
    top:0px;
    right:0;
    cursor:ne-resize;
    border-top: 2px solid #eee;
    border-right: 2px solid #eee;
}
.screen-handle-br{
    bottom:0;
    right:0;
    cursor:nw-resize;
    border-bottom: 2px solid #eee;
    border-right: 2px solid #eee;
}
 .screen-handle-bl{
    bottom:0;
    left:0px;
    cursor:ne-resize;
    border-bottom: 2px solid #eee;
    border-left: 2px solid #eee;
}

.camera-screen {
  background-color: darkorange;
}
</style>