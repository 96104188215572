<template>
  <card v-loading="loading" class="full-height-card">
      <div class="container d-flex justify-content-between align-items-baseline">
        <div class="d-flex"> 
          <h4 class="align-items-baseline d-flex font-weight-bold m-0">
            <inline-svg
              :src="require('@/assets/icons-2/info.svg')"
              class="mr-2"
              width="20px" height="20px"
            />
            Info
          </h4>
        </div>
        <div class="w-50" v-if="isMoroOrHQ">
          <!-- <inline-svg
            :src="require('@/assets/icons-2/filter.svg')"
            class="mx-2 mt-2"
            width="18px" height="18px"
          /> -->
          <base-select
            size="mini"
            :light="true"
            v-model="currentPoliceStationId"
            :options="policeStationsOptions"
          />
        </div>
      </div>
      <hr class="mt-1 mb-1" style="border-top-width: 2px" />
      <div class="container d-flex flex-column">
        <div class="row pt-4">
          <div class="col-5">
            <span class="font-weight-bold font-color-5">Name</span>
          </div>
          <div class="col-7">
            <span class="font-weight-bold font-color-4">{{ policeStationName }}</span>
          </div>
        </div>
        <div class="row pt-4">
          <div class="col-5">
            <span class="font-weight-bold font-color-5">Total Devices</span>
          </div>
          <div class="col-7">
            <span class="font-weight-bold font-color-4">{{ totalDevices }}</span>
          </div>
        </div>
        <div class="row pt-4 pb-4">
          <div class="col-5">
            <span class="font-weight-bold font-color-5" :class="{'problem': data.active.total}">Active Alarms</span>
          </div>
          <div class="col-7">
            <span class="font-weight-bold font-color-4" :class="{'problem': data.active.total}">{{ data.active.total }}</span>
          </div>
        </div>
      </div>
      <hr class="mt-1 mb-1" style="border-top-width: 2px" />
      <div class="container d-flex flex-column">
        <div class="row pt-4">
          <div class="col-6 d-flex justify-content-between">
            <span class="font-weight-bold font-color-5">
              <inline-svg
                :src="require('@/assets/icons-2/bell.svg')"
                class="mx-2 svg-color-1"
                width="18px" height="18px"
              />
              Today
            </span>
            <h5 class="font-weight-bold">{{ data.today }}</h5>
          </div>
          <div class="col-6 d-flex justify-content-between">
            <span class="font-weight-bold font-color-5">
              <inline-svg
                :src="require('@/assets/icons-2/bell.svg')"
                class="mx-2 svg-color-1"
                width="18px" height="18px"
              />
              This Week
            </span>
            <h5 class="font-weight-bold">{{ data.week }}</h5>
          </div>
        </div>
         <div class="row pt-2">
          <div class="col-6 d-flex justify-content-between">
            <span class="font-weight-bold font-color-5">
              <inline-svg
                :src="require('@/assets/icons-2/bell.svg')"
                class="mx-2 svg-color-1"
                width="18px" height="18px"
              />
              This Month
            </span>
            <h5 class="font-weight-bold">{{ data.month }}</h5>
          </div>
          <div class="col-6 d-flex justify-content-between">
            <span class="font-weight-bold font-color-5">
              <inline-svg
                :src="require('@/assets/icons-2/bell.svg')"
                class="mx-2 svg-color-1"
                width="18px" height="18px"
              />
              This Year
            </span>
            <h5 class="font-weight-bold">{{ data.year }}</h5>
          </div>
        </div>
      </div>
  </card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { getDeep } from '@/util/helper';

export default {
  props: {
    namespace: {
      type: String,
      default: "IntrusionSystem/Dashboard"
    },
    moduleName: {
      type: String,
      default: "AlarmsStatistics",
    },
  },
  computed: {
    isMoroOrHQ() {
      return this.currentUserType == 'moro' || this.currentUserType == 'hq';
    },
    storePath() {
      return this.namespace + "/" + this.moduleName;
    },
    ...mapGetters({
      policeStationsOptions: "IntrusionSystem/getPoliceStationOptions",
      currentUserType: "IntrusionSystem/getCurrentUserType",
      globalPoliceStationId: "IntrusionSystem/Dashboard/policeStationId"
    }),
    ...mapState({
      loading(state, getters) {
        return getters[this.storePath + "/loading"];
      },
      data(state, getters) {
        return getters[this.storePath + "/data"];
      },
      totalDevices(state, getters) {
        return getters[this.storePath + "/totalDevices"];
      },
    }),
    currentPoliceStationId: {
      get() {
        return getDeep(this.$store.state,this.storePath + "/policeStationId");
      },
      set(value) {
        this.$store.commit(this.storePath + "/setPoliceStation", value);
        this.$store.commit(this.namespace + "/setPoliceStationId", value);
      },
    },
    policeStationName() {
      let name = this.policeStationsOptions.find(ps => ps.value == this.currentPoliceStationId);
      return (name ? name.label : '-');
    }
  },
  methods: {
    ...mapActions({
      fetchData(dispatch, payload) {
        return dispatch(this.storePath + "/fetchData", payload);
      },
      fetchVillaList(dispatch, payload) {
        return dispatch(this.storePath + "/fetchVillaList", payload);
      },
    })
  },
  mounted() {
    this.fetchData();
    this.fetchVillaList();
  },
  watch: {
    currentPoliceStationId() {
      this.fetchData();
      this.fetchVillaList();
    },
    globalPoliceStationId() {
      this.currentPoliceStationId = this.globalPoliceStationId;
    }
  }
}
</script>

<style lang="scss">
div.col-5 span.font-color-5.problem,
div.col-7 span.font-color-4.problem{
  color: $problem !important;
}
</style>