<template>
  <div>
    <el-table :data="data" class="striped-table">
      <el-table-column
          label="X-AXIS"
          align="center"
        >
          <div slot-scope="{ row }">
              {{ row.position.x }}
          </div>
      </el-table-column>
       <el-table-column
          label="Y-AXIS"
          align="center"
        >
          <div slot-scope="{ row }">
              {{ row.position.y }}
          </div>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
    props:{
        data:{
            type: Array,
            default:() => []
        }
    }
};
</script>

<style></style>
