<template>
  <el-table :data="contacts" max-height="200px">
    <el-table-column prop="firstName" label="First name" style="width: 25%" />
    <el-table-column prop="lastName" label="Last name" style="width: 25%">
      <template slot-scope="{ row }">
        {{ row.lastName ? row.lastName : "--" }}
      </template>
    </el-table-column>
    <el-table-column prop="email" label="Email" style="width: 25%">
      <template slot-scope="{ row }">
        {{ row.email ? row.email : "--" }}
      </template>
    </el-table-column>
    <el-table-column prop="phone" label="Phone" style="width: 25%" />
  </el-table>
</template>

<script>
export default {
  name: "CompanyContactsTable",
  props: {
    contacts: {
      type: Array,
      default: () => [],
      validator: function (value) {
        return value.find(
          (val) =>
            val.firstName === undefined ||
            val.lastName === undefined ||
            val.email === undefined ||
            val.phone === undefined
        ) === undefined;
      },
      required: true,
    },
  },
};
</script>

<style>
</style>