
import socketSubscriber from "../../../plugins/moro-iot-socket/socket/socket-subscriber"
import {
    NEW_ALARM_EVENT,
    UPDATE_STATUS_EVENT,
    TERMINATE_ALARM_EVENT,
    ACKNOWLEDGE_ALARM_EVENT,
    ARCHIVE_ALARM_EVENT,
} from "@/plugins/moro-iot-socket/socket/custom-events";
import { VSAAS_SOCKET_NAMESPACE } from "@/plugins/moro-iot-socket/socket/namespaces";
const subscribtions = [
    {
        eventName: NEW_ALARM_EVENT,
        methodName: "onNewAlarmSocketEvent",
        createDispatcher: true,
    },
    {
        eventName: ACKNOWLEDGE_ALARM_EVENT,
        methodName: "onAlarmIsAcknowledgedSocketEvent",
        createDispatcher: true,
    },
    {
        eventName: TERMINATE_ALARM_EVENT,
        methodName: "onAlarmIsTerminatedSocketEvent",
        createDispatcher: true,
    },
    {
        eventName: UPDATE_STATUS_EVENT,
        methodName: "onUpdateStatusSocketEvent",
        createDispatcher: true,
    },
    {
        eventName: ARCHIVE_ALARM_EVENT,
        methodName: "onArchiveAlarmSocketEvent",
        createDispatcher: true,
    },
];
export const VsaasSocketEvents = {
    newAlarm: NEW_ALARM_EVENT,
    updateStatus: UPDATE_STATUS_EVENT,
    terminateAlarm: TERMINATE_ALARM_EVENT,
    acknowledgeAlarm: ACKNOWLEDGE_ALARM_EVENT,
    archiveAlarm: ARCHIVE_ALARM_EVENT,
}


/**
 * 
 * @param {String} filter "include" || "execlud"  
 * @param {Array<String>} events 
 */
export const filteredMixin = (filter = "execlud", ...events) => {
    let actualSubscribtions = [];
    if (filter == "include") {
        actualSubscribtions = subscribtions.filter(subscribtion => events.some(ee => ee == subscribtion.eventName));
    } else {
        actualSubscribtions = subscribtions.filter(subscribtion => !events.some(ee => ee == subscribtion.eventName));
    }
    return socketSubscriber(
        actualSubscribtions,
        VSAAS_SOCKET_NAMESPACE
    );
};
export default filteredMixin();