<template>
  <div>
    <IntrusionVillaMap />
    <card>
      <villa-list-filter @input="filterChanged" />
      <el-table :data="villasListGetter" class="striped-table">
        <el-table-column
          v-for="(column, index) in columnsConfigsGetter"
          :key="index"
          v-bind="column"
          align="center"
        >
        </el-table-column>
        <el-table-column
          :fixed="$rtl.isRTL ? false : 'right'"
          :label="$tc('report.action', 2)"
          width="165"
        >
          <div slot-scope="{ row }" class="row justify-content-between m-0">

            <div class="col-12 mt-1 d-flex justify-content-center">
              <router-link :to="{ name: 'Villa Info', params: { villaId: row.structure_id } }">
                <base-button
                  class="mx-2 px-2 py-1"
                  simple
                  size="sm"
                  type="secondary"
                  round
                >
                  <i class="fa fa-info"></i>
                </base-button>
              </router-link>
            </div>
          </div>
        </el-table-column>
      </el-table>

      <TablePagination
        slot="footer"
        ref="paginator"
        :initialPerPage="25"
        v-if="villasListGetter && villasListGetter.length"
        :total="villasDetailsTotalRecordsGetter"
        @change="updatePagination"
      />
    </card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import IntrusionVuexGetters from "../store/types/getter-types";
import IntrusionVuexActions from "../store/types/actions-types";
import CustomTable from "@/components/CustomTable.vue";
import TablePagination from "@/components/TablePagination.vue";
import VillaListFilter from "./villa-list-filter";
import IntrusionVillaMap from "../../../pages/Intrusion-System/core/VillasOverall";

export default {
  data() {
    return {
      mapMarkers: [],
      selectedVilla: null,
      filter: {}
    };
  },
  props: {},
  created() {},
  mounted() {
    this.villasListDispatcher({ limit: this.limit, offset: this.currentPage });
    // this.gettingCustomerLocationsDispatcher();
  },
  computed: {
    ...mapGetters("IntrusionStore", {
      villasListGetter: IntrusionVuexGetters.VILLAS_LIST_GETTER,
      columnsConfigsGetter:
        IntrusionVuexGetters.VILLAS_DEATILS_COLUMNS_CONFIG_GETTER,
      villasDetailsTotalRecordsGetter:
        IntrusionVuexGetters.VILLAS_DETAILS_TOTAL_RECORDS_GETTER,
      customerLocationsArrayGetter:
        IntrusionVuexGetters.CUSTOMER_LOCATIONS_ARRAY_GETTER,
    })
  },
  watch: {
    customerLocationsArrayGetter: {
      handler() {
        this.feedMapWithData();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("IntrusionStore", {
      villasListDispatcher: IntrusionVuexActions.GETTING_VILLAS_LIST_ACTION,
      resetStoreToInitialState:
        IntrusionVuexActions.RESET_THE_WHOLE_STATE_ACTION,
      gettingCustomerLocationsDispatcher:
        IntrusionVuexActions.CUSTOMER_LOCATIONS_ARRAY_ACTION,
      setInfoModalVisible: IntrusionVuexActions.OPEN_VILLA_INFORMATION_MODAL_ACTION,
    }),
    updatePagination({ limit, offset }) {
      this.villasListDispatcher({ limit, offset, ...this.filter });
    },
    filterChanged(newFilter){
      this.filter = newFilter;
      this.villasListDispatcher({ limit:25, offset: 0, ...this.filter });
    },
    feedMapWithData() {
      this.mapMarkers = this.customerLocationsArrayGetter;
    }
  },
  beforeDestroy() {
    this.resetStoreToInitialState();
  },
  components: {
    CustomTable,
    TablePagination,
    VillaListFilter,
    IntrusionVillaMap,
  }
};
</script>

<style></style>
