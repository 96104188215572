<template>
  <div>
    lock
    <!-- <div class="row">
      <div class="col-xl-7" style="min-height: 70vh">
        <lock-map /> 
      </div>
      <div class="col-xl-5">
        <div class="row">
          <div class="col-12">
            <lock-info />
          </div>
        </div>
        <div class="row">
          <div class="col-12 ">
            <connectivity-info />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6"  >
        <div>
          <lock-battery />
        </div>
        <div >
          <authorized-users />
        </div>
      </div>
      <div class="col-xl-6" style="min-height: 50vh">
        <lock-alarms />
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex">
        <access-history />
      </div>
    </div> -->
  </div>
</template>

<script>
// import LockMap from './core/Map/index.vue'
// import LockInfo from './core/Info/index.vue'
// import ConnectivityInfo from './core/ConnectivityInfo/index.vue'
// import LockBattery from './core/Battery/index.vue'
// import AuthorizedUsers from './core/AuthorizedUsers/index.vue'
// import LockAlarms from './core/Alarms/index.vue'
// import AccessHistory from './core/AccessHistory/index.vue'
export default {
  name: "Lock",
  components:{
    // LockMap,
    // LockInfo,
    // ConnectivityInfo,
    // LockBattery,
    // AuthorizedUsers,
    // LockAlarms,
    // AccessHistory
  }
}
</script>