<template>
  <card class="h-100" v-if="tasks">
    <card style="margin-bottom: 10px;">
      <div class="row justify-content-center">
        <div class="col-11 text-center">
          <h2 v-if="tasks.length == 0 || percentage == -1">{{$t('No Tasks')}}!</h2>
          <BaseProgress v-else type="success" :value="percentage"></BaseProgress>
        </div>
        <!-- <div class="col-4 d-flex justify-content-around">
          <base-button @click.prevent="Close_All_Task" type="primary" class="closeAll">Close All</base-button>
        </div>-->
      </div>
    </card>
    <div class="row">
      <div class="col-md-6">
        <card>
          <div slot="header">
            <p>{{$t('maintenanceOrder.timeSchedule')}}</p>
          </div>
          <div v-if="mo.schedule_from" class="min-height">
            <div class="row">
              <div class="col-12">
                <span class="myBadge">{{$t('from')}}</span>
              </div>
              <div class="col-12 d-flex justify-content-around pb-3 pt-2">
                <p class="t">{{mo.schedule_from | date}}</p>
                <p class="t">{{mo.schedule_from | time}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <span class="myBadge">{{$t('to')}}</span>
              </div>
              <div v-if="mo.schedule_to" class="col-12 d-flex justify-content-around pb-3 pt-2">
                <p class="t">{{mo.schedule_to | date}}</p>
                <p class="t">{{mo.schedule_to | time}}</p>
              </div>
              <div v-else class="col-12 pb-3 pt-2 text-center">
                <p class="t">--</p>
              </div>
            </div>
          </div>
          <div v-else class="min-height unset">
            <p class="t">{{$t('unset')}}</p>
          </div>
        </card>
      </div>
      <div class="col-md-6">
        <card>
          <div slot="header">
            <p>{{$t('workTime')}}</p>
          </div>
          <div v-if="mo.actual_from" class="min-height">
            <div class="row">
              <div class="col-12">
                <span class="myBadge">{{$t('from')}}</span>
              </div>
              <div class="col-12 d-flex justify-content-around pb-3 pt-2">
                <p class="t">{{mo.actual_from | date}}</p>
                <p class="t">{{mo.actual_from | time}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <span class="myBadge">{{$t('to')}}</span>
              </div>
              <div v-if="mo.actual_to" class="col-12 d-flex justify-content-around pb-3 pt-2">
                <p class="t">{{mo.actual_to | date}}</p>
                <p class="t">{{mo.actual_to | time}}</p>
              </div>
              <div v-else class="col-12 pb-3 pt-2 text-center">
                <p class="t">--</p>
              </div>
            </div>
          </div>
          <div v-else class="min-height unset">
            <p class="t">{{$t('unset')}}</p>
          </div>
        </card>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12">
        <CommentsBox
          ref="commentBox"
          :comments="comments"
          @submit="submitComment"
          :loading="submittingComment"
        ></CommentsBox>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <card>
          <div slot="header">
            <p>{{$tc('attachment', 2)}}</p>
          </div>
          <!-- <div slot="header">
            <p>{{$t('Maintenance Schedule')}}</p>
          </div>-->
          <div class="row">
            <div class="col-12">
              <div class="button-list">
                <button
                  v-for="(att) in mo.attachments"
                  :key="att.id"
                  @click="download(att)"
                  class="file-btn"
                >
                  {{att.filename}}
                  <i class="fa fa-download"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <el-upload
                class="text-center"
                :action="getURL()"
                multiple
                drag
                :http-request="upload"
                :show-file-list="false"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  {{$t('Drop file here or')}}
                  <em>{{$t('click to upload')}}</em>
                </div>
                <!-- <base-button size="sm" type="primary">Click to upload</base-button> -->
                <div
                  slot="tip"
                  class="el-upload__tip"
                >{{$t('jpg/png files with a size less than 500kb')}}</div>
              </el-upload>
            </div>
          </div>
        </card>
      </div>
    </div>
  </card>
</template>

<script>
import BaseProgress from "./../../../components/BaseProgress.vue";
import CommentsBox from "./CommentsBox";
import { Upload } from "element-ui";
import { Badge } from "src/components";
import axios from "../../../axiosConfig";
import { getTime, getDate } from "../../../util/helper";

export default {
  props: ["id"],
  data() {
    return {
      submittingComment: false,
      comments: [],
    };
  },
  filters: {
    time: function (value) {
      if (!value) return "---";
      return getTime(value);
    },
    date: function (value) {
      if (!value) return "---";
      return getDate(value);
    },
  },
  created() {
    this.getComments();
  },
  methods: {
    upload(e) {
      console.log(e.file);
      if (e.file.size > 10485760) {
        //10MB
        this.$notify({
          title: "Limit Exceeded",
          message: "The Maximum Size of Attachment Must Be Less Than 10MB",
          type: "danger",
        });
        return;
      }
      this.$store
        .dispatch("uploadFile", {
          url: `maintenance/order/${this.id}/attachment`,
          file: e.file,
        })
        .then((response) => {
          this.mo.attachments.push({
            filename: e.file.name,
            id: response.data.id,
            created_at: new Date(),
          });
        });
    },
    download(att) {
      this.$store.dispatch("downloadFile", {
        url: `maintenance/order/${this.id}/attachment/${att.id}`,
        filename: att.filename,
      });
    },
    submitComment(comment) {
      this.submittingComment = true;
      this.$api
        .post(`maintenance/order/${this.id}/comment`, {
          text: comment,
        })
        .then((res) => {
          const newComment = {};
          newComment.id = res.data.commentId;
          newComment.created_at = this.gDateFormat(res.data.created_at);
          newComment.text = comment;

          newComment.user = {
            email: this.$store.state.user.email,
            first_name: this.$store.state.user.first_name,
            id: this.$store.state.user.id,
            last_name: this.$store.state.user.last_name,
          };

          this.comments.push(newComment);
          this.$refs.commentBox.clear();
          this.submittingComment = false;
        })
        .catch((err) => {
          this.submittingComment = false;
          console.log(err);
        });
    },
    getComments() {
      this.submittingComment = true;
      this.$api
        .get(`maintenance/order/${this.id}/comment`)
        .then((res) => {
          res.data.data.forEach((comment) => {
            comment.created_at = this.gDateFormat(comment.created_at);
          });
          this.comments = res.data.data;
          this.submittingComment = false;
        })
        .catch((err) => {
          this.submittingComment = false;
          console.log(err);
        });
    },
    getURL() {
      return axios.defaults.baseURL + `maintenance/order/${this.id}/attachment`;
    },
  },
  components: {
    BaseProgress,
    CommentsBox,
    [Upload.name]: Upload,
    Badge,
  },
  computed: {
    mo() {
      return this.$store.getters.selectedMO;
    },
    tasks() {
      return this.$store.getters.tasks;
    },
    percentage() {
      return this.$store.getters.percentage;
    },
  },
};
</script>

<style scoped>
.unset {
  display: flex;
  justify-content: center;
  align-items: center;
}
.min-height {
  min-height: 180px;
}
.t {
  font-size: 1.2rem;
}
.myBadge {
  text-transform: uppercase;
  line-height: 12px;
  border: none;
  text-decoration: none;
  margin-bottom: 5px;
  background: var(--primary);
  color: white;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
.rtl .col-md-6 {
  text-align: right;
}
.rtl .col-12 {
  text-align: right;
}
.temp {
  min-height: 200px;
}
.card {
  -webkit-box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.5);
}
::v-deep .el-upload-dragger {
  background: transparent;
}
.comments {
  background: var(--default);
}
::v-deep .progress {
  height: 1rem !important;
}
::v-deep .progress-container.progress-sm .progress .progress-value {
  left: 50%;
  font-size: 0.8rem;
  top: 8px;
  font-weight: 600;
  color: #ffffffcc;
}
::v-deep .card-header {
  border-bottom: 1px solid #ffffff29;
  border-radius: 3px;
}
.button-list {
  position: relative;
  left: 0;
  top: 0;
  overflow: auto;
  max-height: 98px;
  z-index: 1020;
  margin-bottom: 3rem;
}
.button-list button {
  margin: 0.2rem 0.4rem;
}
.file-btn {
  cursor: pointer;
  background: #00f2c3;
  background-image: linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3);
  background-size: 210% 210%;
  background-position: top right;
  background-color: #00f2c3;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #ffffff;

  border-width: 2px;
  border: none;
  position: relative;
  overflow: hidden;
  margin: 4px 1px;
  border-radius: 0.4285rem;
  z-index: 1000;
  padding: 0.2rem 0.7rem;
}
.file-btn i {
  padding: 0.4rem;
}
</style>
