<template>
  <el-dialog :visible.sync="show">
    <div class="d-flex justify-content-between flex-wrap">
      <div >
        <base-select
          v-model="globalSelector.status"
          label="Status"
          :options="StatusOptions"
          :size="'small'"
        ></base-select>
      </div>

      <div >
        <base-select
          label="Notification"
          v-model="globalSelector.notification"
          :options="NotifictationOptions"
          :size="'small'"
        ></base-select>
      </div>

      <div >
        <label>Email</label>
        <base-checkbox v-model="globalSelector.email" />
      </div>

      <div >
        <label>Whatsapp</label>
        <base-checkbox v-model="globalSelector.whatsapp" />
      </div>

      <div >
        <label>SMS</label>
        <base-checkbox v-model="globalSelector.sms" />
      </div>
    </div>
    <div class="d-flex flex-start flex-wrap mt-3">
      <div>
        <base-button @click="applyGlobalSelector(false)">Cancel</base-button>
      </div>
      <div>
        <base-button
          type="primary"
          @click="applyGlobalSelector(true)"
          :disabled="!isChanged"
          >Confirm</base-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import StatusOptionsData from "../tables/status-options";
import NotifictationOptionsData from "../tables/notification-options";
export default {
  name: "TemplateGlobalFilter",
  props: ["isGlobalFilterOpen"],
  computed: {
    show: {
      get() {
        return this.isGlobalFilterOpen;
      },
      set(val) {
        this.$emit("handleGlobalSelector", {
          globalSelector: null,
          status: val,
        });
      },
    },
    isChanged() {
      let isStatusChanged=this.globalSelector.status!="";
      console.log(this.globalSelector.notification,'notify');
      let isNotificationChanged=this.globalSelector.notification.toString()!='';
      return (
        (JSON.stringify(this.oldFilter) != JSON.stringify(this.globalSelector))&isStatusChanged&isNotificationChanged
      );
    },
  },
  data() {
    return {
      oldFilter: undefined,
      globalSelector: {
        status: "",
        notification: "",
        email: false,
        whatsapp: false,
        sms: false,
      },
      NotifictationOptions: NotifictationOptionsData,
      StatusOptions: StatusOptionsData,
    };
  },
  created() {
    this.oldFilter = { ...this.globalSelector };
  },
  methods: {
    applyGlobalSelector(status) {
      this.$emit("handleGlobalSelector", {
        globalSelector: this.globalSelector,
        status: status,
      });
    },
  },
};
</script>
