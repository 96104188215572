<template>
  <div>
    <StructureComparison
      :data="{ structure_id: selectedStructureId }"
      :namespace="'SwamStore/StructurePortfolio'"
      :hideStructureSelector="false"
    />
    <DeviceComparison :namespace="'SwamStore/StructurePortfolio'" />
  </div>
</template>

<script>
import StructureComparison from "../../core/Structure-Comparison";
import DeviceComparison from "../../core/Device-Comparison";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "Index",
  components: {
    StructureComparison,
    DeviceComparison
  },
  data() {
    return {
      selectedSolutions: [],
      firstSolution: undefined,
      secondSolution: undefined
    };
  },
  computed: {
    selectedStructureId() {
      let id = this.$route.params.structureId;
      if (id) id = +id;
      return id;
    },
    ...mapGetters("SwamStore", {
      solutionsForStructure: "solutionsForStructure"
    })
  },
  methods: {
    ...mapActions({
      reset(dispatch, payload) {
        return dispatch("SwamStore/StructurePortfolio/reset", payload);
      }
    }),
    ...mapMutations({
      setStructureId(commit, payload) {
        return commit("SwamStore/StructurePortfolio/setStructureId", payload);
      }
    })
    // selectSolution(data) {
    //     let oldId=data.oldId,newId=data.newId;
    //     let idx = this.selectedSolutions.indexOf(oldId);
    //     if(idx != -1)
    //         this.selectedSolutions.splice(idx,1);
    //     this.selectedSolutions.push(newId);
    // }
  },
  destroyed() {
    this.reset();
  },
  created() {
    if (this.selectedStructureId) {
      this.setStructureId(+this.selectedStructureId);
    }
    this.selectedSolutions = [];
    let solutions = this.solutionsForStructure(+this.selectedStructureId);
    this.firstSolution = solutions[0];
    this.selectedSolutions.push(this.firstSolution.value);
    if (solutions.length > 1) {
      this.secondSolution = solutions[1];
      this.selectedSolutions.push(this.secondSolution.value);
    }
  },
  watch: {
    selectedStructureId() {
      this.setStructureId(this.selectedStructureId);
      this.selectedSolutions = [];
      let solutions = this.solutionsForStructure(+this.selectedStructureId);
      this.firstSolution = solutions[0];
      this.selectedSolutions.push(this.firstSolution.value);
      if (solutions.length > 1) {
        this.secondSolution = solutions[1];
        this.selectedSolutions.push(this.secondSolution.value);
      } else {
        this.secondSolution = undefined;
      }
    }
  }
};
</script>

<style scoped>
div.solutionCard {
  border: 1px solid #c0c4cc;
  border-radius: 20px;
  padding: 6px;
  margin-bottom: 6px;
  transition-duration: 0.2s;
}

div.solutionCard p {
  padding-left: 10px;
  margin: auto;
  color: #6a6a6b;
}
div.solutionCard:hover {
  background-color: #e5eaf5;
  border: 1px solid var(--primary);
  cursor: pointer;
}
</style>
