import { mapActions } from "vuex";
import ConnectionManagerMixin from "./connection-manager"
/**
 * 
 * @param {Array<Object>} subscribtions 
 * @param {String} namespace 
 */
const subscriber = (subscribtions, namespace) => {
    const socketName = "sockets_" + namespace;
    return {
        mixins:[ConnectionManagerMixin(namespace)],
        created() {
            if(!this.storePath && subscribtions.filter(method => method.createDispatcher).length != 0){
                console.error("you must define the store path to deal with")
            }
            subscribtions.forEach((subscribtion) => {
                this[socketName].subscribe(
                    subscribtion.eventName,
                    this[subscribtion.methodName] || function () { }
                );
            });
        },
        beforeDestroy() {
            subscribtions.forEach(subscribtion => {
                this[socketName].unsubscribe(subscribtion.eventName);
            });
        },
        methods: {
            ...mapActions(
                subscribtions.filter(subscribtion => subscribtion.createDispatcher).map(subscribtion => subscribtion.methodName).reduce((previos, value,) => {
                    previos[value] = function (dispatch, payload) {
                        return dispatch(
                            this.storePath + '/' + value, payload
                        );
                    }
                    return previos;
                }, {})
            )
        }
    }
}

export default subscriber;