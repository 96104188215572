<template>
  <div>
    <div class="row align-items-center">
        <div class="col-lg-3 col-md-12">
            <statusAlarms
                :namespace="'SwamStore/StructurePortfolio'"/>
        </div>
        <div class="col-lg-6 col-md-12">
            <operationalAlarms
                :namespace="'SwamStore/StructurePortfolio'"
            />
        </div>
        <div class="col-lg-3 col-md-12">
            <devices
                :namespace="'SwamStore/StructurePortfolio'"
            />
        </div>
    </div>
    <div class="row">
        <div class="col-lg-5 col-md-12">
              <StructuresLocations />
        </div>
        <div class="col-lg-7 col-md-12">
            <Statistics :namespace="'SwamStore/StructurePortfolio'" />
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" :class="!secondSolution ? 'col-lg-12' : 'col-lg-6'">
            <SolutionStatistics
                v-if="firstSolution"
                :namespace="'SwamStore/StructurePortfolio'"
                :moduleName="'SolutionStatistics1'"
                :data="{structure_id:selectedStructureId,solution_id:firstSolution.value}"
                :fullWidth="!secondSolution"
                :key="selectedStructureId + '-' + firstSolution.value"
                :selectedSolutions="selectedSolutions"
                :globalMode="true"
                :hideSelectors="true"
                @selectSolution="selectSolution"
            />
        </div>
        <div class="col-lg-6 col-md-12">
            <SolutionStatistics
                v-if="secondSolution"
                :namespace="'SwamStore/StructurePortfolio'"
                :moduleName="'SolutionStatistics2'"
                :data="{structure_id:selectedStructureId,solution_id:secondSolution.value}"
                :key="selectedStructureId + '-' + secondSolution.value"
                :selectedSolutions="selectedSolutions"
                :globalMode="true"
                :hideSelectors="true"
                @selectSolution="selectSolution"
            />
        </div>
    </div>
    <AlarmsCount
        :data="{structure_id:selectedStructureId}"
    />
    <StructureComparison
        :data="{structure_id:selectedStructureId}"
        :namespace="'SwamStore/StructurePortfolio'"
        :hideStructureSelector="false"
    />
    <DeviceComparison
        :namespace="'SwamStore/StructurePortfolio'"
    />
    <StructuresWithDevicesList />
    
  </div>
</template>

<script>
import StructureComparison from "../../core/Structure-Comparison";
import StructuresWithDevicesList from "../../components/StructuresWithDevicesList"
import DeviceComparison from "../../core/Device-Comparison";
import SolutionStatistics from "../../core/Solution-Statistics/index"
import statusAlarms from '../../core/Structures-Statistics/status-alarms';
import operationalAlarms from '../../core/Structures-Statistics/operational-alarms';
import devices from '../../core/Structures-Statistics/devices';
import Statistics from "../../core/Statistics/Overall";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import AlarmsCount from "../../core/Alarms-Count/index";
import StructuresLocations from "../../core/StructuresLocations/index";

export default {
  name:'Index',
    components:{
        StructureComparison,
        StructuresWithDevicesList,
        DeviceComparison,
        SolutionStatistics,
        statusAlarms,
        operationalAlarms,
        devices,
        Statistics,
        AlarmsCount,
        StructuresLocations
    },
    data() {
        return {
            selectedSolutions: [],
            firstSolution:undefined,
            secondSolution:undefined,
        }
    },
    computed:{
        selectedStructureId(){
            let id = this.$route.params.structureId;
            if(id)
                id = +id;
            return id;
        },
        ...mapGetters('SwamStore', {
            solutionsForStructure:"solutionsForStructure",
        }),
    },
    methods: { 
        ...mapActions({
            reset(dispatch, payload){
              return dispatch("SwamStore/StructurePortfolio/reset",payload)
            },
        }),
        ...mapMutations({
            setStructureId(commit, payload) {
                return commit(
                  "SwamStore/StructurePortfolio/setStructureId",
                  payload
                );
            },
        }),
        selectSolution(data) {
            let oldId=data.oldId,newId=data.newId;
            let idx = this.selectedSolutions.indexOf(oldId);
            if(idx != -1)
                this.selectedSolutions.splice(idx,1);
            this.selectedSolutions.push(newId);
        }
    },
    destroyed() {
        this.reset();
    },
    created() {
        if(this.selectedStructureId) {
            this.setStructureId(+this.selectedStructureId);
        }
        this.selectedSolutions = [];
        let solutions = this.solutionsForStructure(+this.selectedStructureId);
        this.firstSolution = solutions[0];
        this.selectedSolutions.push(this.firstSolution.value);
        if(solutions.length > 1) {
            this.secondSolution = solutions[1];
            this.selectedSolutions.push(this.secondSolution.value);
        }
    },
    watch: {
        selectedStructureId() {
            this.setStructureId(this.selectedStructureId);
            this.selectedSolutions = [];
            let solutions = this.solutionsForStructure(+this.selectedStructureId);
            this.firstSolution = solutions[0];
            this.selectedSolutions.push(this.firstSolution.value);
            if(solutions.length > 1) {
                this.secondSolution = solutions[1];
                this.selectedSolutions.push(this.secondSolution.value);
            } else {
                this.secondSolution = undefined;
            }
        }
    },
}
</script>

<style scoped>
div.solutionCard {
  border: 1px solid #c0c4cc;
  border-radius: 20px;
  padding: 6px;
  margin-bottom: 6px;
  transition-duration: 0.2s;
}

div.solutionCard p{
  padding-left: 10px;
  margin: auto;
  color: #6a6a6b;
}
div.solutionCard:hover{
  background-color: #e5eaf5;
  border: 1px solid var(--primary);
  cursor: pointer;
}
