<template>
  <el-dialog title="All company user" :visible.sync="show">
    <el-table :data="processedUsers" height="400">
      <el-table-column
        prop="first_name"
        label="First Name"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="last_name"
        label="Last Name"
        width="200"
      ></el-table-column>
      <el-table-column prop="email" label="Email"></el-table-column>

      <el-table-column label="added to template" min-width="200" align="center">
        <template slot-scope="scope">
          <base-checkbox v-model="scope.row.state" />
        </template>
      </el-table-column>
    </el-table>
    <div class="d-flex justify-content-end algin-items-center">
      <base-button type="info" class="mx-2" size="sm" @click="handelOnClickButton(false)"
        >Cancel</base-button
      >
      <base-button type="primary" class="mx-2" size="sm" @click="handelOnClickButton(true)"
        >Save</base-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Users",
  props: ["companyId", "templateId", "isUserModelOpen"],
  computed: {
    ...mapGetters({
      allusers:"SwamStore/NotificationsTemplate/allusers",
      assignedUserIds: "SwamStore/NotificationsTemplate/assignedUserIds"
    }),
    processedUsers() {
      if (this.allusers.length > 0) {
        // eslint-disable-next-line
        this.users = [];
        let defaultContent = "___";
        for (let user of this.allusers) {
          let isUserAssigned=this.isUserAssignedFunc(user.id);
          console.log(isUserAssigned)
          // eslint-disable-next-line
          this.users.push({
            id: user.id,
            first_name:
              user.first_name != null ? user.first_name : defaultContent,
            last_name: user.last_name != null ? user.last_name : defaultContent,
            email: user.email != null ? user.email : defaultContent,
            state: isUserAssigned,
          });
        }
      }
      return this.users;
    },
    show: {
      get() {
        return this.isUserModelOpen;
      },
      set(val) {
        this.$emit("handleOnClick", false);
      },
    },
  },
  data: function () {
    return {
      users: [],
    };
  },

  mounted() {
    this.getCompanyUsers(this.companyId);
    this.getUsersByTemplateId(this.templateId);
  },
  methods: {
    ...mapActions({
      getCompanyUsers:"SwamStore/NotificationsTemplate/getCompanyUsers",
      assignTemplateToUsers:"SwamStore/NotificationsTemplate/assignTemplateToUsers",
      getUsersByTemplateId:"SwamStore/NotificationsTemplate/getUsersByTemplateId"
    }),
    handelOnClickButton(status) {
      if (status) {
        let userIds = this.users.filter((e) => e.state).map((a) => a.id);
        this.assignTemplateToUsers({
          templateId: this.templateId,
          usersIds: userIds,
        });
      }
      this.$emit("handleOnClick", false);
    },
    isUserAssignedFunc(userId) {
      let isUserAssigned = false;
      for (let i = 0; i < this.assignedUserIds.length; i++) {
        if (parseInt(userId) == parseInt(this.assignedUserIds[i])) {
          isUserAssigned = true;
          break;
        }
      }
      return isUserAssigned;
    },
  },
};
</script>
