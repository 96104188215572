<template>
    <div v-loading="loading">
        <Ticker :data="data"
          :slideEffect="true"
          :animationDuration="'30'"
          :cardWidth="450"
          :timeToMove="5"
          :stopOnHover="true"
          v-if="data.length"
        >
            <template #tickerBody="{item}">
              <AnalyticCard :cardInfo="item"/>
            </template>
        </Ticker>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AnalyticCard from '../../components/analytic-card.vue';
import Ticker from '@/components/Ticker/Ticker';
import {filteredMixin, VsaasSocketEvents} from "../../socket/vsaas-events-subscriber.js"

export default {
    mixins:[filteredMixin("include",VsaasSocketEvents.newAlarm,VsaasSocketEvents.archiveAlarm)],
    name: "vsaas-alarms-statistics",
    components: {
        AnalyticCard,
        Ticker
    },  
    props: {
        namespace: {
            type: String,
            default: 'VsaasStore/OperationalAlarms'
        },
        moduleName: {
            type: String,
            default: 'AlarmsStatistics'
        }
    },
    computed: {
        storePath() {
            return this.namespace + '/' + this.moduleName;
        },
        ...mapState({
            loading(state,getters) {
                return getters[this.storePath + '/loading'];
            },
            data(state,getters) {
                return getters[this.storePath + '/data'];
            }
        }),
    },
    methods: {
        ...mapActions({
            fetchData(dispatch,payload) {
                dispatch(this.storePath + '/fetchData');
            }
        })
    },
    created() {
        this.fetchData();
    }
}
</script>

<style>

</style>