<template>
    <div>
        <OperationalFilter
            v-if="canFilter"
            :filterOptions="filterOptions"
            :initialFilter="{}"
            @filter="updateFilters"
        />
        <card v-loading="loading">
            <div class="d-flex flex-row-reverse position-absolute"
                 style="top:20px; right:10px;z-index: 10"
            >
                 <router-link
                    class="history-button btn btn-primary btn-simple btn-sm"
                    to="/VSAAS/Operational-Alarms-History"
                >
                    History
                </router-link>
            </div>
            <div
              class="align-items-center d-flex position-absolute"
              style="left: 45%;top:25px"
            >
                <span
                  class="socket-status"
                  :class="live ? 'background-good' : 'background-problem'"
                ></span>
                <p class="font-weight-bold font-color-4 letter-spacing: 1px; m-0">
                  {{ live ? "Live" : "offline" }}
                </p>
            </div>
            <CustomTable
            :columns="columns"
            :table-key="tableKey"
            @config-change="configChangeHandler"
            @reset="resetHandler"
        >
            <el-table
                :data="tableData"
                highlight-current-row
                :row-style="highlightAlarmRow"
            >
                <template v-for="(column, index) in columns.filter(e => e.visible)">
                    <el-table-column
                        :key="index"
                        v-bind="column"
                        align="center"
                        v-if="column.customTemplate"
                    >
                        <template slot-scope="{ row }">
                            <template v-if="column.prop=='isDeviceOff'">
                                <span> {{ row.device.on ? 'On' : 'Off' }} </span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        v-else
                        :key="index"
                        v-bind="column"
                    />
                    
                </template>
                <VsaasActionsColumn
                    v-if="tableData.length > 0"
                    @change-ack-state="changeAckState"
                    @escalate="escalate"
                    @terminate="terminate"
                />
            </el-table>
            </CustomTable>
            <TablePagination
          slot="footer"
          ref="paginator"
          v-if="tableData.length"
          :total="total"
          @change="updatePagination"
            />
            <ModalsViewer :alarm="selectedAlarm" />
        </card>
    </div>
    
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { USER_PREFERENCES_KEYS } from '@/global/user-preferences-store/keys';
import CustomTable from "@/components/CustomTable.vue";
import TablePagination from "@/components/TablePagination.vue";
import VsaasActionsColumn from '@/pages/VSAAS/components/VsaasActionsColumn';
import ModalsViewer from '../../components/modals/modals-viewer';
import OperationalFilter from '@/pages/VSAAS/components/OperationalFilter';
import VsaasEventsSubscriber from "../../socket/vsaas-events-subscriber.js";

export default {
    mixins:[VsaasEventsSubscriber],
    name: "vsaas-operational-alarms-table",
    components: {
        CustomTable,
        VsaasActionsColumn,
        TablePagination,
        ModalsViewer,
        OperationalFilter
    },
    props: {
        namespace: {
            type: String,
            default: 'VsaasStore/OperationalAlarms'
        },
        moduleName: {
            type: String,
            default: 'OperationalAlarmsTable'
        },
        canFilter: {
            type: Boolean,
            default: true,
        },
        initFilters: {
            type: Object,
        },
    },
    data() {
        return {
            selectedAlarm: undefined,
            columns: [],
            tableKey: USER_PREFERENCES_KEYS.VSAAS_OPERATION_ALARMS_TABLE_CONFIGURATIONS
        }
    },
    computed: {
        storePath() {
            return this.namespace + '/' + this.moduleName;
        },
        ...mapState({
            loading(state,getters) {
                return getters[this.storePath + '/loading'];
            },
            initColumns(state,getters) {
                return getters[this.storePath + '/columns'];
            },
            tableData(state,getters) {
                return getters[this.storePath + '/tableData'];
            },
            total(state,getters) {
                return getters[this.storePath + '/total'];
            },
            pagination(state,getters) {
                return getters[this.storePath + '/pagination'];
            },
            filterOptions(state,getters) {
                return getters[this.storePath + '/filterOptions'];
            },
        })
    },
    methods: {
        ...mapActions({
            fetchData(dispatch,payload) {
                return dispatch(this.storePath + '/fetchData',payload);
            },
            fetchFilterOptions(dispatch,payload) {
                return dispatch(this.storePath + '/fetchFilterOptions');
            }
        }),
        configChangeHandler(configurations) {
            this.columns = configurations;
        },
        resetHandler() {
          this.columns = this.initColumns.map(e => ({ ...e }));
        },
        updatePagination(data) {
            let parmas = {
                ...data
            };
            if(data.total) {
                delete data.total;
                parmas.total= '';
            }
            this.$store.commit(this.storePath + "/updatePagination",data);
            this.fetchData(parmas);
        },
        changeAckState(row) {
            let ActionsStore = 'VsaasStore/OperationalAlarms/OperationalAlarmsActions';
            this.$store.dispatch(ActionsStore + '/openAcknowledgmentModal',row);
        },
        escalate(row) {
            let ActionsStore = 'VsaasStore/OperationalAlarms/OperationalAlarmsActions';
            this.$store.dispatch(ActionsStore + '/openEscalateModal',row);
        },
        terminate(row) {
            let ActionsStore = 'VsaasStore/OperationalAlarms/OperationalAlarmsActions';
            this.$store.dispatch(ActionsStore + '/openTerminationModal',row);
        },
        updateFilters(filters) {
            this.$store.commit(this.storePath + "/updateFilters",filters);
            this.updatePagination({
                limit:this.pagination.limit,
                offset: 0,
                total:true,
            });
        },
        highlightAlarmRow({ row, rowIndex }) {
            let ackowledgeStyleInterval = 5;
            if (row.highlight) {
              setTimeout(() => {
                row.highlight = null;
              }, ackowledgeStyleInterval * 1000);
              return {
                "background-color": "#ef52522b !important"
              };
            }
        }
    },
    created() {
        this.resetHandler();
        if(this.canFilter)
            this.fetchFilterOptions();
        if(this.initFilters)
            this.updateFilters(this.initFilters);
        this.fetchData({total:''});
    },
    watch: {
        pagination: {
          handler(v) {
            if (this.$refs.paginator) this.$refs.paginator.goTo(v.limit, v.offset);
          },
        },
        initFilters: {
            handler() {
                this.updateFilters(this.initFilters);
            },
            deep: true
        }
    },
}
</script>

<style scoped>
.socket-status {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 5px;
}
</style>