<template>
  <el-dialog :title="$t('acknowledgmentAlarm')" :visible.sync="show" width="650px">
    <card v-if="contacts.length">
      <company-contacts-table :contacts="contacts || []" />
    </card>
    <div class="p-3">
      <base-input :placeholder="$t('comment')" v-model="form.comment"></base-input>
    </div>
    <div class="d-flex justify-content-center pt-3">
      <base-button
        type="primary"
        size="sm"
        class="mx-2"
        @click="submit(false)"
      >
        {{ $t('Submit') }}
      </base-button>
      <base-button
        type="primary"
        simple
        size="sm"
        class="mx-2"
        @click="submit(true)"
        v-if="hasEscalate"
      >
        {{ $t('submitAndEscalate') }}
      </base-button>
    </div>
  </el-dialog>
</template>

<script>
import CompanyContactsTable from "@/pages/Profiles/components/CompanyContactsTable.vue";

export default {
    components: {CompanyContactsTable},
    props:{
        isOpen:{
            type: Boolean
        },
        hasEscalate: {
          type: Boolean,
          default: () => true
        },
        contacts: {
          type: Array,
          default: () => []
        }
    },
    data: () => ({
      form: {
        comment: '',
        escalate: false
      }
    }),
    methods: {
      submit(escelate) {
        this.form.escalate = escelate
        this.$emit('submit', this.form)
        this.resetForm()
      },
      resetForm() {
        this.form = {
          comment: '',
          escalate: false
        }
      }
    },
    computed: {
      show: {
        get() {
          return this.isOpen
        },
        set(val) {
          this.$emit('close')
          this.resetForm()
        }
      }
    }
}
</script>

<style>

</style>