<template>
  <form @submit.prevent="submit">
    <div class="row">
      <div class="col-md-1 col-122 col-md-4">
        <base-input
          :viewMode="!canupdate"
          :disabled="!canupdate"
          v-model.trim="$v.sensorName.$model"
          placeholder="Sensor Name"
        >
          <template v-if="$v.sensorName.$error" v-slot:error>
            <label class="error">Sensor name is required</label>
          </template>
        </base-input>
      </div>
      <div class="col-12 col-md-8 row">
        <h3 class="col-12 col-md-4 text-md-right pt-1">Sensor Life</h3>
        <base-select
          class="col-12 col-md-4"
          placeholder="Year"
          type="number"
          v-model.trim="$v.lifeYear.$model"
          :options="yearOptions"
          :min="0"
          :max="12"
          :disabled="!canupdate"
          :label="'Year'"
          :viewMode="!canupdate"
        >
          <template v-if="$v.lifeYear.$error" v-slot:error>
            <label class="error">Sensor life must be set</label>
          </template>
        </base-select>
        <base-select
          class="col-12 col-md-4"
          placeholder="Month"
          type="number"
          v-model.trim="$v.lifeMonth.$model"
          :options="monthOptions"
          :min="0"
          :max="11"
          :disabled="!canupdate"
          :label="'Month'"
          :viewMode="!canupdate"
        >
          <template v-if="$v.lifeMonth.$error" v-slot:error>
            <label class="error">Sensor life must be set</label>
          </template>
        </base-select>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <base-select
          v-model.trim="$v.sensorType.$model"
          placeholder="Select Sensor"
          :options="sensors"
          :disabled="!canupdate"
          :viewMode="!canupdate"
        >
          <template v-if="$v.sensorType.$error" v-slot:error>
            <label class="error">Please select sensor type</label>
          </template>
        </base-select>
      </div>
      <div class="col-12 col-md-2">
        <base-checkbox :disabled="!canupdate" :viewMode="!canupdate" v-model="enableDecimal">Enable Decimal Values</base-checkbox>
      </div>
    </div>
    <div class="mt-2">
      <div class="row">
        <div class="col-3 d-flex justify-content-around">
          <p>The smallest possible value</p>
          <base-input>
            <el-input-number
              :controls="false"
              size="small"
              class="range-min"
              v-model="smallestValue"
              :disabled="!canupdate"
            ></el-input-number>
          </base-input>
        </div>
        <div class="col-3 d-flex justify-content-around">
          <p>The largest possible value</p>
          <base-input>
            <el-input-number
              :controls="false"
              size="small"
              class="range-min"
              v-model="largestValue"
              :disabled="!canupdate"
            ></el-input-number>
          </base-input>
        </div>
      </div>
      <div class="row mt-4 mb-2 problem1">
        <div class="text-md-center col-md-1 col-12">
          <h5 class="red pt-2 pb-2">PROBLEM</h5>
        </div>
        <div class="col-12 col-md-4">
          <el-slider
            :value="[smallestValue,problem1]"
            @input="problem1 = $event[1];"
            range
            :min="smallestValue"
            :max="largestValue"
            :step="enableDecimal ? 0.1 : 1"
            :disabled="!canupdate"
          ></el-slider>
        </div>
        <div class="col-1">
          <base-input>
            <el-input-number
              :value="smallestValue"
              :controls="false"
              size="small"
              class="range-min"
              disabled
            ></el-input-number>
          </base-input>
        </div>
        <div class="col-1">
          <base-input>
            <el-input-number
              v-model="problem1"
              :min="smallestValue"
              :max="largestValue"
              :controls="false"
              size="small"
              class="range-max"
              :disabled="!canupdate"
            ></el-input-number>
          </base-input>
        </div>
        <div class="col-md-3 col-12">
          <base-input :disabled="!canupdate" :viewMode="!canupdate" placeholder="Information" v-model="problem1Info"></base-input>
        </div>
         <div class="col-md-2 col-12">
          <base-checkbox disabled :viewMode="!canupdate" v-model="problem1RaseAlarm">Rase Alarm</base-checkbox>
        </div>
      </div>
      <div class="row mt-4 mb-2 poor1">
        <div class="text-md-center col-md-1 col-12">
          <h5 class="orange pt-2 pb-2">POOR</h5>
        </div>
        <div class="col-12 col-md-4">
          <el-slider
            :value="[problem1, poor1]"
            @input="poor1 = $event[1];"
            range
            :min="smallestValue"
            :max="largestValue"
            :step="enableDecimal ? 0.1 : 1"
            :disabled="!canupdate"
          ></el-slider>
        </div>
        <div class="col-1">
          <base-input>
            <el-input-number
              :value="problem1"
              disabled
              :controls="false"
              size="small"
              class="range-min"
            ></el-input-number>
          </base-input>
        </div>
        <div class="col-1">
          <base-input>
            <el-input-number
              v-model="poor1"
              :min="problem1"
              :max="largestValue"
              :controls="false"
              size="small"
              class="range-max"
              :disabled="!canupdate"
            ></el-input-number>
          </base-input>
        </div>
        <div class="col-md-3 col-12">
          <base-input :disabled="!canupdate" :viewMode="!canupdate" placeholder="Information" v-model="poor1Info"></base-input>
        </div>
        <div class="col-md-2 col-12">
          <base-checkbox :disabled="!canupdate" :viewMode="!canupdate" v-model="poor1RaseAlarm">Rase Alarm</base-checkbox>
        </div>
      </div>
      <div class="row mt-4 mb-2 ok1">
        <div class="text-md-center col-md-1 col-12">
          <h5 class="yellow pt-2 pb-2">OK</h5>
        </div>
        <div class="col-12 col-md-4">
          <el-slider
            :value="[poor1, ok1]"
            @input="ok1 = $event[1];"
            range
            :min="smallestValue"
            :max="largestValue"
            :step="enableDecimal ? 0.1 : 1"
            :disabled="!canupdate"
          ></el-slider>
        </div>
        <div class="col-1">
          <base-input>
            <el-input-number
              :value="poor1"
              disabled
              :controls="false"
              size="small"
              class="range-min"
            ></el-input-number>
          </base-input>
        </div>
        <div class="col-1">
          <base-input>
            <el-input-number
              v-model="ok1"
              :min="poor1"
              :max="largestValue"
              :controls="false"
              size="small"
              class="range-max"
              :disabled="!canupdate"
            ></el-input-number>
          </base-input>
        </div>
        <div class="col-md-3 col-12">
          <base-input :disabled="!canupdate" :viewMode="!canupdate" placeholder="Information" v-model="ok1Info"></base-input>
        </div>
         <div class="col-md-2 col-12">
          <base-checkbox :disabled="!canupdate" :viewMode="!canupdate" v-model="ok1RaseAlarm">Rase Alarm</base-checkbox>
        </div>
      </div>
      <div class="row mt-4 mb-2">
        <div class="text-md-center col-md-1 col-12">
          <h5 class="green pt-2 pb-2">GOOD</h5>
        </div>
        <div class="col-12 col-md-4">
          <el-slider
            :value="[ok1,good]"
            @input="good = $event[1];"
            range
            :min="smallestValue"
            :max="largestValue"
            :step="enableDecimal ? 0.1 : 1"
            :disabled="!canupdate"
          ></el-slider>
        </div>
        <div class="col-1">
          <base-input>
            <el-input-number :value="ok1" disabled :controls="false" size="small" class="range-min"></el-input-number>
          </base-input>
        </div>
        <div class="col-1">
          <base-input>
            <el-input-number
              v-model="good"
              :min="ok1"
              :max="largestValue"
              :controls="false"
              size="small"
              class="range-max"
              :disabled="!canupdate"
            ></el-input-number>
          </base-input>
        </div>
        <div class="col-md-3 col-12">
          <base-input :disabled="!canupdate" :viewMode="!canupdate" placeholder="Information" v-model="goodInfo"></base-input>
        </div>
         <div class="col-md-2 col-12">
          <base-checkbox disabled :viewMode="!canupdate" v-model="goodRaseAlarm">Rase Alarm</base-checkbox>
        </div>
      </div>
      <div class="row mt-4 mb-2 ok2">
        <div class="text-md-center col-md-1 col-12">
          <h5 class="yellow pt-2 pb-2">OK2</h5>
        </div>
        <div class="col-12 col-md-4">
          <el-slider
            :value="[good,ok2]"
            @input="ok2 = $event[1];"
            range
            :min="smallestValue"
            :max="largestValue"
            :step="enableDecimal ? 0.1 : 1"
            :disabled="!canupdate"
          ></el-slider>
        </div>
        <div class="col-1">
          <base-input>
            <el-input-number
              :value="good"
              disabled
              :controls="false"
              size="small"
              class="range-min"
            ></el-input-number>
          </base-input>
        </div>
        <div class="col-1">
          <base-input>
            <el-input-number
              v-model="ok2"
              :min="good"
              :max="largestValue"
              :controls="false"
              size="small"
              class="range-max"
              :disabled="!canupdate"
            ></el-input-number>
          </base-input>
        </div>
        <div class="col-md-3 col-12">
          <base-input :disabled="!canupdate" :viewMode="!canupdate" placeholder="Information" v-model="ok2Info"></base-input>
        </div>
         <div class="col-md-2 col-12">
          <base-checkbox :disabled="!canupdate" :viewMode="!canupdate" v-model="ok2RaseAlarm">Rase Alarm</base-checkbox>
        </div>
      </div>
      <div class="row mt-4 mb-2 poor2">
        <div class="text-md-center col-md-1 col-12">
          <h5 class="orange pt-2 pb-2">POOR2</h5>
        </div>
        <div class="col-12 col-md-4">
          <el-slider
            :value="[ok2,poor2]"
            @input="poor2 = $event[1];"
            range
            :min="smallestValue"
            :max="largestValue"
            :step="enableDecimal ? 0.1 : 1"
            :disabled="!canupdate"
          ></el-slider>
        </div>
        <div class="col-1">
          <base-input>
            <el-input-number :value="ok2" disabled :controls="false" size="small" class="range-min"></el-input-number>
          </base-input>
        </div>
        <div class="col-1">
          <base-input>
            <el-input-number
              v-model="poor2"
              :min="ok2"
              :max="largestValue"
              :controls="false"
              size="small"
              class="range-max"
              :disabled="!canupdate"
            ></el-input-number>
          </base-input>
        </div>
        <div class="col-md-3 col-12">
          <base-input :disabled="!canupdate" :viewMode="!canupdate" placeholder="Information" v-model="poor2Info"></base-input>
        </div>
         <div class="col-md-2 col-12">
          <base-checkbox :disabled="!canupdate" :viewMode="!canupdate" v-model="poor2RaseAlarm">Rase Alarm</base-checkbox>
        </div>
      </div>
      <div class="row mt-4 mb-2 problem2">
        <div class="text-md-center col-md-1 col-12">
          <h5 class="red pt-2 pb-2">PROBLEM2</h5>
        </div>
        <div class="col-12 col-md-4">
          <el-slider
            :value="[poor2,largestValue]"
            @input="problem2 = $event[1];"
            range
            :min="smallestValue"
            :max="largestValue"
            :step="enableDecimal ? 0.1 : 1"
            disabled
          ></el-slider>
        </div>
        <div class="col-1">
          <base-input>
            <el-input-number
              :value="poor2"
              disabled
              :controls="false"
              size="small"
              class="range-min"
            ></el-input-number>
          </base-input>
        </div>
        <div class="col-1">
          <base-input>
            <el-input-number
              :value="largestValue"
              :min="poor2"
              :max="largestValue"
              :controls="false"
              size="small"
              class="range-max"
              disabled
            ></el-input-number>
          </base-input>
        </div>
        <div class="col-md-3 col-12">
          <base-input :disabled="!canupdate" :viewMode="!canupdate" placeholder="Information" v-model="problem2Info"></base-input>
        </div>
         <div class="col-md-2 col-12">
          <base-checkbox disabled :viewMode="!canupdate" v-model="problem2RaseAlarm">Rase Alarm</base-checkbox>
        </div>
      </div>
    </div>
    <div class="row description">
      <div class="col-8">
        <base-input :label="`${sensorTypeName || 'Sensor'} Information`">
          <textarea :disabled="!canupdate" v-model="sensorInfo" class="form-control" :class="{'viewMode':!canupdate}"></textarea>
        </base-input>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-md-4">
        <base-input
          :viewMode="!canupdate"
          v-for="(description, index) in $v.highDescription.$each.$iter"
          :key="index"
          :label="`High ${sensorTypeName || 'Level' } `"
          type="text"
          v-model.trim="description.text.$model"
          :disabled="!canupdate"
        >
          <template v-slot:addonRight v-if="index == 0 && canupdate">
            <base-button
              @click="addInput($event, 'high')"
              simple
              style="width: 40px; height: 40px; margin: 0px"
              :disabled="!canupdate"
            >
              <p class="mt-2">
                <i v-if="true" class="fas fa-plus"></i>
              </p>
            </base-button>
          </template>
          <template v-slot:addonRight v-else-if="canupdate">
            <base-button
              @click="removeInput($event, 'high', index)"
              simple
              style="width: 40px; height: 40px; margin: 0px"
              :disabled="!canupdate"
            >
              <p class="mt-2">
                <i v-if="true" class="fas fa-minus"></i>
              </p>
            </base-button>
          </template>
          <template v-slot:error v-if="description.$error">
            <span>Description must be empty or at least 5 characters</span>
          </template>
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input
          :viewMode="!canupdate"
          v-for="(description, index) in $v.mediumDescription.$each.$iter"
          :key="index"
          :label="`Medium ${sensorTypeName || 'Level'} `"
          type="text"
          v-model.trim="description.text.$model"
          :disabled="!canupdate"
        >
          <template v-slot:addonRight v-if="index == 0 && canupdate">
            <base-button
              @click="addInput($event, 'medium')"
              simple
              style="width: 40px; height: 40px; margin: 0px"
              :disabled="!canupdate"
            >
              <p class="mt-2">
                <i v-if="true" class="fas fa-plus"></i>
              </p>
            </base-button>
          </template>
          <template v-slot:addonRight v-else-if="canupdate">
            <base-button
              @click="removeInput($event, 'medium', index)"
              simple
              style="width: 40px; height: 40px; margin: 0px"
              :disabled="!canupdate"
            >
              <p class="mt-2">
                <i v-if="true" class="fas fa-minus"></i>
              </p>
            </base-button>
          </template>
          <template v-slot:error v-if="description.$error">
            <span>Description must be empty or at least 5 characters</span>
          </template>
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input
          :viewMode="!canupdate"
          v-for="(description, index) in $v.lowDescription.$each.$iter"
          :key="index"
          :label="`Low ${sensorTypeName || 'Level'} `"
          type="text"
          v-model.trim="description.text.$model"
          :disabled="!canupdate"
        >
          <template v-slot:addonRight v-if="index == 0 && canupdate">
            <base-button
              @click="addInput($event, 'low')"
              simple
              style="width: 40px; height: 40px; margin: 0px"
              :disabled="!canupdate"
            >
              <p class="mt-2">
                <i v-if="true" class="fas fa-plus"></i>
              </p>
            </base-button>
          </template>
          <template v-slot:addonRight v-else-if="canupdate">
            <base-button
              @click="removeInput($event, 'low', index)"
              simple
              style="width: 40px; height: 40px; margin: 0px"
              :disabled="!canupdate"
            >
              <p class="mt-2">
                <i v-if="true" class="fas fa-minus"></i>
              </p>
            </base-button>
          </template>
          <template v-slot:error v-if="description.$error">
            <span>Description must be empty or at least 5 characters</span>
          </template>
        </base-input>
      </div>
    </div>
  </form>
</template>

<style scoped>
.viewMode{
  background-color: #f5f7fa21 !important;
  color: #ffff !important;
  font-weight: bold !important;
  border: 0px !important;
  cursor:auto !important;
}

textarea{
      box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.3);
}
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #f5f7fa21;
  border-color: #e4e7ed;
}
::v-deep .form-group .el-input__inner {
  color: white;
  font-weight: bold;
  letter-spacing: 0.5px;
}
h5 {
  border-radius: 10px;
}
::v-deep .range-max input,
::v-deep .range-min input {
  padding-left: 15px !important;
}

.circle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 5px 9px;
  cursor: pointer;
  color: white;
}

.green {
  background-color: var(--green);
}

.red {
  background-color: var(--red);
}

.yellow {
  background-color: var(--yellow);
}

.orange {
  background-color: var(--orange);
}
</style>

<script>
import BaseSelect from "src/components/Inputs/BaseSelect";
import { Slider, InputNumber } from "element-ui";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  props: {
    id: {},
    canupdate: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    validation() {
      this.$v.$touch();
      if (this.good == this.ok1) {
        this.$notify({
          message: 'The Range "GOOD" Must Be Provided!',
          type: "warning"
        });
        return false;
      }
      return !this.$v.$invalid;
    },
    submit() {
      console.log(this.$v);
      if (!this.validation()) {
        return;
      }
      const info = {
        sensorInfo: this.sensorInfo,
        low: this.lowDescription.filter(d => d.text.length > 5),
        high: this.highDescription.filter(d => d.text.length > 5),
        medium: this.mediumDescription.filter(d => d.text.length > 5),
      };
      let ranges = [];
      // Add Problem1
      ranges.push({
        status: 'problem',
        start_range: this.smallestValue,
        end_range: this.problem1,
        info: this.problem1Info,
        raise_alarm: this.problem1RaseAlarm,
        inclusive: 'left',
        bound: 'lower'
      });
      // Add Poor1
      ranges.push({
        status: 'poor',
        start_range: this.problem1,
        end_range: this.poor1,
        info: this.poor1Info,
        raise_alarm: this.poor1RaseAlarm,
        inclusive: 'left',
        bound: 'mid'
      });
      // Add ok1
      ranges.push({
        status: 'ok',
        start_range: this.poor1,
        end_range: this.ok1,
        info: this.ok1Info,
        raise_alarm: this.ok1RaseAlarm,
        inclusive: 'left',
        bound: 'mid'
      });
      // Add good
      ranges.push({
        status: 'good',
        start_range: this.ok1,
        end_range: this.good,
        info: this.goodInfo,
        raise_alarm: this.goodRaseAlarm,
        inclusive: 'center',
        bound: 'mid'
      });
      // Add ok2
      ranges.push({
        status: 'ok',
        start_range: this.good,
        end_range: this.ok2,
        info: this.ok2Info,
        raise_alarm: this.ok2RaseAlarm,
        inclusive: 'right',
        bound: 'mid'
      });
      // Add poor2
      ranges.push({
        status: 'poor',
        start_range: this.ok2,
        end_range: this.poor2,
        info: this.poor2Info,
        raise_alarm: this.poor2RaseAlarm,
        inclusive: 'right',
        bound: 'mid'
      });
      // Add problem2
      ranges.push({
        status: 'problem',
        start_range: this.poor2,
        end_range: this.largestValue,
        info: this.problem2Info,
        raise_alarm: this.problem2RaseAlarm,
        inclusive: 'right',
        bound: 'upper'
      });

      return {
        name: this.sensorName,
        type: this.sensorType,
        isDecimal: this.enableDecimal,
        sensor_life: `${this.lifeYear}|${this.lifeMonth}`,
        ranges,
        description: JSON.stringify(info)
      };
    },
    startWith() {
      if (this.id) {
        let sensorData;
        this.$store
        .dispatch("SwamStore/SensorsTemplates/FETCH_ITEM", {
          key: `sensors item`,
          query: {id:this.id},
        })
        .then((response) => {
          sensorData = response.data;
          var life = sensorData.sensor_life.split("|");
  
          this.sensorName = sensorData.name;
          this.sensorType = sensorData.type
          this.enableDecimal = !!sensorData.isDecimal;
  
          this.lifeYear = life[0];
          this.lifeMonth = life[1];
          try{
            const info = JSON.parse(sensorData.description);
            console.log(info);
            if(info){
              this.sensorInfo = info.sensorInfo;
              this.highDescription = info.high.length > 0 ? info.high : [{text: ""}] ;
              this.mediumDescription = info.medium.length > 0 ? info.medium : [{text: ""}];
              this.lowDescription = info.low.length > 0 ? info.low : [{text: ""}];
            }
          }catch(e){
            console.error(e);
          }
  
          let renges = sensorData.status;
  
          for (var i = 0; i < renges.length; i++) {
            let st = renges[i];
            switch (st.status) {
              case "good":
                this.good = st.end_range != null ? st.end_range : "";
                this.goodInfo = st.info;
                this.goodRaseAlarm = st.raise_alarm;
                break;
              case "ok":
                if(st.inclusive == 'left')
                {
                  this.ok1 = st.end_range != null ? st.end_range : "";
                  this.ok1Info = st.info;
                  this.ok1RaseAlarm = st.raise_alarm;
                } else {
                  this.ok2 = st.end_range != null ? st.end_range : "";
                  this.ok2Info = st.info;
                  this.ok2RaseAlarm = st.raise_alarm;
                }
                break;
              case "poor":
                if(st.inclusive == 'left')
                {
                  this.poor1 = st.end_range != null ? st.end_range : "";
                  this.poor1Info = st.info;
                  this.poor1RaseAlarm = st.raise_alarm;
                } else {
                  this.poor2 = st.end_range != null ? st.end_range : "";
                  this.poor2Info = st.info;
                  this.poor2RaseAlarm = st.raise_alarm;
                }
                break;
              case "problem":
                //
                if(st.inclusive == 'left')
                {
                  this.smallestValue = st.start_range != null ? st.start_range : "";
                  this.problem1 = st.end_range != null ? st.end_range : "";
                  this.problem1Info = st.info;
                  this.problem2RaseAlarm = st.raise_alarm;
                } else {
                  this.problem2 = st.end_range != null ? st.end_range : "";
                  this.problem2Info = st.info;
                  this.largestValue = this.problem2;
                  this.problem1RaseAlarm = st.raise_alarm;
                }
                break;
            }
          }
        })
        .catch((error) => {
          console.error("Error while fetching sensors data", error);
        });
      }
    },
    addInput(value, inputName) {
      this[inputName + "Description"].push({text: ""});
    },
    removeInput(value, inputName, index) {
      this[inputName + "Description"].splice(index, 1);
    }
  },
  data() {
    return {
      smallestValue: 0,
      problem1: 15,
      poor1: 30,
      ok1: 45,
      good: 60,
      ok2: 75,
      poor2: 90,
      problem2: 100,
      largestValue: 100,
      problem1Info: null,
      poor1Info: null,
      ok1Info: null,
      goodInfo: null,
      ok2Info: null,
      poor2Info: null,
      problem2Info: null,
      problem1RaseAlarm:true,
      poor1RaseAlarm:true,
      ok1RaseAlarm:false,
      goodRaseAlarm:false,
      ok2RaseAlarm:false,
      poor2RaseAlarm:true,
      problem2RaseAlarm:true,


      sensorType: null,
      sensorTypeName: null,
      sensorName: null,
      lifeYear: null,
      lifeMonth: null,
      enableDecimal: false,
      yearOptions: [
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" }
      ],
      monthOptions: [
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" }
      ],
      highDescription: [{ text: "" }],
      mediumDescription: [{ text: "" }],
      lowDescription: [{ text: "" }],
      sensorInfo: null
    };
  },
  validations: {
    sensorName: {
      required
    },
    sensorType: {
      required
    },
    lifeYear: {
      required,
      validator: function() {
        return !(this.lifeMonth == "0" && this.lifeYear == "0");
      }
    },
    lifeMonth: {
      required
    },
    highDescription: {
      $each: {
        text: {
          minLength: minLength(5)
        }
      }
    },
    mediumDescription: {
      $each: {
        text: {
          minLength: minLength(5)
        }
      }
    },
    lowDescription: {
      $each: {
        text: {
          minLength: minLength(5)
        }
      }
    }
  },
  components: {
    [BaseSelect.name]: BaseSelect,
    [Slider.name]: Slider,
    [InputNumber.name]: InputNumber
  },
  computed: {
    sensors() {
      return this.$store.state.sensor_type.map(s => {
        return { value: s.id, label: s.name };
      });
    }
  },
  mounted() {
    if (this.id) {
      this.startWith();
    }
  },
  watch: {
    sensorType: {
      handler(newVal) {
        if (newVal) {
          this.sensorTypeName = this.sensors.find(s => s.value == newVal).label;
        }
      }
    }
  }
};
</script>
