<template>
  <div>
    <div class="map-section">
      <card :bodyClasses="'p-0'">
        <base-map v-if="customerData && customerData.lat" :zoom="12" :center="{lat:customerData.lat,lng:customerData.lng}" :style="`height: 350px`">
          <base-map-marker v-if="customerData && customerData.lat" :position="{lat:customerData.lat,lng:customerData.lng}"/>
        </base-map>
      </card>
    </div>
      <card>
        <el-table :data="tableData" :span-method="objectSpanMethod">
          <el-table-column
            prop="device_name"
            label="nvr"
            min-width="100"
            align="center"
          >
          <template slot-scope="{ row }">
            <span>{{ getDeviceName(row) }}</span>
          </template>
        </el-table-column>
          <el-table-column
            label="nvr details"
            min-width="100"
            align="center"
          >
          <template slot-scope="{ row }">
            <span>{{ getDeviceDetails(row) }}</span>
          </template>
        </el-table-column>
          <el-table-column
            label="nvr alarms email"
            min-width="150"
            align="center"
          >
          <template slot-scope="{ row }">
            <span>{{ getDeviceAlarmsEmail(row) }}</span>
          </template>
        </el-table-column>
          <el-table-column
            v-for="(column,index) in tableColumns"
            :key="index"
            v-bind="column"
          />
        </el-table>
      </card>
      <div class="row mt-3">
        <div class="col-6">
          <card class="remove-p full-height-card">
            <div class="d-flex h-100">
              <div class="col-6" style="border-right: 1px solid #e8e8e8">
                <div class="p-3 pb-0">
                  <div class="py-2">
                    <h4 class="primary-header font-weight-bold m-0">
                      {{ $t("structureWords.structureName") }}
                    </h4>
                    <span class="font-color-3">{{
                      customerData.structure_name || "--"
                    }}</span>
                  </div>
                <div class="py-2">
                  <h4 class="primary-header font-weight-bold m-0">City</h4>
                  <span class="font-color-3">{{
                    customerData.city_name || "--"
                  }}</span>
                </div>
                <div class="py-2">
                  <h4 class="primary-header font-weight-bold m-0">Community</h4>
                  <span class="font-color-3">{{
                    customerData.community_name_en || "--"
                  }}</span>
                </div>
                <div class="py-2">
                  <h4 class="primary-header font-weight-bold m-0">longitude</h4>
                  <span class="font-color-3">{{ customerData.lng || "--" }}</span>
                </div>
                </div>
              </div>
              <div class="col-6">
                <div class="p-3 pb-0">
                  <div class="py-2">
                    <h4 class="primary-header font-weight-bold m-0">Floors</h4>
                    <span class="font-color-3">{{
                      customerData.floors || "--"
                    }}</span>
                    </div>
                  <div class="py-2">
                  <h4 class="primary-header font-weight-bold m-0">Country</h4>
                  <span class="font-color-3">{{
                    customerData.country_name || "--"
                  }}</span>
                  </div>
                  <div class="py-2">
                  <h4 class="primary-header font-weight-bold m-0">Customer</h4>
                  <span class="font-color-3">{{
                    customerData.customer_name || "--"
                  }}</span>
                  </div>
                  <div class="py-2">
                  <h4 class="primary-header font-weight-bold m-0">latitude</h4>
                  <span class="font-color-3">{{ customerData.lat || "--" }}</span>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
        <div class="col-6">
          <card class="full-height-card">
            <h5 slot="header">contacts list</h5>
            <company-contacts-table :contacts="customerData.contacts"/>
          </card>
        </div>
      </div>
  </div>
</template>

<script>
import api from "@/axiosWithDelimiterFile.js"
import BaseMap from 'src/components/BaseMap/Map'
import MapMarker from "src/components/BaseMap/MapMarker.vue";
import { emptyTableCellFormatter } from "@/modules/common/utils/helper";
import CompanyContactsTable from '../../../pages/Profiles/components/CompanyContactsTable.vue';
export default {
  components: {
    CompanyContactsTable,
    [BaseMap.name]: BaseMap,
    [MapMarker.name]: MapMarker,
  },
  data() {
    return {
      customerData: {},
      tableColumns: [
        {
          prop:"cameraName",
          label:'camera name',
          minWidth:"100",
        },
        {
          prop:"channel",
          label:'channel',
          minWidth:"100",
        },
        {
          prop:"cameraDetails",
          label:'camera details',
          minWidth:"150",
        },
        {
          prop:"off",
          label:'state',
          minWidth:"100",
          formatter: emptyTableCellFormatter,
        }
      ]
    }
  },
  created() {
    let id = this.$route.params.id;
    const url = `/vsaas/villas/${id}`
    api.get(url).then(res=> {
      this.customerData = res.data.data;
      this.customerData.contacts = this.customerData.contacts.map(r=> ({
        ...r,
        firstName: r.first_name,
        lastName: r.last_name,
      }));
    })
  },
  computed: {
    tableData() {
      let result = [];
      if(!this.customerData || !this.customerData.devices) {
        return result;
      }
      this.customerData.devices.forEach(device => {
        //let deviceName = device.device_name;
        if(device.cameras) {
          device.cameras.forEach(camera => {
            result.push({
              //deviceName,
              cameraName:camera.device_name,
              channel:camera.channel_id,
              cameraDetails: camera.device_details,
              type: camera.type,
              alarmEmail: camera.alarm_email,
              off: camera.off ? 'off' : 'on',
              parent_id: camera.parent_id,
            });
          })
        }
      });
      return result;
    }
  },
  methods: {
    getDeviceName(row) {
      let deviceId = row.parent_id;
      return this.customerData.devices.find(device => device.id == deviceId).device_name;
    },
    getDeviceDetails(row) {
      let deviceId = row.parent_id;
      return this.customerData.devices.find(device => device.id == deviceId).device_details;
    },
    getDeviceAlarmsEmail(row) {
      let deviceId = row.parent_id;
      return this.customerData.devices.find(device => device.id == deviceId).alarm_email;
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let deviceId = this.tableData[rowIndex].parent_id;
      let camerasCount = this.customerData.devices.find(device => device.id == deviceId).cameras.length;
      if (columnIndex === 0) {
        if(rowIndex % camerasCount === 0) {
          return {
            rowspan: camerasCount,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.primary-header {
  color: $primary !important;
}
</style>