<template>
  <form>
    <card footer-classes="text-right">
      <div slot="header">
        <h4 class="card-title" v-if="id">Update Release</h4>
        <h4 class="card-title" v-else>Create Release</h4>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input :placeholder="$t('Version')" v-model="$v.version.$model">
            <template v-if="$v.version.$error" v-slot:error>
              <label class="error" v-if="!$v.version.required">Version required</label>
            </template>
          </base-input>
        </div>
        <div class="col-md-6">
          <base-input :placeholder="$t('Last Modified')" v-model="$v.last_modified.$model">
            <template v-if="$v.last_modified.$error" v-slot:error>
              <label class="error" v-if="!$v.last_modified.required">Last Modified required</label>
            </template>
          </base-input>
        </div>
      </div>
      <template slot="footer" class="text-right">
        <base-button native-type="submit" @click.prevent="submit" type="primary">
          <span v-if="id">Update</span>
          <span v-else>Create</span>
        </base-button>
      </template>
    </card>
  </form>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      version: "",
      last_modified: "",
      id: "",
    };
  },
  validations: {
    version: {
      required,
    },
    last_modified: {
      required,
    },
  },
  mounted() {
    if (/update\/[0-9]/.test(this.$route.path)) {
      this.id = this.$route.params.id;
      this.$api
        .get(`releases/${this.id}`)
        .then((res) => {
          this.version = res.data.data.version;
          this.last_modified = res.data.data.last_modified;
        })
        .catch((err) => {});
    }
  },
  methods: {
    validate() {
      this.$v.$touch();
    },
    async submit() {
      var date;
      if ((date = new Date(this.last_modified) == "Invalid Date")) {
        this.$notify({
          type: "danger",
          title: "Invalid Date",
          message: "Please Enter valid date in last modified field",
        });
        return;
      } else {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$notify({
            type: "danger",
            title: "Invalid Data",
            message: "There are Invalid Fields",
          });
          return;
        } else {
          let data = {
            version: this.version,
            last_modified: this.last_modified,
          };
          console.log(data);
          if (this.id) {
            this.$api
              .put(`/releases/${this.id}`, data)
              .then((res) => {
                this.$router.push({ path: "/release" });
              })
              .catch((err) => {});
          } else {
            this.$api
              .post(`/releases`, data)
              .then((res) => {
                this.$router.push({ path: "/release" });
              })
              .catch((err) => {});
          }
        }
      }
    },
  },
};
</script>
