<template>
  <div>
    <div class="my-2 map-section">
      <base-map v-if="villa" :zoom="12" :center="center" :style="`height: 350px`">
        <base-map-marker v-if="this.marker.lat" :position="marker"/>
      </base-map>
    </div>

    <div class="row mt-3">
      <div class="col-6">
        <card class="remove-p full-height-card">
          <div class="d-flex h-100">
            <div class="col-6" style="border-right: 1px solid #e8e8e8">
              <div class="p-3 pb-0">
                <div class="py-2">
                  <h4 class="primary-header font-weight-bold m-0">
                    {{ $t("structureWords.structureName") }}
                  </h4>
                  <span class="font-color-3">{{
                    this.villa.structure_name || "--"
                  }}</span>
                </div>
                <div class="py-2">
                  <h4 class="primary-header font-weight-bold m-0">City</h4>
                  <span class="font-color-3">{{
                    this.villa.city_name || "--"
                  }}</span>
                </div>
                <div class="py-2">
                  <h4 class="primary-header font-weight-bold m-0">Community</h4>
                  <span class="font-color-3">{{
                    this.villa.community_name_en || "--"
                  }}</span>
                </div>
                <div class="py-2">
                  <h4 class="primary-header font-weight-bold m-0">longitude</h4>
                  <span class="font-color-3">{{ this.villa.lng || "--" }}</span>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="p-3 pb-0">
                <div class="py-2">
                  <h4 class="primary-header font-weight-bold m-0">Floors</h4>
                  <span class="font-color-3">{{
                    this.villa.floors || "--"
                  }}</span>
                </div>
                <div class="py-2">
                  <h4 class="primary-header font-weight-bold m-0">Country</h4>
                  <span class="font-color-3">{{
                    this.villa.country_name || "--"
                  }}</span>
                </div>
                <div class="py-2">
                  <h4 class="primary-header font-weight-bold m-0">Customer</h4>
                  <span class="font-color-3">{{
                    this.villa.customer_name || "--"
                  }}</span>
                </div>
                <div class="py-2">
                  <h4 class="primary-header font-weight-bold m-0">latitude</h4>
                  <span class="font-color-3">{{ this.villa.lat || "--" }}</span>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
      <div class="col-6">
        <card class="full-height-card">
          <company-contacts-table :contacts="villa.contacts || []" />
        </card>
      </div>
      <div class="col-md-6">
        <card class="full-height-card">
          <devices-table :devices="villa.devices || []" />
        </card>
      </div>
      <div class="col-md-6">
        <card class="full-height-card">
          <div slot="header" class="row">
            <div class="col-6">
              <p>total devices: {{(villa.devices || "").length}}</p>
            </div>
            <div class="col-6">
              <p>active alarms: {{(villa.devices || []).reduce((acc, val)=>{return val.active_intrusion_alarms+acc},0)}}</p>
            </div>
          </div>
          <villa-map
            v-if="villaMapSrc"
            :mapSrc="villaMapSrc"
            :sensors="villa.devices.filter(s=> typeof s.x == 'number' && typeof s.y == 'number')"
            :alarmedSensors="alarmedSensors"
          />
        </card>
      </div>
    </div>

    <div>
      <base-button
        :round="false"
        size="sm"
        :type="'primary'"
        :simple="!isIntrusionSelected"
        class="py-1"
        @click="switchAlarmsTables('intrusion')"
        >intrusion
      </base-button>
      <base-button
        :round="false"
        size="sm"
        :type="'primary'"
        :simple="!isOperationalSelected"
        class="py-1"
        @click="switchAlarmsTables('operational')"
        >operational
      </base-button>
    </div>
    <intrusion-alarms
      v-if="isIntrusionSelected"
      type="intrusion"
      :minimized="true"
      :villa_id="$route.params.villaId"
    />
    <operational-alarms
      v-if="isOperationalSelected"
      type="operational"
      :minimized="true"
      :villa_id="$route.params.villaId"
    />
  </div>
</template>

<script>
import CompanyContactsTable from "../../../pages/Profiles/components/CompanyContactsTable.vue";
import DevicesTable from "./devices-table";
import IntrusionVuexActions from "../store/types/actions-types";
import { mapActions } from "vuex";
import IntrusionAlarms from "./intrusion-alarms.vue";
import OperationalAlarms from "./operational-alarms.vue";
import VillaMap from "../pages/InteractiveMap/components/VillaMap.vue";
import { INTRUSION_SOCKET_NAMESPACE } from '../../../plugins/moro-iot-socket/socket/namespaces';
import { ACKNOWLEDGE_ALARM_EVENT, NEW_ALARM_EVENT, TERMINATE_ALARM_EVENT, VERIFY_ALARM_EVENT } from '../../../plugins/moro-iot-socket/socket/custom-events';
import BaseMap from 'src/components/BaseMap/Map'
import MapMarker from "src/components/BaseMap/MapMarker.vue";

export default {
  components: {
    CompanyContactsTable,
    DevicesTable,
    IntrusionAlarms,
    OperationalAlarms,
    VillaMap,
    [BaseMap.name]: BaseMap,
    [MapMarker.name]: MapMarker,
  },
  data: () => ({
    villa: {},
    markerIcon: {
      default() {
        return require("src/assets/icons-2/google-map-marker.svg");
      }
    },
    alarmedSensors: [],
    selectedAlarmsTable: "intrusion"
  }),
  mounted() {
    this[`sockets_${INTRUSION_SOCKET_NAMESPACE}`].subscribe(
      NEW_ALARM_EVENT,
      this.newAlarmFromSocket
    );
    this[`sockets_${INTRUSION_SOCKET_NAMESPACE}`].subscribe(
      ACKNOWLEDGE_ALARM_EVENT,
      this.restOfTheSocketEvents
    );
    this[`sockets_${INTRUSION_SOCKET_NAMESPACE}`].subscribe(
      VERIFY_ALARM_EVENT,
      this.restOfTheSocketEvents
    );
    this[`sockets_${INTRUSION_SOCKET_NAMESPACE}`].subscribe(
      TERMINATE_ALARM_EVENT,
      this.restOfTheSocketEvents
    );


    let villaId = this.$route.params.villaId;
    this.villaId = villaId;
    this.getVillaInfoDispatcher(villaId).then(data => {
      this.villa = data.data;
      if (Object.keys(this.villa).length == 0) {
        this.$notify({
          message: "villa data error, try again later",
          type: "danger"
        });
        this.$router.back();
      }
   
      if (this.villa.contacts)
        this.villa.contacts = this.villa.contacts.map(item => ({
          ...item,
          firstName: item.first_name,
          lastName: item.last_name
        }));
    });
  },
  methods: {
    newAlarmFromSocket(data){
      let device = this.villa.devices.find(d=> d.id == data.device_id);
      if(data.device.structure_id == this.villaId ){
        device.active_intrusion_alarms++;
        this.turnOnSensor(device.id);
      }
    },
    restOfTheSocketEvents(data){
      let device = this.villa.devices.find(d=> d.id == data.device_id);
      if(data.archived && device){
        device.active_intrusion_alarms--;
        if(!device.active_intrusion_alarms){
          this.turnOffSensor(device.id);
        }
      }
    },
    ...mapActions("IntrusionStore", {
      getVillaInfoDispatcher: IntrusionVuexActions.GET_VILLA_INFO_ACTION
    }),
    switchAlarmsTables(newType) {
      this.selectedAlarmsTable = newType;
    },
    turnOnSensor(sensorId) {
      this.alarmedSensors.push(sensorId)
    },
    turnOffSensor(sensorId) {
      let idx = this.alarmedSensors.findIndex(e => e === sensorId);
      if(idx != -1)this.alarmedSensors.splice(idx, 1)
    }
  },
  computed: {
    villaMapSrc(){
      if(this.villa.structure_id)
      return `${process.env.VUE_APP_URL}assets/villas/${this.villa.structure_id}`;
      else null;
    },
    isOperationalSelected() {
      return this.selectedAlarmsTable == "operational";
    },
    isIntrusionSelected() {
      return this.selectedAlarmsTable == "intrusion";
    },
    show: {
      get() {
        return this.isOpen;
      },
      set(val) {
        this.$emit("close");
      }
    },
    center() {
      if (!this.villa) return { lat: 0, lng: 0 };
      return {
        lat: this.villa.lat ? this.villa.lat : 0,
        lng: this.villa.lng ? this.villa.lng : 0
      };
    },
    marker() {
      return {
        lat: this.villa.lat,
        lng: this.villa.lng
      };
    }
  },
  beforeDestroy() {
    this.resetStoreToInitialState();
    this[`sockets_${INTRUSION_SOCKET_NAMESPACE}`].unsubscribe(NEW_ALARM_EVENT);
    this[`sockets_${INTRUSION_SOCKET_NAMESPACE}`].unsubscribe(
      ACKNOWLEDGE_ALARM_EVENT
    );
    this[`sockets_${INTRUSION_SOCKET_NAMESPACE}`].unsubscribe(
      VERIFY_ALARM_EVENT
    );
    this[`sockets_${INTRUSION_SOCKET_NAMESPACE}`].unsubscribe(
      TERMINATE_ALARM_EVENT
    );

    // clearInterval(this.socketInterval);
  },
};
</script>

<style lang="scss">
.map-section {
  border-radius: 20px;
  overflow: hidden;
}

.remove-p {
  .card-body {
    padding: 0;
  }
}

.primary-header {
  color: $primary !important;
}
.devices-table{
  max-height:598px;
  overflow-y: scroll;
}
</style>
