<template>
  <form class="row">
    <div class="col-md-6">
      <base-input
        :placeholder="$t('Name')"
        v-model="$v.name.$model"
        :readonly="viewMode"
      >
        <template v-if="$v.name.$error" v-slot:error>
          <label class="error" v-if="!$v.name.required">{{$t("requireds.name")}}</label>
        </template>
      </base-input>
    </div>
    <div class="col-md-6">
      <base-input>
        <el-date-picker
          type="date"
          :placeholder="$t('dateOfConstruction')"
          v-model="$v.date_of_construction.$model"
        ></el-date-picker>
        <template v-if="$v.date_of_construction.$error" v-slot:error>
          <label class="error" v-if="!$v.date_of_construction.required">{{$t("requireds.Date")}}</label>
        </template>
      </base-input>
    </div>

    <div class="col-md-6">
      <base-input :placeholder="'Premises Number'" v-model.trim="$v.number.$model">
        <template v-if="$v.number.$error" v-slot:error>
          <label class="error" v-if="!$v.number.required">Premises {{$t("requireds.Number")}}</label>
          <label
            class="error"
            v-if="!$v.number.alphaNum"
          >Premises Number {{$t("requireds.alphaNum")}}</label>
        </template>
      </base-input>
    </div>

    <div class="col-md-6">
      <BaseAutoComplete
        :placeholder="$t('Neighbourhood / Community')"
        :source="endPoint"
        v-model="community"
        resultsProperty="data"
        :resultsDisplay="lang==='en'? 'Community_English': 'Community_Arabic'"
      >
        <template v-if="$v.community.$error" v-slot:error>
          <label
            class="error"
            style="color: #ec250d;"
            v-if="!$v.community.required"
          >community is required</label>
        </template>
      </BaseAutoComplete>
    </div>

    <div class="col-md-6">
        <countries-select :placeholder="$t('Country')" v-model="$v.country.$model">
                  <template v-if="$v.country.$error" v-slot:error>
                    <label class="error" v-if="!$v.country.required">{{$t("requireds.Country")}}</label>
                  </template>
          </countries-select >
    </div>
    <div class="col-md-6">

  <cities-select :placeholder="$t('City')" v-model="$v.city.$model" :countryId="(country || {}).id">
                  <template v-if="$v.city.$error" v-slot:error>
                    <label class="error" v-if="!$v.city.required">{{$t("requireds.City")}}</label>
                  </template>
                </cities-select >

    </div>
    <div class="col-md-6">
      <base-select :placeholder="$t('Parking')" v-model="$v.parking.$model" :options="parkings">
        <template v-if="$v.parking.$error" v-slot:error>
          <label class="error" v-if="!$v.parking.required">{{$t("requireds.Parking")}}</label>
        </template>
      </base-select>
    </div>
    <!-- building_type -->
    <div class="col-md-6">
      <base-select
        v-model="$v.building_type.$model"
        :options="building_types"
        :placeholder="'Building Type'"
      >
        <template v-if="$v.building_type.$error" v-slot:error>
          <label class="error" v-if="!$v.building_type.required">{{$t("requireds.Type")}}</label>
        </template>
      </base-select>
    </div>
    <!-- Commercial_type -->
    <div class="col-md-6" v-if="isBuilding && (building_type == 2 || building_type== 3)">
      <base-select
        v-model="$v.building_sub_type.$model"
        :options="building_sub_types"
        :placeholder="'Commercial Type'"
      >
        <template v-if="$v.building_sub_type.$error" v-slot:error>
          <label
            class="error"
            v-if="!$v.building_sub_type.required"
          >{{$t("requireds.commercialType")}}</label>
        </template>
      </base-select>
    </div>
    <div class="col-md-6">
      <base-input>
        <gmap-autocomplete
          :placeholder="$t('enter location')"
          ref="gmap"
          @place_changed="setPlace"
          :readonly="viewMode"
          :disabled="viewMode"
        ></gmap-autocomplete>

        <template v-if="(!(this.marker.lng && this.marker.lat)) && touched " v-slot:error>
          <label class="error" v-if="!$v.address.required">{{$t("requireds.Location")}}</label>
        </template>
      </base-input>
    </div>
    <div class="col-3">
      <base-input placeholder="Longitud" v-model.number="marker.lng">

      </base-input>
    </div>
    <div class="col-3">
      <base-input placeholder="Latitude" v-model.number="marker.lat">

      </base-input>
    </div>
    <div class="mapSwitch">
      <label>Map</label>
      <base-switch v-model="showMap" on-text="ON" off-text="OFF" style="margin-bottom: 0;"></base-switch>
    </div>
    <div class="col-md-12 mt-3" v-show="showMap">
      <base-map :zoom="12" :center="center" style="height: 400px">
        <base-map-marker
          v-if="(this.marker.lng && this.marker.lat)"
          :position="marker"
          @click="center=marker"
        />
      </base-map>
    </div>
  </form>
</template>

<script>
import {
  required,
  email,
  requiredIf,
  alphaNum
} from "vuelidate/lib/validators";
import moment from "moment";
import BaseAutoComplete from "@/components/BaseAutoComplete.vue";
import { mapGetters } from "vuex";
import BaseSwitch from "@/components/BaseSwitch";
import BaseMap from 'src/components/BaseMap/Map'
import MapMarker from "src/components/BaseMap/MapMarker.vue";

export default {
  props: {
    structure_type: {
      required
    }
  },
  data() {
    return {
      lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
      name: "",
      number: "",
      date_of_construction: "",
      viewMode: false,
      parking: null,
      country: null,
      city: null,
      marker:{},
      center: { lat: parseFloat(25.11), lng: parseFloat(55.18) },
      address: "",
      community: null,
      touched: false,
      id: "",
      showMap: false
    };
  },
  computed: {
    building_types() {
      return this.$store.getters.building_types.map(type => ({
        value: type.id,
        label: type.name
      }));
    },
    building_sub_types() {
      return this.$store.getters.building_sub_types.map(type => ({
        value: type.id,
        label: type.name
      }));
    },
    parkings() {
      return this.$store.state.parkings.map(pk => ({
        value: pk.id,
        label: pk.name + " Parking"
      }));
    },
    building_type: {
      get() {
        return this.$store.getters.buildingType;
      },
      set(val){
        this.$store.commit('setBuildingType',val)
      }
    },
    building_sub_type: {
      get() {
        return this.$store.getters.buildingSubType;
      },
      set(val){
        this.$store.commit('setBuildingSubType',val)
      }
    },
    countriesCode() {
      return this.$store.state.countriesCode;
    },
    ...mapGetters({
      isBuilding: "isBuilding",
      isVilla: "isVilla",
      isWarehouse: "isWarehouse"
    })
  },
  components: {
    BaseAutoComplete,
    [BaseSwitch.name]: BaseSwitch,
    [BaseMap.name]: BaseMap,
    [MapMarker.name]: MapMarker,
  },
  methods: {
    endPoint(input) {
      return `${this.$api.defaults.baseURL}communities/${this.lang}/${input}`;
    },
    handleInput(data) {
      this.community = data;
    },
    validate() {
      this.$v.$touch();
      this.touched = true;
      if (!(this.marker.lng && this.marker.lat) && this.$refs.gmap.getTextValue() == "") {
        this.$notify({
          message: "Invalid location",
          timeout: 3000,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger"
        });
        return false;
      }
      if (this.$v.$invalid) {
        return false;
      } else {
        this.commit();
        return true;
      }
    },
    commit() {
      const data = {
        structure_type: this.structure_type,
        structure_name: this.name,
        structure_number: this.number,
        date_of_construction: this.date_of_construction,
        building_type: this.building_type,
        building_sub_type: this.building_sub_type,
        parking: this.parking,
        city_id: this.city.id,
        address: this.$refs.gmap.getTextValue(),
        community: this.community ? this.community.selectedObject.id : 1,
        ...this.marker
      };

      this.$store.commit("setStructureInformation", data);
    },
    setPlace(place) {
      const addressComponents = place.address_components;
      if (!addressComponents) {
        this.$notify({
          message: "Please Choose A Valid Address",
          timeout: 3000,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger"
        });

        return;
      }
      const validPlace = true || addressComponents.some(value => {
        return (
          value.short_name == this.country &&
          value.types.some(type => {
            return type == "country";
          })
        );
      });

      if (validPlace) {
        console.log(place);
        const marker = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        };

        this.marker =  marker ;
        this.center = marker;
        this.address = this.$refs.gmap.getTextValue();
      } else {
        this.$refs.gmap.setTextValue("");
        this.currentPlace = null;
        this.address = "";
        this.$notify({
          message: "Can not choose current Place out of the selected Country",
          timeout: 3000,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger"
        });
      }
    },
  },
  validations: {
    structure_type: {
      required
    },
    name: { required },
    number: { required, alphaNum },
    date_of_construction: { required },
    building_type: {
      required
    },
    building_sub_type: {
      required: requiredIf(comp => {
        return comp.isBuilding && comp.building_type == 2;
      })
    },
    parking: { required },
    country: { required },
    city: { required },
    marker: { required },
    address: { required:requiredIf(function(){
      return !this.marker.lng || !this.marker.lat;
    }) },
    community: { required }
  },
  watch:{
    marker:{
      handler(newVal){
        let temp;
        if(newVal.lng){
          temp = parseFloat(newVal.lng);
          if(isNaN(temp)){
            newVal.lng = null
          }
          else{
            newVal.lng = temp;
          }
        }
        if(newVal.lat){
          temp = parseFloat(newVal.lat);
          if(isNaN(temp)){
            newVal.lat = null
          }
          else{
            newVal.lat = temp;
          }
        }
        if(newVal.lat && newVal.lng){
          this.center = newVal;
        }
      },
      deep:true
    }
  }
};
</script>

<style scoped>
.mapSwitch {
  flex: 0 0 50%;
  justify-content: center;
  padding: 0 15px;
  padding-top: 5px;
}
.mapSwitch label {
  margin: 0 10px;
  font-size: 0.9rem;
}
.input-group-prepend > h6 {
  min-width: 80px;
}
</style>
