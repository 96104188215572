<template>
  <div>
    <AcknowledgmentModal
      :contacts="customerContactsListGetter"
      :isOpen="closeOpenAckGetter"
      @submit="submitAndCloseAcknowledgment"
      @close="submitAndCloseAcknowledgment"
    />
    <TerminateModal
      :isOpen="closeOpenTerminateGetter"
      @submit="submitAndCloseTerminateModal"
      @close="submitAndCloseTerminateModal"
    />
    <CommentsModal
      @close="closeCommentsModal"
      @send-msg="addComment"
      :isChatOpen="closeOpenCommentsGetter"
      :users="usersCommentsGetter"
      :messageList="commentsListGetter"
    />
    <EscalateModal
      :isOpen="closeOpenEscalateGetter"
      :deviceCompanies="devicesCompaniesGetter"
      @close="closeEscalateModal"
      @submit="submitAndCloseEscalateModalt"
    />
  </div>
</template>

<script>
// Libs
import { mapActions, mapGetters } from "vuex";

// User import
import AcknowledgmentModal from "./vsaas-acknowledgment-modal";
import CommentsModal from "./vsaas-comments-modal";
import VSaaSActionsTypes from "../../../store/types/actions-types";
import VSaaSGettersTypes from "../../../store/types/getter-types";
import EscalateModal from "./vsaas-escalate-modal";
import TerminateModal from "./vsaas-terminate-modal";
export default {
  components: {
    AcknowledgmentModal,
    TerminateModal,
    CommentsModal,
    EscalateModal
  },
  props: {
    alarm: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters("VSaaSStore", {
      closeOpenAckGetter: VSaaSGettersTypes.OPEN_ACKNOWLEDGEMENT_MODAL_GETTER,
      closeOpenCommentsGetter: VSaaSGettersTypes.OPEN_COMMENTS_MODAL_GETTER,
      usersCommentsGetter: VSaaSGettersTypes.COMMENTS_USERS_GETTER,
      commentsListGetter: VSaaSGettersTypes.COMMENTS_LIST_GETTER,
      closeOpenEscalateGetter: VSaaSGettersTypes.OPEN_ESCALATE_MODAL_GETTER,
      closeOpenTerminateGetter: VSaaSGettersTypes.OPEN_TERMINATE_MODAL_GETTER,
      devicesCompaniesGetter: VSaaSGettersTypes.DEVICES_COMPANIES_GETTER,
      customerContactsListGetter:
        VSaaSGettersTypes.CUSTOMER_CONTACTS_LIST_GETTER
    })
  },
  methods: {
    ...mapActions("VSaaSStore", {
      closeOpenAckDispatcher:
        VSaaSActionsTypes.OPEN_ACKNOWLEDGEMENT_MODAL_ACTION,
      closeOpenCommentsDispatcher: VSaaSActionsTypes.OPEN_COMMENTS_MODAL_ACTION,
      closeOpenEscalateDispatcher: VSaaSActionsTypes.OPEN_ESCALATE_MODAL_ACTION,
      closeOpenTerminateDispatcher:
        VSaaSActionsTypes.OPEN_TERMINATE_MODAL_ACTION,
      ackAlarmDispatcher: VSaaSActionsTypes.ACK_ALARM_ACTION,
      escalateAlarmDispatcher: VSaaSActionsTypes.ESCALATE_ALARM_ACTION,
      terminateAlarmDispatcher: VSaaSActionsTypes.TERMINATE_ALARM_ACTION
    }),

    // Acknowledgement Modal Area
    submitAndCloseAcknowledgment(data) {
      if (data) {
        this.ackAlarmDispatcher({
          alarmId: this.alarm.id,
          comment: data.comment
        });
        if (data.escalate) {
          this.closeOpenEscalateDispatcher({
            closeOrOpen: true,
            deviceId: this.alarm.deviceId
          });
        }
      }
      this.closeOpenAckDispatcher({ closeOrOpen: false });
    },

    submitAndCloseTerminateModal(data) {
      if (data) {
        this.terminateAlarmDispatcher({
          alarmId: this.alarm.id,
          comment: data.comment
        });
      }
      this.closeOpenTerminateDispatcher(false);
    },

    // Comments Modal Area
    closeCommentsModal() {
      console.log("CLosing comments modal");
      this.closeOpenCommentsDispatcher(false);
    },
    addComment() {},

    // Escalate Modal Area
    submitAndCloseEscalateModalt(data) {
      if (data) {
        const payload = {
          alarmId: this.alarm.id,
          comment: data.comment,
          companyIds: data.deviceCompanies
        };
        this.escalateAlarmDispatcher(payload);
      }
      this.closeOpenEscalateDispatcher({ closeOrOpen: false });
    },
    closeEscalateModal() {
      this.closeOpenEscalateDispatcher({ closeOrOpen: false });
    }
  }
};
</script>

<style></style>
