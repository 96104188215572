<template>
  <div>
    <router-link to="/tickets/view" tag="div" style="max-width:100px;">
      <h5 style="color:white;max-width:100px;" class="dash-title my-a ego-h5">
        <i class="fa fa-arrow-left m-2 fa-xx"></i>Back
      </h5>
    </router-link>

    <div class="row TB1">
      <div class="col-12">
        <card card-body-classes="table-full-width ">
          <el-table :data="[ticket]">
            <el-table-column
              min-width="150"
              sortable
              align="left"
              header-align="left"
              label="TICKET"
              class-name="okay"
              property="subject"
            ></el-table-column>

            <!-- <el-table-column
              min-width="150"
              sortable
              align="left"
              header-align="left"
              label="FROM"
              class-name="okay"
              property="fromWho"
            ></el-table-column>-->

            <el-table-column
              min-width="150"
              sortable
              align="left"
              header-align="left"
              :label="$t('from')"
              class-name="okay"
              property="fromWho"
            >
              <template slot-scope="{row}">
                <el-tooltip :disabled="row.iAmOwner">
                  <div slot="content">
                    <span v-if="!row.iAmSender" class="mb-1 text-capitalize">
                      <span v-if="row.from.company">Company Type: {{row.from.type}}</span>
                      <span v-else>Account Type: {{row.from.type}}</span>
                    </span>
                    <br />
                    Sent By: {{row.from.email}}
                  </div>

                  <span v-if="row.iAmOwner" class="you">You</span>
                  <span v-else-if="row.iAmSender" class="your-company">Your Company</span>
                  <span v-else>{{row.from.name}}</span>
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column
              min-width="150"
              sortable
              align="left"
              header-align="left"
              label="CREATED AT"
              class-name="okay"
              property="created_at"
            ></el-table-column>

            <el-table-column
              min-width="150"
              sortable
              align="left"
              header-align="left"
              label="LAST ACTION"
              class-name="okay"
              property
            >
              <template slot-scope="scope">
                <h3
                  class="text-left"
                  style="margin-bottom:0"
                >{{ scope.row.last_action_at ? scope.row.last_action_at : "-" }}</h3>
              </template>
            </el-table-column>
          </el-table>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card
          card-body-classes="table-full-width"
          v-loading="loading"
          element-loading-text="Loading..."
        >
          <h4 slot="header" class="card-title">Receivers</h4>

          <el-table :data="ticket.receivers">
            <!-- <el-table-column
              min-width="150"
              sortable
              align="left"
              header-align="left"
              label="TO"
              class-name="okay"
            >
              <template slot-scope="thing">{{thing.row.to.name}}</template>
            </el-table-column>-->

            <el-table-column
              min-width="150"
              sortable
              align="left"
              header-align="left"
              :label="$t('TO')"
              class-name="okay"
            >
              <template slot-scope="data">
                <el-tooltip>
                  <div slot="content" class="text-capitalize">
                    <span v-if="data.row.to.company">Company Type: {{data.row.to.type}}</span>
                    <span v-else>Account Type: {{data.row.to.type}}</span>
                    <br class="mb-1" />
                    <!-- admin || super_admin -->
                    <span v-if="!data.row.to.company && data.row.to.email">Email: {{data.row.to.email}}</span>
                  </div>
                  <span>{{data.row.to.name}}</span>
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column
              min-width="150"
              sortable
              align="left"
              header-align="left"
              label="STATUS"
              class-name="okay"
              property
            >
              <template slot-scope="thing">
                <h6>
                  <base-button
                    class="animation-on-hover but-Multi_res"
                    type="success"
                    :class="getStatusColor(thing.row.status)"
                  >{{ statusText(thing.row.status) }}</base-button>
                </h6>
              </template>
            </el-table-column>

            <el-table-column
              min-width="150"
              sortable
              align="left"
              header-align="left"
              label="LAST ACTION"
              class-name="okay"
              property
            >
              <template slot-scope="thing">
                <p v-if="thing.row.last_action_at" class="h5">{{ thing.row.last_action_at }}</p>
                <h3 v-else class="text-left">{{ "-" }}</h3>
              </template>
            </el-table-column>

            <el-table-column
              min-width="150"
              sortable
              align="left"
              header-align="left"
              label="DETAILS"
              class-name="okay"
            >
              <base-button
                slot-scope="row"
                class="animation-on-hover but-Multi_res"
                type="info"
                @click="save(row.row)"
              >DETAILS</base-button>
            </el-table-column>
          </el-table>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap align-items-center"
            align="right"
          >
            <div class>
              <p class="card-category">Showing {{ from }} to {{ to }} of {{ total }} entries</p>
            </div>
            <div class="footerPag">
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="pagination.total"
              ></base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { BasePagination } from "src/components";
import { mixin } from "./TicketsHelper";
import { extractReceiver } from "./Ticketing-Store";
export default {
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      loading: false,
      allReceivers: {} // page:receivers
    };
  },
  components: {
    BasePagination
  },
  mixins: [mixin],
  mounted() {},
  asyncComputed: {
    test: {
      get() {
        if (this.allReceivers[`${this.pagination.currentPage}`]) {
          this.ticket.receivers = this.allReceivers[
            `${this.pagination.currentPage}`
          ];
          return;
        }
        this.loading = true;
        this.$api
          .get(
            `messageReceivers/messageId/${this.ticket.id}?limit=${this.pagination.perPage}&offset=${this.offset}`
          )
          .then(res => {
            this.setReceivers(res.data.data);
            this.loading = false;
          })
          .catch(err => {
            this.loading = false;
          });
      }
    }
  },
  computed: {
    ticket() {
      return this.$store.getters.openedTicket;
    },
    to() {
      if (
        this.pagination.perPage * this.pagination.currentPage >
        this.ticket.receivers.length
      ) {
        return this.ticket.receivers.length + this.offset;
      }
      return this.pagination.perPage * this.pagination.currentPage;
    },
    from() {
      return this.offset + 1;
    },
    limit() {
      return this.pagination.perPage;
    },
    offset() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    }
  },
  created() {
    if (!this.ticket) {
      this.$router.push("/tickets");
      return;
    }
    this.setReceivers(this.ticket.receivers);

    this.pagination.total = this.ticket.receiversCount;
  },
  methods: {
    save(receiver) {
      this.$store.commit("openedRoom", { ...this.ticket, ...receiver });
      this.$router.push(`/tickets/${receiver.roomId}/Messages`);
    },
    setReceivers(rr) {
      rr.forEach(r => {
        if (r.last_action_at) {
          r.last_action_at = this.gDateFormat(r.last_action_at);
        }

        // if (!r.company_type&& r.receiver_role_id) {
        //   // TODO: check super admin
        //   r.to = {
        //     name: "Admin",
        //     type: "admin",
        //     id: r.receiver_user_id
        //   };
        // } else {
        //   r.to = {
        //     name: r.company_name,
        //     type: r.company_type,
        //     id: r.receiver_company_id
        //   };
        // }

        r.to = extractReceiver(r);

        r.status = r.owner_status;
      });
      this.allReceivers[`${this.pagination.currentPage}`] = rr;
      this.ticket.receivers = rr;
    }
  },
  destroyed() {
    //this.$store.commit("openTicket", null)
  }
};
</script>

<style scoped>
.but-Multi_res {
  width: 110px;
  padding: 7px 14px;
}

.ego-h5 {
  cursor: pointer;
}
.ego-h5:hover {
  color: rgb(195, 82, 234) !important;
}
.TB1 {
  padding-bottom: 50px;
}
.your-company,
.you {
  font-weight: 600;
  color: var(--lighter);
  text-transform: capitalize;
}
</style>
