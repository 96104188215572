<template>
  <div class="font-color-1">
    <div class="chartContainer of-visible">
      <zingchart
        ref="chart"
        v-if="config"
        :data="config"
        style="text-transform: capitalize; width: 100% !important;"
        :height="'350px'"
        :width="'100%'"
      ></zingchart>
    </div>
  </div>
</template>

<script>
  let colors = [
    "#F2393B",
    "#F3722C",
    "#F8961E",
    "#F9C74F",
    "#3FAC79",
    "#90BE6D",
  ];

  export default {
    props: {
      items:{
        type: Array,
        default: ()=> []
      }
    },
    data: () => ({}),
    methods: {
      getConfigGauge(items) {
        const total = items.reduce((ac, item) => ac + item.value, 0)

        const getPercent = (value) => value * 100 / total

        const config = {
          type: "ring",
          backgroundColor: 'transparent',
          legend: {
            layout: 'float',
            borderWidth: 0,
            align: 'center',
            verticalAlign: 'bottom',
            backgroundColor: 'transparent',
            item: {
              fontColor: '#525f7f',
            },
            marker: {
              type: 'circle'
            }
          },
          scale: {
            sizeFactor: '100%'
          },
          scaleR: {
            ring: {
              size: 10
            }
          },
          plot: {
            slice: '60%',
            borderWidth: 0,
            valueBox: {
              text: '%v'
            }
          },
          series: []
        };

        items.forEach((item, index) => {
          config.series.push({
            values: [item.value],
            text: item.label,
            backgroundColor: colors[index],
            valueBox: {
              text: getPercent(item.value) > 3 ? '%v' : ''
            }
          })
        })

        return config
      },
    },
    computed: {
      config() {
        return this.items.length ? this.getConfigGauge(this.items) : null;
      },
    },
  }
</script>

<style scoped lang="scss">
  div.chartContainer {
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .of-visible {
    overflow: visible !important;
    div {
      overflow: visible !important;
    }

    svg {
      overflow: visible !important;
    }
  }
</style>