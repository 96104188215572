<template>
  <card class="mb-2">
    <h3 slot="header" class="mb-2 header-title font-weight-bold">
      {{ $tc("search.filter", 2) }}
    </h3>

    <div class="row">
      <div class="col-md-2-5">
        <base-select
          :label="'stations'"
          v-model="filter.police_station_id"
          :options="filterOptions.policeStationOptions"
        ></base-select>
      </div>
      <div class="col-md-2-5">
        <base-select
          :label="$t('villa')"
          v-model="filter.villa_id"
          :options="filterOptions.villaOptions"
        ></base-select>
      </div>
      <div class="col-md-2-5">
        <base-select
          :label="$t('alarm')"
          v-model="filter.alarm_type"
          :options="filterOptions.alarmOptions"
        ></base-select>
      </div>
      <div class="col-md-2-5">
        <base-select
          :label="$t('event')"
          v-model="filter.event_type"
          :options="filterOptions.eventOptions"
        ></base-select>
      </div>
      <div class="col-md-2-5">
        <base-select
          :label="$t('area')"
          v-model="filter.area_id"
          :options="filterOptions.areaOptions"
        ></base-select>
      </div>
      <!-- <div class="col-md-2-5">
        <base-select
          :label="$t('status')"
          v-model="filter.status"
          :options="filterOptions.statusOptions"
        ></base-select>
      </div>-->
      <div class="col-md-2-5">
        <base-input label="alarm id">
            <el-input-number
              :controls="false"
              v-model="filter.alarm_id"
            ></el-input-number>
          </base-input>
      </div>
      <div class="col-md-4 align-self-end mb-2">
        <el-date-picker
          v-model="filter.date"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
        >
        </el-date-picker>
      </div>
    </div>
    <slot name="extra" v-bind:filter="filter"></slot>
    <div
      slot="footer"
      class="d-flex mt-4 justify-content-between"
      style="width: 175px"
    >
      <base-button
        :disabled="!isChanged"
        type="info"
        size="sm"
        class="btn-apply font-size-16 font-weight-bold"
        @click="apply"
        >{{ $t("instructions.apply") }}</base-button
      >
      <base-button
        class="btn-reset font-size-16 font-weight-normal"
        :disabled="disableReset"
        size="sm"
        @click="reset"
        >{{ $t("instructions.reset") }}</base-button
      >
    </div>
  </card>
</template>

<script>
import { mapGetters } from "vuex";
import helper from "@/util/helper";
export default {
  props: {
    initialFilter: {
      description: "a filter to start the component with,"
    },
    filterOptions: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      old: null,
      filter: {}
    };
  },
  created() {
    this.$set(this.filter,"villa_id",this.initialFilter.villa_id)
    this.apply();
  },
  computed: {
    ...mapGetters({}),
    isChanged() {
      console.log(this.old, this.filter)
      return JSON.stringify(this.filter) != this.old;
    },
    disableReset() {
      return JSON.stringify(this.filter) == "{}";
    },
    
  },
  methods: {
    reset() {
      this.filter = {};
      this.old = null;
      this.apply();
    },
    apply() {
      this.old = JSON.stringify(this.filter);
      const data = { ...this.filter };
      if(data.date){
        data.start = new Date(data.date[0]);
        data.end = new Date(data.date[1]);
        delete data.date;
      }
      this.$emit("filter", data);
    }
  },
  watch: {
    filter: {
      handler: helper.debounce(function(newVal) {
        if (this.isChanged) {
          const old = JSON.parse(this.old);
          Object.assign(old, this.filter);
        }
      }, 10000),
      deep: true
    }
  }
};
</script>

<style scoped>
::v-deep .card-footer {
  padding-top: 0;
}
::v-deep .card-body {
  padding-bottom: 0;
}
.header-title {
  font-size: 26px;
}
.btn-apply {
  background-color: #41b581;
  color: #fff;
}

.btn-reset {
  background-color: #707070;
  color: #fff;
}
</style>
