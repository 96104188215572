<template>
  <div class="row">
    <div class="col-lg-8">
      <div class="row">
        <div class="col-md-9 col-xs-6">
          <base-input
            placeholder="Name"
            class="custom-input">
          </base-input>
        </div>
        <div class="col-md-3 col-xs-6">
          <base-button block class="my-0 py-2 px-4" type="primary">
            <inline-svg class="mr-3" :src="require('@/assets/Icons/smartlock/qr_code-24px.svg')"/> 
            Scan QR Code
          </base-button>
        </div>
      </div>
      <div class="mt-4">
        <base-input
          placeholder="Structure"
          class="custom-input w-100 mb-0">
        </base-input>
      </div>
      <div class="row mt-4">
        <div class="col-md-4 col-xs-12">
          <base-select
            v-model="value"
            :options="options"
            placeholder="City"
            :hideShadow="true"/>
        </div>
        <div class="col-md-4 col-xs-12">
          <base-select
            v-model="value"
            :options="options"
            placeholder="Country"
            :hideShadow="true"/>
        </div>
        <div class="col-md-4 col-xs-12">
          <base-select
            v-model="value"
            :options="options"
            placeholder="Street/Community"
            :hideShadow="true"/>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4 col-xs-12">
          <base-input
            placeholder="Latitude"
            class="custom-input">
          </base-input>
        </div>
        <div class="col-md-4 col-xs-12">
          <base-input
            placeholder="Longitude"
            class="custom-input">
          </base-input>
        </div>
        <div class="col-md-4 col-xs-12">
          <div class="text-center">          
            <base-button block type="primary" class="py-2">
              <inline-svg height="27" width="27" class="mr-2" :src="require('@/assets/Icons/smartlock/location_on-24whitepx.svg')"/> 
              Drop pin on map
            </base-button>            
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 text-center">
      <div>
        <inline-svg width="200" height="200" :src="imgPath"/> 
      </div>
      <base-button
        type="primary"
        :simple="true" 
        class="mt-2"
        onclick="document.getElementById('uploader').click()">
        <inline-svg :src="require('@/assets/Icons/smartlock/add_photo_alternate-24px.svg')"/> 
        change Lock image
      </base-button>
      <input
        type="file"
        accept="image/*"
        hidden
        style="position:absolute; height:100%;width:100%; z-index:2px; opacity:0"
        id="uploader"
        @change="onImageChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props:{
    img:{
      type: String
    }
  },
  data(){
    return{
      options: [],
      value: ""
    }
  },
  computed:{
    imgPath(){
      if(this.img) return this.img;
      return require('@/assets/Icons/smartlock/NoPath.svg')
    }
  },
  methods:{
    validateSize(file) {
      const FileSize = file.size / 1024 / 1024; // in MB
      if (FileSize > 3) {
        return false;
      }
      return true;
    },
    async onImageChange(event) {
      let lockImage = event.target.files[0];

      if (!lockImage) {
        return;
      }

      if (!lockImage.type.startsWith("image/")) {
        this.$notify({
          title: "Wrong File Formats",
          message: "Only images are allowed",
          timeout: 5000,
          type: "danger"
        });
        return;
      }
      if (!this.validateSize(lockImage)) {
        console.log("size exceeded");
        this.$notify({
          title: "Image size limit exceeded",
          message: "Lock Image must be less than 3 MB",
          timeout: 5000,
          type: "danger"
        });
        return;
      }
      let base64 = lockImage ? await this.$helper.getBase64(lockImage) : null;
      this.$emit("imageChanged", base64);
    }
  }
}
</script>

<style>
.add-lock .input{
  box-shadow: none !important;
  height: 100%;
  border-radius: 3px !important
}
.add-lock .custom-input div{
  height: 100%
}
.add-lock .custom-input{
  height: 45px 
}
</style>