const connectionManager = namespace => ({
    [`sockets_${namespace}`]: {
        connect: function () {
            this.live = true;
        },
        connect_error: function (err) {
            this.live = false;
        },
        disconnect: function () {
            this.live = false;
        },
    },
    data: () => ({
        live: false
    }),
    mounted() {
        if (
            this[`$moroIoTSocketIo_${namespace}`][
              `io_${namespace}`
            ].connected
          ) {
            this.live = true;
          }
    },
})
  
export default connectionManager