<template>
    <el-dialog :visible.sync="show" :append-to-body="true" top="3%">
        <div slot="title">
            <router-link class="h2 px-3 m-0 font-color-1" :to="title.link" v-if="title.link"
              > {{ title.label }} </router-link
            >
            <h2 v-else>{{ title.label }}</h2>
        </div>
        <card class="d-flex overflow-auto p-0 shadow-none">
            <div v-for="(item,index) in data" :key="index" class="content">
                <div class="d-flex justify-content-between">
                    <div class="d-flex">
                        <h4 class="img-container">
                            <inline-svg
                              v-if="item.icon"
                              :src="item.icon"
                              fill="white"
                            ></inline-svg>
                        </h4>
                        <h4>{{ item.key }}</h4>
                    </div>
                    <div>
                        <h4>{{ item.value ? item.value : "--" }}</h4>
                    </div>
                </div>
            </div>
        </card>
        <div slot="footer" class="dialog-footer d-flex justify-content-center mt-0">
            <base-button type="info" @click="closeModel">{{$t('close')}}</base-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        title: {
            type: Object,
            description: '{ label: titleLabel, link(optional) }'
        },
        data: {
            type: Array,
            description: '[ {label: , value: , icon(optional) } ]'
        }
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        open() {
            this.show = true;
        },
        closeModel() {
            this.show = false;
        }
    },
}
</script>


<style lang="scss" scoped>
.img-container {
  max-width: 60px;
  margin-inline-end: 1rem;
}
.img-container svg {
  width: 20px;
  height: auto;
  filter: invert(40%) sepia(8%) saturate(225%) hue-rotate(182deg)
    brightness(91%) contrast(87%);
}
h4,
h2 {
  font-weight: bolder;
}
::v-deep.content {
  margin-top: 1rem;
  padding: 0.5rem 1rem 0 1rem;
  box-shadow: $custom-shadow;
  border-radius: 10px;
  font-weight: bolder;
  .img-container svg path {
    fill: white !important;
  }
}
</style>