<template>
    <div>
        <CustomTable
          :columns="columns"
          :table-key="tableKey"
          @config-change="configChangeHandler"
          @reset="resetHandler"
        >
            <div class="striped-table">
                <el-table
                    :data="data"
                    style="width: 100%"
                    max-height="500"
                >
                    <el-table-column
                        v-for="(column, index) in columns"
                        :key="index"
                        v-bind="column"
                    />
                </el-table>
            </div>
        </CustomTable>
    </div>
</template>

<script>
import { USER_PREFERENCES_KEYS } from '@/global/user-preferences-store/keys';
import CustomTable from "@/components/CustomTable.vue";

export default {
    components: {
        CustomTable
    },
    props: {
        tableColumns: {},
        data: {},
    },
    data() {
        return {
            columns: [],
            tableKey: USER_PREFERENCES_KEYS.VSAAS_OPERATIONAL_ALARMS_HISTORY_TABLE_CONFIGURATIONS
        }
    },
    methods: {
        configChangeHandler(config) {
            this.columns = config;
        },
        resetHandler() {
            this.columns = this.tableColumns.map(e => ({ ...e }));
        },
    },
    created() {
        this.resetHandler();
    }
}
</script>

<style>

</style>