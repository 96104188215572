<template>
  <div>
    <card>
      <villa-map
        :sensors="sensors"
        @chooseMap="chooseMap"
        @addSensor="addSensor"
        @removeSensor="removeSensor"
        @resetSensors="resetSensors"
      />
      <div v-if="enableAddingSensor">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <h4 class="m-0 font-weight-bold font-color-4">
              Click on map to add sensor
            </h4>
          </div>
          <div class="col-8 text-center my-3">
            <el-divider>
              <h4 class="m-0 font-weight-bold font-color-4">
                OR
              </h4>
            </el-divider>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <h4 class="m-0 font-weight-bold font-color-4">
              Sensor Position :
            </h4>
          </div>
          <div class="col-md-4">
            <base-input v-model="sensor.position.x" :placeholder="$t('X-Axis')">
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input v-model="sensor.position.y" :placeholder="$t('Y-Axis')">
            </base-input>
          </div>
          <div class="col-md-2">
            <base-button
              type="info"
              size="sm"
              class="btn-apply font-size-16 font-weight-bold"
              @click="addSensor(sensor.position)"
              >{{ $t("add") }}</base-button
            >
          </div>
          <div class="col-12">
            <sensors :data="sensors"></sensors>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import VillaMap from "./components/VillaMap.vue";
import Sensors from "./components/Sensors.vue";
import { Divider } from "element-ui";
export default {
  components: { Sensors, VillaMap, [Divider.name]: Divider },
  data: () => ({
    sensor: { position: {} },
    sensors: [],
    currentVillaImg: { url: "" },
    enableAddingSensor: false
  }),
  methods: {
    addSensor(position) {
      if (isNaN(position.x) || isNaN(position.y)) return;
      this.sensors.push({ position: position });
      this.sensor = { position: {} };
    },
    removeSensor(sensor) {
      let idx = this.sensors.findIndex(e => e === sensor);
      this.sensors.splice(idx, 1);
    },
    chooseMap(map) {
      this.currentVillaImg = map;
      this.enableAddingSensor = true;
    },
    resetSensors() {
      this.sensors = [];
    }
  }
};
</script>

<style></style>
