<template>
  <div class="container full-height-card">
    <div class="row align-items-center">
        <div class="col-9 d-flex align-items-center">
            <h4 class="font-weight-bold mb-0">
                <inline-svg
                    v-if="icon"
                    :src="icon"
                    class="mx-2"
                    width="30px" height="30px"
                    style="fill: var(--primary);"
                />
                {{ title }}
            </h4>
        </div>
        <h4 class="col-3 font-weight-bold m-0">{{ total }}</h4>
    </div>
    <hr class="mt-1 mb-1" style="border-top-width: 2px;"/>
    <div class="d-flex flex-column justify-content-around mt-3" style="height: 68%">
      <div class="row font-weight-900 mt-1" v-for="(item,i) in data" :key="i">
        <div class="col-1">
            <span class="point" :class="getClass(item)" :style="getStyle(item)"></span>
        </div>
        <div class="col-8">
            <span class="font-color-5">{{ item.label }}</span>
        </div>
        <div class="col-3">
            <p class="font-color-3">{{ item.value }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '-'
        },
        icon: {},
        total: {
            default: '-'
        },
        data: {
            type: Array,
            description: "[ { label:'', value:0 } ]",
        }
    },
    methods:{
        getStyle(item){
            if(item.color){
                return "background-color: "+item.color;
            }
            return ''
        },
        getClass(item){
            if(item.backgroundClass){
                return "background-" + item.backgroundClass;
            }
            return ''
        }
    }
}
</script>

<style scoped>
.point{
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
</style>