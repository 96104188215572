<template>
  <card class="mb-2">
    <!-- <h3 slot="header" class="mb-2 header-title font-weight-bold">
      {{ $tc("search.filter", 2) }}
    </h3> -->
    <div class="row">
      <div class="col-md-2" v-if="exists(filterOptions.policeStationOptions)">
          <!-- :label="'stations'" -->
        <base-select
          v-model="filter.police_station_id"
          :options="filterOptions.policeStationOptions"
        ></base-select>
      </div>
      <div class="col-md-2" >
          <!-- :label="$t('villa')" -->
        <base-select
          v-model="filter.villaId"
          :options="filterOptions.villaOptions"
        ></base-select>
      </div>
      <div class="col-md-2">
          <!-- :label="$t('alarm')" -->
        <base-select
          v-model="filter.alarmType"
          :options="filterOptions.alarmOptions"
        ></base-select>
      </div>
      <div class="col-md-2">
          <!-- :label="$t('area')" -->
        <base-select
          v-model="filter.areaId"
          :options="filterOptions.areaOptions"
        ></base-select>
      </div>
      <div class="col-md-2">
        <!-- label="alarm id" -->
        <base-input 
              :placeholder="'alarm id'"
              v-model="filter.alarmId"
        />
              <!-- :controls="false" -->
            <!-- <el-input-number
            ></el-input-number> -->
          <!-- </base-input> -->
      </div>
      <!-- slot="footer" -->
    <div
      class="col-2 text-center"
    >
      <base-button
        :disabled="!isChanged"
        type="info"
        size="sm"
        class="btn-apply font-size-16 font-weight-bold"
        @click="apply"
        >{{ $t("instructions.apply") }}</base-button
      >
      <base-button
        class="btn-reset font-size-16 font-weight-normal"
        :disabled="disableReset"
        size="sm"
        @click="reset"
        >{{ $t("instructions.reset") }}</base-button
      >
    </div>

    </div>
    <!-- <slot name="extra" v-bind:filter="filter"></slot> -->
  </card>
</template>

<script>
import { mapGetters } from "vuex";
import helper from "@/util/helper";
export default {
  props: {
    initialFilter: {
      description: "a filter to start the component with,"
    },
    filterOptions: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      old: null,
      filter: {}
    };
  },
  created() {
    this.$set(this.filter,"villa_id",this.initialFilter.villaId)
    this.apply();
  },
  computed: {
    ...mapGetters({}),
    isChanged() {
      console.log(this.old, this.filter)
      return JSON.stringify(this.filter) != this.old;
    },
    disableReset() {
      return JSON.stringify(this.filter) == "{}";
    },
    
  },
  methods: {
    reset() {
      this.filter = {};
      this.old = null;
      this.apply();
    },
    apply() {
      this.old = JSON.stringify(this.filter);
      const data = { ...this.filter };
      if(data.date){
        data.start = new Date(data.date[0]);
        data.end = new Date(data.date[1]);
        delete data.date;
      }
      this.$emit("filter", data);
    },
    exists(filterOptions) {
        return (filterOptions && filterOptions.length > 0);
    }
  },
  watch: {
    filter: {
      handler: helper.debounce(function(newVal) {
        if (this.isChanged) {
          const old = JSON.parse(this.old);
          Object.assign(old, this.filter);
        }
      }, 10000),
      deep: true
    }
  }
};
</script>

<style scoped>
::v-deep .card-footer {
  padding-top: 0;
}
::v-deep .card-body {
  padding-bottom: 0;
}
.header-title {
  font-size: 26px;
}
.btn-apply {
  background-color: #41b581;
  color: #fff;
}

.btn-reset {
  background-color: #707070;
  color: #fff;
}
</style>
