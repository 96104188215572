<template>
  <div class="confirmMsg">
    <el-dialog title="Warning Message" :visible.sync="isConfirmDataMessageModalOpenComp" width="40%">
      <h4>Please submit the changed filter or return it to the previous status</h4>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="clickEvent(false)">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name:'ConfirmMessage',
  props:["isConfirmDataMessageModalOpen"],
  computed:{
    isConfirmDataMessageModalOpenComp:{
      get(){
        return this.isConfirmDataMessageModalOpen;
      },
      set(val)
      {
        this.$emit('confirmCorrectDataMsg',false)
      }
    }
  },
  methods:{
      clickEvent(status){
        this.$emit('confirmCorrectDataMsg',status)
    }
  },
}
</script>
