<template>
  <summary-list cardClasses="full-height-card" :data="getSummaryListData" title="villa list alarms status" :loading="loading" :clickAble="true" @itemClicked="itemClicked">
    <template slot="header-icon">
      <inline-svg :src="villaIcon" class="mx-2" width="30px" height="30px" />
    </template>
    <template slot="item-icon">
      <inline-svg :src="villaIcon" width="30px" height="30px" class="svg-color-1" />
    </template>
  </summary-list>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SummaryList from "../../components/SummaryList.vue";
export default {
  components: { SummaryList },
  props:{
    namespace: {
      type: String,
      default: "IntrusionSystem/Dashboard",
    },
    moduleName: {
      type: String,
      default: "IntrusionVillasOverall",
    },
  },
  data() {
    return {
      policeBadgeIcon: require("@/assets/icons-2/intrusion/police-badge.svg"),
      villaIcon: require("@/assets/icons-2/intrusion/villa.svg"),
    };
  },
  computed:{
    storePath() {
      return this.namespace + "/" + this.moduleName;
    },
    ...mapState({
      statistics(state, getters) {
        return getters[this.storePath + "/getVillaList"];
      },
      loading(state, getters) {
        return getters[this.storePath + "/loading"];
      },
      policeStationId(state, getters) {
        return getters[this.namespace + "/policeStationId"];
      },
    }),
    getSummaryListData(){
      return this.statistics.map(s=>{
        return {
          id: s.structureId,
          title: s.structureName,
          firstValue: {
            value: s.devicesCount,
            key: "total Devices"
          },
          secondValue: {
            value: s.activeIntrusionAlarms,
            key: "active alarms"
          },
        }
      })
    }
  },
  mounted(){
    this.$store.commit(this.storePath + "/setPoliceStationId", this.policeStationId);
    this.fetchVillas();
  },
  methods:{
    ...mapActions({
      fetchVillas(dispatch, payload) {
        return dispatch(this.storePath + "/fetchVillaList", payload);
      },
    }),
    itemClicked(data){
        this.$router.push(`/intrusion/villas-info/${data.id}`);
    }
  },
  watch: {
    policeStationId() {
      this.$store.commit(this.storePath + "/setPoliceStationId", this.policeStationId);
      this.fetchVillas();
    }
  }
};
</script>

<style lang="scss" >
.police-station-badge{
  path {
    fill: #27293D;
  }
}
</style>