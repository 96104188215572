<template>
  <card v-loading="alarms.loading">
      <div class="d-flex justify-content-between">
        <h3 slot="header" class="m-0">Alarms</h3>
        <div class="col-8 col-md-4">
            <el-date-picker type="datetimerange" v-model="datesFilter" class="w-25" @change="changeAlarmsDatesFilter" />
        </div>
      </div>
    <el-table :data="alarms.data" :row-class-name="getRowStyle">
      <el-table-column label="ID" prop="id" width="100px" />
      <el-table-column label="alarm message" prop="body">
          <template slot-scope="{ row }">
              <p class="alarm-body">
                  {{row.body}}
              </p>
          </template>
      </el-table-column>
      <el-table-column label="date" prop="created_at">
          <template slot-scope="{ row }">
              {{gDateFormat(row.created_at)}}
          </template>
      </el-table-column>
    </el-table>
    <TablePagination :total="alarms.total" @change="updateAlarmsPagination"/>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TablePagination from "../../../components/TablePagination.vue";

export default {
    data(){
        return{
            datesFilter: []
        }
    },
    created(){
        this.fetchAlarms();
        this.connectToAlarmsSocket();
    },  
    computed:{
        ...mapGetters("VsassStore",{
            alarms: "getAlarms"
        })
    },
    methods:{
        ...mapActions("VsassStore",{
            fetchAlarms:"fetchAlarms",
            updateAlarmsPagination:"updateAlarmsPagination",
            changeAlarmsDatesFilter:"changeAlarmsDatesFilter",
            connectToAlarmsSocket:"connectToAlarmsSocket",
            disconnectFromAlarmsSocket:"disconnectFromAlarmsSocket",
        }),
        getRowStyle({row}){
            if(row.new){
                return "new";
            }
            return "old"
        }
    },
    components:{
        TablePagination
    },
    destroyed(){
        this.disconnectFromAlarmsSocket();
    }
};
</script>

<style scoped>
.alarm-body{
    white-space: pre;
}
</style>
<style>
    .new{
        background-color: rgba(255, 255, 255, 0.15) !important;
    }
    .old{

    }
</style>