<template>
  <div class="h-100">
    <div v-if="error" class="align-items-center d-flex h-100 justify-content-center">
      <h3>No Villa Map Available!</h3>
    </div>
    <div class="row justify-content-center text-center h-100 align-items-center" v-else>
      <!-- <div class="col-12">
      <el-upload
        :on-change="selectMap"
        ref="upload"
        action=""
        :auto-upload="false"
        :show-file-list="false"
      >
        <el-button slot="trigger" size="small" type="primary"
          >select villa map</el-button
        >
      </el-upload>
    </div> -->
      <div class="col-12 my-4">
        <div class="map-container">
          <img :src="mapSrc" @click="clicked" ref="image" alt="villa layout" @error="onMapLoadingError" />
          <div v-for="(sensor, idx) in sensors" :key="idx">
            <span
              v-if="sensor.type === 'sensor'"
              @click="showSensorInfo(sensor)"
              :class="sensor.active_intrusion_alarms? 'active': ''"
              :style="
                `top:${getValidPosition({ x: sensor.x, y: sensor.y }).y}px;
                 left:${getValidPosition({ x: sensor.x, y: sensor.y }).x}px;
                 width:${marker.width}px;
                 height:${marker.height}px`
              "
            >
            </span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="Sesnor Info" :visible.sync="infoDialog" width="350px">
      <h2 class="m-0 font-weight-bold font-color-4">
        {{ activeSensor.name }}
      </h2>
      <h4 class="my-3 font-color-4">
        Sensor Position :<br /><br />
        X = {{ activeSensor.x }}<br />
        Y = {{ activeSensor.y }}<br />
      </h4>
      <span slot="footer" class="dialog-footer">
        <el-button @click="infoDialog = false">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Upload } from "element-ui";

export default {
  components: { [Upload.name]: Upload },
  props: {
    sensors: {
      type: Array,
      default: () => []
    },
    alarmedSensors: {
      type: Array,
      default: () => []
    },
    mapSrc: {
      type: String,
    }
  },
  watch: {
    mapSrc: {
      immediate: true,
      handler(val, oldVal) {
        // do your stuff
        this.getMeta(val);
      }
    }
  },
  data: () => ({
    currentVillaImg: { url: "" },
    infoDialog: false,
    activeSensor: { position: {} },
    marker: { width: 20, height: 20 },
    error: false
  }),
  computed: {
    currentVillaImgSrc() {
      return this.currentVillaImg.url;
    }
  },
  methods: {
    onMapLoadingError(error){
      this.error = true;
      console.error(error)
    },
    isAlarmed(sensor) {
      let idx = this.alarmedSensors.findIndex(e => e === sensor.id);
      console.log(sensor.id)
      return idx !== -1;
    },
    getMeta(url) {
      var img = new Image();
      img.src = url;
      var _this = this;
      img.onload = function() {
        console.log(this.width, this.height);
        _this.currentVillaImg.width = this.width;
        _this.currentVillaImg.height = this.height;
        _this.currentVillaImg.url = url;
      };
    },
    clicked(e) {
      let x = e.layerX / this.currentVillaImg.width;
      let y = e.layerY / this.currentVillaImg.height;
      this.$emit("addSensor", { x: x, y: y });
    },
    removeSensor() {
      this.$emit("removeSensor", this.activeSensor);
      this.infoDialog = false;
    },
    showSensorInfo(sensor) {
      this.activeSensor = sensor;
      console.log(this.activeSensor);
      this.infoDialog = true;
    },
    getValidPosition(position) {
      let villaMapSide = 598;
      let x = (position.x * villaMapSide) ;
      let y = (position.y * villaMapSide) ;
      return { x: x - this.marker.width / 2, y: y - this.marker.height / 2 };
    },
    selectMap(file, fileList) {
      this.setUrl(file.raw);
    },
    setUrl(file) {
      let reader = new FileReader();
      var file = file;
      reader.onload = e => {
        let dataURI = e.target.result;
        if (dataURI) {
          let _this = this;
          this.currentVillaImg.url = dataURI;
          setTimeout(function() {
            _this.currentVillaImg.height = _this.$refs.image.clientHeight;
            _this.currentVillaImg.width = _this.$refs.image.clientWidth;
            _this.$emit("chooseMap");
          }, 200);
        }
      };
      reader.readAsDataURL(file);
    }
  }
};
</script>

<style lang="scss" scoped>


.map-container {
  position: relative;
  width: fit-content;
  margin: auto;
  span {
    border-radius: 50%;
    position: absolute;
    background: green;
    transition: all 0.5s ease;
    cursor: pointer;
    &:hover {
      background: green;
    }
  }
  .active{
     -webkit-animation: scales 500ms ease infinite alternate;
      -moz-animation: scales 500ms ease infinite alternate;
      animation: scales 500ms ease infinite alternate;
  }
  
  img {
    max-width: 598px;
    max-height: 598px;
  }

}
@keyframes scales{
  from{
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    transform: scale(0.8);
    background:black;
  }
  to{
   -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    transform: scale(1.1);
    background: red;
  }
}
</style>
