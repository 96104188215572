<template>
  <div>
    <LocationsMap
      :markers="markers"
      :center="center"
      :markerIcon="markerIcon"
      :mapType="mapType"
      :markerLabel="markerLabel"
      @onMarkerClicked="onCustomerClicked"
    >
      <div slot="footer">
        <div class="d-flex justify-content-between align-items-center">
          <div slot="left-footer" class="pl-2 d-flex align-items-center">
            <img class="marker-image" :src="markerIcon" alt="" />
            <span class="font-weight-bold pl-2 font-color-1 h4 mb-0"> {{ mapType == 1 ? devicesCount : alarmsCount }} </span>
          </div>
            <div slot="right-footer" class="overflow-hidden rounded">
                <base-button
                  :round="false"
                  size="sm"
                  :type="'primary'"
                  :simple="mapType != 1"
                  class="py-1"
                  @click="changeMap(1)"
                  >Customers</base-button
                >
                <base-button
                  :round="false"
                  size="sm"
                  :type="'primary'"
                  :simple="mapType != 2"
                  class="py-1"
                  @click="changeMap(2)"
                  >Customers With Alarms</base-button
                >
            </div>
        </div>
      </div>
    </LocationsMap>
    <InformationModal :title="modalData.title" :data="modalData.data" ref="infoModal" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { getDeep } from "@/util/helper";
import LocationsMap from "src/components/Locations/locations.vue";
import InformationModal from '@/components/InformationModal';

export default {
    components: {
        LocationsMap,
        InformationModal
    },
    props: {
        namespace: {
            type: String,
            default: 'VsaasStore/OperationalAlarms'
        },
        moduleName: {
            type: String,
            default: 'CustomerMap'
        }
    },
    computed: {
        storePath() {
            return this.namespace + '/' + this.moduleName;
        },
        ...mapState({
            loading(state, getters) {
                return getters[this.storePath + "/loading"];
            },
            markerIcon(state, getters) {
                return getters[this.storePath + "/markerIcon"];
            },
            markers(state, getters) {
                return getters[this.storePath + "/markers"];
            },
            devicesCount(state,getters) {
                return 'Total Devices: ' + getters[this.storePath + "/devicesCount"]
            },
            alarmsCount(state,getters) {
                return 'Total Alarms: ' + getters[this.storePath + "/alarmsCount"]
            },
            modalData(state,getters) {
                return getters[this.storePath + "/modalData"];
            }
        }),
        center: {
            get() {
                return getDeep(this.$store.state,this.storePath + "/center");
            },
            set(value) {
                this.$store.commit(this.storePath + "/setCenter",value);
            }
        },
        mapType: {
            get() {
                return getDeep(this.$store.state,this.storePath + "/mapType");
            },
            set(value) {
                this.$store.commit(this.storePath + "/setMapType",value);
            }
        },
        
    },
    methods: {
        ...mapActions({
            fetchData(dispatch, payload) {
                return dispatch(this.storePath + "/fetchData",payload);
            } 
        }),
        onCustomerClicked(customer) {
            this.center = customer;
            this.$refs['infoModal'].open();
        },
        changeMap(type) {
            this.mapType = type;
        },
        markerLabel(customer) {
            return (this.mapType == 1 ? customer.devicesCount : customer.activeAlarmsCount).toString();
        }
    },
    created() {
        this.fetchData();
    }
}
</script>

<style lang="scss" scoped>
.marker-image {
    width: 25px;
}
button {
  margin: 0;
  border-radius: 0;
  border: 3px solid $primary !important;
}
.btn-primary:hover {
  border-color: $primary !important;
  background: $primary !important;
  color: white;
  transform: translateY(0px);
}
.btn-primary:focus {
  background: $primary !important;
  color: white;
  transform: translateY(0px);
}
</style>