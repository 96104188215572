<template>
  <card style="overflow:visible">
    <h2 slot="header" class="font-weight-bold">Filter</h2>
    <div class="row">
      <div class="col-sm-6 col-md-3">
        <base-select
          label="Area Filter"
          :placeholder="$t('Area Filter')"
          v-model="communityIdComp"
          :filterable="true"
          :options="communitiesOptions"
          @input="changeStructure"
        ></base-select>
      </div>
      <div class="col-sm-6 col-md-3">
        <base-select
          label="Structure Filter"
          :placeholder="$t('Structure Filter')"
          v-model="structureIdComp"
          :options="structuresOptions"
          :filterable="true"
        ></base-select>
      </div>

      <div class="col-sm-6 col-md-3">
        <auto-copmlete
          label="Customer Filter"
          :placeholder="$t('Customer Filter ')"
          :source="endPoint"
          v-model="selectedTargetValue"
          :resultsDisplay="Target.keyToDisplay"
          resultsProperty="data"
          ref="search"
          @input="input"
        />
      </div>

      <div
        class="col-sm-6 col-md-3 d-flex justify-content-center align-items-center"
      >
        <base-button
          :disabled="!isChanged"
          native-type="submit"
          @click="isConfirmMessageModalOpen = true"
          type="primary"
          size="sm"
          >{{ $t("Submit") }}</base-button
        >
      </div>
      <ConfirmMessage
        @confirmMsg="confirmMsg"
        :isConfirmMessageModalOpen="isConfirmMessageModalOpen"
        v-if="isConfirmMessageModalOpen == true"
      />
    </div>
  </card>
</template>
<script>
import ConfirmMessage from "./ConfirmMessage";
import { mapGetters, mapActions } from "vuex";
import axios from "@/axiosConfig";
import AutoCopmlete from "@/components/BaseAutoComplete.vue";

export default {
  name: "FilterTemplate",
  props: ["sendedFilter"],
  data: function () {
    return {
      oldFilter: null,
      selectedTargetValue: null,
      structureInArea:null,
      isConfirmMessageModalOpen: false,
      filter: {
        structure_id: undefined,
        community_id: undefined,
        customer_id: null,
      },
      Target: {
        type: "customer",
        url: "customer/search/",
        keyToDisplay: "name",
      },
    };
  },
  watch:{
    sendedFilter(newValue,oldValue){
      if(JSON.stringify(newValue)!=JSON.stringify(oldValue))
      {
        this.filter.structure_id = this.sendedFilter.structure_id;
        this.filter.community_id = this.sendedFilter.community_id;
        this.filter.customer_id = this.sendedFilter.customer_id;
        this.oldFilter={...this.filter};
       }
    }
  },
  created() {
    this.oldFilter={...this.filter};
    if (this.structures.length == 0) {
      this.$store.dispatch("SwamStore/FETCH_ITEMS", {
        key: "all",
      });
    }
    this.structureInArea=[...this.structures]
  },
  computed: {
    ...mapGetters({
      structures: "SwamStore/haveDevices",
    }),
    structureIdComp: {
      get() {
        return this.filter.structure_id==null?undefined:this.filter.structure_id;
      },
      set(val) {
        this.filter.structure_id = val;
      },
    },
    communityIdComp: {
      get() {
        return this.filter.community_id==null?undefined:this.filter.community_id;
      },
      set(val) {
        this.filter.community_id = val;
      },
    },
    customerIdComp: {
      get() {
        return this.filter.customer_id;
      },
      set(val) {
        this.filter.customer_id = val;
      },
    },
    structuresOptions() {
      const data = this.structureInArea.map((s) => {
        return {
          label: s.structure_name,
          value: s.id
        };
      });
      data.unshift({
        label: "All",
        value: undefined,
      });
      return data;
    },
    endPoint(query) {
      return axios.defaults.baseURL + this.Target.url;
    },
    communitiesOptions() {
      const data = this.structures.map((s) => {
        console.log(s.community);
        return {
          label: s.community_name,
          value: s.community,
        };
      });
      let values = [];
      let result = [];
      data.forEach(item => {
        if(!values.includes(item.value)) {
          values.push(item.value);
          result.push(item);
        }
      })
      result.unshift({
        label: "All",
        value: undefined,
      });
      return result;
    },
    isChanged() {
      let isChanged=JSON.stringify(this.filter) != JSON.stringify(this.oldFilter);
      this.$emit('isFilterChanged',isChanged);
      return isChanged;
    },
  },
  components: { ConfirmMessage, AutoCopmlete },
  methods: {
    changeStructure(val){
      if(val!=undefined)
         this.structureInArea=[...this.structures.filter(e=>e.community==val)];
      else
         this.structureInArea=[...this.structures];
    },
    confirmMsg(status) {
      if (status) {
        this.oldFilter = {...this.filter};
        if (this.selectedTargetValue != null)
          this.filter.customer_id = this.selectedTargetValue.selectedObject.id;
        this.$emit("changedFilter", this.filter);
      }
      this.isConfirmMessageModalOpen = false;
    },
    input(val){
      this.filter.customer_id = val.selectedObject.id;
    }
  },
};
</script>
