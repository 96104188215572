<template>
  <div class="h-100">
    <location-info-structure
      v-if="villaCardData"
      :data="villaCardData"
      :openDialog="villaCardData != null"
      @closeModel="selectVilla(null)"
    />
    <villas-locations @markerClick="onCustomerClicked" :showHeatmap="mapType == 2" :markerIcon="markerIcon" :markerLabelFormatter="markerLabelFormatter" :markers="markers">
      <div slot="footer">
        <div class="d-flex justify-content-between algin-items-center">
          <div slot="left-footer" class="pl-2 d-flex">
            <img class="marker-image" :src="markerIcon" alt />
            <h4 class="m-0 mx-2 mt-1 font-weight-bold">Total villas: &nbsp; {{ markers.length }}</h4>
          </div>

          <div slot="right-footer" class="d-flex">
            <base-select class="px-2" size="mini" :options="mapFilter" v-model="activeFilter">
            </base-select>
            <div>
              <base-button
                :round="false"
                size="sm"
                :type="'primary'"
                :simple="!isCustomersMapSelected"
                class="py-1"
                @click="changeMap(1)"
              >villas</base-button>
              <base-button
                :round="false"
                size="sm"
                :type="'primary'"
                :simple="!isAlarmsMapSelected"
                class="py-1"
                @click="changeMap(2)"
              >heatmap</base-button>
            </div>
          </div>
        </div>
      </div>
    </villas-locations>
  </div>
</template>

<script>
import Locations from "src/components/Locations/locations.vue";
import VillasLocations from "src/components/Locations/VillasLocations";

import { mapActions, mapState } from "vuex";
import LocationInfoStructure from "../../../SWAM/components/LocationInfoStructure.vue";

export default {
  name: "intrusion-villa-map",
  components: { Locations, LocationInfoStructure, VillasLocations },
  props: {
    namespace: {
      type: String,
      default: "IntrusionSystem/Dashboard"
    },
    moduleName: {
      type: String,
      default: "IntrusionVillasOverall"
    },
    secondFilterOnMapFilterKey: {
      type: String,
      default: "activeIntrusionAlarms"
    }
  },
  data: function() {
    return {
      center: { lat: 25.221643, lng: 55.255257 },
      mapType: 1,
      activeFilter: undefined
    };
  },
  mounted() {
    this.$store.commit(
      this.storePath + "/setPoliceStationId",
      this.policeStationId
    );
    this.fetchVillas();
  },

  computed: {
    storePath() {
      return this.namespace + "/" + this.moduleName;
    },
    ...mapState({
      markers(state, getters) {
        if(this.mapType == 2){
          return getters[this.storePath + "/getVillaList"].filter(marker=> marker.activeIntrusionAlarms);
        }else{
          return getters[this.storePath + "/getVillaList"];
        }
      },
      mapFilter(state, getters){
        return [{
          label: "active alarms",
        },...getters[this.storePath + "/periodsFilter"]]
      },
      villaCardData(state, getters) {
        return getters[this.storePath + "/getVillaCardData"];
      },
      policeStationId(state, getters) {
        return getters[this.namespace + "/policeStationId"];
      }
    }),
    markerIcon() {
      if (this.isCustomersMapSelected)
        return require("src/assets/icons-2/google-map-marker.svg");
      else if (this.isAlarmsMapSelected)
        return require("src/assets/icons-2/google-map-marker-red.svg");
    },
    isCustomersMapSelected() {
      return this.mapType == 1;
    },
    isAlarmsMapSelected() {
      return this.mapType == 2;
    },
    mapMarkers() {
      return this.mapType == 1
        ? this.markers
        : this.markers.filter(m => m[this.secondFilterOnMapFilterKey] != 0);
    },
    selectedPeriod(){
      return this.mapFilter.find(f => f.value == this.activeFilter);
    }
  },
  methods: {
    ...mapActions({
      fetchVillas(dispatch, payload) {
        const query = typeof this.selectedPeriod.value == "number" ? {
          start_date: this.selectedPeriod.from().toDate(),
          end_date: this.selectedPeriod.to().toDate(),
        }: undefined;
        return dispatch(this.storePath + "/fetchVillaList", query);
      },
      selectVilla(dispatch, payload) {
        return dispatch(this.storePath + "/selectVilla", payload);
      }
    }),
    markerLabelFormatter(marker){
      return ((this.mapType == 1
        ? marker.devicesCount
        : marker[this.secondFilterOnMapFilterKey]
      ) || "").toString();
    },
    onCustomerClicked(customer) {
      this.center = customer;
      this.selectVilla(customer);
    },
    changeMap(type) {
      this.mapType = type;
    }
  },
  watch: {
    policeStationId() {
      this.$store.commit(
        this.storePath + "/setPoliceStationId",
        this.policeStationId
      );
      this.fetchVillas();
    },
    activeFilter(){
      this.fetchVillas();
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  .marker-image {
    width: 15px;
  }

  button {
    margin: 0;
    border-radius: 0;
  }
}
</style>
