<template>
  <div>
    <div class="row mt-4">
      <div class="col-md-4 col-xs-12">
        <base-input
          placeholder="Usage"
          class="custom-input">
        </base-input>
      </div>
      <div class="col-md-4 col-xs-12">
        <base-input
          placeholder="Key Location:"
          class="custom-input">
        </base-input>
      </div>
      <div class="col-md-4 col-xs-12">
        <base-input
          placeholder="Device IMEI Number:"
          class="custom-input">
        </base-input>
      </div>
    </div>        
    <div class="row mt-4">
      <div class="col-md-4 col-xs-12">
        <base-input
          placeholder="Primary Supervisor"
          class="custom-input">
        </base-input>
      </div>
      <div class="col-md-4 col-xs-12">
        <base-input
          placeholder="Secondary Supervisor"
          class="custom-input">
        </base-input>
      </div>
      <div class="col-md-2 col-xs-12">
        <base-button block type="primary"> Add</base-button>
      </div>
    </div>        
    <div class="row mt-4">
      <div class="col-md-4 col-xs-12">
        <base-select
          v-model="value"
          :options="options"
          placeholder="Alert User"
          :hideShadow="true"/>
      </div>
      <div class="col-md-2 col-xs-12">
        <base-button block type="primary"> Add</base-button>
      </div>
    </div>        
    <div class="row mt-4">
      <div class="col-md-4 col-xs-12">
        <base-input
          placeholder="SIM ICCID Number"
          class="custom-input">
        </base-input>
      </div>
    </div>  
  </div>
</template>

<script>
export default {
  data(){
    return{
      options: [],
      value: ""
    }
  },
}
</script>

<style>
.add-lock .input{
  box-shadow: none !important;
  height: 100%;
  border-radius: 3px !important
}
.add-lock .custom-input div{
  height: 100%
}
.add-lock .custom-input{
  height: 45px 
}
</style>