import i18n from 'src/i18n';


 const tableColumns= [
    {
      prop: "name",
      label: i18n.t("Name"),
      minWidth: 200,
    },
    {
      prop: "Community_English",
      label: i18n.t("Community"),
      minWidth: 100,
    },
    {
      prop: "structure_name",
      label: i18n.t("Structure"),
      minWidth: 100,
    },
    {
      prop: "customer_name",
      label: i18n.t("Customer"),
      minWidth: 100,
    },
    {
      prop: "reminder",
      label: i18n.t("Reminder"),
      minWidth: 100,
    },
  ];

  export default tableColumns;
