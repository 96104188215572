<template>
  
    <card footer-classes="text-right">
      <div slot="header">
        <h4 class="card-title">{{$t("Create")}} {{$t("Template")}}</h4>
      </div>
        
        <div class="row">
            <div class="col-md-6">
                    <base-select :label="$t('Category')" v-model="selected_category" :options="device_categories"></base-select>
            </div>
        </div>
        
        <!-- placeholder -->

        <component :is="selected_component" :category_id="selected_category" />




    </card>

</template>
<script>



import CreateIOTTemplate from './CreateIOTTemplate.vue';
import CreateTeltonikaTemplate from './CreateTeltonikaTemplate.vue';

const categoryMap = {
    IOT:'CreateIOTTemplate',
    Teltonika:'CreateTeltonikaTemplate'
}
export default {
    props:{
        device_categories:{
            type:Array,
            required:true
        }
    },
    components:{
        CreateIOTTemplate,
        CreateTeltonikaTemplate
    },
    data(){
        return {
            selected_category:this.device_categories[0].value
        }
    },
    computed:{
           
            
            selected_component(){
                let name = this.device_categories.find(cat=>cat.value === this.selected_category).label;
                console.log(name)
                return categoryMap[name];
            }
       

    },
    
    
    mounted(){
    }
  
};
</script>
<style scoped>
.okStyle{
  background-color: rgb(232, 217, 29);
}
.problemSty{
background-color: rgb(242, 57, 59);;
}
.poorSty{
  background-color: rgb(230, 162, 60);
}
</style>
