<template>
  <div>
      <CustomerMap />
      <AlarmsStatistics />
      <OperationalAlarmsTable />
  </div>
</template>

<script>
import CustomerMap from '../../core/CustomersMap/index.vue';
import AlarmsStatistics from '../../core/AlarmsStatistics/index.vue';
import OperationalAlarmsTable from '../../core/OperationalAlarmsTable/index.vue';

export default {
    components: {
        CustomerMap,
        AlarmsStatistics,
        OperationalAlarmsTable
    }
}
</script>

<style>

</style>