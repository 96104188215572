<template>
  <form>
    <card footer-classes="text-right">
      <div slot="header">
        <h4 class="card-title">{{$t("Create")}} {{$t("Webhook")}} for company {{company_name}}</h4>
      </div>

      <div>
        <!--  -->
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-4">
                <base-input :placeholder="$t('Url')" v-model="$v.url.$model" :specialChar="true">
                  <template v-if="$v.url.$error" v-slot:error>
                    <ul class="list-unstyled">
                      <li class="error" v-if="!$v.url.required">Url is required</li>
                      <li class="error" v-if="!$v.url.validURL">Not valid url</li>
                    </ul>
                  </template>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input>
                  <textarea class="form-control" :placeholder="$t('Token')" v-model="token"></textarea>
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <!--  -->
      </div>
      <template slot="footer" class="text-right">
        <base-button native-type="submit" @click.prevent="submit" type="primary">{{$t("Register")}}</base-button>
      </template>
    </card>
  </form>
</template>
<script>
import { required } from "vuelidate/lib/validators";

function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator

  return !!pattern.test(str);
}

export default {
  props: ["id", "webhookid"],
  data() {
    return {
      company_name: "",
      url: "",
      token: "",
    };
  },
  components: {},
  validations: {
    url: {
      required,
      validURL,
    },
  },
  mounted() {
    console.log("comapny id", this.id);
    console.log("webhook id", this.webhookid);
    this.company_name = this.$route.query.name;
    if (this.webhookid) {
      this.$api
        .get(`webhook/${this.webhookid}`)
        .then((res) => {
          if (res.data.msg === "success") {
            let data = res.data.data;
            this.url = data.url;
            this.token = data.token;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  computed: {},
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        let data = {
          url: this.url,
          token: this.token,
          company_id: this.id,
        };
        if (this.webhookid) {
          this.$api
            .patch(`webhook/${this.webhookid}`, data)
            .then((res) => {
              console.log("webhook update", res);
              if (res.data.msg === "success") {
                this.$router.go(-1);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$api
            .post(`webhook`, data)
            .then((res) => {
              if (res.data.msg === "success") {
                this.$router.go(-1);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
  },
};
</script>
<style scoped>
.SpPhone {
  border-color: #e14eca;
}
</style>
