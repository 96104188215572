<template>
  <div>
    <LocationInfoStructure
      v-if="loadCustomerData"
      :data="loadCustomerData"
      :openDialog="openDialog"
      @closeModel="closeModel"
    />

    <LocationsMap
      :markers="mapMarkers"
      :center="center"
      :markerIcon="markerIcon"
      :mapType="mapType"
      @onMarkerClicked="onCustomerClicked"
      :markerLabel="markerLabel"
      :showHeatmap="isAlarmsMapSelected"
    >
      <div slot="footer">
        <div class="d-flex justify-content-between algin-items-center">
          <div slot="left-footer" class="pl-2 d-flex">
            <img class="marker-image" :src="markerIcon" alt=""/>
            <h4 class="m-0 mx-2 mt-1 font-weight-bold">Total villas: &nbsp; {{markers.length}} </h4>
          </div>

          <div slot="right-footer">
            <base-button
              :round="false"
              size="sm"
              :type="'primary'"
              :simple="!isCustomersMapSelected"
              class="py-1"
              @click="changeMap(1)"
            >{{ firstFilterOnMapName }}
            </base-button
            >
            <base-button
              :round="false"
              size="sm"
              :type="'primary'"
              :simple="!isAlarmsMapSelected"
              class="py-1"
              @click="changeMap(2)"
            >{{ secondFilterOnMapName }}
            </base-button
            >
          </div>
        </div>
      </div>
    </LocationsMap>
  </div>
</template>

<script>
  import LocationsMap from "src/components/Locations/locations.vue";
  import LocationInfoStructure
    from "@/pages/SWAM/components/LocationInfoStructure";
import { mapGetters } from 'vuex';
import IntrusionVuexGetter from "../../store/types/getter-types";

  export default {
    name: "customers-map",
    components: {LocationInfoStructure, LocationsMap},
    props: {
      markers: {
        type: Array,
        required: false,
        default: () => []
      },
      firstFilterOnMapName: {
        type: String,
        required: false,
        default: "Villas"
      },
      secondFilterOnMapName: {
        type: String,
        required: false,
        default: "Villas With Alarms"
      },
      secondFilterOnMapFilterKey: {
        type: String,
        default: "activeAlarmsCount"
      }
    },
    data: function () {
      return {
        center: {lat: 25.221643,
        lng: 55.255257},
        mapType: 1,
        mySelectedCustomer: {solutions: []},
        openDialog: false,
      };
    },
    created() {
    },

    computed: {
      ...mapGetters("IntrusionStore", {
        userType: IntrusionVuexGetter.MORO_OR_POLICE_GETTER
      }),
      loadCustomerData() {
        const data = {
          title: {
            icon: require("@/assets/Icons/structure.svg"),
            text1: this.mySelectedCustomer.structure_name,
            // onClick: (this.selectedStructure || {}).structure_name
            //   ? ''
            //   : `SWAM/Structure-Portfolio/${this.mySelectedCustomer.id}`,
          },
          rows: [
            // {
            //   icon: require("@/assets/Icons/id.svg"),
            //   key: "ID",
            //   value: this.mySelectedCustomer.id,
            // },
            {
              icon: require("@/assets/Icons/id.svg"),
              key: "structure id",
              value: this.mySelectedCustomer.structure_id,
            },
            {
              icon: require("@/assets/Icons/bell-filled-white.svg"),
              key: "Active Alarms Count",
              value: this.mySelectedCustomer.activeAlarmsCount,
            },
            {
              icon: require("@/assets/Icons/bell-filled-white.svg"),
              key: "Active intrusion alarms",
              value: this.mySelectedCustomer.active_intrusion_alarms,
            },
            {
              icon: require("@/assets/Icons/communities.svg"),
              key: "Devices count",
              value: this.mySelectedCustomer.devicesCount,
            },
          ],
        };
        if(this.userType == "moro"){
          data.rows.splice(3,0,{
              icon: require("@/assets/Icons/bell-filled-white.svg"),
              key: "Active operational alarms",
              value: this.mySelectedCustomer.active_operational_alarms,
            },)
        }
        return data;
      },
      markerIcon() {
        if (this.isCustomersMapSelected)
          return require("src/assets/icons-2/google-map-marker.svg");
        else if (this.isAlarmsMapSelected)
          return require("src/assets/icons-2/google-map-marker-red.svg");
      },
      isCustomersMapSelected() {
        return this.mapType == 1;
      },
      isAlarmsMapSelected() {
        return this.mapType == 2;
      },
      mapMarkers() {
        return this.mapType == 1
          ? this.markers
          : this.markers.filter(m => m[this.secondFilterOnMapFilterKey] != 0);
      }
    },
    methods: {
      closeModel() {
        this.openDialog = false;
      },
      markerLabel(customer) {
        return (this.mapType == 1
            ? customer.devicesCount
            : customer[this.secondFilterOnMapFilterKey]
        ).toString();
      },
      onCustomerClicked(customer) {
        this.center = customer;
        this.openDialog = true;
        this.mySelectedCustomer = customer;
      },
      changeMap(type) {
        this.mapType = type;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .card {
    .marker-image {
      width: 15px;
    }

    button {
      margin: 0;
      border-radius: 0;
    }
  }
</style>
