<template>
  <div class="row">
    <div class="col-md-12">
      <div v-if="files" class="d-flex flex-wrap">
        <div
          v-for="(file, index) in files"
          :key="index"
          class="m-1 text-center"
        >
          <inline-svg
            :src="require('@/assets/Icons/file.svg')"
            width="40"
            height="40"
            class="areas-main-icon"
          />
          <span class="d-block">{{ file.name | lastPart }}</span>
        </div>
      </div>

      <label
        >Files
        <input type="file" ref="files" multiple @change="handelFilesUpload" />
      </label>
      <button @click="submitFiles" class="btn">Submit</button>

      <!-- progress -->
      <div
        v-show="uploadPercentage > 0 && uploadPercentage < 100"
        id="viewerProgressDiv"
        style="
          position: absolute;
          top: 50%;
          left: 32%;
          background-color: rgba(128, 128, 128, 0.7);
          padding: 20px;
          border-radius: 25px;
        "
      >
        <progress
          id="viewerProgress"
          class="viewerComponent"
          :value="uploadPercentage"
          max="100"
          style="width: 400px"
        ></progress>
        <div class="d-flex justify-content-between align-items-center">
          <label
            id="viewerProgressLabel"
            class="viewerComponent"
            style="
              margin-top: 2px;
              display: block;
              margin-left: 10px;
              color: rgb(30 30 42);
            "
            >Loading {{ uploadPercentage }} %</label
          >
          <button class="btn btn-sm" @click="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: ["id"],
  data() {
    return {
      files: null,
      uploadPercentage: 0,
      cancelRequest: null,
    };
  },
  filters: {
    lastPart(value) {
      let parts = value.split(".");
      let name = parts[0];
      let ext = parts[1];
      name = name.substring(name.length - 12);
      return `...${name}.${ext}`;
    },
  },

  mounted() {},
  methods: {
    cancel() {
      if (this.cancelRequest) {
        this.cancelRequest();
      }

      this.uploadPercentage = 0;
      this.files = null;
      this.cancelRequest = null;
    },
    handelFilesUpload(e) {
      console.log(this.$refs.files.files);
      this.files = this.$refs.files.files;
    },
    submitFiles() {
      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        formData.append("files[" + i + "]", file);
      }

      const CancelToken = axios.CancelToken;
      let vm = this;
      this.$api
        .post(`structures/${this.id}/model`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            vm.cancelRequest = c;
          }),
        })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
</style>