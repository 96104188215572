<template>
  <form>
    <card footer-classes="text-right">
      <div slot="header">
        <h4 class="card-title">Update Main Order Details</h4>
      </div>

      <div>
        <!--  -->
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">
                <BaseAutoComplete
                  :placeholder="$t('AMC Company search')"
                  :source="AmcEndpoint"
                  v-model="amccompany"
                >
                  <template v-if="$v.amccompany.$error" v-slot:error>
                    <label
                      class="error"
                      v-if="!$v.amccompany.required"
                    >{{$t("requireds.AMC Company")}}</label>
                  </template>
                </BaseAutoComplete>
              </div>

              <div class="col-md-6">
                <base-input :placeholder="$t('address')" v-model="$v.amcaddress.$model" readonly>
                  <template v-if="$v.amcaddress.$error" v-slot:error>
                    <label class="error" v-if="!$v.amcaddress.required">{{$t("requireds.Address")}}</label>
                  </template>
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">
                <base-input :placeholder="$t('Phone')" v-model="$v.amcphone.$model" readonly>
                  <template v-if="$v.amcphone.$error" v-slot:error>
                    <label class="error" v-if="!$v.amcphone.required">{{$t("requireds.Phone")}}</label>
                  </template>
                </base-input>
              </div>

              <div class="col-md-6">
                <base-input
                  :placeholder="$t('Contact Person')"
                  v-model="$v.amccontactperson.$model"
                  readonly
                >
                  <template v-if="$v.amccontactperson.$error" v-slot:error>
                    <label
                      class="error"
                      v-if="!$v.amccontactperson.required"
                    >{{$t("requireds.Contact Person")}}</label>
                  </template>
                </base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">
                <BaseAutoComplete
                  :placeholder="$t('Paying Customer')"
                  :source="payingCustomerEndpoint"
                  v-model="payingcustomer"
                >
                  <template v-if="$v.payingcustomer.$error" v-slot:error>
                    <label
                      class="error"
                      v-if="!$v.payingcustomer.required"
                    >{{$t("requireds.Paying Customer")}}</label>
                  </template>
                </BaseAutoComplete>
              </div>
              <div class="col-md-6">
                <base-input
                  :placeholder="$t('Paying Customer address')"
                  v-model="$v.payingcustomeraddress.$model"
                  readonly
                >
                  <template v-if="$v.payingcustomeraddress.$error" v-slot:error>
                    <label
                      class="error"
                      v-if="!$v.payingcustomeraddress.required"
                    >{{$t("requireds.Paying Customer address")}}</label>
                  </template>
                </base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">
                <base-input
                  :placeholder="$t('Paying Customer Phone')+ '('+$t('should fill auto from Database')  +')'"
                  v-model="$v.payingcustomerphone.$model"
                  readonly
                >
                  <template v-if="$v.payingcustomerphone.$error" v-slot:error>
                    <label
                      class="error"
                      v-if="!$v.payingcustomerphone.required"
                    >{{$t("requireds.Paying Customer phone")}}</label>
                  </template>
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input
                  :placeholder="$t('Paying Customer contact person')+ '('+ $t('should fill auto from Database') +')'"
                  v-model="$v.payingcustomercontactperson.$model"
                  readonly
                >
                  <template v-if="$v.payingcustomercontactperson.$error" v-slot:error>
                    <label
                      class="error"
                      v-if="!$v.payingcustomercontactperson.required"
                    >{{$t("requireds.Paying Customer contact person")}}</label>
                  </template>
                </base-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer" class="text-right">
        <base-button native-type="submit" @click.prevent="submit" type="primary">{{$t("Register")}}</base-button>
      </template>
    </card>
  </form>
</template>
<script>
import {
  required,
  //sameAs,
  minLength,
  email
  //numeric
} from "vuelidate/lib/validators";
import moment from "moment";
import BaseAutoComplete from "@/components/BaseAutoComplete.vue";
import { users_types } from "../../store/index";
export default {
  components: {
    BaseAutoComplete
  },
  data() {
    return {
      id: null,
      amccompany: null,
      payingcustomer: null
    };
  },
  validations: {
    amccompany: {
      required
    },
    amcaddress: {
      required
    },
    amcphone: {
      required
    },
    amccontactperson: {
      required
    },
    payingcustomer: {
      required
    },
    payingcustomeraddress: {
      required
    },
    payingcustomerphone: {
      required
    },
    payingcustomercontactperson: {
      required
    }
  },
  computed: {
    amcaddress() {
      if (this.amccompany) {
        return this.amccompany.selectedObject.address;
      }
      return null;
    },
    amcuser() {
      let user;
      let amc = this.amccompany;
      if (amc) {
        if (
          amc.selectedObject.users &&
          Array.isArray(amc.selectedObject.users)
        ) {
          console.log(amc.selectedObject.users);
          user = amc.selectedObject.users.find(u => u.type == users_types.Supervisor);
        } else if (amc.selectedObject.user) {
          user = amc.selectedObject.user;
        }
      }

      return user;
    },
    customeruser() {
      let user;
      let customer = this.payingcustomer;
      
      if (customer) {
        if (
          customer.selectedObject.users &&
          Array.isArray(customer.selectedObject.users)
        ) {
          user = customer.selectedObject.users.find(u => u.type == users_types.Supervisor);
        } else if (customer.selectedObject.user) {
          user = customer.selectedObject.user;
        }
      }

      return user;
    },
    amcphone() {
      return this.amcuser ? this.amcuser.phone : "";
    },
    amccontactperson() {
      return this.amcuser ? this.amcuser.email : "";
    },
    payingcustomeraddress() {
      if (this.payingcustomer) {
        return this.payingcustomer.selectedObject.address;
      }
      return null;
    },
    payingcustomerphone() {
      return this.customeruser ? this.customeruser.phone : "";
    },
    payingcustomercontactperson() {
      return this.customeruser ? this.customeruser.email : "";
    }
  },

  // mounted
  mounted() {
    this.id = this.$route.params.id;
    this.$api
      .get(`orders/${this.id}`)
      .then(res => {
        if (res.status === 200) {
          let data = res.data.data;

          this.amccompany = {
            display: data.mainamc.name,
            selectedObject: data.mainamc
          };

          this.payingcustomer = {
            display: data.customers.name,
            selectedObject: data.customers
          };
        }
      })
      .catch(err => {
        console.log(err);
      });
  },

  methods: {
    AmcEndpoint(input) {
      return `${this.$api.defaults.baseURL}amc/search/${input}`;
    },
    payingCustomerEndpoint(input) {
      return `${this.$api.defaults.baseURL}customer/search/${input}`;
    },

    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let data = {
          id: this.id,
          amc_id: this.amccompany.selectedObject.id,
          customer_id: this.payingcustomer.selectedObject.id
        };

        this.$api
          .post(`orders/updatemainsection`, data)
          .then(res => {
            if (res.status === 200) {
            }
            this.$router.push({ path: "/order/list" });
          })
          .catch(err => {});
      }
    }
  }
};
</script>
<style>
</style>
