<template>
    <card v-loading="loading">
        <div class="striped-table">
            <el-table
                :data="tableData"
                style="width: 100%"
                max-height="500"
            >
                <template v-for="(column, index) in columns">
                    <el-table-column
                        :key="index"
                        v-bind="column"
                        align="center"
                        v-if="column.customTemplate"
                    >
                        <template slot-scope="{ row }">
                            <template v-if="column.prop=='address'">
                                <span> {{ row.countryName + ", " + row.cityName }} </span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        v-else
                        :key="index"
                        v-bind="column"
                    />
                    
                </template>
                <el-table-column
                    :fixed="$rtl.isRTL ? false : 'right'"
                    :label="$tc('report.action', 2)"
                    width="150"
                >
                    <div slot-scope="{ row }" class="row justify-content-between m-0">
                      <div class="col-12 mt-1 d-flex justify-content-center">
                        <router-link :to="`/VSAAS/Customer-Info/${row.structureId}`">
                          <base-button
                            class="mx-2 px-2 py-1"
                            simple
                            size="sm"
                            type="secondary"
                            round
                          >
                            <i class="fa fa-info"></i>
                          </base-button>
                        </router-link>
                      </div>
                    </div>
                </el-table-column>
            </el-table>
        </div>
        <TablePagination
          slot="footer"
          ref="paginator"
          v-if="tableData.length"
          :total="total"
          @change="updatePagination"
        />
    </card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TablePagination from "@/components/TablePagination.vue";

export default {
    components: {
        TablePagination
    },
    props: {
        namespace: {
            type: String,
            default: 'VsaasStore/CustomerList'
        },
        moduleName: {
            type: String,
            default: 'CustomerListTable'
        }
    },
    computed: {
        storePath() {
            return this.namespace + '/' + this.moduleName;
        },
        ...mapState({
            loading(state,getters) {
                return getters[this.storePath + '/loading'];
            },
            columns(state,getters) {
                return getters[this.storePath + '/columns'];
            },
            tableData(state,getters) {
                return getters[this.storePath + '/data'];
            },
            pagination(state,getters) {
                return getters[this.storePath + '/pagination'];
            },
            total(state,getters) {
                return getters[this.storePath + '/total']
            }
        })
    },
    methods: {
        ...mapActions({
            fetchData(dispatch,payload) {
                return dispatch(this.storePath + "/fetchData",payload);
            }
        }),
        updatePagination(data) {
            this.$store.commit(this.storePath + "/updatePagination",data);
            this.fetchData();
        }
    },
    created() {
        this.fetchData();
    },
    watch: {
        pagination: {
          handler(v) {
            if (this.$refs.paginator) this.$refs.paginator.goTo(v.limit, v.offset);
          },
        },
    },
}
</script>

<style>

</style>