<template>
  <card class="h-100 vsaas-devicess">
    <div class="row">
      <div class="col-12">
        <h4 class="m-0">Devices</h4>
      </div>
    </div>

    <div class="mt-4">
      <div class="row mb-3">
        <div class="col-12">
          <div class="row">
            <div
              class="rounded-circle d-flex justify-content-center align-items-center count mr-2 ml-4"
            >
              <inline-svg
                :src="require('@/assets/Icons/geo-location.svg')"
                width="30"
                height="30"
                class="black-icon"
              />
            </div>
            <base-select v-model="place" :options="placesOptions"></base-select>
          </div>
        </div>
      </div>
      <div v-show="place == 1">
        <canvas id="canvas" width="550" height="400"></canvas>

        <div
          id="viewerProgressDiv"
          style="
            position: absolute;
            top: 50%;
            left: 16%;
            background-color: rgba(128, 128, 128, 0.7);
            padding: 20px;
            border-radius: 25px;
          "
        >
          <progress
            id="viewerProgress"
            class="viewerComponent"
            value="0"
            max="100"
            style="width: 400px"
          ></progress>
          <label
            id="viewerProgressLabel"
            class="viewerComponent"
            style="margin-top: 2px; display: block; margin-left: 10px"
            >Loading...</label
          >
        </div>
      </div>

      <div v-show="place != 1" class="text-center">
        <h1>No Image to show</h1>
      </div>
    </div>
    <div id="virtualVisit"></div>
    <div id="aerialView"></div>
    <div id="levelsAndCameras"></div>

    <!-- <SlideYUpTransition v-if="openCameraShow">
      <camera-show v-if="openCameraShow" @close="openCameraShow = false" />
    </SlideYUpTransition> -->
  </card>
</template>
<script>
import { BaseCheckbox, BaseRadio } from "src/components/index";
import CameraShow from "./../CameraShow.vue";
import { SlideYUpTransition } from "vue2-transitions";
import { colors } from "src/util/helper.js";
import { mapActions, mapState } from "vuex";
import Vue from "vue";

// three js

import * as THREE from "three";
window.THREE = THREE;
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { OBJLoader2 } from "three/examples/jsm/loaders/OBJLoader2.js";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader.js";
import { MtlObjBridge } from "three/examples/jsm/loaders/obj2/bridge/MtlObjBridge.js";
import { Interaction } from "three.interaction";

export default {
  components: {
    BaseCheckbox,
    CameraShow,
    SlideYUpTransition,
  },
  data() {
    return {
      colors,
      openCameraShow: false,
      selected_camera: null,
      place: 1,
      placesOptions: [
        {
          value: 1,
          label: "Home",
        },
        {
          value: 2,
          label: "Office",
        },
      ],
    };
  },
  computed: {
    ...mapState("VsassStore", {
      alive: (state) => state.alive,
      cameraAlive: (state) => state.cameraAlive,
    }),
  },
  mounted() {
    let vm = this;
    let canvas = document.getElementById("canvas");

    var progress = document.getElementById("viewerProgress");
    var progressDiv = document.getElementById("viewerProgressDiv");

    var home3d = null;
    var securityCams = [];
    var scene, camera, renderer, orbit, interaction;

    var group = new THREE.Group();
    var seleced_camera = null;

    main();

    function main() {
      var manager = new THREE.LoadingManager();

      manager.onStart = function (item, loaded, total) {
        //console.log("Loading started");
      };

      manager.onLoad = function () {
        console.log("Loading complete");
        progressDiv.style.visibility = "hidden";
      };

      manager.onProgress = function (item, loaded, total) {
        // console.log(item, loaded, total);
        // console.log("Loaded:", Math.round((loaded / total) * 100, 2) + "%");
        progress.value = Math.round((loaded / total) * 100, 2);
      };

      manager.onError = function (url) {
        console.log("Error loading");
      };
      const mtlLoader = new MTLLoader(manager);
      mtlLoader.load(
        "home3d/SweetHome3DExample3.mtl",
        (mtlParseResult) => {
          const objLoader = new OBJLoader2(manager);
          const materials = MtlObjBridge.addMaterialsFromMtlLoader(
            mtlParseResult
          );
          objLoader.addMaterials(materials);
          objLoader.load(
            "home3d/SweetHome3DExample3.obj",
            (root) => {
              home3d = root;
              // const objLoader2 = new OBJLoader2(manager);
              // var ship_material = new THREE.MeshBasicMaterial({
              //   color: 0xffffff,
              // });
              // objLoader2.load(
              //   "Camera_altcam/CCTV_Camera.obj",
              //   (root2) => {
              //     // root2.traverse(function (child) {
              //     //   if (child instanceof THREE.Mesh) {
              //     //     child.material = ship_material;
              //     //   }
              //     // });
              //     for (var i = 0; i < 4; i++) {
              //       let copy = root2.clone();
              //       copy.scale.set(3, 3, 3);
              //       copy.rotation.y = i + 1 * 1000;
              //       copy.position.x = i + 2;
              //       copy.camera_id = i;
              //       securityCams.push(copy);
              //     }
              //     init();
              //     render();
              //   }
              // );

              mtlLoader.load(
                "low-poly/LowPoly CCTV_Camera.mtl",
                (secParseResult) => {
                  const objLoader2 = new OBJLoader2(manager);
                  const materials2 = MtlObjBridge.addMaterialsFromMtlLoader(
                    secParseResult
                  );
                  objLoader2.addMaterials(materials2);
                  objLoader2.load(
                    "low-poly/LowPoly CCTV_Camera.obj",
                    (root2) => {
                      console.log(root2);
                      for (var i = 0; i < 4; i++) {
                        let copy = root2.clone();
                        copy.scale.set(220, 220, 220);
                        //copy.rotation.y = i + 1 * 100;
                        copy.position.z = -32 * i;
                        copy.position.y = -45;
                        copy.camera_id = i + 1;
                        securityCams.push(copy);
                      }

                      init();
                      render();
                    }
                  );
                }
              );
            }
          );
        }
      );
    }

    function init() {
      scene = new THREE.Scene();
      scene.add(new THREE.GridHelper(10000, 100, 0x888888, 0x444444));
      camera = new THREE.PerspectiveCamera(
        75,
        canvas.width / canvas.height,
        0.1,
        1000
      );

      renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: true });

      interaction = new Interaction(renderer, scene, camera);

      orbit = new OrbitControls(camera, renderer.domElement);
      orbit.target.set(0, 5, 0);
      orbit.update();

      {
        const skyColor = 0xb1e1ff; // light blue
        const groundColor = 0xb97a20; // brownish orange
        const intensity = 0.7;
        const light = new THREE.HemisphereLight(
          skyColor,
          groundColor,
          intensity
        );
        scene.add(light);
      }
      {
        const color = 0xffffff;
        const intensity = 1;
        const light = new THREE.DirectionalLight(color, intensity);
        light.position.set(5, 10, 2);
        scene.add(light);
        scene.add(light.target);
      }

      group.add(home3d);
      securityCams.forEach((cam) => {
        cam.cursor = "pointer";
        // cam.on("mousedown", function (e) {
        //   console.log(e);
        //   let camera_id = e.data.target.camera_id;

        //   e.stopPropagation();
        //   seleced_camera = e.data.target;
        //   vm.createCameraShow(camera_id);
        // });
        

        group.add(cam);
      });

      scene.add(group);
      const box = new THREE.Box3().setFromObject(home3d);
      const boxSize = box.getSize(new THREE.Vector3()).length();
      const boxCenter = box.getCenter(new THREE.Vector3());

      // set the camera to frame the box
      frameArea(boxSize * 1.2, boxSize, boxCenter);
      orbit.maxDistance = boxSize * 10;
      orbit.target.copy(boxCenter);
      orbit.update();
    }

    function frameArea(sizeToFitOnScreen, boxSize, boxCenter) {
      const halfSizeToFitOnScreen = sizeToFitOnScreen * 0.5;
      const halfFovY = THREE.MathUtils.degToRad(camera.fov * 0.5);
      const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);
      // compute a unit vector that points in the direction the camera is now
      // in the xz plane from the center of the box
      const direction = new THREE.Vector3()
        .subVectors(camera.position, boxCenter)
        .multiply(new THREE.Vector3(1, 0, 1))
        .normalize();

      // move the camera to a position distance units way from the center
      // in whatever direction the camera was from the center already
      camera.position.copy(direction.multiplyScalar(distance).add(boxCenter));

      // pick some near and far values for the frustum that
      // will contain the box.
      camera.near = boxSize / 100;
      camera.far = boxSize * 100;

      camera.updateProjectionMatrix();

      // point the camera to look at the center of the box
      camera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z);
    }

    function render() {
      renderer.render(scene, camera);
      requestAnimationFrame(render);
    }
  },
  methods: {
    createCameraShow(id) {
      let exist = document.getElementById(`camera-show-${id}`);

      if (!exist) {
        let body = document.querySelector("body");
        const mountNode = document.createElement("div");
        body.appendChild(mountNode);

        let CameraShowCom = Vue.extend(CameraShow);
        let instance = new CameraShowCom({
          propsData: {
            id
          },
        });
        instance.$mount(mountNode);
      }
    },
    ...mapActions("VsassStore", ["checkConnection"]),
    recreateNode(el, withChildren) {
      if (withChildren) {
        el.parentNode.replaceChild(el.cloneNode(true), el);
      } else {
        var newEl = el.cloneNode(false);
        while (el.hasChildNodes()) newEl.appendChild(el.firstChild);
        el.parentNode.replaceChild(newEl, el);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped>
canvas {
  display: block;
}
.count {
  width: 40px;
  height: 40px;
  background-color: #d650d5;
}

.areas i {
  font-size: 20px;
}
.odd {
  background-color: #2f324a;
  border-radius: 10px;
}
.icon-simple-remove {
  color: #f5465c;
  font-weight: bolder;
}
</style>
<style>
.black-icon path {
  fill: #2f324a;
}
.custom-check input {
  position: relative !important;
}
.settings-icon path {
  fill: #a5f560;
}
.worker-icon path {
  fill: #fff;
}
.areas-main-icon path {
  fill: #00ced4;
}
</style>
