<template>
  <div>
    <CustomersMap :markers="mapMarkers" />
    <card class="mt-5">
      <div class="striped-table">
        <el-table
          :data="customerListTableData"
          style="width: 100%"
          max-height="500"
        >
          <el-table-column
            v-for="(column, index) in columnsGetter"
            :key="index"
            v-bind="column"
            align="center"
          >
            <template slot-scope="scope">
              <span style="margin-left: 10px">
                {{ scope.row[`${column.prop}`] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
          :fixed="$rtl.isRTL ? false : 'right'"
          :label="$tc('report.action', 2)"
          width="165"
          >
          <div slot-scope="{ row }" class="row justify-content-between m-0">

            <div class="col-12 mt-1 d-flex justify-content-center">
              <router-link :to="{ name: 'customer info', params: { id: row.structureId } }">
                <base-button
                  class="mx-2 px-2 py-1"
                  simple
                  size="sm"
                  type="secondary"
                  round
                >
                  <i class="fa fa-info"></i>
                </base-button>
              </router-link>
            </div>
          </div>
        </el-table-column>
        </el-table>
      </div>
      <TablePagination
        slot="footer"
        ref="paginator"
        v-if="customerListTableData.length"
        :total="customerListTotalRecordsGetter"
        @change="updatePagination"
      />
    </card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VSaaSActionsTypes from "../store/types/actions-types";
import VSaaSGettersTypes from "../store/types/getter-types";
import CustomersMap from "./map/customers-map";
import TablePagination from "@/components/TablePagination.vue";

export default {
  components: {
    CustomersMap,
    TablePagination
  },
  data() {
    return {
      customerListTableData: [],
      currentPage: 0,
      selectedPageSize: 10,
      pageSizeOptionsArray: [5, 10, 25, 50],
      mapMarkers: [],
    };
  },
  created() {},
  mounted() {
    // Initial call to feed table with data
    this.getListOfCustomersFromServer(this.selectedPageSize, this.currentPage);
    this.gettingCustomerLocationsDispatcher();
  },

  watch: {
    // Detect data members when change and re-feed table with data
    selectedPageSize: function(_) {
      this.getListOfCustomersFromServer(
        this.selectedPageSize,
        this.currentPage
      );
      this.getListOfCustomersFromServer(
        this.selectedPageSize,
        this.currentPage
      );
    },
    currentPage: function(_) {
      this.getListOfCustomersFromServer(
        this.selectedPageSize,
        this.currentPage
      );
      this.getListOfCustomersFromServer(
        this.selectedPageSize,
        this.currentPage
      );
    }
  },
  computed: {
    // Mapping getters
    ...mapGetters("VSaaSStore", {
      customerListGetter: VSaaSGettersTypes.CUSTOMER_LIST_GETTER,
      columnsGetter: VSaaSGettersTypes.CUSTOMER_TABLE_COLUMNS_GETTER,
      customerListTotalRecordsGetter:
        VSaaSGettersTypes.CUSTOMER_TOTAL_RECORDS_GETTER,
      customerLocationsArrayGetter:
        VSaaSGettersTypes.CUSTOMER_LOCATIONS_ARRAY_GETTER
    })
  },
  methods: {
    // Mapping actions
    ...mapActions("VSaaSStore", {
      gettingCustomerDispatcher: VSaaSActionsTypes.GETTING_CUSTOMER_ACTION,
      gettingCustomerContactsListDisptacher:
        VSaaSActionsTypes.CUSTOMER_CONTACTS_LIST_ACTION,
      gettingCustomerLocationsDispatcher:
        VSaaSActionsTypes.CUSTOMER_LOCATIONS_ARRAY_ACTION,
    }),

    updatePagination(paginationData) {
      const { limit, offset } = paginationData;
      this.selectedPageSize = limit;
      this.currentPage = offset;
    },
    getListOfCustomersFromServer(limit, offset) {
      this.gettingCustomerDispatcher({
        limit: limit,
        offset: offset
      })
        .then(requestSucceeded => {
          this.feedTableWithData();
          this.feedMapWithData();
        })
        .catch(exc => {
          console.log("Request did not succeeded [customer-alarms.vue]: ", exc);
        });
    },

    feedTableWithData() {
      this.customerListTableData = [...this.customerListGetter];
    },
    feedMapWithData() {
      this.mapMarkers = this.customerLocationsArrayGetter.map(loc => {
        return {
          ...loc
        };
      });
    },
  }
};
</script>
