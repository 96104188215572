<template>
  <div class="confirmMsg">
    <el-dialog title="Apply Filter" :visible.sync="isConfirmMessageOpenComp" width="30%">
      <span>All Your Data will Change</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clickEvent(false)">Cancel</el-button>
        <el-button type="primary" @click="clickEvent(true)">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name:'ConfirmMessage',
  props:["isConfirmMessageModalOpen"],
  computed:{
    isConfirmMessageOpenComp:{
      get(){
        return this.isConfirmMessageModalOpen;
      },
      set(val)
      {
        this.$emit('confirmMsg',false)
      }
    }
  },
  methods:{
      clickEvent(status){
        this.$emit('confirmMsg',status)
    }
  },


}
</script>
