const statusSensor = [
  {
    status: "off",
    notification: 2,
    email: true,
    whatsapp: false,
    sms: false,
  },
  {
    status: "low battery",
    notification: 2,
    email: true,
    whatsapp: false,
    sms: false,
  },
  {
    status: "contract expiring",
    notification: 2,
    email: true,
    whatsapp: false,
    sms: false,
  },
  {
    status: "sensor life expiring",
    notification: 2,
    email: true,
    whatsapp: false,
    sms: false,
  },
];

export default statusSensor;
