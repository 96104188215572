<template>
  <card>
    <div class="customtableheader">
      <h4 slot="header" class="card-title">Company Notifications Templates</h4>
      <el-tooltip
        content="Create Notification Template"
        effect="light"
        :open-delay="300"
        placement="top"
      >
        <base-button type="primary" round icon @click="createTemplate()">
          <i class="tim-icons icon-simple-add"></i>
        </base-button>
      </el-tooltip>
    </div>
    <el-table :data="correctedData">
      <el-table-column
        sortable
        v-for="column in tableColumns"
        :key="column.label"
        :min-width="column.minWidth"
        :prop="column.prop"
        :label="column.label"
      ></el-table-column>

      <el-table-column
        sortable
        :min-width="200"
        align="left"
        :label="$t('Created at')"
      >
        <div slot-scope="props">
          <span>{{ gDateFormat(props.row.created_at) }}</span>
        </div>
      </el-table-column>

      <el-table-column
        :min-width="155"
        align="right"
        class="text-center"
        :label="$t('Actions')"
      >
        <div slot-scope="props">
          <el-tooltip
            content="Assign template to users"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click.native="assignToUser(props.row.id)"
              class="remove btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="fa fa-tasks"></i>
            </base-button>
          </el-tooltip>
          <el-tooltip
            content="Edit"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click.native="editTemplate(props.row.id)"
              class="like btn-link"
              type="info"
              size="sm"
              icon
            >
              <i class="tim-icons icon-pencil"></i>
            </base-button>
          </el-tooltip>
          <el-tooltip
            content="Delete"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click.native="onDelete(props.row.id, ondeleteTemplate)"
              class="remove btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </el-tooltip>
        </div>
      </el-table-column>
    </el-table>
    <UsersModel
      :companyId="companyId"
      :isUserModelOpen="isUserModelOpen"
      :templateId="templateId"
      v-if="isUserModelOpen"
      @handleOnClick="handleOnClick"
    />
  </card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { deleteHandler } from "src/util/helper";
import UsersModel from "./users-model";
// headers columns in the table
import tableColumns from "./table-column";
export default {
  components: { UsersModel },
  data() {
    return {
      isUserModelOpen: false,
      templateId: 0,
      tableColumns: tableColumns,
    };
  },
  props: ["companyId"],

  computed: {
    ...mapGetters({
       templatesList:"SwamStore/NotificationsTemplate/templatesList"
    }),
    correctedData(){
      let list=[];
      let defaultValue='____';
      for(let i=0;i<this.templatesList.length;i++)
      {
        // eslint-disable-next-line
        this.templatesList[i].structure_name=this.templatesList[i].structure_name!=null?this.templatesList[i].structure_name:defaultValue;
        // eslint-disable-next-line
        this.templatesList[i].customer_name=this.templatesList[i].customer_name!=null?this.templatesList[i].customer_name:defaultValue;
        // eslint-disable-next-line
        this.templatesList[i].Community_English=this.templatesList[i].Community_English!=null?this.templatesList[i].Community_English:defaultValue;
      }
      return this.templatesList;
    }
  },
  methods: {
    ...mapActions({
      getList:"SwamStore/NotificationsTemplate/getList",
      deleteTemplate:"SwamStore/NotificationsTemplate/deleteTemplate"
    }),
    assignToUser(templateId) {
      this.templateId = templateId;
      this.isUserModelOpen = true;
    },
    createTemplate() {
      this.$router.push({
        path: "/SWAM/notifications-templates/create-template",
        query: {
          companyId: this.companyId,
        },
      });
    },

    editTemplate(value) {
      this.$router.push({
        path: "/SWAM/notifications-templates/edit-template",
        query: {
          companyId: this.companyId,
          templateId: value,
        },
      });
    },
    handleOnClick(status) {
      this.isUserModelOpen = status;
    },
    onDelete: deleteHandler,
    ondeleteTemplate(template_id) {
      this.deleteTemplate(template_id)
      .then(res=>{
        this.deleteRow(template_id);
      })
    },
    deleteRow(template_id) {
      let index = this.templatesList.findIndex(
        (temp) => temp.id === template_id
      );
      if (index > -1) {
        this.templatesList.splice(index, 1);
        if (this.total > 0) {
          this.total -= 1;
        }
      }
    },
  },
  created()
  {
    this.getList({companyId: this.companyId});
  },
};
</script>
