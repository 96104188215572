<template>
    <el-dialog :visible.sync="show" :append-to-body="true" :before-close="close">
        <WorkOrderDetails :data="data" ref="form" />
        <div class="d-flex flex-row-reverse">
            <base-button native-type="submit" @click.prevent="submit" type="primary">{{ data ? 'Update' : 'Create' }}</base-button>
        </div>
    </el-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import WorkOrderDetails from './WorkOrderDetails';

export default {
    components: {
        WorkOrderDetails
    },
    props: {
        data:{}
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        ...mapActions('VsaasStore/WorkOrder', {
            createWorkOrder: 'createWorkOrder',
            updateWorkOrder: 'updateWorkOrder',
        }),
        async submit() {
            let formData = this.$refs['form'].extactData();
            if(!formData) {
                return;
            }
            if(this.data) {
                await this.updateWorkOrder({id:this.data.id,...formData});
                this.$emit('change');
            } else {
                await this.createWorkOrder(formData);
                this.$emit('change');
            }
            this.close();
        },
        open() {
            this.show = true;
        },
        close() {
            this.$refs.form.reset();
            this.show = false;
        }
    },
}
</script>

<style>

</style>