<template>
  <div>
    <div class="row my-3">
      <div class="col-lg-7 col-xs-12">
        <div class="row d-flex align-items-center">
          <div class="col-md-4 col-sm-6 col-8">
            <span class="h4 font-color-4 font-weight-bold mb-0">Activate Geofence zone ? </span>
          </div>
          <div class="col-md-4 col-4">
            <base-radio class="my-0 font-weight-bold mx-3" inline name="yes" v-model="type">Yes</base-radio>
            <base-radio class="my-0 font-weight-bold mx-3" inline name="no" v-model="type">No</base-radio>            
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <base-select
              v-model="value"
              :options="options"
              class="mb-0"
              placeholder="Geofence limit"
              :hideShadow="true"/>
          </div>
        </div>
      </div>
    </div>
    <base-map class="" :zoom="12" :center="{lat: center.lat,lng: center.lng}" :style="`height: 350px`">
      <base-map-marker :position="{lat: center.lat,lng: center.lng}"/>
    </base-map>
  </div>
</template>

<script>
import BaseMap from 'src/components/BaseMap/Map'
import MapMarker from "src/components/BaseMap/MapMarker.vue";
import { mapActions, mapGetters } from 'vuex';
import { BaseRadio} from "src/components"
export default {
  components:{
    BaseRadio,
    [BaseMap.name]: BaseMap,
    [MapMarker.name]: MapMarker
  },
  data(){
    return{
      center: {
        lat: 25.235052, 
        lng: 55.511372,
      },
      type: "",
      options: [],
      value: ""
    }
  },
  computed:{
    ...mapGetters('', [

    ])
  },
  methods:{
    ...mapActions('', [

    ])
  }
}
</script>