<template>
  <card>
    <div class="col-12">
      <el-table
        :data="operationalDataWithThreeState"
        :span-method="objectSpanMethod"
        strip
        height="400"
        style="width: 100%"
      >
        <!-- 1- Sensor -->

        <el-table-column
          prop="sensor_type_id"
          label="Sensor"
          min-width="100"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ nameOfSenor(scope.row.sensor_type_id) }}</span>
          </template>
        </el-table-column>

        <!-- 2- Status -->
        <el-table-column
          prop="status"
          label="Status"
          min-width="100"
          align="center"
        >
        </el-table-column>

        <!-- 2- Notification -->
        <el-table-column label="Notification" min-width="100" align="center">
          <template slot-scope="scope">
            <base-select
              v-model="scope.row.notification"
              :options="notifictationOptions"
              :size="'small'"
            ></base-select>
          </template>
        </el-table-column>

        <!-- 2- Email -->
        <el-table-column label="Email" min-width="100" align="center">
          <template slot-scope="scope">
            <base-checkbox v-model="scope.row.email" @input="input" />
          </template>
        </el-table-column>

        <!-- 2- Whatsapp -->
        <el-table-column label="Whatsapp" min-width="100" align="center">
          <template slot-scope="scope">
            <base-checkbox v-model="scope.row.whatsapp" @input="input" />
          </template>
        </el-table-column>

        <!-- 2- SMS -->
        <el-table-column label="SMS" min-width="100" align="center">
          <template slot-scope="scope">
            <base-checkbox v-model="scope.row.sms" @input="input" />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </card>
</template>
<script>
import notifictationOptionsData from "./notification-options";
import statusOptionsData from "./status-options";
import {
  convertObjectToArray,
  BuildOperationalWithStatusNotification,
} from "../utlilities";

export default {
  name: "OperationalAlarmTable",
  props: ["sendedOperational", "allSensors", "isCreate"],
  methods: {
    input() {
      this.$emit("operationalChanged", this.operationalArray);
    },
    applyGlobalSelector(globalSelector) {
      this.operationalArray.forEach((element) => {
        if (element.status == globalSelector.status) {
          element.notification = globalSelector.notification;
          element.email = globalSelector.email;
          element.whatsapp = globalSelector.whatsapp;
          element.sms = globalSelector.sms;
        }
      });
    },
    nameOfSenor(val) {
      if (this.allSensors.length > 0) {
        if (this.allSensors.find((s) => s.id == parseInt(val)) != undefined)
          return this.allSensors.find((s) => s.id == parseInt(val)).name;
      }
    },
    createOperationalTableFromParentProps(){
        this.operationalArray = [];
        let operationalArray = convertObjectToArray(this.operational);
        this.operationalArray = BuildOperationalWithStatusNotification(operationalArray);
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 3 === 0) {
          return {
            rowspan: 3,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
  },
  computed: {
    operationalDataWithThreeState: {
      get() {
        if (!this.isCreate)
        {
          if (this.operational)
            this.createOperationalTableFromParentProps();
        }
        else
        {
          if (this.operational)
            this.createOperationalTableFromParentProps();
          else {
            // eslint-disable-next-line
            this.operationalArray = BuildOperationalWithStatusNotification([],this.allSensors);
          }
        }
        this.$emit("operationalChanged", this.operationalArray);
        return this.operationalArray;
      },
      set(val) {
        this.operationalArray = [...val];
      },
    },
  },
  watch: {
    sendedOperational(newValue, oldValue) {
      this.operational = newValue;
    },
  },
  data: function () {
    return {
      operational: undefined,
      operationalArray: [],
      sensors: [],
      notifictationOptions: notifictationOptionsData,
      statusOptions: statusOptionsData,
    };
  },
};
</script>
