<template>
  <card v-loading="loading">
    <div slot="header" class="container">
      <h4 class="font-color-4 mb-3 font-weight-bold">
        {{title}}
      </h4>
      <hr class="mt-1 mb-1" style="border-top-width: 2px" />
    </div>
    <donut-chart :items="reasons" />
  </card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DonutChart from '@/components/Charts/DonutChart.vue';
export default {
  components: { DonutChart },
  name: "count-statistics",
  props: {
    namespace: {
      type: String,
      default: "IntrusionSystem/Dashboard",
    },
    moduleName: {
      type: String,
      default: "VerificationReasons",
    },
    title:{
      type: String,
      default: "Verification Reasons",
    }
  },
  computed: {
    storePath() {
      return this.namespace + "/" + this.moduleName;
    },
    ...mapState({
      reasons(state, getters) {
        return getters[this.storePath + "/getReasons"];
      },
      loading(state, getters) {
        return getters[this.storePath + "/loading"];
      },
      policeStationId(state, getters) {
        return getters[this.namespace + "/policeStationId"];
      },
    })
  },
  created(){
    this.$store.commit(this.storePath + "/setPoliceStationId", this.policeStationId);
    this.fetchReasons('get'+this.moduleName);
  },
  methods:{
    ...mapActions({
      fetchReasons(dispatch, payload) {
        return dispatch(this.storePath + "/fetchReasons", payload);
      },
    }),
  },
  watch: {
    policeStationId() {
      this.$store.commit(this.storePath + "/setPoliceStationId", this.policeStationId);
      this.fetchReasons('get'+this.moduleName);
    }
  }
};
</script>

<style scoped lang="scss">
div.title{
    border-bottom: 1px solid $gray-7;
}
</style>