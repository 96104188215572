<template>
  <div class="row">
    <div class="col-8">
      <IntrusionVillaMap />
    </div>
    <div class="col-4">
      <AlarmsStatistics />
    </div>
    <!-- <div class="col-4">
      <StatusAlarms />
    </div> -->
    <!-- <div class="col-5">
                <card>
                    <StatisticsAlarmDetails
                        :data="[{key:'test',value:10},{key:'test2',value:20}]"
                    />
                </card>
                
            </div> -->
    <div class="col-8">
      <div class="row">
        <div class="col-6">
          <StatusAlarms />
        </div>
        <div class="col-6">
          <MoroStatistics v-if="currentUserType == 'moro'"/>
          <VillasSummary v-else /> 
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <count-statistics />
        </div>
        <div class="col-6">
          <count-statistics moduleName="AlarmsTypes" title="Alarms Types" />
        </div>
      </div>
    </div>
    <div class="col-4">
      <PoliceStationStatistics v-if="isMoroOrHQ && !policeStationId" />
      <VillasSummaryList v-else />
    </div>
    <div class="col-12">
      <IntrusionComparison :alarmType="'intrusion'" :hideAlarmTypeSelect="true" />
    </div>
    <div class="col-12" v-if="currentUserType == 'moro'">
      <IntrusionComparison :alarmType="'operational'" moduleName="IntrusionComparison2" />
    </div>
  </div>
</template>

<script>
import IntrusionComparison from "../../core/IntrusionComparison";
import IntrusionVillaMap from "../../core/VillasOverall";
import VillasSummary from "../../core/VillasOverall/VillasSummary";
import AlarmsStatistics from "../../core/AlarmsStatistics";
import StatisticsAlarmDetails from "@/components/StatisticsAlarmDetails";
import PoliceStationStatistics from "../../core/PoliceStationStatistics";
import StatusAlarms from "@/pages/Intrusion-System/core/AlarmsStatistics/StatusAlarms";
import { mapActions, mapGetters, mapState } from "vuex";
import CountStatistics from "../../core/CountStatistics";
import VillasSummaryList from '@/pages/Intrusion-System/core/VillasOverall/VillasSummaryList';
import MoroStatistics from "../../core/MoroStatistics";

export default {
  components: {
    IntrusionComparison,
    AlarmsStatistics,
    StatisticsAlarmDetails,
    CountStatistics,
    PoliceStationStatistics,
    IntrusionVillaMap,
    VillasSummary,
    StatusAlarms,
    VillasSummaryList,
    MoroStatistics
  },
  computed: {
    isMoroOrHQ() {
      return this.currentUserType == 'moro' || this.currentUserType == 'hq';
    },
    ...mapGetters({
      currentUserType: "IntrusionSystem/getCurrentUserType",
      policeStationId: "IntrusionSystem/Dashboard/policeStationId"
    }),
  },
  methods: {
    ...mapActions("IntrusionSystem/Dashboard", {
      reset: "reset",
    }),
  },
  destroyed() {
    this.reset();
  },
};
</script>

<style>
</style>