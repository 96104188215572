<template>
  <form>
    <card footer-classes="text-right">
      <div slot="header">
        <h4 class="card-title">{{$t("Create")}} {{$t("admins")}}</h4>
      </div>

      <div>
        <div class="row">
          <div class="col-md-4 col-sm-4">
            <h4 class="card-title">{{$t("Avatar")}}</h4>
            <image-upload
              type="avatar"
              :src="avatarUrl"
              select-text="Add photo"
              @change="onAvatarChange"
            >
              <template v-if="$v.avatar.$error" v-slot:error>
                <label class="error" v-if="!$v.avatar.required">{{$t("requireds.Avatar")}}</label>
              </template>
            </image-upload>
          </div>
        </div>

        <!--  -->
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-4">
                <base-input :placeholder="$t('Email')" v-model="$v.email.$model" @keydown.="">
                  <template v-if="$v.email.$error" v-slot:error>
                    <label class="error" v-if="!$v.email.required">{{$t("requireds.Email")}}</label>
                    <label
                      class="error"
                      v-if="!$v.email.email"
                    >{{$t("validations.Not valid email")}}</label>
                  </template>
                </base-input>
              </div>

               <div class="col-md-4">
                <countries-select :placeholder="$t('Country')" v-model="$v.country.$model">
                  <template v-if="$v.country.$error" v-slot:error>
                    <label class="error" v-if="!$v.country.required">{{$t("requireds.Country")}}</label>
                  </template>
                </countries-select >
              </div>

               <div class="col-md-4">
                <cities-select :placeholder="$t('City')" v-model="$v.city.$model" :countryId="country.id">
                  <template v-if="$v.city.$error" v-slot:error>
                    <label class="error" v-if="!$v.city.required">{{$t("requireds.City")}}</label>
                  </template>
                </cities-select >
              </div>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="row">
               <div class="col-md-4">
                <phone-input :placeholder="$t('Phone')" v-model="$v.phone.$model" @phoneValid="validatePhone" :countryId="country.id">
                  <template v-if="$v.phone.$error" v-slot:error>
                    <label class="error" v-if="!$v.phone.required||$v.phone.isvalidPhone">{{$t("requireds.Phone")}}</label>
                  </template>
                </phone-input >
              </div>

              <!-- <base-input placeholder="solution"></base-input> -->
              <div class="col-md-4">
                <base-multi-select v-model="$v.solutions.$model" :options="options">
                  <template v-if="$v.solutions.$error" v-slot:error>
                    <label class="error" v-if="!$v.solutions.required">{{$t("requireds.Solution")}}</label>
                  </template>
                </base-multi-select>
              </div>
            </div>
          </div>
        </div>

        <!--  -->
      </div>
      <template slot="footer" class="text-right">
        <base-button native-type="submit" @click.prevent="submit" type="primary">{{$t("Register")}}</base-button>
      </template>
    </card>
  </form>
</template>
<script>
const notEmpty = arr => arr.length > 0;
import { required, email, requiredIf } from "vuelidate/lib/validators";
import { ImageUpload } from "src/components/index";



export default {
  props: [],
  data() {
    return {
      id: "",
      avatar: null,
      avatarUrl: "",
      //name: "",
      //lastname: "",
      email: "",
      phone: '',
      city: '',
      country: '',
      // status: "",
      solutions: [],
      user_id: "",
      countriesCode: [],
      Iselected: 0,
      IsLogoChanged: false,
      // statusOptions: [
      //   { value: "active", label: "active" },
      //   { value: "inactive", label: "inactive" }
      // ]
      phoneValid:false
    };
  },
  components: {
    ImageUpload
  },
  validations: {
    // name: {
    //   required
    // },
    // lastname: {
    //   required
    // },
    email: {
      required,
      email
    },
    phone: {
      required,
      isvalidPhone:function(){
        return this.phoneValid;
      }
    },
    // status: {
    //   required
    // },
    solutions: {
      notEmpty
    },
    avatar: {
      required: requiredIf(vm => {
        return !vm.id;
      })
    },
    city: {
      required
    },
    country: {
      required
    }
  },
  mounted() {
//    this.fetchCountryCodes();

    console.log('admin update',this.$route.path);

    if (/update\/[0-9]/.test(this.$route.path)) {
      this.id = this.$route.params.id;
      this.$api
        .get(`admin/${this.id} `)
        .then(res => {
          let data = res.data.data;
          //const phoneNumber = parsePhoneNumberFromString(data.phone);
          //this.phone = phoneNumber ? phoneNumber.nationalNumber : "";
          //this.name = data.name;
          //this.lastname = data.last_name;
          this.email = data.email;
          this.phone = data.phone;
          
          // this.status = data.status;
          this.user_id = data.user_id;
          this.createDate = this.gDateFormat(data.created_at)
          this.country = data.country;
          this.city = data.city;
          this.avatarUrl = this.$api.defaults.baseURL + data.img_path;
          this.solutions = this.options
            .filter(opt => {
              return data.solutions.includes(opt.value);
            })
            .map(sl => sl.value);
        })
        .catch(err => {
          console.log(err);
          this.$router.push({ path: "/admins/list" });
        });
    }
  },
  computed: {
    options() {
      return this.$store.state.solutions.map(sol => {
        return { value: sol.id, label: sol.name };
      });
    },
  },
  methods: {
    validatePhone(evt){
        this.phoneValid=evt;
    },
    /*inVlaFun(evt){
      this.inValue=evt;
      console.log("evt",this.inValue)
    },*/
    onAvatarChange(file) {
      this.avatar = file;
    },
    async submit() {
      this.$v.$touch();
      console.log(this.$v);
      if (this.$v.$invalid) {
      } else {
        let base64 = this.avatar
          ? await this.$helper.getBase64(this.avatar)
          : null;
        let data = {
          id: this.id,
          user_id: this.user_id,
          name: this.name,
          last_name: this.lastname,
          //username:this.username,
          email: this.email,
          //    phone: this.selectedCode + this.phone, **
          phone:this.phone,
          // status: this.status,
          solutions: this.solutions,
          avatar: base64,
          //country: this.country.id,
          city_id: this.city.id
        };
        console.log("admin data", data);
        if (this.id) {
          this.$api
            .put(`admin/${this.id}/update`, data)
            .then(res => {
              console.log("regiter user", res);
              this.$router.push({ path: "/admins/list" });
            })
            .catch(() => {});
        } else {
          this.$api
            .post(`admin/create`, data)
            .then(res => {
              console.log("create admin", res);
              this.$router.push({ path: "/admins/list" });
            })
            .catch(() => {});
        }
      }
    }
  }
};
</script>
<style scoped>
.SpPhone {
  border-color: #e14eca;
}
</style>
