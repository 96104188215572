<template>
  <card v-loading="loading">
        <div class="container">
            <div class="d-flex justify-content-between"> 
                <h4 class="align-items-baseline d-flex font-weight-bold m-0">
                  <inline-svg
                    :src="require('@/assets/icons-2/statistics.svg')"
                    class="mr-2"
                    width="20px" height="20px"
                  />
                  Moro Statistics
                </h4>
                <base-select 
                    :options="onePeriod"
                    v-model="selectedPeriodIndex"
                    :light="true"
                    size="mini"
                />
            </div>
        </div>
        <hr class="mt-1 mb-1" style="border-top-width: 2px" />
        <div class="container">
            <StatisticsAlarmDetails
                :data="escalateData"
                class="pt-4"
            />
            <StatisticsAlarmDetails
                :data="terminationsData"
                class="pt-4 pb-2"
            />
        </div>
  </card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { getDeep } from "@/util/helper";
import StatisticsAlarmDetails from '@/components/StatisticsAlarmDetails';

export default {
    props: {
        namespace: {
            type: String,
            default: "IntrusionSystem/Dashboard"
        },
        moduleName: {
            type: String,
            default: "MoroStatistics",
        },
    },
    components: {
        StatisticsAlarmDetails
    },
    data(){
        return{
            selectedPeriodIndex: 7
        }
    },
    computed: {
        storePath() {
            return this.namespace + "/" + this.moduleName;
        },
        ...mapState({
            loading(state, getters) {
                return getters[this.storePath + "/loading"];
            },
            data(state, getters) {
                return getters[this.storePath + "/data"];
            },
            onePeriod(state, getters) {
                return getters[this.storePath + "/onePeriod"];
        }   ,
        }),
        escalateData() {
            if(!this.data) {
                return;
            }
            let result = [];
            result.push({key:'Escalated' + this.getValue(this.data.escalated), value:this.data.escalated});
            result.push({key:'Not Escalated' + this.getValue(this.data.not_escalated), value:this.data.not_escalated});
            return result;
        },
        terminationsData() {
            if(!this.data) {
                return;
            }
            let result = [];
            result.push({key:'Police Terminations' + this.getValue(this.data.police_terminations),value:this.data.police_terminations});
            result.push({key:'Moro Terminations' + this.getValue(this.data.moro_terminations),value:this.data.moro_terminations});
            return result;
        }
    },
    methods: {
        getValue(value){
            return typeof value == "number" ? ` ( ${value} ) ` : "";
        },
        ...mapActions({
            fetchData(dispatch, payload){
                return dispatch(this.storePath + "/fetchData",payload)
            },
        }),
    },
    mounted() {
        this.fetchData(7);
    },
    watch:{
        selectedPeriodIndex(){
            this.fetchData(this.selectedPeriodIndex);
        }
    }
}
</script>

<style>

</style>