<template>
        
        <TemplateSelection v-if="ready" :device_categories="device_categories" />

</template>
<script>

import TemplateSelection from './TemplateSelection.vue';

export default {
   
    components:{
        
        TemplateSelection
    },
    computed:{
           device_categories(){

                let device_categories = this.$store.state.device_categories;
                
               if(/update\/[0-9]/.test(this.$route.path)){
                   let category = this.$route.params.category;
                   return device_categories.filter(cat=>cat.id == category).map(cat=>({value:cat.id,label:cat.name}));

               }

               return device_categories.map(cat=>({value:cat.id,label:cat.name}));
           },
           ready(){
               return this.device_categories.length >0;
           }
            
       

    },
    
    
    mounted(){


    }
  
};
</script>
<style scoped>
.okStyle{
  background-color: rgb(232, 217, 29);
}
.problemSty{
background-color: rgb(242, 57, 59);;
}
.poorSty{
  background-color: rgb(230, 162, 60);
}
</style>
