<template>
  <card class="h-100 vsaas-devicess">
    <div class="row">
      <div class="col-12">
        <h4 class="m-0">Customer Details</h4>
      </div>
    </div>

    <div class="mt-4">
      <div class="row">
        <div class="col-md-4 col-lg-3 col-sm-3 col-6 text-center">
          <inline-svg
            :src="require('@/assets/Icons/hash.svg')"
            width="20"
            height="20"
            class="d-block m-auto"
          />
          <span class="d-block small"><u>Customer ID</u></span>
          <h3>{{nvr_info.customer_id}}</h3>
        </div>
        <div class="col-md-4 col-lg-3 col-sm-3 col-6 text-center">
          <inline-svg
            :src="require('@/assets/Icons/noun_person_2005118.svg')"
            width="20"
            height="20"
            class="d-block m-auto"
          />
          <span class="d-block small"><u>Account num</u></span>
          <h3>204001</h3>
        </div>
        <div class="col-md-4 col-lg-3 col-sm-3 col-6 text-center">
          <inline-svg
            :src="require('@/assets/Icons/nvr.svg')"
            width="20"
            height="20"
            class="d-block m-auto"
          />
          <span class="d-block small"><u>NVR num</u></span>
          <h3>1</h3>
        </div>
        <div class="col-md-4 col-lg-3 col-sm-3 col-6 text-center">
          <inline-svg
            :src="require('@/assets/Icons/camera.svg')"
            width="20"
            height="20"
            class="d-block m-auto"
          />
          <span class="d-block small"><u>Camera num</u></span>
          <h3>{{nvr_info.cameras_num}}</h3>
        </div>
      </div>

      <!--  -->

      <div class="row">
        <div class="col-md-4 col-lg-3 col-sm-3 col-6 text-center">
          <inline-svg
            :src="require('@/assets/Icons/geo-location.svg')"
            width="20"
            height="20"
            class="d-block m-auto"
          />
          <span class="d-block small"><u>Location</u></span>
          <h3>City center</h3>
        </div>
        <div class="col-md-4 col-lg-3 col-sm-3 col-6 text-center">
          <inline-svg
            :src="require('@/assets/Icons/camera-model.svg')"
            width="20"
            height="20"
            class="d-block m-auto"
          />
          <span class="d-block small"><u>Camera Model</u></span>
          <h3>Canon</h3>
        </div>
        <div class="col-md-4 col-lg-3 col-sm-3 col-6 text-center">
          <inline-svg
            :src="require('@/assets/Icons/alarm-history.svg')"
            width="20"
            height="20"
            class="d-block m-auto"
          />
          <span class="d-block small"><u>Last alarm time</u></span>
          <h3>-</h3>
        </div>
        <div class="col-md-4 col-lg-3 col-sm-3 col-6 text-center">
          <inline-svg
            :src="require('@/assets/Icons/calender.svg')"
            width="20"
            height="20"
            class="d-block m-auto"
          />
          <span class="d-block small"><u>Contract expire date</u></span>
          <h3>2022-09-29</h3>
        </div>
      </div>

      <!--  -->

      
    </div>
  </card>
</template>
<script>
import { mapGetters } from "vuex";
import { BaseCheckbox, BaseRadio } from "src/components/index";

export default {
  components: {
    BaseCheckbox,
  },
  computed: {
    ...mapGetters("VsassStore", ["nvr_info"]),
  },
};
</script>
<style scoped>
.count {
  width: 25px;
  height: 25px;
}
</style>
